/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import ErrorsAPI from './errorsAPI';

export default {
  /* Common section should always go first */
  Common: {
    AboutThisProduct: 'About This Product',
    Accept: 'Accept',
    AccessConfiguration: 'Access Configuration',
    AccessRestrictions: 'Access Restrictions',
    Action: 'Action',
    Actions: 'Actions',
    Active: 'Active',
    ActiveDirectory: 'Active Directory',
    ActiveDirectoryCertificate: 'Active Directory Certificate Services',
    ActiveDirectoryLightweightDirectory: 'Active Directory Lightweight Directory Services',
    ActiveDirectoryRightsManagement: 'Active Directory Rights Management Services',
    Add: 'Add',
    AdditionalTools: 'Additional Tools',
    AddressLine1: 'Address Line 1',
    AddressLine2: 'Address Line 2',
    AddValidAddress: 'Please enter a valid Address.',
    AddValidDescription: 'Please enter a valid Description',
    AddValidIPAddressOrFQDN: 'Please enter a valid IP Address or FQDN.',
    AddValidName: 'Please enter a valid Name.',
    Alert: 'Alert',
    All: 'All',
    ALL: 'ALL',
    AllAllowed: 'All Allowed',
    AllApplicationLabels: 'All Application Labels',
    AllApplications: 'All Applications',
    AllBlocked: 'All Blocked',
    AllEnvironmentLabels: 'All Environment Labels',
    AllEnvironments: 'All Environments',
    AllLocationLabels: 'All Location Labels',
    AllLocations: 'All Locations',
    Allow: 'Allow',
    Allowed: 'Allowed',
    AllowedAcrossBoundary: 'Allowed across Boundary',
    AllowItemsRemove: 'These items are available to delete',
    AllPorts: 'All Ports',
    AllPortsProtocols: 'All Ports/Protocols',
    AllRoleLabels: 'All Role Labels',
    AllRoles: 'All Roles',
    AllServices: 'All Services',
    And: 'and',
    Anything: 'Anything',
    API: 'API',
    AppGroup: 'App Group',
    AppGroupMap: 'App Group Map',
    AppGroups: 'App Groups',
    AppGroupsList: 'App Groups List',
    AppGroupsMore: 'App Groups...',
    AppGroupsSettings: 'App Group Configuration',
    Application: 'Application',
    ApplicationDescription: 'The name of the application that the Workload supports. E.g., eCommerce or ERP.',
    Applications: 'Applications',
    Apply: 'Apply',
    AreYouSure: 'Are you sure?',
    Arrow: 'Arrow',
    ArrowDown: 'Arrow down',
    ArrowLeft: 'Arrow left',
    ArrowLeftLong: 'Arrow left long',
    ArrowRight: 'Arrow right',
    ArrowRightLong: 'Arrow right long',
    ArrowUp: 'Arrow up',
    AsCSV: 'as CSV',
    AsJSON: 'as JSON',
    AssignRole: 'Assign Role',
    AssignRoles: 'Assign Roles',
    Attributes: 'Attributes',
    AUSUsers: 'Adaptive User Segmentation (AUS) users',
    Authentication: 'Authentication',
    AuthenticationSettings: 'Authentication Settings',
    AutoRefresh: 'Auto Refresh',
    AzureNSGFlow: 'Azure NSG Flow',
    Back: 'Back',
    BackToList: 'Back to list',
    BlockAction: 'Reject Connections',
    Blocked: 'Blocked',
    BlockedByDenyRules: 'Blocked by Deny Rules',
    Boundary: 'Boundary',
    BrowserGrayWarning: 'Your browser is not formally supported',
    Build: 'Build',
    Busy: 'Busy',
    By: 'by',
    ByEndpoint: 'by {endpoint}',
    Bytes: 'Bytes',
    BytesIn: 'Bytes In',
    BytesOut: 'Bytes Out',
    CABundle: 'Trusted CA Bundle',
    Calculate: 'Calculate',
    Cancel: 'Cancel',
    Canceled: 'Canceled',
    CancelRequested: 'Cancel Requested',
    CannotRemoveItemInUse: 'This item cannot be removed because it is in use.',
    CannotRemoveItemsInUse: 'The selected items cannot be removed because they are in use.',
    Capslock: 'Capslock',
    Certificate: 'Certificate',
    Chain: 'Chain',
    Change: 'Change',
    ChangesHighlighted: 'Changes are highlighted in Green (Addition) and Red (Deletion).',
    ChooseApiExplorer: 'Choose an item from the menu on the left side',
    City: 'City',
    ClassicIllumination: 'Classic Illumination',
    ClassicIlluminationDeprecated:
      'The Illumination Classic feature has been deprecated and is slated for discontinuation in a forthcoming version of the Illumio PCE. For improved network visibility, we strongly recommend using the newly-introduced Map page.',
    ClassicUI: 'Classic UI',
    ClickHere: 'Click here for more information',
    Close: 'Close',
    CloudSecure: 'CloudSecure',
    CloudWorkload: 'Cloud Workload',
    Coexistence: 'Coexistence',
    Collapse: 'Collapse',
    CollapseGroups: 'Collapse all Groups',
    ColumnsHidden: '({count, plural, =1 {# column is} other {# columns are}} hidden)',
    Comment: 'Comment',
    CompanyURL: 'Company URL',
    CompatibilityReport: 'Compatibility Report',
    Completed: 'Completed',
    ConfigurationWizard: 'Configuration Wizard',
    Configure: 'Configure',
    Confirm: 'Confirm',
    ConfirmChange: 'Confirm Change',
    ConfirmPassword: 'Confirm Password',
    ConfirmRemoval: 'Confirm Removal',
    ConnectionFailed: 'Failed to establish connection to the PCE Server',
    Connections: 'Connections',
    ConnectionState: 'Connection State',
    Connectivity: 'Connectivity',
    Console: 'Console',
    ConsumerAndProvider: 'Consumers And Providers',
    ConsumerFqdn: 'Consumer FQDN',
    ConsumerOrProvider: 'Consumers Or Providers',
    ConsumersGlobal: 'Global Consumers',
    ConsumerWorkload: 'Source is a Workload',
    Container: 'Container',
    ContainerHost: 'Container Host',
    Containers: 'Containers',
    ContainerWorkload: 'Container Workload',
    ContainerWorkloads: 'Container Workloads',
    Continue: 'Continue',
    ContinueWaiting: 'Continue waiting',
    Contract: 'Contract',
    Copy: 'Copy',
    CopyToClipboard: 'Copy to clipboard',
    Core: 'Core',
    Corporate: 'Corporate',
    Corrupt: 'Corrupt',
    CountMore: '+{count, number} more',
    CountMoreLabels: '+{count, plural, =1 {# more label} other {# more labels}}',
    CountMoreLabelsAndScopes:
      '+{bothLeft, select, true { {labelsLeft, plural, =0 {} =1 {# label} other {# labels}} and {scopesLeft, plural, =0 {} =1 {# more scope} other {# more scopes}}} false {{labelsLeft, plural, =0 {} =1 {# label} other {# labels}} {scopesLeft, plural, =0 {} =1 {# more scope} other {# more scopes}}}}',
    Country: 'Country',
    Create: 'Create',
    Created: 'Created',
    CreatedBy: 'Created By',
    CreatedOn: 'Created On',
    Critical: 'Critical',
    CSV: 'CSV',
    Custom: 'Custom',
    CustomColumns: 'Customize columns',
    CustomServices: 'Custom Services',
    Dashboard: 'Dashboard',
    DateAtTime: '{when, date, L} at {when, date, HH_mm_ss}',
    DateAtTimeBy: '{when, date, L} at {when, date, HH_mm_ss} by {name}',
    Days: 'Days',
    DaysNumber: '{count, plural, =1 {# Day} other {# Days}}',
    Default: 'Default',
    Delete: 'Delete',
    Deleted: 'Deleted',
    DeletedWorkload: 'Deleted Workload',
    DeletedWorkloadCount: '{count, plural, =1 {# Deleted Workload IP} other {# Deleted Workload IPs}}',
    DeletedWorkloadIps: '{count} Deleted Workload IPs',
    DeletedWorkloads: 'Deleted {count, plural, =1 {Workload} other {Workloads}}',
    Deny: 'Deny',
    Description: 'Description',
    DescriptionIsTooLong: 'Description must not be more than {number} characters',
    Destination: 'Destination',
    DestinationFqdn: 'Destination FQDN',
    DestinationIP: 'Destination IP',
    DestinationPort: 'Destination Port',
    DestinationProcess: 'Destination Process',
    Destinations: 'Destinations',
    DestinationsAndSources: 'Destinations And Sources',
    DestinationsOrSources: 'Destinations Or Sources',
    Details: 'Details',
    DeviceType: 'Device Type',
    Disable: 'Disable',
    Disabled: 'Disabled',
    DisabledLow: 'disabled',
    Discard: 'Discard',
    DiscardUnsavedChanges: 'Discard Unsaved Changes',
    DiscardUnsavedChangesMessage: 'Are you sure you want to discard unsaved changes?',
    DiscardUnsavedChangesToObjectMessage:
      'Are you sure you want to discard unsaved changes to the {object, select, service {Service} iprange {IP Range} label {Label} label_group {Label Group} rule {Rule}}?',
    Dismiss: 'Dismiss',
    DisplayingPartialResults: 'Displaying Partial Results',
    DisplayingPartialResultsMessage:
      'The number of matching connections found in the database exceeds the configured maximum. The displayed connection count is partial and could be misleading.',
    DistinguishedName: 'Distinguished Name',
    Domain: 'Domain',
    Domains: 'Domains',
    DomainTable: 'Unmanaged FQDNs',
    Done: 'Done',
    DoNotAllow: 'Do Not Allow',
    DoNotShowMessage: "Don't show this message again.",
    DontAllow: "Don't Allow",
    DoubleClickToExpand: 'Double-click to expand',
    Down: 'Down',
    Download: 'Download',
    Draft: 'Draft',
    DraftAll: 'All Draft',
    DraftEnforcementBoundary: 'Draft Deny Rule',
    DraftPolicy: 'Draft Policy Decision',
    Duplicate: 'Duplicate',
    Edge: 'Edge',
    Edit: 'Edit',
    Edited: 'Edited',
    Email: 'Email',
    EmailAddress: 'Email Address',
    EmailProvideValid: 'Please provide a valid email',
    EmailUsernameGroup: 'Email/Username/Group Name',
    EmptyRecentFilters: 'No Recent Filter.',
    EmptySavedFilters: 'No Saved Filter. To save a filter set, click the Save icon',
    Enable: 'Enable',
    Enabled: 'Enabled',
    EnabledClassicUIPages: 'Enabled Classic UI pages',
    EnabledLow: 'enabled',
    EndOfData: 'End of Data Set',
    Enforce: 'Enforce',
    Enforced: 'Enforced',
    EnforcedHighDetail: 'Enforced: High Detail',
    EnforcedLowDetail: 'Enforced: Low Detail',
    EnforcedNoDetail: 'Enforced: No Detail',
    Enforcement: 'Enforcement',
    EnforcementStop: 'Stop Enforcement',
    EntitiesIsNot: 'Workload is not',
    EntitiesIsNotMore: 'Workload is not...',
    EntitiesMore: 'Workload...',
    EntityState: 'Entity State',
    EntriesCount: '{current, number} of {all, number} Entries',
    Environment: 'Environment',
    EnvironmentDescription: 'Stage of application development. E.g., QA, Staging, Production.',
    Environments: 'Environments',
    Error: 'Error',
    ErrorCount: '{count, plural, =1 {# Error} other {# Errors}}',
    Errors: 'Errors',
    Event: 'Event',
    Events: 'Events',
    EventSettings: 'Event Settings',
    Examples: 'Examples',
    Exclude: 'Exclude',
    Exclusive: 'Exclusive',
    Expand: 'Expand',
    Expired: 'Expired',
    ExpiresAt: 'Expires At',
    ExpiresIn: 'Expires In',
    Explore: 'Explore',
    Explorer: 'Explorer',
    ExplorerDeprecated:
      'The Explorer feature is now deprecated and is scheduled for removal in an upcoming version of Illumio PCE. We recommend transitioning to the Traffic page, which offers enhanced functionality for network flow inspection.',
    Export: 'Export',
    ExportAll: 'Export All',
    ExportFiltered: 'Export Filtered',
    ExportJSON: 'Export to JSON',
    ExportToCsv: 'Export to CSV',
    ExposureScore: 'Exposure Score',
    External: 'External',
    ExternalConnections: 'External Connections',
    ExternalNonCorporate: 'External (Non-Corporate)',
    ExternalReference: 'External Data Reference',
    ExternalSet: 'External Data Set',
    Failed: 'Failed',
    Failure: 'Failure',
    False: 'False',
    Filter: 'Filter',
    FilterByTime: 'Filter by Time',
    FilterInvalidResolveActors:
      'Searching for all existing rules in combination with the disabled status is not allowed.',
    FilterLimitExceeded:
      'The limit of elements in the filter has been exceeded. The maximum number of elements in the filter is {limit}.',
    FilterView: 'Select properties to filter view',
    Finish: 'Finish',
    FlipHorizontal: 'Flip Horizontal',
    FlowCollectionFilters: 'Flow Collection',
    Flows: 'Flows',
    Folder: 'Folder',
    Formats: 'Formats',
    FormError: 'Correct the errors in red before saving',
    Fqdns: 'FQDNs',
    FullmapSelectModeAlert:
      'Dragging the mouse is used only to select traffic links in full map. To reposition a group, use the Hand Tool',
    General: 'General',
    Generate: 'Generate',
    GeneratedAt: 'Generated At',
    GeneratedAtTime: 'Generated at {time}',
    GeneratedBy: 'Generated By',
    GeneratedByAgent: 'Generated By Agent',
    GeneratedByUser: 'Generated By User',
    GenerateKey: 'Generate Key',
    GenerateSupportReport: 'Generate Support Bundle',
    Generating: 'Generating...',
    GetStarted: 'Get Started',
    Global: 'Global',
    Go: 'Go',
    Grab: 'Grab',
    Group: 'Group',
    GroupDashboard: 'Group Dashboard',
    GroupHelpTextSubtitle:
      'Displays a list of groups, admin groups (identifiable with a blue badge), provisioning status, and the number of endpoints in a group.',
    Groups: 'Groups',
    GroupsByCount: '{count, plural, =1 {Group} other {Groups}}',
    GroupsCount: '{count, plural, =1 {One Group} other {# Groups}}',
    GroupWizard: 'Group Wizard',
    GroupWizardHelpTextSubtitle:
      'Helps create groups, designate admin groups, indicate which admin group can communicate with a target group, and provides an option to encrypt traffic.',
    Healthy: 'Healthy',
    Help: 'Help',
    Hidden: 'Hidden',
    Hide: 'Hide',
    High: 'High',
    Home: 'Home',
    HostAttributes: 'Host Attributes',
    Hostname: 'Hostname',
    Hours: 'Hours',
    HoursNumber: '{count, plural, =1 {# Hour} other {# Hours}}',
    Href: 'Href',
    ID: 'ID',
    Idle: 'Idle',
    IdleControl: 'VEN does not take control of the host firewall',
    Illuminate: 'Illuminate',
    Illuminated: 'Illuminated',
    Illumination: 'Illumination',
    IlluminationMap: 'Illumination Map',
    IlluminationPlus: 'Illumination Plus',
    Import: 'Import',
    Inbound: 'Inbound',
    Include: 'Include',
    IncreaseTrafficUpdateRate: 'Increase Traffic Update Rate',
    IncreaseTrafficUpdateRateMessage:
      'Increase traffic flow frequency to {minutes, plural, =1 {one minute} other {# minutes}}. After {minutes, plural, =1 {one minute} other {# minutes}}, traffic flow information is sent to the PCE at regular intervals.',
    IncreaseTrafficUpdateRateTooltip:
      'Increase the rate of traffic flow updates from Workloads for the next {minutes, plural, =1 {one minute} other {# minutes}}',
    Info: 'Info',
    Information: 'Information',
    InProgress: 'In Progress',
    Installed: 'Installed',
    InSync: 'In Sync',
    Internet: 'Internet',
    InUse: 'In use',
    InUseBy: 'In use by',
    InUseByBlockAction: 'In Use By Reject Connections',
    InUseByContainerInheritHostPolicy: 'In Use By Container Inherit Host Policy',
    InUseByContainerWorkloads: 'In Use By Container Workloads',
    InUseByContainerWorkloadsProfiles: 'In Use By Container Workloads Profiles',
    InUseByContent: 'In Use by {content}',
    InUseByFirewallCoexistence: 'In Use By Firewall Coexistence',
    InUseByIPForwarding: 'In Use By IP Forwarding',
    InUseByLabelGroups: 'In Use By Label Groups',
    InUseByLoopbackInterfaces: 'In Use By Loopback Interfaces',
    InUseByPairingProfiles: 'In Use By Pairing Profiles',
    InUseByRBAC: 'In Use By RBAC',
    InUseByRulesets: 'In Use By Rulesets',
    InUseByStaticPolicy: 'In Use By Static Policy',
    InUseByVirtualServer: 'In Use By Virtual Servers',
    InUseByVirtualServices: 'In Use By Virtual Services',
    InUseByWorkloads: 'In Use By Workloads',
    InvalidDay: 'Invalid day',
    InvalidPortRangeMessage: 'Invalid Port Range \n E.g. 71-74, 80-90 TCP',
    InvalidProcessNameMessage: 'Invalid Process Name. E.g. c:\\windows\\myprocess.exe',
    InvalidProtocolMessage: 'Invalid Port and/or Protocol \n E.g. 22, 514 UDP, ICMP',
    InvalidWinServiceMessage: 'Invalid Windows Service Name',
    Invite: 'Invite',
    Invited: 'Invited',
    IPAddress: 'IP Address',
    IPAddressesMore: 'IP Addresses...',
    IPAddressHostname: 'IP Address or Hostname',
    IPAddressInvalid: 'Invalid IP Address',
    IPAddressOptions: 'IPs, IP Ranges, FQDNs, CIDR Blocks, Any IP (0.0.0.0/0 and ::/0)',
    IPAddressOrCIDRBlock: 'IP Address/CIDR Block',
    IPAddressPublic: 'Public IP Address',
    IPList: 'IP List',
    IPLists: 'IP Lists',
    IPListsCount: '{count, plural, =1 {# IP List} other {# IP Lists}}',
    IPOverrides: 'IP Overrides',
    IPRange: 'IP Range',
    IpTables: 'iptables',
    IsNot: 'is not',
    IsRequired: '{label} is required',
    Items: 'Items',
    ItemsCannotRemove: 'These items cannot be removed:',
    JSON: 'JSON',
    JSONResponse: 'JSON Response for: {selected}',
    Kubelink: 'Kubelink',
    KubernetesAttributes: 'Kubernetes Attributes',
    KubernetesLabels: 'Kubernetes Labels',
    KubernetesWorkloads: 'Kubernetes Workloads',
    Label: 'Label',
    Labels: 'Labels',
    LabelSettings: 'Label Settings',
    LastModified: 'Last Modified',
    LastModifiedAt: 'Last Modified At',
    LastModifiedBy: 'Last Modified By',
    LastModifiedOn: 'Last Modified On',
    LastPairingKeyGenerated: 'Last Pairing Key Generated',
    LastReportUpdate: 'Last Report Update',
    LastUsed: 'Last Used',
    LastVENPaired: 'Last VEN Paired',
    LDAP: 'LDAP',
    LDAPS: 'LDAPS',
    LearnMore: 'Learn More',
    Leave: 'Leave',
    Limited: 'Limited',
    Link: 'Link',
    Linked: 'Linked',
    Loading: 'Loading',
    Local: 'Local',
    Location: 'Location',
    LocationDescription: 'Physical or geographic location of the Workload',
    Locations: 'Locations',
    Lock: 'Lock',
    Locked: 'Locked',
    LogIn: 'Log In',
    Low: 'Low',
    MachineAuthentication: 'Machine Authentication',
    MachineId: 'Machine ID',
    MainDropdownMenu: 'Main Dropdown Menu',
    Managed: 'Managed',
    Management: 'Management',
    Map: 'Map',
    MatchingServices: '{count, plural, =1 {# Matching Service} other {# Matching Services}}',
    MatchingServicesOnly: 'Matching Services',
    MaxlengthError:
      '{label, select, false {Value} other {{label}}} must be less than or equal to {maxlength, plural, =1 {# character} other {# characters}}',
    Medium: 'Medium',
    Members: 'Members',
    Minutes: 'Minutes',
    MinutesNumber: '{count, plural, =1 {# Minute} other {# Minutes}}',
    Missing: 'Missing',
    Mode: 'Mode',
    ModificationNotAllowed: 'Modification Not Allowed',
    Modify: 'Modify',
    MonthsNumber: '{count, plural, =1 {# Month} other {# Months}}',
    More: 'more',
    Move: 'Move',
    MoveModeAlert:
      'The Hand Tool is used only to pan the map. To select or reposition an object, use the Selection Tool.',
    MoveTool: 'Hand Tool',
    MoveToolDescription: 'Drag to pan the map view. Shortcut: Press space bar to enable.',
    MustBeAnInteger: '{name} must be an integer',
    MustClearDeletedUserGroups: 'Deleted User Groups must be cleared to enable save.',
    NA: 'N/A',
    Name: 'Name',
    NameExist: 'Name {value} already exists',
    NameIsNotAllowed: 'That name is not allowed, please use another name',
    NameIsTooLong: 'Name must not be more than 255 characters',
    NameIsTooShort: 'Name must be atleast 2 characters',
    Namespace: 'Namespace',
    NamespaceIsTooLong: '{namespace} must not be more than 255 characters',
    NamespaceOrProject: 'Namespace/Project',
    NavigateUp: 'Navigate Up',
    NetworkInterfaces: 'Network Interfaces',
    NetworkNameInvalid: 'Invalid Network Name',
    NetworkProfile: 'Network Profile',
    Never: 'Never',
    NeverExpires: 'Never expires',
    New: 'New',
    NewUI: 'New UI',
    Next: 'Next',
    No: 'No',
    NoAppGroup: 'No App Group',
    NoApplication: 'No Application',
    NoApplicationLabel: 'No Application Label',
    NoData: 'No data to display',
    NoDataContainerCluster: 'No Container Clusters to display',
    NoDataContainerWorkloadProfiles: 'No Container Workload Profiles to display',
    NoDataContainerWorkloads: 'No Container Workloads to display',
    NoDataKubernetesWorkloads: 'No Kubernetes Workloads to display',
    NoDataLoadBalancer: 'No Load Balancers to display',
    NoDataNetwork: 'No Networks to display',
    NoDataServiceBackends: 'No Service Backends to display',
    NoDataWorkload: 'No Workloads to display',
    Node: 'Node',
    NoEnvironment: 'No Environment',
    NoEnvironmentLabel: 'No Environment Label',
    NoInternetConnectionAvailable: 'No internet connection available',
    NoLabel: 'No Label',
    NoLabels: 'No {name} Labels',
    NoLocation: 'No Location',
    NoLocationLabel: 'No Location Label',
    NoMatchDataContainerClusters: 'No matching Container Workload Profiles to display',
    NoMatchDataContainerWorkloads: 'No matching Container Workloads to display',
    NoMatchDataKubernetesWorkloads: 'No matching Kubernetes Workloads to display',
    NoMatchRules: 'No matching Rules',
    NoMatchServices: 'No matching Services to display',
    NoMembersToDisplay: 'No Members to display',
    None: 'None',
    NoneSelected: 'None selected',
    NoObjectSelected: 'No objects were selected',
    NoPeers: 'No Peers',
    NoResultsFound: 'No results found',
    NoRole: 'No Role',
    NoRoleLabel: 'No Role Label',
    NoRules: 'No rules to display',
    NoSavedFilters: '<span class="{className}"> None Saved<br/>To save a filter set, click the Save icon.</span>',
    NoServicesData: 'No Services to display',
    NotApplicable: 'Not Applicable',
    NotAvailable: 'Not available',
    NotConsumed: 'Not Consumed',
    Note: 'Note',
    NotEnforced: 'Not Enforced',
    Notification: 'Notification',
    Notifications: 'Notifications',
    NotInUse: 'Not in use',
    NotProvided: 'Destination not in Group',
    NotRecommended: 'Not Recommended',
    NotSecure: 'Not Secure',
    NotYetConnected: 'Not yet connected',
    NumberSign: '#',
    NumOfNum: '{low, number} of {high, number}',
    Object: 'Object',
    Off: 'Off',
    OK: 'OK',
    OkToRemoveRemovable: 'If you click OK, only the removable items will be removed.',
    On: 'On',
    Online: 'Online',
    OpenLDAP: 'OpenLDAP',
    OpenShift: 'OpenShift',
    Optional: 'Optional',
    OptionalDescription: 'Optional Description',
    Options: 'Options',
    Organization: 'Organization',
    OrganizationEvent: 'Organization Event',
    OrganizationEvents: 'Organization Events',
    OS: 'OS',
    OSFamily: 'OS Family',
    Other: 'Other',
    Outbound: 'Outbound',
    OutOfSync: 'Out of Sync (Last Connection Time: {when, date, L_HH_mm_ss})',
    Override: 'Override',
    Packages: 'Packages',
    PageFirst: 'First page',
    PageLast: 'Last page',
    Pagination:
      '<strong>{notZero, select, true {{min, number} – } false {}}{max, number}</strong> of <strong>{total, number}{plus, select, true {+} false {}}</strong> {type, select, label {Label Set Connections} connections {Connections} matched {Matched} total {Total}}',
    PaginationNumberString:
      '<strong>{notZero, select, true {{min, number} – } false {}}{max, number}</strong> of <strong>{total, number}{plus, select, true {+} false {}}</strong>',
    PaginationTypeString:
      '{type, select, label {Label Set Connections} connections {Connections} matched {Matched} total {Total}}',
    Paired: 'Paired',
    PairedOn: 'Paired On',
    PairingKey: 'Pairing Key',
    Password: 'Password',
    PasswordChange: 'Change Password',
    Patents: 'Patents',
    Pause: 'Pause',
    PCE: 'PCE',
    PCEMaxDisplay:
      'The PCE web console only displays a maximum of {count, number} entries in a list. To see more results, apply a filter to the list.',
    PCEUnavailable: 'PCE is currently unavailable. Wait or try again after some time.',
    PCEUpgradeRulesetName: 'Illumio PCE Upgrade - Non-Corporate Endpoint Policies',
    Pending: 'Pending',
    PerPageCount: '{count, number} per page',
    Play: 'Play',
    Policies: 'Policies',
    PolicyData: 'Policy Data',
    PolicyDecision: 'Policy Decision',
    PoolTarget: 'Pool Target',
    PortAndOrProtocol: 'Port and/or Protocol',
    Ports: 'Port(s)',
    PostalCode: 'Postal Code',
    PotentiallyBlockByDenyRules: 'Potentially Blocked by Deny Rules',
    PotentiallyBlocked: 'Potentially Blocked',
    Presets: 'Presets',
    Prev: 'Prev',
    Preview: 'Preview',
    Previous: 'Previous',
    PrivacyPolicy: 'Privacy Policy',
    PrivateAddress: 'Private Address',
    PrivateAddresses: 'Private Addresses',
    Process: 'Process',
    Processes: 'Processes',
    Product: 'Product',
    ProductDemo: 'Watch Product Demo',
    ProductVersion: 'Product Version',
    Project: 'Project',
    Protect: 'Protect',
    Protected: 'Protected',
    ProtectedCount: '{count} Protected',
    ProtectionReady: 'Protection Ready',
    ProtectionReadyCount: '{count} Protection Ready',
    Protocol: 'Protocol',
    ProtocolInvalid: 'Invalid protocol',
    ProtocolMissing: 'Missing protocol',
    ProvideAName: 'Provide a name',
    ProvideName: 'Please provide your name',
    ProviderAndConsumer: 'Providers And Consumers',
    ProviderFqdn: 'Provider FQDN',
    ProviderOrConsumer: 'Providers Or Consumers',
    ProviderPort: 'Provider Port',
    ProviderWorkload: 'Destination is a Workload',
    Provision: 'Provision',
    PublicAddress: 'Public Address',
    PublicAddresses: 'Public Addresses',
    Radios: 'Radios',
    RBAC: 'RBAC',
    ReadOnly: 'Read Only',
    Recalculate: 'Recalculate',
    RecentlyUsed: 'Recently Used {name}',
    Recommendation: 'Recommendation',
    Recommended: 'Recommended',
    Refresh: 'Refresh',
    Regenerate: 'Regenerate',
    Reload: 'Reload',
    Remove: 'Remove',
    Removed: 'Removed',
    RemoveGroup: 'Remove Group',
    Report: 'Report',
    Reported: 'Reported',
    ReportedEnforcementBoundary: 'Reported Deny Rule',
    ReportedPolicy: 'Reported Policy Decision',
    ReportPolicy: 'Report Policy',
    Reports: 'Reports',
    Required: 'Required',
    Reset: 'Reset',
    ResetColumnSelection: 'Reset to default columns',
    ResetLayout: 'Reset Layout',
    ResetSelsectionAndSortingBy: 'Reset to default columns and default sorting (by {name})',
    ResetSortingBy: 'Reset to default sorting{name, select, undefined {} other {(by {name})}}',
    ResetToDefault: 'Reset to Default',
    ResolveDomainsAlert:
      'FQDNs are resolved using reverse DNS from the PCE. Accuracy of results may vary depending on the relative locations of the PCE and the target device.',
    Resource: 'Resource',
    ResourceType: 'Resource Type',
    Restore: 'Restore',
    Retry: 'Retry',
    Reverse: 'Reverse',
    Revert: 'Revert',
    RFC1918: 'RFC 1918',
    RightClickForOptions: 'Right-click for options',
    Role: 'Role',
    RoleDescription: 'The function the Workload plays in the application. E.g., Database.',
    Roles: 'Roles',
    RoleUnassigned: 'Role not assigned',
    Rules: 'Rules',
    RulesCount: '{count, plural, =1 {# Rule} other {# Rules}}',
    RuleSearch: 'Rule Search',
    Ruleset: 'Ruleset',
    Rulesets: 'Rulesets',
    RulesetsCount: '{count, plural, =1 {# Ruleset} other {# Rulesets}}',
    Run: 'Run',
    Running: 'Running',
    SAML: 'SAML',
    Save: 'Save',
    SaveAndApply: 'Save and Apply',
    SaveAndProvision: 'Save and Provision',
    SaveAs: 'Save As',
    SaveChangesConfirmation: 'Are you sure you want to save the changes?',
    SaveChangesTitle: 'Save Changes',
    Saving: 'Saving',
    Scope: 'Scope',
    Scoped: 'Scoped',
    Scopes: 'Scopes',
    Search: 'Search',
    Seconds: 'Seconds',
    SecondsNumber: '{count, plural, =1 {# Second} other {# Seconds}}',
    Secret: 'Secret',
    Secure: 'Secure',
    SecureConnect: 'SecureConnect',
    SecureConnectGateway: 'SecureConnect Gateway',
    SecureConnectGateways: 'Secure Connect Gateways',
    SecureConnectGatewaysCount: '{count, plural, =1 {# Secure Connect Gateway} other {# Secure Connect Gateways}}',
    Security: 'Security',
    SecuritySettings: 'Security Settings',
    Select: 'Select',
    SelectAll: 'Select All',
    SelectConsumers: 'Select Consumers',
    Selected: 'Selected',
    SelectedCount: '{count, plural, other {<span class="{className}">#</span>&nbsp;Selected}}',
    SelectedNumber: '<span class="{className}">{count, number}</span>',
    SelectionTool: 'Selection Tool',
    SelectionToolDescription: 'Click to select objects. Drag to move objects.',
    SelectLabels: 'Select Labels',
    SelectLabelsLabelGroups: 'Select Labels And Label Groups',
    SelectModeAlert: 'Dragging the mouse is used only to select traffic links. To pan the map, use the Hand Tool.',
    SelectModeAlertShortcut:
      '<span class="{className}">Shortcut: </span>Press and hold the space bar to enable the Hand Tool.',
    SelectProtocol: 'Select a Protocol',
    SelectProviders: 'Select Providers',
    SelectScope: 'Select Scope',
    Server: 'Server',
    Servers: 'Servers',
    ServersAndEndpoints: 'Servers & Endpoints',
    Service: 'Service',
    ServiceAccounts: 'Service Accounts',
    ServiceBackends: 'Service Backends',
    ServiceBindings: 'Service Bindings',
    ServiceIs: 'Service is',
    ServiceIsNot: 'Service is not',
    ServiceIsNotMore: 'Service is not...',
    ServiceMore: 'Service...',
    ServiceNotRunning: 'Service not running',
    ServicePort: 'Service/Port',
    ServiceRunning: 'Service running',
    Services: 'Services',
    ServicesCount: '{count, plural, =1 {# Service} other {# Services}}',
    ServiceSelection: {
      AddNewService: 'Add New Service',
    },
    ServiceTag: '{multiple, select, false {Service Tag} true {Service Tags}}',
    Setting: 'Setting',
    Settings: 'Settings',
    SettingsCount: '{count, plural, =1 {# Setting} other {# Settings}}',
    Severity: 'Severity',
    Show: 'Show',
    ShowAllMembers: 'Show All Members',
    ShowLess: 'Show less',
    ShowTrafficMembers: 'Show Members with Traffic',
    SideBySideDiff: 'Side-by-side diff',
    Sort: 'Sort',
    SortedBy: 'Sorted by {column}',
    Source: 'Source',
    Sourced: 'Sourced',
    SourceFqdn: 'Source FQDN',
    SourceProcess: 'Source Process',
    Sources: 'Sources',
    SourcesAndDestinations: 'Sources And Destinations',
    SourcesOrDestinations: 'Sources Or Destinations',
    SourceToTarget: '{source}-To-{target}',
    Staged: 'Staged',
    Standard: 'Standard',
    StandardServices: 'Standard Services',
    Start: 'Start',
    STARTTLS: 'STARTTLS',
    State: 'State',
    Stateless: 'Stateless',
    StateProvinceTerritory: 'State/Province/Territory',
    Static: 'Static',
    StaticPolicy: 'Static Policy',
    Status: 'Status',
    Step: 'Step {step}',
    Steps: {
      SelectServices: 'Select Services',
    },
    Stop: 'Stop',
    Stopped: 'Stopped',
    Style: 'Style',
    SubDropdownMenu: 'Sub Dropdown Menu',
    Success: 'Success',
    Summary: 'Summary',
    SuperClusterBlockedTrafficDataWarning:
      'This page only shows blocked traffic events from Workloads that have been paired with this PCE',
    SuperClusterPartialDataWarning: 'Partial Map based on data for this PCE only',
    Support: 'Support',
    SupportPortal: 'Support Portal',
    System: 'System',
    SystemInitializing: 'System initializing',
    Terms: 'Terms',
    TermsAndAgreements: 'Terms and Agreements',
    Test: 'Test',
    ThirdParty: 'Third Party',
    Timestamp: 'Timestamp',
    Timezone: 'Time Zone',
    ToClose: 'to close',
    Today: 'Today',
    ToNavigate: 'to navigate',
    ToSelect: 'to select',
    Total: 'Total',
    TotalFlows: 'Total Flows',
    TotalNumber: '<span class="{className}">{count, number}</span> Total',
    TotalPCEs: 'Total PCEs',
    TotalVENs: 'Total VENs',
    Traffic: 'Traffic',
    TrafficType: 'Traffic Type',
    TrafficView: 'Traffic View',
    TransmissionMode: 'Transmission Mode',
    Troubleshoot: 'Troubleshoot',
    Troubleshooting: 'Troubleshooting',
    True: 'True',
    TurnOff: 'Turn Off',
    TurnOn: 'Turn On',
    Type: 'Type',
    Types: 'Types',
    Unavailable: 'Unavailable',
    Unchanged: 'Unchanged',
    Undo: 'Undo',
    Unidentified: 'Unidentified',
    UnifiedDiff: 'Unified diff',
    Uninstalled: 'Uninstalled',
    Unknown: 'Unknown',
    Unlimited: 'Unlimited',
    Unlink: 'Unlink',
    Unlock: 'Unlock',
    Unlocked: 'Unlocked',
    Unmanage: 'Unmanage',
    Unmanaged: 'Unmanaged',
    UnmanagedIPAddresses: 'Unmanaged IP Addresses',
    UnmanagedIPs: 'Unmanaged IPs',
    UnmanagedWorkload: 'Unmanaged Workload',
    Unpair: 'Unpair',
    UnsavedChanges: 'You have unsaved changes.',
    UnsavedPendingWarning: 'Leaving this page will discard pending changes.',
    Up: 'Up',
    Updated: 'Updated',
    UpdatedAt: 'Updated at',
    UpdatedBy: 'Updated By',
    Upgrade: 'Upgrade',
    Upload: 'Upload',
    UserEmail: 'Email Address/Username',
    UserGroup: 'User Group',
    UserGroups: 'User Groups',
    Username: 'Username',
    UserProfile: 'User Profile',
    UsesVirtualServices: 'Uses Virtual Services only',
    UsesVirtualServicesWorkloads: 'Uses Virtual Services and Workloads',
    UseVirtualService: 'Use port/protocol in the Virtual Service',
    UUID: 'UUID',
    ValidationError: 'Validation Error',
    Value: 'Value',
    VEN: 'VEN',
    VenDashboard: 'VEN Dashboard',
    VENS: 'VENs',
    VENStatistics: 'VEN Statistics',
    Verify: 'Verify',
    VerifyTLS: 'Verify TLS',
    Version: 'Version',
    Video: 'Video',
    View: 'View',
    ViewDraft: 'View Draft',
    ViewMore: 'View more',
    ViewScope: 'View Scope',
    VIPPort: 'VIP & Port',
    VirtualServer: 'Virtual Server',
    VirtualServers: 'Virtual Servers',
    VirtualServersCount: '{count, plural, =1 {# Virtual Server} other {# Virtual Servers}}',
    VirtualService: 'Virtual Service',
    VirtualServices: 'Virtual Services',
    VirtualServicesCount: '{count, plural, =1 {# Virtual Service} other {# Virtual Services}}',
    Visibility: 'Visibility',
    VisibilityOnly: 'Visibility Only',
    Visible: 'Visible',
    Vulnerabilities: 'Vulnerabilities',
    Vulnerability: 'Vulnerability',
    VulnerabilityData: 'Vulnerability Data',
    Warning: 'Warning',
    WarningCount: '{count, plural, =1 {# Warning} other {# Warnings}}',
    WelcomeToIllumio: 'Welcome to Illumio.',
    WhatsNew: "What's New?",
    WinService: 'Windows Services',
    WinServiceMessage: 'E.g. myprocess',
    Workload: 'Workload',
    WorkloadManagement: 'Workloads and VENs',
    Workloads: 'Workloads',
    Xpress: 'Xpress',
    XpressDashboard: 'Xpress Dashboard',
    Yes: 'Yes',
    ZIPCode: 'ZIP Code',
    ZoomIn: 'Zoom in',
    ZoomOut: 'Zoom out',
    ZoomToFit: 'Zoom to fit',
  },
  /* eslint-disable-next-line sort-keys -- Common section above should always go first */
  AccessRestriction: {
    AccessRestrictionDelete: 'Remove {count, plural, =1 {Access Restriction} other {Access Restrictions}}',
    AccessRestrictionDeleteConfirm:
      'Are you sure you want to remove {count, plural, =1 {this Access Restriction} other {# selected Access Restrictions}}?',
    AccessRestrictionTitle: 'Access Restriction',
    AddNew: 'Add a new Access Restriction',
    AddValidRestriction: 'Add a valid Access Restriction',
    APIKey: 'API Key',
    Errors: {
      APIKey: 'Unable to Edit API Key',
      Create: 'Unable to Create Access Restriction',
      Edit: 'Unable to Edit Access Restriction',
      SuperclusterMember:
        'API keys cannot be edited from a Super Cluster Member. Please log out and log into the Super Cluster Leader',
    },
    List: {
      Message: {
        RemoveSuccess:
          '{count, plural, =1 {Access Restriction has} other {# Access Restrictions have}} been successfully removed',
      },
    },
    MaxIPAddresses: 'Maximum 8 IPv4 Addresses or CIDR Blocks',
    NoData: 'No Data to display',
    RestrictionAppliedTo: 'Restriction Applied To',
    TypeRestrictionDescription: 'Type Access Restriction Description',
    TypeRestrictionName: 'Type Access Restriction Name',
    UserSession: 'User Session',
    UserSessionAndAPIKey: 'User Session and API Key',
    Warning: {
      FirstMessage: 'Assigning misconfigured access restrictions can prevent users from accessing the PCE',
      SecondMessage:
        'Assigning misconfigured access restrictions can prevent users from accessing the PCE. Are you sure you want to continue?',
    },
  },
  Antman: {
    AllowRuleCreation: 'This will create {count} new allow {count, plural, =1 {rule} other {rules}}',
    AppGroups: {
      ContainerWorkloads: 'App Group Pods',
    },
    AutoRefresh: 'Refresh the {entity} list every 30 seconds',
    BlockedTraffic: {
      BlockedOnConsumer: 'Traffic is blocked by the Source',
      BlockedOnProvider: 'Traffic is blocked by the Destination',
      List: {
        ByTheConsumer: 'By the Source',
        ByTheProvider: 'By the Destination',
      },
      PotentiallyBlockedOnConsumer: 'Traffic is simulated block by the Source',
      PotentiallyBlockedOnProvider: 'Traffic is simulated block by the Destination',
    },
    Common: {
      AdvancedMode: {
        Message: 'Includes Essential Mode + advanced customizations and controls for power users',
        Title: 'Advanced Mode',
      },
      AgentReport: 'Agent Report',
      Analyzing: 'Analyzing ...',
      ConsumersGlobal: 'Global Sources',
      ContainerWorkload: 'Pod',
      ContainerWorkloads: 'Pods',
      ErrorMessageToContactSupport:
        '{errorMessage}. If the issue persists, contact Illumio Support for more information.',
      EssentialMode: {
        Message: 'Simple, quick, turn-key solutions for protecting your workloads and more',
        Title: 'Essential Mode (Recommended)',
      },
      FeatureMode: 'Feature Mode',
      InUseByContainerWorkloads: 'In Use By Pods',
      InUseByContainerWorkloadsProfiles: 'In Use By Namespaces',
      Next: 'Next >',
      NoDataContainerCluster: 'No Kubernetes Clusters to display',
      NoDataContainerWorkloadProfiles: 'No Namespaces to display',
      NoDataContainerWorkloads: 'No Pods to display',
      NoMatchDataContainerClusters: 'No matching Namespaces to display',
      NoMatchDataContainerWorkloads: 'No matching Pods to display',
      PortsAndProtocols: 'Ports and Protocols',
      PotentiallyBlocked: 'Simulated Block',
      PotentiallyBlockedByBoundary: 'Simulated Block By Boundary',
      SelectConsumers: 'Select Sources',
      SelectForPolicy: 'Select for Policy',
      SelectProviders: 'Select Destinations',
      ServerRoles: 'Server Roles',
      Unlabeled: 'Unlabeled',
      UnlabeledCount: '{count} Unlabeled',
      WarningDeletion: 'This {item} is used by onboarding wizards and cannot be removed',
      WarningEdit: 'This {item} is used by onboarding wizards and cannot be edited',
      XpressPreferences: 'Xpress Preferences',
    },
    Connectivity: {
      ConsumerError: 'Source error',
      ConsumerMissing: 'Missing Source',
      PortAndProtocol: 'Destination Port and Protocol',
      ProviderError: 'Destination error',
      ProviderProvider: 'Missing Destination',
      SelectWorkloadContainerWorkloadOrIP: 'Select Workload, Pod, or IP Address',
      VerifyRulesExist: 'Verify if Rules exist that allow connections between Workloads, Pods, or IP addresses',
    },
    ContainerClusters: {
      AddNew: 'Add a new Kubernetes Cluster',
      AddNewContainerWorkloadProfile: 'Add a new Namespace',
      CannotDelete:
        'The following {count, plural, =1 {Kubernetes Cluster is} other {# Kubernetes Clusters are}} still online. Please stop Kubelink before deleting the cluster from the PCE.',
      CannotDeleteContainerWorkloadProfile:
        'The following {count, plural, =1 {Namespace is} other {# Namespaces are}} cannot be deleted.',
      ContainerWorkloadProfiles: 'Namespaces',
      ContainerWorkloadProfilesAdd: 'Add Namespace',
      ContainerWorkloadProfilesMessage: 'Editing the template does not affect existing Namespaces.',
      ContainerWorkloadProfilesUnmanaged:
        'Namespaces will be created automatically for discovered {isOpenshift, select, false {Namespaces} true {Projects}}',
      ContainerWorkloadProfileTemplate: 'Namespace - Default Settings',
      CreatedByContainerCluster: 'Created by Kubernetes Cluster',
      Delete: 'Remove {count, plural, =1 {Kubernetes Cluster} other {# Kubernetes Clusters}}',
      DeleteConfirm:
        'Are you sure you want to remove the {count, plural, =1 {Kubernetes Cluster} other {# Kubernetes Clusters}}?',
      DeleteContainerWorkloadProfile: 'Remove {count, plural, =1 {Namespace} other {# Namespaces}}',
      DeleteContainerWorkloadProfileConfirm:
        'Are you sure you want to remove the {count, plural, =1 {Namespace} other {# Namespaces}}?',
      DeletedContainerWorkloadProfileSuccessfully:
        'The following {count, plural, =1 {Namespace has} other {#  Namespaces have}} been successfully deleted',
      DeletedSuccessfully:
        'The following {count, plural, =1 {Kubernetes Cluster has} other {# Kubernetes Clusters have}} been successfully deleted',
      DuplicateMachineIDsError:
        'There are duplicate machine IDs among your cluster nodes. Kubernetes Cluster functionality will be limited until this issue is resolved.',
      UndeletedVirtualServices: 'Created by Deleted Kubernetes Cluster',
      UseContainerAnnotations: 'Use Kubernetes Annotations',
    },
    ContainerWorkloads: {
      All: 'All Pods',
      ByCount: '{count, plural, =1 {Pod} other {Pods}}',
      Total: 'Total Pods',
      View: 'View Pods',
      WithCount: '{count} {count, plural, =1 {Pod} other {Pods}}',
    },
    CoreServices: {
      AcceptedCoreService: '{name} is hosting the {service} core service',
      AcceptedError: 'An unexpected error occurred. The server could not be accepted as hosting the Core Service',
      AcceptedNotification:
        'Edit Labels to distinguish workloads running core services from other workloads. Click "Reject" if a server no longer provides the core service.',
      RejectAcceptedError: 'An unexpected error occurred. The server could not be rejected as hosting the Core Service',
      RejectedCoreService: '{name} is no longer recognized as hosting the {service} core service',
    },
    Dashboard: {
      BlockedOrPotentiallyBlockedFlow: 'Blocked / Simulated Block Flow',
    },
    DenyRuleCreation: 'This will create {count} new deny {count, plural, =1 {rule} other {rules}}',
    Endpoints: {
      Add: 'Add Endpoints',
      Admin: 'Admin Endpoints',
      CopyScriptToEndpoints: 'Copy the agent installation script and run it on your endpoints as a privileged user',
      Endpoint: 'Endpoint',
      Endpoints: 'Endpoints',
      EndpointsWillAppearBelow: 'Endpoints will appear below as you add them to the network',
      Enforcement: {
        ConfirmTitle: 'Confirm Endpoint Enforcement Change',
        EmptyMessage: 'No endpoints to enforce',
        Filter: 'Filter by endpoint name',
        Subtitle:
          'Select an endpoint type to review traffic from the past 24 hours and change the enforcement mode for one or more endpoints',
        Title: 'Endpoint Enforcement',
      },
      InstallAgents: "Let's start by installing agents on your endpoints",
      NoneFound: 'No endpoints found',
      PairEndpointText: 'Pair Endpoint and Install VEN',
      Pairing: {
        WindowsAdmin: 'Windows Admin',
        WindowsAdminScript: 'Windows Admin Pairing Script',
        WindowsUser: 'Windows User',
        WindowsUserScript: 'Windows User Pairing Script',
      },

      Protect: 'Protect Endpoints',
      ProtectDescription: 'Protect your user workstations by making sure only authorized inbound traffic is allowed',
      Traffic: {
        AllowRecommended: 'Allow (Recommended)',
        ApplyingRules: 'Applying Rules...',
        DontAllowRecommended: "Don't Allow (Recommended)",
        Error: 'There was an error saving rules based on traffic recommendations to your endpoints. Please try again.',
        InboundToAllow: 'Review and accept inbound traffic recommendations',
        InboundToAllowDescription:
          'By default, Illumio will block any additional inbound traffic not listed, including future traffic as well',
        IncludePreviouslyPairedServers: 'Include previously paired servers',
        Loading: 'Loading Traffic...',
        NoTraffic: 'No traffic detected',
        ObservedTraffic: 'Observed Services & Traffic',
        SaveRules: 'Save Rules',
        Success: 'Rules based on recommendations have been applied to your endpoints',
        Unchanged: 'There were no new rules to be saved',
      },
      User: 'User Endpoints',
    },
    Enforcement: {
      MoveAll: 'Move all {type} to enforcement',
      MoveTo: 'Move to enforcement',
    },
    EnforcementBoundaries: {
      BlockedConnections: 'Deny Rule  Blocked Connections',
      Description:
        'a Deny Rule is defined by a scope consisting of Source, Provider, and Service. When a Deny Rule is provisioned, connections that match the scope are blocked. A blocked connection can be allowed to cross the Deny Rule by writing a Rule.',
      Errors: {
        ConsumerProviders: 'Cannot use IP Lists for both Sources and Destinations',
      },

      Remove: 'Remove Deny Rule',
      RulesProvision:
        '{count, plural, =1 {# Deny Rule} other {# Deny Rules}} added to the Deny Ruleset allow connections to cross the boundary. Be sure to provision these rules before provisioning the boundary.',
    },
    /* eslint-disable rulesdir/intl-key-verify */
    ErrorsAPI: {
      'err:ams_with_label_not_allowed': 'All Workloads cannot be used with labels or label groups in a Deny Rule',
      'err:cant_specify_ip_value_actor': 'Invalid Destination or user',
      'err:consumer_not_found': 'Sources for Rule not found',
      'err:container_cluster_virtual_service_delete':
        'Virtual service created by a Kubernetes Cluster that is now deleted may only be deleted from the leader region',
      'err:container_cluster_virtual_service_edit':
        'Cannot edit a virtual service created by a Kubernetes Cluster that is now deleted',
      'err:container_host_rule_invalid_provider':
        'Only Workloads, Labels, or All Workloads can be used as Destinations if container_host is the Source',
      'err:container_host_rule_invalid_resolve_labels':
        'resolve_labels_as for Destinations and Sources must be "workloads" if container_host is the Source',
      'err:container_host_rule_no_windows_service':
        'Windows services are not supported with container_host as the Source',
      'err:container_host_rule_sec_connect_machine_auth':
        'SecureConnect and Machine Authentication cannot be used with container_host as the Source',
      'err:container_host_rule_single_consumer': 'container_host cannot be used with any other Source',
      'err:container_host_rule_stateless': 'The stateless option cannot be used with container_host as the Source',
      'err:container_host_rule_tcp_only':
        'Rule ingress_service_ports only supports tcp (6) protocol with container_host as the Source',
      'err:container_workload_profile_invalid_pairing_key': 'Namespace pairing key does not match',
      'err:ingress_service_must_be_empty':
        'If the only value for the Destinations resolve_labels field is "virtual_services", then ingress_services must be empty',
      'err:ingress_service_required':
        'At least one ingress service is required if Destination resolve_labels_as includes workloads',
      'err:invalid_egress_windows_service_consumer':
        'Windows based service on Source side cannot have IP Lists as a Source',
      'err:invalid_windows_service_provider': 'Windows based services cannot have IP Lists as a Destination',
      'err:ip_list_referenced_by_enforcement_boundary':
        'Can not delete Ip List that is referenced by an existing Deny Rule',
      'err:label_group_referenced_by_enforcement_boundaries': 'Label Group is still referenced by a Deny Rule',
      'err:label_still_has_associated_cwp': 'Label is referenced by Namespaces',
      'err:label_still_has_associated_enforcement_boundary': 'Label is referenced by Deny Rule',
      'err:namespace_key_mismatch': 'Paring key is invalid for Namespace',
      'err:non_brn_must_use_ip_list':
        'A rule with Network Type "All" or "Non-Corporate (Endpoints Only)" must have only IP lists on Sources or Destinations',
      'err:provider_not_found': 'Destinations for Rule not found',
      'err:rbac_cannot_operate_on_rule_with_broader_consumer_scope':
        'You cannot modify Rules with broader Source Scope(s) than your permitted Scope(s)',
      'err:rbac_rule_ub_scope_should_be_same_as_scope': "User's scope and Source-scope labels cannot be different: %s",
      'err:resolve_actors_requires_rule_actors':
        'Resolve Actors must be used with one of Destinations, Sources, or providers_or_consumers',
      'err:resolve_labels_required':
        'Both the Destinations and Sources fields must have at least one value for resolve_labels_as',
      'err:rs_duplicate_consumer': 'Duplicate Source %s',
      'err:rs_duplicate_provider': 'Duplicate Destination %s',
      'err:rs_provider_required': 'Rule must specify at least one Destination.',
      'err:rule_pb_only_ip_list_or_all':
        'Only IP Lists or All are allowed in the Destinations field of Unmanaged Services.',
      'err:rule_ub_must_be_managed': 'Only Managed Workloads are allowed in the Sources field of Unmanaged Services.',
      'err:service_binding_invalid_operation': 'Pod service bindings cannot be deleted',
      'err:service_provider_too_long': 'Service Destination is too long.',
      'err:service_referenced_by_enforcement_boundary':
        'Cannot delete service that is referenced by an existing Deny Rule',
      'err:stateless_rule_cannot_have_ip_list_provider': 'Stateless rules cannot have IP Lists as their Destination',
      'err:stateless_rule_consumer_count_exceeded':
        'Multiple Sources are allowed only if they are Any (0.0.0.0/0 and ::/0) and ams or labels of different types',
      'err:unmanaged_container_workload_profile_labels': 'An unmanaged Namespace cannot have assigned labels',
      'err:ves_invalid_projected_actor': 'Projected VES calculation only supports a subset of Destinations and Sources',
      'err:virtual_server_no_service_with_workload': 'Virtual Server Workload Destination requires a Service.',
      'err:virtual_server_non_workload_label_provider': 'Virtual server Destination must be workload or label',
    },
    /* eslint-enable rulesdir/intl-key-verify */
    Events: {
      PotentiallyBlockedTraffic: 'Simulated Block Traffic',
    },
    EventUtils: {
      ContainerClusterChangeRate: 'Kubernetes Cluster Change Rate',
      ContainerClusterCreated: 'Kubernetes Cluster created',
      ContainerClusterDeleted: 'Kubernetes Cluster deleted',
      ContainerClusterDuplicateMachineID: 'Kubernetes Cluster duplicate machine ID',
      ContainerClusterRegionMismatch: 'Kubernetes Cluster region mismatch',
      ContainerClusterUpdated: 'Kubernetes Cluster updated',
      ContainerWorkloadInvalidPairingConfig: 'Pod invalid pairing config',
      ContainerWorkloadNotCreated: 'Pod not created',
      ContainerWorkloadProfileCreated: 'Namespace created',
      ContainerWorkloadProfileDeleted: 'Namespace deleted',
      ContainerWorkloadProfileUpdated: 'Namespace updated',
      ContainerWorkloadUpdateServices: 'Pod Update Services',
    },
    Explorer: {
      ConsumerApp: 'Source App',
      ConsumerApplications: 'Source Applications',
      ConsumerCount: '{count, plural, =0 {no Sources} =1 {# Source IP} other {# Source IPs}}',
      ConsumerEnv: 'Source Env',
      ConsumerEnvironments: 'Source Environments',
      ConsumerHostname: 'Source Hostname',
      ConsumerIsNot: 'Source is not',
      ConsumerLabels: 'Source Labels',
      ConsumerLoc: 'Source Loc',
      ConsumerLocations: 'Source Locations',
      ConsumerRole: 'Source Role',
      ExcludeConsumers: 'Exclude in Sources',
      ExcludeConsumersOrProviders: 'Exclude Sources Or Destinations',
      ExcludeProviders: 'Exclude in Destinations',
      FQDNNotSupported: 'The IP List select contains an FQDN which is not supported as a Source Filter',
      IncludeConsumers: 'Include in Sources',
      IncludeConsumersOrProviders: 'Include Sources Or Destinations',
      IncludeProviders: 'Include in Destinations',

      ProviderApp: 'Destination App',
      ProviderApplications: 'Destination Applications',
      ProviderCount: '{count, plural, =0 {no Destinations} =1 {# Destination IP} other {# Destination IPs}}',
      ProviderEnforcement: 'Destination Enforcement',
      ProviderEnv: 'Destination Env',
      ProviderEnvironments: 'Destination Environments',
      ProviderIPList: 'Destination IPList',
      ProviderIsNot: 'Destination is not',
      ProviderLabels: 'Destination Labels',
      ProviderLoc: 'Destination Loc',
      ProviderLocations: 'Destination Locations',
      ProviderRole: 'Destination Role',
      SwapConsumersProviders: 'Swap Destinations and Sources',
    },
    Group: {
      NoMatchingData: 'No Matching Rules',
    },
    Help: {
      Desc: {
        CCServices: 'Kubelink creates a virtual service in the PCE for services in the Kubernetes Cluster.',
        ContainerClusters: 'An object used to store all the information about a Kubernetes Cluster in the PCE.',
        ContainerInheritHostPolicy:
          'Handle network traffic to/from containers on this host the same way as traffic to/from the host itself',
        ContainerWorkloadInIllumination:
          'After deploying containers, you can view Pods and traffic associated with them in Illumination, App Group Map, Vulnerability Map, and Explorer.',
        Explorer:
          'Analyze traffic flows for auditing, reporting, and troubleshooting purposes. You can view traffic by time, port, Sources, Destinations, and services. Explorer is not an interactive tool, so you cannot write rules using it.',
        PolicySettings:
          'Describe context before provisioning rules. By default, it is set to ‘No’. On changing it to ‘Yes’, makes providing a ‘Provision Note’ mandatory before provisioning rules. Also allows the order of Destination and Source columns to be changed across the UI.',
        ProvidedTraffic:
          'Traffic that has been observed into a vulnerable port, and if it was allowed, blocked or simulated block',
        ProvidingService:
          'Resource defined by a Rule that allows specific types of communication (such MySQL) between Destinations and Sources.',
        RejectedCoreService:
          'Displays the list of core service recommendations that were rejected. When you reject a core service recommendation, that IP address is no longer recommended as a Destination of the requested core service.',
        SelectiveEnforcementState:
          'Rules are enforced directionally for selected services when a workload is within the scope of Deny Rules. To apply a Deny Rule to workloads, you must move the required workloads into the Selective Enforcement state.',
      },
    },
    Labels: {
      ConsumerEdit: 'Edit Source Labels',
      ProviderEdit: 'Edit Destination Labels',
    },
    Map: {
      CantChangeLabelsOnContainers: 'Cannot change the labels of Pods.',
      ConsumingAppGroups: 'Source App Groups',
      ExtraScopeConsumers: 'Extra Scope Sources',
      FlowVisibility: {
        BlockAllowed: 'VEN logs connection information for allowed, blocked and simulated block traffic',
        BlockAllowedEdge: 'Illumio Edge logs and display traffic information for allowed and simulated block traffic',
        BlockTraffic: 'VEN logs connection information for blocked and simulated block traffic only',
        EnhancedDataCollectionDesc:
          'VEN logs byte counts in addition to connection details for allowed, blocked, and simulated block traffic',
      },
      LegendPanel: {
        PotentiallyBlockedVulnerableTraffic: 'Simulated Block Vulnerable Traffic',
      },
      ProvidingAppGroups: 'Destination App Groups',
      Search: {
        ContainerWorkload: 'Pod: {workload}',
      },
      SearchConsumingAppGroups: 'Search Source App Groups',
      SearchProvidingAppGroups: 'Search Destination App Groups',
      ViewBoundContainerWorkloads: 'View Bound Pods',
      Workloads: {
        RoleContainerWorkloads: '{count, plural, =0 {}  =1 {1 Pod} other {# Pods}}',
      },
    },
    Menu: {
      ContainerClusters: '{multiple, select, false {Kubernetes Cluster} true {Kubernetes Clusters}}',
    },
    Onboarding: {
      LandingPrompt: 'What would you like to do?',
      WorkloadCopyPairingScript:
        "Copy the relevant script for {os} and run it as administrator on the workload you'd like to add.",
    },
    PairingProfiles: {
      CopyScript: 'Copy Script',
      PreviewScript: 'Preview Script',
    },
    PolicyGenerator: {
      ExtraScope: {
        NumConsumingAppGroup: '{count, plural, =1 {1 Source App Group} other {# Source App Groups}}',
      },
      MergeConsumers: 'Merge rules with common Destination and Service',
      MergeServices: 'Merge rules with common Destination and Source',
    },

    Port: {
      ConsumerProcess: 'Source Port/Process',
      ProtocolProcess: 'Port/Protocol/Process',
    },
    QueryExplorer: 'Query data in Explorer',
    QueryExplorerDescription:
      'Examine traffic by source and destination labels or IP addresses, port, protocol, and time period. Write rules for simulated block and blocked traffic.',
    Role: {
      GlobalAdmin: 'Administrator',
      GlobalOrgOwner: 'Organization Owner',
      GlobalPolicyObjectProvisioner: 'Policy Objects (Non-Rulesets) Provisioner',
      GlobalReadOnly: 'Read-Only User',
    },
    RowsDuplicated: '{count} rows will be skipped as duplicates',
    RowsProvided: 'Analysis complete: {count} rows',
    RowsUpdated: '{count} workloads will be updated',
    Rule: {
      Validation: {
        BothVirtualServicesOnlyVirtualServicesWorkloads:
          "Cannot use 'Uses Virtual Services and Workloads' and 'Uses Virtual Services only' together for Sources.",
        ConsumerIpListVirtualServiceVirtualServerWorkload:
          "Cannot use IP Lists, Virtual Services, or Workloads along with 'Uses Virtual Services and Workloads' for Sources.",
        ConsumerIpListWindowsService: 'Cannot use Windows Egress Services as a Service when IP Lists is the Source.',
        ConsumerIpListWithMachineAuth:
          'Machine Authentication is not supported for Rules that use IP Lists for Sources.',
        ConsumerIpListWorkload:
          "Cannot use IP Lists or Workloads if you select 'Uses Virtual Services only' for a Source.",
        ConsumerMustHaveOnlyIPLists:
          "Sources must have only IP List(s) with 'Non-Corporate Networks (Endpoints Only)' or 'All Networks'",
        ConsumerWithVirtualService:
          "To use Virtual Services for Sources, you must first select 'Uses Virtual Services only' or 'Uses Virtual Services and Workloads'.",
        ConsumingServicesVirtualServices:
          "Cannot use a Service if 'Uses Virtual Services only' or 'Uses Virtual Services and Workloads' is a Source.",
        ContainerHostItself: "'Container Host' must be the only Source specified in the rule.",
        ContainerHostNonTcpService: "Must use a TCP Port or Port Range with 'Container Host'.",
        ContainerHostProviders:
          "Only Labels, Workloads, or All Workloads can be used as Destinations if 'Container Host' is the Source.",
        ContainerHostSecureConnectMachineAuthStateless:
          "Cannot use SecureConnect, Stateless Rules, or Machine Authentication in a Rule if 'Container Host' is the Source.",
        EgressSpecifiedWithNoIngress: 'An outbound service is specified with no inbound service',
        IPListsCantBeProviderAndConsumer: 'Cannot use IP Lists for both Destinations and Sources.',
        MachineAuthWithNullService:
          'Machine Authentication is not supported for Rules that use From Destinations for Services',
        MachineAuthWithNullUbService:
          'Machine Authentication is not supported for Rules that use IP Lists, ‘Uses Virtual Services and Workloads’, or ‘Uses Virtual Services only’ for Destinations or Sources.',
        NetworkTypeMustBeDefaultCorporate:
          "'Non-Corporate Networks (Endpoints Only)' or 'All Networks' cannot be used with the 'Use Virtual Services only' option as Sources or Destinations",
        NonCorporateMustUseIpList:
          "'Non-Corporate Networks (Endpoints Only)' or 'All Networks' is only supported for Rules that contain IP List(s) as Sources or Destinations",
        ProcessServicesCantWithIPListProvider: 'Process-based Services cannot be used with IP List Destinations',
        ProviderIpListVirtualServiceVirtualServerWorkload:
          "Cannot use IP Lists, Virtual Services, Virtual Servers, or Workloads along with 'Uses Virtual Services and Workloads' for Destinations.",
        ProviderIpListWindowsService: 'Cannot use Windows Services as a Service when IP Lists is the Destination.',
        ProviderIpListWithMachineAuth:
          'Machine Authentication is not supported for Rules that use IP Lists for Destinations.',
        ProviderMustHaveOnlyIPLists:
          "Destinations must have only IP List(s) with 'Non-Corporate Networks (Endpoints Only)' or 'All Networks'",
        ProviderWithIpListScopedUser:
          'Users with Scoped Roles cannot create Extra-Scope Rules which have IP Lists as Destinations.',
        ProviderWithIpListWorkload:
          "Cannot use IP Lists or Workloads if you select 'Uses Virtual Services only' for a Destination.",
        ProviderWithVirtualService:
          "To use Virtual Services or Virtual Servers for Destinations, you must first select 'Uses Virtual Services only' or 'Uses Virtual Services and Workloads'.",
        ProvidingServicesVirtualServices: "Cannot use a Service if 'Uses Virtual Services only' is a Destination.",
        RequiresProviderServiceConsumer: 'Cannot save. A Rule requires a Destination, Service, and Source.',
        SecureConnectCantWithIPListBoundVirtual:
          "SecureConnect is not supported for Rules that use IP Lists, 'Uses Virtual Services and Workloads', or 'Uses Virtual Services only' for Destinations or Sources.",
        SecureConnectWithNullUbService:
          "SecureConnect is not supported for Rules that use IP Lists, 'Uses Virtual Services and Workloads', or 'Uses Virtual Services only' for Destinations.",
        StatelessInvalidConsumers:
          'A Stateless Rule can only have the following types of Source: Any IP List plus All Workloads, or a Label (maximum one of a specific type) , or an individual item',
        StatelessWithIPList: 'IP Lists in Destinations with a Stateless Rule is not supported',
        StatelessWithLabelGroup: 'Label Group in Sources with a Stateless Rule is not supported',
        UserGroupsCantWithIPListConsumer: 'Cannot use User Groups if IP Lists is a Source.',
      },
    },
    RuleSearch: {
      AdvancedDescription: 'Search by Destination, Source, or both',
      Placeholders: {
        Consumers: 'Filter by Sources',
        Providers: 'Filter by Destinations and Rule attributes',
      },
    },
    Rulesets: {
      EnforcementBoundaryRuleset: 'Proposed Deny Ruleset',
      ImpactOfRule: 'Impact of Rule',
      InspectTraffic: 'Inspect Traffic',
      NewProposedRuleset:
        'This Proposed Ruleset contains rules to allow blocked connections across the Deny Rule. Rules in the Proposed Ruleset can be edited prior to saving',
      OverrideAllWorkloads: "Selecting Labels or Label Groups replaces 'All Workloads' selection",
      OverrideLabels: "Selecting 'All Workloads' replaces Labels and Label Groups selection",
      Rules: {
        DerivedFromProviderVirtualServices: 'Derived from Destination Virtual Services',
        ExtraScope: {
          Add: 'Add Deny Rule',
          AddDesc: 'Deny traffic from specified Source to Destination not explicitly allowed by an Allow Rule',
          IPListWarning:
            'To allow outbound access to IP Lists, create an Allow Rule to prevent the Rule from being applied to a larger set of Workloads.',
          NoMatch: 'No matching Deny Rules',
          Rules: '{count, plural, =0 {No Deny Rules} =1 {1 Deny Rule} other {# Deny Rules}}',
        },
        ExtraToIntra: 'Rule changed from Deny Rule to Allow Rule.',
        IntraScope: {
          Add: 'Add Allow Rule',
          AddDesc: 'Allow traffic from specified Source to Destination',
          NoData: 'No Allow Rules to display',
          NoMatch: 'No matching Allow Rules',
          Rules: '{count, plural, =0 {No Allow Rules} =1 {1 Allow Rule} other {# Allow Rules}}',
          Title: 'Allow Rule',
        },
        IntraToExtra: 'Rule changed from Allow Rule to Deny Rule.',
        OverrideDeny: {
          AddDesc: 'Deny traffic from specified Source to Destination regardless of subsequent Allow Rules',
          AddRule: 'Add Override Deny Rule',
          Rules: 'Override Deny Rules',
        },
      },
      TrafficBasedOn: '<b>Draft View</b> traffic based on data from the <b>{timeframe}</b>',
    },
    SelectOrTypeConsumers: 'Select or type for more sources',
    SelectOrTypeProviders: 'Select or type for more destinations',
    Servers: {
      Add: 'Add Servers',
      AgentReportForHostname: 'Agent Report for {hostname}',
      CopyScriptToServers: 'Copy the agent installation script and run it on your servers as a privileged user.',
      Enforcement: {
        ConfirmMessage: 'Are you sure you want to enforce the existing policy?',
        ConfirmTitle: 'Confirm Server Enforcement Change',
        Description: 'Moving to enforcement will enforce all policies applied to this server.',
        EmptyMessage: 'No servers to enforce',
        Mode: 'Enforcement Mode',
        Server: 'Server: {server}',
        Subtitle: 'Click on a server to review traffic and change its enforcement mode',
        Title: 'Server Enforcement',
        TrafficSelector: 'Show Traffic over',
      },
      InstallAgents: "Let's start by installing agents on your servers",
      Labeling: {
        ChooseProtectionSchema: 'Choose Protection Schema',
        Description:
          'Illumio automatically identifies some of your servers and suggests appropriate labels and policies.',
        LearnMoreAboutLabels: 'To learn more about labels, visit <a>here</a>.',
        LearnMoreAboutPolicies: 'To learn more about policies, visit <a>here</a>.',
        Manual: '(Manual)',
        ProtectionSchema: 'Protection Schema',
        ProtectionSchemaMaximum:
          'Maximum protection schemas reached. Deselect a protection schema to select a different schema.',
        ProtectionSchemaSelectionInfo: 'Select up to 10 protection schemas',
        Recommended: '(Recommended)',
        ServerRolesRefreshRate: 'Server roles refresh rate details',
        ServerRolesRefreshRateDetails:
          'Discovered server roles are generated when you pair a server that is already configured with server roles or 24 hours after server roles are configured or when restarting the VEN.',
        SubDescription: 'You can also change the recommendation.',
        Title: 'Choose protection schemas to apply',
        Why: 'Why this recommendation?',
        WhyTooltip:
          '{count, plural, =1 {This protection schema was} other {These protection schemas were}} recommended because of the detected server role(s)',
      },
      NoneFound: 'No servers found',
      NoPairingScript: 'No pairing script available for {os}, please select another or contact Illumio for support.',
      PairServerText: 'Pair Server and Install VEN',
      Protect: 'Protect Servers',
      ProtectDescription:
        'Identify and protect your critical applications using best-practices for ring-fencing, tailored to your network traffic',
      RecommendedRulesForHostname: 'Recommended Rules for {hostname}',
      RulesThatApplyDescription: '{count, plural, =1 {{ruleset} Rule)} other {{ruleset} Rules}}',
      ServersWillAppearBelow: 'Servers will appear below as you add them.',
      StatusMessages: {
        CompatibleConfiguration: 'Compatible configuration',
        IncompleteConfiguration: 'Incomplete configuration',
        NoReportAvailable: 'No report available',
      },
    },
    Services: {
      Mixin: {
        AtLeastOneEgressProcess: 'Atleast one service name is required',
      },
      ServiceName: 'Service Name',
      SourceProcessServices: 'Source Process Services',
    },
    Settings: {
      Containers: {
        AddScopeForContainerInheritHostPolicy: 'Add Workload labels for Container Hosts',
        AnnotationLabelHint:
          'Labels as specified by Kubernetes annotations are accepted by default. You can assign a Label here or restrict the Labels that may be used in Kubernetes annotations. If you assign a label here, it cannot be overridden by Kubernetes annotations',
        AnnotationLabelTooltip:
          '"Use Kubernetes Annotations" gives the ability to container platform administrators to set the Labels on pods and services using annotations in the manifest files, within the allowed list defined by the Illumio admin.\n\n"Assign Label" unidirectionally sets the Label selected on pods and services within this namespace/project. This label cannot be overwritten with container annotations.',
        ConfigInstruction: 'You need to select at least one label to enable this functionality',
        ContainerInheritHostPolicyDesc:
          'Illumio Core handle traffic to/from containers on this host the same way as traffic to/from the host itself if enabled.',
        DuplicatedScopesAndLabels: 'The same Workload Labels have already been configured',
        EditScopeForContainerInheritHostPolicy: 'Edit Workload labels for Container Hosts',
        ManagedDesc: 'Pods in their namespace are managed by PCE',
        UnmanagedDesc: 'Pods in this namespace are ignored by PCE',
      },
      IPForwarding: {
        Description:
          'IP forwarding is blocked by default unless the host is part of a Kubernetes Cluster. Linux Workloads that match these scopes will allow all forwarded traffic. Illumio Core does not provide visibility of forwarded traffic.',
      },
    },
    Tally: {
      Allow: '{count, plural, =1 {Allow Rule} other {Allow Rules}}',
      OverrideDeny: '{count, plural, =1 {Override Deny Rule} other {Override Deny Rules}}',
    },
    TemplatesWizard: {
      AddBestPracticeRuleset: 'Add Best Practice Ruleset',
      AddFromScratch: 'Add from Scratch',
      AddFromTemplate: 'Add from Template',
      Confirm: 'Confirm and Save',
      DomainControllerSelection: 'Select Labels and/or Workloads for Domain Controller',
      ErrorDetails:
        'Sorry, it seems something went wrong. Please try again, or reach out to support if the problem persists.',
      ErrorMessage: 'An unexpected error occurred while trying to add a Best Practice ruleset.',
      PreviewImpact: 'Preview Impact of Rules',
      ProvideAdditionalDetails: 'Provide Additional Details',
      SelectBestPractice: 'Select Best Practice',
      SelectFromRecommendations: 'Select from best practices we recommend',
      SelectorPlaceholder: 'Select Labels and/or Workloads',
      ShowPreview: 'Show Preview of Rules',
    },
    Tooltip: {
      ContainerWorkload: 'Pod:',
      ContainerWorkloadCount: '{count, plural, =1 {Pod} other {Pods}}: <span class="{className}">{count}</span>',
    },
    TrafficEvents: {
      PotentiallyBlockedEvents: 'Simulated Block Events',
    },
    Users: {
      LimitedRuleWritingSublabel:
        'Manage Rulesets that match the scope and Rules where the Destination and Source match the scope.',
      RulesetManagerSublabel: 'Manage Rulesets that match the scope and Rules where the Destination matches the scope.',
    },
    VEN: {
      ContainerCluster: 'Kubernetes Cluster',
    },
    ViewApplicationMap: 'View an application map',
    ViewApplicationMapDescription: 'Examine traffic to/from a particular app group.',
    ViewGlobalMap: 'View my global map',
    ViewGlobalMapDescription:
      'Examine traffic across all applications and environments. Write rules for simulated block and blocked traffic.',
    ViewSecurityPolicies: 'View my Security Policies',
    ViewVENs: 'View my VENs',
    ViewWorkloads: 'View my Workloads',
    VirtualServices: {
      AddWorkloads: {
        Description:
          'Select a Workload to bind to the Virtual Service. If the Service has a Port/Protocol, you can specify which ports to override for those Workloads when a connection is initiated by a Source.',
      },
      BoundContainerWorkloads: 'Bound Pods',
      ManagedBy: {
        ContainerCluster: 'This Virtual Service is managed by a Kubernetes Cluster',
        ContainerClusterDeletedAndOnLeader:
          'This Virtual Service was managed by a Kubernetes Cluster that is now deleted. You may delete the Virtual Service.',
        ContainerClusterDeletedAndOnMember:
          'This Virtual Service was managed by a Kubernetes Cluster that is now deleted. You may delete the Virtual Service from the leader PCE.',
      },
    },
    Workloads: {
      CSVColumns: 'Columns can be in any order.',
      CSVCreateNewLabel: "Create new labels if they don't exist",
      CSVEmpty: 'The CSV file is empty',
      CSVFile: 'CSV File',
      CSVFormat: 'The format: href, hostname, loc, env, app, role.',
      CSVImport: "Import a CSV file with the workloads info and the labels you'd like to assign to them.",
      CSVImportTitle: 'Import a CSV to edit workload labels',
      CSVInvalid: 'The CSV file is invalid',
      CSVMissing: 'A CSV file needs to be uploaded',
      CSVMissingHeader: 'The CSV file is missing a valid header row',
      CSVParseError: 'Fail to parse the CSV file: {reason}',
      CSVRowsExceed: 'The CSV file exceeds {count} rows',
      CSVTooLarge: 'The CSV file is too large',
      IncompatibilityTypes: {
        UnwantedWFProviders: 'Non-Illumio WFP Destinations',
      },
      WorkloadsAppearHere: 'Your Workloads will appear here',
    },
  },
  APIKeySettings: {
    APIKeySettings: 'API Key Settings',
    DefaultAPIKeyExpiresIn: 'By default, API Key for Service Accounts expires in',
    ExpirationSettingText: 'Changing this setting will not change the expiration of existing API Keys',
    ExpirationSettingViewText: 'Time rounded to days. Click Edit to see exact time in hours.',
    KeepExpiredAPIKeysFor: 'Keep Expired API Keys for',
  },
  AppGroupCoverage: {
    AddToRuleset: 'Adding to Ruleset:',
    AppendExtraRules: 'Append Extra-Scope Rules',
    AppendIntraRules: 'Append Intra-Scope Rules',
    AppendIPListRules: 'Append IP Lists Rules',
    BuildNewRuleset: 'Building New Ruleset:',
    ConnectionWithoutRules: '{count, plural, =1 {Connection} other {Connections}} without Rules',
    ConnectionWithRules: '{count, plural, =1 {Connection} other {Connections}} with Rules',
    CoverageNotCalculated: 'Rule Coverage not calculated',
    DeletingRulesInRuleset: 'Deleting Rules from Ruleset',
    ExistObjectInRuleset: 'Existing Objects in Ruleset',
    ExtraScope: 'Extra-Scope',
    ExtraScopeRuleCoverage: '<strong>Extra-Scope - {val, number, percent}</strong> Rule Coverage',
    GoToAppGroupRules: 'View Rules',
    IntraScope: 'Intra-Scope',
    IntraScopeRuleCoverage: '<strong>Intra-Scope - {val, number, percent}</strong> Rule Coverage',
    IpListRuleCoverage: '<strong>IP List - {val, number, percent}</strong> Rule Coverage',
    LastCalculated: 'Last Calculated: {when, date, L_HH_mm_ss}',
    MoreRecentRulesMessage: 'Your rules or traffic have been updated more recently than our calculations',
    NeverCalculatedMessage: 'The rule coverage for this App Group have never been calculated',
    NewExtraRuleCoverageStrong: '<strong>{val, number, percent}</strong> New Extra-Scope Rule Coverage',
    NewIntraRuleCoverageStrong: '<strong>{val, number, percent}</strong> New Intra-Scope Rule Coverage',
    NewIpListRuleCoverageStrong: '<strong>{val, number, percent}</strong> New IP List Rule Coverage',
    NewObjectInRuleset: 'Adding New Objects to Ruleset',
    NoConnections: 'No Connections',
    NoConnectionsFound: 'No Connections Found',
    NoRuleset: 'Rule coverage is provided by rulesets that were not created by Policy Generator',
    Recalculate: 'Recalculate?',
    RecalculateConfirm: 'Recalculating the connections might take a few minutes.',
    ReplaceExtraRules: 'Replace Extra-Scope Rules',
    ReplaceIntraRules: 'Replace Intra-Scope Rules',
    ReplaceIPListRules: 'Replace IP Lists Rules',
    RuleCoverage: '{val, number, percent} Rule Coverage',
    RuleCoverageStrong: '<strong>{val, number, percent}</strong> Rule Coverage',
    RulesetAvailableForPolicyGenerator: 'is available to be used for the Policy Generator.',
    RulesetDisabled: 'The default policy generator ruleset is {disabled}. You must make it active to add rules.',
    StartWithExtra: 'Start with Extra-Scope',
    StartWithIntra: 'Start with Intra-Scope',
    StartWithIpList: 'Start with IP Lists',
    TheRuleset: 'The Ruleset',
    TooManyWorkloadsInAppGroup: 'Too Many Workloads in App Group',
    UseThisRuleset: 'Use this Ruleset',
  },
  AppGroups: {
    AppEnv: 'Application | Environment',
    AppEnvDesc: 'Two Workload labels required to match. (Location label is ignored.)',
    AppEnvLoc: 'Application | Environment | Location',
    AppEnvLocDesc: 'Three Workload labels required to match',
    AppGroupNoMember: 'The current App Group has no members, please select a different App Group.',
    AppGroupType: 'App Group Type',
    AppGroupTypeDescription:
      'App Groups are created automatically based on Workload labels and the App Group Type setting. App Groups can be configured to require two or three matching labels.',
    ChangingAppGroupsType: 'Re-set App Group Type',
    ChangingAppGroupsWarning:
      'The App Group Map must recalculate traffic before re-displaying the map. Recalculating traffic takes longer for large Organizations.',
    ContainerWorkloads: 'App Group Container Workloads',
    EditAppGroupType: 'Edit App Group Type',
    Explorer: 'App Group Explorer',
    OpenAppGroupMap: 'Open App Group Map',
    PairingProfiles: 'App Group Pairing Profiles',
    PolicyGenerator: 'App Group Policy Generator',
    Rules: 'App Group Rules',
    SelectAppGroup: 'Select a different App Group',
    SetAppGroupType: 'Set App Group Type',
    VirtualServers: 'App Group Virtual Servers',
    VirtualServices: 'App Group Virtual Services',
    Vulnerabilities: 'App Group Vulnerabilities',
    Workloads: 'App Group Workloads',
  },
  AuthenticationSettings: {
    AddServer: 'Create Server',
    AnonymousBind: 'Anonymous Bind',
    AuthenticationSettingsIntro:
      'Choose your <b>Authentication Method</b> to authenticate users for accessing the <b>PCE</b>',
    Banner: 'Authentication Settings banner image',
    BindDN: 'Bind DN',
    BindDNExample: 'Example: cn=Manager,dc=domain,dc=com',
    BindDNHint: 'This is the distinguished name used to bind to the LDAP server',
    BindPassword: 'Bind Password',
    CheckYourConfigurationAndRetry: 'Check your configuration and retry.',
    ConfirmBindPassword: 'Confirm Bind Password',
    ConfirmDeleteLDAPConfig: 'Delete this LDAP server configuration and remove it from the list?',
    ConfirmDisableSAML: 'Are you sure you want to disable SAML as the default authentication setting?',
    ConfirmEnableLDAP: 'Confirm LDAP as the default authentication setting?',
    ConfirmEnableLDAPWithoutConfigs:
      'LDAP configuration has been enabled, but no servers have been added. Please add LDAP servers.',
    ConfirmEnableSAML: 'Confirm SAML as the default authentication setting?',
    ConfirmLastLDAPConfigRemoval:
      "Deleting the last LDAP server configuration disables LDAP authentication. Only the 'Local' setting will be available. Click OK to proceed.",
    CountOfMax: '({count} of {max})',
    EmptyLDAPConfigs: 'No LDAP servers configured',
    EnableLDAP: 'LDAP authentication is not active. Click Turn On to apply on all the LDAP servers.',
    EnableLDAPSupercluster:
      'LDAP authentication is not active. Click Turn On to apply on all the LDAP servers on all PCEs.',
    EnableSAML: 'SAML authentication is not active. Click Turn On to enable SAML.',
    EnableSAMLSupercluster: 'SAML authentication is not active. Click Turn On to enable SAML on all PCEs.',
    FullNameAttribute: 'Full Name Attribute',
    FullNameAttributeExample: 'Example: cn,commonName,displayName',
    FullNameAttributeHint: 'Attribute on a user object which contains the full name',
    GroupMembershipAttribute: 'Group Membership Attribute',
    GroupMembershipAttributeExample: 'Example: memberOf',
    GroupMembershipAttributeHint: 'Attribute on a user object containing group membership information',
    HostnameOrIPAddressHint: 'Enter an IP address or Hostname',
    LDAP: {
      Config: {
        AnonymousBindRequired: 'Anonymous Bind is required',
        AnonymousBindTooltip: "It is not recommended to use 'Allow' Anonymous Bind.",
        BindDNRequired: 'Bind DN is required',
        BindPasswordRequired: 'Bind Password is required',
        ConfigError: 'LDAP server configuration error',
        ConfirmAnonymousBind:
          "It is not recommended to use 'Allow' Anonymous Bind. Click confirm to 'Allow' or click Cancel and select 'Do Not Allow'.",
        ConfirmBindPasswordRequired: 'Confirm Bind Password is required',
        IPAddressOrHostnameRequired: 'IP Address or Hostname is required',
        NameRequired: 'Configuration Name is required',
        PasswordsDoNotMatch: 'Passwords do not match',
        PCERequired: 'PCE is required',
        PortRequired: 'Port is required',
        PortRequiredHint: 'Port number must be greater than or equal to 1',
        ProtocolRequired: 'Protocol is required',
        RequestTimeoutPeriodRequired: 'Request Timeout Period is required',
        RequestTimeoutPeriodRequiredHint: 'Request Timeout Period must be less than or equal to 60 seconds',
        UserBaseDNRequired: 'User Base DN is required',
        UserDNPatternError: 'User DN pattern must have exactly one * character in the pattern',
        UserNameAttributeRequired: 'UserName Attribute is required',
      },
    },
    LDAPEnabled: 'LDAP authentication is active.',
    LDAPServerConfiguration: 'LDAP server configuration',
    LearnMore: 'Learn about supported SSO providers',
    MaxServersAllowed: 'Cannot add more than 3 servers.',
    MaxServersAllowedSupercluster: 'Cannot add more than 3 servers per PCE.',
    NameHint: 'Enter a unique name for this setting',
    Options: {
      LDAP: 'LDAP users can also authenticate to the PCE using local credentials.',
      Local:
        "User will sign in to the PCE only with a local credential provided by the user's organization password policy.",
      SAML: 'SAML users can also authenticate to the PCE using local credentials.',
    },
    PasswordConfirmed: 'Password is confirmed',
    PortExample: 'Defaults: 389 for LDAP or LDAP with STARTTLS, 636 for LDAPS. Valid values are 1 to 65,535',
    PortHint: 'Enter a Port',
    RequestTimeoutPeriod: 'Request Timeout Period (seconds)',
    RequestTimeoutPeriodExample: 'Time to wait when opening new server connection.',
    SAML: {
      Config: {
        ConfigError: 'SAML configuration error',
      },
    },
    SAMLEnabled: 'SAML authentication is active.',
    SignInOnlySAMLOptionsTip: 'Sign in to the PCE using SAML along with local credentials.',
    SignInOptionsTip: 'Sign in to the PCE using either SAML or LDAP along with local credentials.',
    TestConnection: 'Test Connection',
    TypeHint: 'Select a Type',
    UserBaseDN: 'User Base DN',
    UserBaseDNExample: 'Example: CN=Users,DC=MyDomain,DC=com',
    UserBaseDNHint: 'The location of your LDAP users, specified by the DN of your user subtree',
    UserDirectoryConfiguration: 'User Directory Configuration',
    UserDNPattern: 'User DN Pattern',
    UserDNPatternHint:
      'Pattern used to create a DN string for a user during login,\n e.g. uid=*,ou=people, where * will be replaced with the username',
    UserNameAttribute: 'User Name Attribute',
    UserNameAttributeExample: 'Default directory Active Directory (AD): sAMAccountName',
    UserNameAttributeHint: 'The user attribute that contains the username',
    UserSearchFilter: 'User Search Filter',
    UserSearchFilterExample: 'Example: department=IT',
    UserSearchFilterHint: 'The LDAP search filter used to filter users',
  },
  BlockedTraffic: {
    BlockedConnections: 'Blocked Connections',
    BlockedOnConsumer: 'Traffic is blocked by the Consumer',
    BlockedOnProvider: 'Traffic is blocked by the Provider',
    Entity: {
      EntityName: 'Entity Name',
    },
    List: {
      ByTheConsumer: 'By the Consumer',
      ByTheProvider: 'By the Provider',
      ConfirmDeleteServices: 'Are you sure you want to remove these blocked traffic events?',
      DeleteServices: 'Remove Selected Blocked Traffic',
      Description:
        'Traffic is <span class="{className}">{status}</span> when a Workload is in <span class="{className}">{state}</span> mode.',
      DescriptionSelective:
        'If the Workload is in <span class="{className}">{state}</span>, traffic is <span class="{className}">{status1}</span> or <span class="{className}">{status2}</span> depending on the Deny Rule.',
      DisabledNotice: 'Blocked Traffic feature is disabled',
      LastDetected: 'Last Detected',
    },
    Name: 'Blocked Traffic',
    PotentiallyBlockedOnConsumer: 'Traffic is potentially blocked by the Consumer',
    PotentiallyBlockedOnProvider: 'Traffic is potentially blocked by the Provider',
    TrafficStatus: 'Traffic Status',
  },
  Certificate: {
    CertExpired: 'Your Illumio PCE certificate has expired.',
    CertExpiredMessage: 'Your VENs can no longer communicate with the PCE.',
    Days: '{count, plural, =1 {DAY} other {DAYS}}',
    MessageRealCert: 'VENs will be unable to communicate with the PCE when the certificate expires.',
    MessageSelfSigned: 'You are seeing this message because the PCE is using a temporary self-signed certificate.',
    TrialEnded: 'Your Illumio Core trial has ended.',
    TrialEndedMessage:
      'To continue using Illumio Core, update the PCE with a valid certificate issued by a trusted certificate authority.',
    ValidCert: 'Your Illumio PCE certificate expires on <span class="{className}">{when, date, LL}</span>.',
    ValidTrial: 'Your Illumio Core trial is valid until <span class="{className}">{when, date, LL}</span>.',
  },
  ComponentForm: {
    AdvancedExamples: 'Advance Examples',
    CidrIpv4: 'Cidr and Ipv4',
    ComplexRadio: 'Complex Radio',
    DisableExample: 'Disable Example',
    DisplayDisabledExample: 'Display Disabled Example',
    DynamicFormRadio: 'Dynamic with FormRadio',
    EnableDisabledExample: 'Enabled Example',
    EnabledOptions: 'Enabled Options',
    EnterUniqueName: 'Enter a unique name no more than {count} characters',
    FormTextareaLimited: 'FormTextarea limited Formik props - no errors',
    HorizontalRadios: 'Horizontal Radios',
    IPListEditor: 'IP List Editor',
    MaxIpAllowed: 'Max Allowed IP Inputs',
    MinimumOneItem: 'Minimum one item',
    NonRestrictingFileUpload: 'Non-Restricting File Upload',
    OtherExamples: 'Other Examples',
    RestrictingFileUpload: 'Restricting File Upload',
    SelectExample: 'Select Example',
    TextareaRowsCols: 'FormTextarea with rows, cols',
    Themr: 'Themr',
    UncontrolledElements: 'Uncontrolled Elements',
    UploadFile: 'Upload File',
    VerticalRadios: 'Vertical Radios',
  },
  Components: {
    AttributeListTest: 'AttributeList Test',
    ConfirmDelete: 'Do you really want to delete?',
    ConfirmDeleteIPLists: 'Are you sure you want to remove the selected IP list(s)?',
    ConfirmLeavePage: 'Are you sure you want to leave the page?',
    Dialog: 'Dialog',
    FieldsetTitle: 'Fieldset Title',
    InviteAnotherUser: 'Invite Another User',
    Modal: 'Modal',
    PleaseEnterFullName: 'Please enter your full name',
    PleaseEnterTimeZone: 'Please enter your time zone information',
    PleaseLogOut: 'Please Log out',
    PleaseLogOutBeforeCompleteInvite: 'Please log out before attempting to complete an invitation for another user.',
    PleaseSelectRuleset: 'Please select a Ruleset',
    ProvideRulesetName: 'Please provide a Ruleset name',
    RulesetNameExists: 'A Ruleset already exists with that name',
    TestEmailUnableToSend: 'The test email was unable to be sent.',
    TextareaLabel: 'Textarea Label',
    TextLabel: 'Text Label',
    ThreeSixtyWorkloads: '360 Workloads',
    Transmission: 'Transmission',
  },
  Connectivity: {
    BothEntitiesCantBeIP: 'Both entities cannot be IP Addresses',
    CheckRules: 'Check Rules',
    ConsumerError: 'Consumer error',
    ConsumerMissing: 'Missing Consumer',
    Example22TCP: 'Example: 22 TCP',
    NetworkError: 'Network error',
    NonCorporateMustUseIpListError:
      "'Non-Corporate Networks (Endpoints Only)' or 'Any' is only supported for Rules that contain IP Address as Sources or Destinations",
    PortAndProtocol: 'Provider Port and Protocol',
    ProviderError: 'Provider error',
    ProviderProvider: 'Missing Provider',
    RulesAllowConnection: 'The Rules below allow this connection',
    RulesAllowConnectionFail: 'No Rules exist to allow this connection',
    RulesAllowConnectionFailMessage: 'Change the criteria or add a Rule to allow this connection',
    SelectWorkloadContainerWorkloadOrIP: 'Select Workload, Container Workload, or IP Address',
    ServiceError: 'Service error',
    VerifyRulesExist:
      'Verify if Rules exist that allow connections between Workloads, Container Workloads, or IP addresses in IP Lists',
  },
  ContainerClusters: {
    AddNew: 'Add a new Container Cluster',
    AddNewContainerWorkloadProfile: 'Add a new Container Workload Profile',
    Allowed: 'allowed',
    AssignedLabels: 'Assigned Labels',
    AssignLabel: 'Assign Label',
    CannotDelete:
      'The following {count, plural, =1 {Container Cluster is} other {# Container Clusters are}} still online. Please stop Kubelink before deleting the cluster from the PCE.',
    CannotDeleteContainerWorkloadProfile:
      'The following {count, plural, =1 {Container Workload Profile is} other {# Container Workload Profiles are}} cannot be deleted.',
    ClusterId: 'Cluster ID',
    ClusterPairingToken: 'Cluster Pairing Token',
    ClusterToken: 'Cluster Token',
    ContainerAnnotationLabels: 'Container Annotation Labels',
    ContainerRuntime: 'Container Runtime',
    ContainerWorkloadProfiles: 'Container Workload Profiles',
    ContainerWorkloadProfilesAdd: 'Add Container Workload Profile',
    ContainerWorkloadProfilesEditError: 'Unable to Edit Container Workload Profiles',
    ContainerWorkloadProfilesMessage: 'Editing the template does not affect existing Container Workload Profiles.',
    ContainerWorkloadProfilesUnmanaged:
      'Container Workload Profiles will be created automatically for discovered {isOpenshift, select, false {Namespaces} true {Projects}}',
    ContainerWorkloadProfileTemplate: 'Container Workload Profile - Default Settings',
    CopyKey: 'Copy Key',
    CopyPairingKeyMessage:
      'Please copy the following pairing key. This information will not be available after you leave the page. A new profile will need to be created.',
    CopyTokenMessage:
      'Please copy the following token and ID. This information will not be available after you leave the page. A new token will need to be generated.',
    CreatedByContainerCluster: 'Created by Container Cluster',
    Delete: 'Remove {count, plural, =1 {Container Cluster} other {# Container Clusters}}',
    DeleteConfirm:
      'Are you sure you want to remove the {count, plural, =1 {Container Cluster} other {# Container Clusters}}?',
    DeleteContainerWorkloadProfile:
      'Remove {count, plural, =1 {Container Workload Profile} other {# Container Workload Profiles}}',
    DeleteContainerWorkloadProfileConfirm:
      'Are you sure you want to remove the {count, plural, =1 {Container Workload Profile} other {# Container Workload Profiles}}?',
    DeletedContainerWorkloadProfileSuccessfully:
      'The following {count, plural, =1 {Container Workload Profile has} other {#  Container Workload Profiles have}} been successfully deleted',
    DeletedSuccessfully:
      'The following {count, plural, =1 {Container Cluster has} other {# Container Clusters have}} been successfully deleted',
    DuplicatedIdList: 'The duplicate IDs are: {list}',
    DuplicatedIdNodeList: 'The nodes with duplicate IDs are: {list}',
    DuplicateMachineIDsError:
      'There are duplicate machine IDs among your cluster nodes. Container cluster functionality will be limited until this issue is resolved.',
    EditDefaultSettings: 'Edit default settings',
    KubelinkVersion: 'Kubelink Version',
    LabelAssignType: 'Label Assign Type',
    LabelsAllowedAssign: 'Labels Allowed / Label Assign',
    LabelsAllowedNumberAndType: '{count} {labelTypeName} Labels allowed',
    LabelType: 'Label Type',
    NoLabelAllowed: 'No label allowed',
    NoLabelAllowedHeader: 'No Label Allowed',
    NoProfilesToEditSpecified: 'Container Workload Profiles to edit was not specified',
    ProfileLink: 'Profile Link',
    UndeletedVirtualServices: 'Created by Deleted Container Cluster',
    UseContainerAnnotations: 'Use Container Annotations',
  },
  Containers: {
    PlatformVersion: 'Platform Version',
  },
  ContainerWorkloads: {
    All: 'All Container Workloads',
    ByCount: '{count, plural, =1 {Container Workload} other {Container Workloads}}',
    ContainerID: 'Container ID',
    HostIpAddress: 'Host IP Address',
    StartedAt: 'Started At',
    StoppedAt: 'Stopped At',
    Total: 'Total Container Workloads',
    View: 'View Container Workloads',
    WithCount: '{count} {count, plural, =1 {Container Workload} other {Container Workloads}}',
  },
  CoreServices: {
    AcceptDescription: 'Describe why this recommendation is accepted',
    Accepted: 'Accepted',
    AcceptedCoreService: '{name} is accepted as a provider of the {service} core service',
    AcceptedCoreServices: 'Accepted Core Service',
    AcceptedCount: 'Accepted ({count})',
    AcceptedError: 'An unexpected error occurred. The server could not be accepted as a provider of the Core Service',
    AcceptedNotification:
      'Edit Labels to distinguish core service providers from other workloads. Click "Reject" if a server no longer provides the core service.',
    AcceptInfoMsg: 'An unmanaged workload is created automatically if the accepted server is an unknown IP address.',
    Confidence: '{confidence}% confidence',
    ConfirmRejectAccepted: 'Are you sure you want to reject this Recommendation?',
    CoreService: 'Core Service',
    CoreServices: 'Core Services',
    CoreServicesDetector: 'Core Services Detector',
    CoreServiceSettings: 'Core Services Settings',
    Current: 'Current',
    Default: '(Default)',
    DefaultSettings: 'Default Settings',
    Detection: 'Core Services Detection',
    DetectionModel: 'Detection Model',
    DisabledDetails: 'Do not analyze traffic data for Core Service Detection',
    EditDefaultSettingsBanner:
      'These are default label assignments for workloads providing the {name} Service. Editing the default setting does not affect previously edited workload Labels.',
    EditDefaultSettingsLabel: 'Edit Default Settings',
    EditLabelsCheckBoxLabel:
      'Save edited Role and Application Labels as the default label assignments for workloads providing the {name} Service',
    EditLabelsError: 'An unexpected error occurred. Could not edit the labels for this Core Service',
    EmptyServices: 'No Services to Display',
    EnabledDetails: 'Analyze traffic data every two weeks to identify servers that provide Core Services',
    ErrorFetchingWorkload: 'Workload does not exist.',
    FollowUp: 'Follow Up',
    FollowUpDescription: 'Describe why a decision cannot be made, what further steps need to be taken, and by whom.',
    LastRunAtNotification: 'Core Service Detection algorithm was last run at {timestamp}.',
    NoLastRunAtNotification: 'Core service detection algorithm has not run yet.',
    NoScannerNotification: 'Scanner detection algorithm has not run yet.',
    PeerCountInfo: 'Top Detected Processes',
    PeerCountNoTraffic: 'No Traffic to Display',
    PeerCountSubtitle: 'Top Detected Processes by Number of Ports',
    Peers: 'Peers',
    PortMatch: 'Port Matching',
    PortMatchInformation: 'Rule-based model based on connections to specific ports',
    ProcessNames: 'Process-based ML',
    ProcessNamesInformation: 'Machine learning model based on processes running on the server',
    RecommendedCoreService: 'Recommended Core Service',
    RecommendedCount: 'Recommended ({count})',
    RecommendedNotification:
      'The {name} core service was detected on the following servers on {date}. Click to Accept or Reject the recommendations.',
    RecommendedNotificationNoTimestamp:
      'The {name} core service was detected on the following servers. Click to Accept or Reject the recommendations.',
    Reject: 'Reject',
    RejectAcceptedBanner:
      'It is recommended that you edit or remove Role and Application Labels assigned to the rejected server.',
    RejectAcceptedError:
      'An unexpected error occurred. The server could not be rejected as a provider of the Core Service',
    RejectDescription: 'Describe why this recommendation is rejected.',
    Rejected: 'Rejected',
    RejectedCoreService: '{name} is no longer recommended as a provider of the {service} core service',
    RejectedCoreServices: 'Rejected Core Service',
    RejectedCount: 'Rejected ({count})',
    RejectedNotification: 'If a server is rejected in error, click "Accept" to move it to the Accepted tab.',
    ScannerDetection: 'Traffic Pattern',
    ScannerDetectionInformation: 'Algorithmic model based on traffic patterns associated with scanner activity.',
    ScannerNotification:
      '{scannerStatus, select, true {Scanner Detection is {scannerDetectionSettings}.} false {Scanner Detection algorithm was last run at {scannerTimestamp}.}}',
    SetDefaultSuggestedError:
      'An unexpected error occurred. Could not set default suggested labels for this Core Service',
    SettingsNotification:
      'The accuracy of Core Service Detection improves with the amount of traffic data collected. A minimum of 14 days is recommended for optimal results.',
    TrafficFlows: 'Port-based ML',
    TrafficFlowsInformation: 'Machine learning model based on connections to specific ports',
    UnknownIP: 'Unknown IP',
    UnknownProcess: 'Unknown Process',
    UseCurrentLabel: 'Use current {type} Label',
    UseCurrentLabels: 'Use current Labels',
  },
  Dashboard: {
    ActivePolicy: 'Active Policy',
    AddWidget: 'Add Widget',
    BlockedOrPotentiallyBlockedFlow: 'Blocked / Potentially Blocked Flow',
    DraftPolicyChanges: 'Draft Policy Changes',
    DragNewWidget: 'Drag a widget to add it into the dashboard',
    HostOsUndefined: 'Host OS not defined',
    LastFourteen: 'Last 14 Days',
    LastSeven: 'Last 7 Days',
    LastThirty: 'Last 30 Days',
    NoOsAttribute: 'No OS Attribute',
    RefreshDashboard: 'Refresh Dashboard',
    Title: 'Title',
    VenCountByEnforcementMode: 'Total VENs by Enforcement Mode',
    VenCountByOS: 'Active VENs by OS',
    VenCountByStatusOrHealth: 'Total VENs by Status / Health',
    VenCountByVersion: 'Active VENs by Version',
    WarningVensToolTip: 'View workloads with warnings and errors',
  },
  DateTimeInput: {
    Anytime: 'Anytime',
    CustomTime: 'Custom Time',
    From: 'From',
    InvalidTimeRange: 'Invalid time range selected',
    Last1Hour: 'Last 1 hour',
    Last7days: 'Last 7 days',
    Last24Hours: 'Last 24 hours',
    Last30days: 'Last 30 days',
    LastPolicyProvisioned: 'Last Policy Provisioned - {when, date, L_hh_mm_ss}',
    Later: 'Later',
    LimitedTimeRange: 'Time range is limited to {count, plural, =1 {1 day} other {# days}}',
    ListenOnlyEnableTime: 'Listen Only Enabled - {when, date, L_hh_mm_ss}',
    Now: 'Now',
    SelectDateAndTime: 'Select Date and Time',
    SelectedTime: '{when, date, L_HH_mm}',
    SelectFromPlaceholder: 'Select or anytime (blank)',
    SelectToPlaceholder: 'Select or now (blank)',
    ShortDays: {
      Fri: 'F',
      Mon: 'M',
      SunSat: 'S',
      TuesThurs: 'T',
      Wed: 'W',
    },
    To: 'To',
  },

  EnforcementBoundaries: {
    BlockedConnectionCount: '{count} {count, plural, =1 {connection} other {connections}}',
    BlockedConnectionDesc: 'These connections will be blocked when the Deny Rule is provisioned',
    BlockedConnections: 'Deny Rule Blocked Connections',
    DeleteConfirm: 'Are you sure you want to remove this Deny Rule?',
    DenyRulesNoData: 'No Deny Rules to display',
    DenyRulesNoMatchData: 'No matching Deny Rules to display',
    Description:
      'A Deny Rule is defined by a scope consisting of Consumer, Provider, and Service. When a Deny Rule is provisioned, connections that match the scope are blocked. A blocked connection can be allowed to cross the Deny Rule by writing a Rule.',
    EnfRulesCount: '{count} {count, plural, =1 {rule} other {rules}}',
    Errors: {
      ConsumerProviders: 'Cannot use IP Lists for both Consumers and Providers',
      Create: 'Unable to Create Deny Rule',
      Edit: 'Unable to Edit Deny Rule',
      MatchingWorkloadsFullEnforcement: 'No workloads are fully enforced',
      MatchingWorkloadsIdle: 'No workloads are idle',
      MatchingWorkloadsSelective: 'No workloads are selectively enforced',
      MatchingWorkloadsVisibility: 'No workloads are visible only',
    },
    List: {
      DeleteConfirm:
        'Are you sure you want to remove the selected Enforcement {count, plural, =1 {Boundary} other {Boundaries}}?',
    },
    MixedEnforcement: 'Mixed Enforcement',
    NoBlockedConnectionDesc: 'No connections will be blocked by this boundary',
    Remove: 'Remove Deny Rules',
    RulesCount: '{count, plural, =1 {# Deny Rule} other {# Deny Rules}}',
    RulesDesc: 'No rules exist to allow connections across the Boundary',
    RulesProvision:
      '{count, plural, =1 {# Deny Rule} other {# Deny Rules}} added to the Deny Rule Ruleset allow connections to cross the boundary. Be sure to provision these rules before provisioning the boundary.',
    SelectiveEnforcement: 'Selective Enforcement',
    UpdateResultTitle: 'Result of {count, plural, =1 {Deny Rule} other {Deny Rules}} status update',
    UpdateSuccess: '{count, plural, =1 {Deny Rule has} other {# Deny Rules have}} been successfully updated',
    Warning: {
      FQDN: 'FQDNs are not supported in Deny Rules. Illumio recommends creating IP lists without FQDNs for use in Deny Rules. IP lists containing FQDNs will be dropped from Deny Rules when the policy is delivered to the VEN.',
      IPList:
        'This IP list containing FQDNs is used in a Deny Rule.\nFQDNs are not supported in Deny Rules. Illumio recommends creating IP lists without FQDNs for use in Deny Rules. IP lists containing FQDNs will be dropped from Deny Rules when the policy is delivered to the VEN.',
    },
    WorkloadEnforcementDesc: 'This workload is within the scope of {count}',
    WorkloadEnforcementFollowingServices: 'The following services are selectively enforced.',
    WorkloadsInScope: 'Workloads in Scope',
    WorkloadsInScopeCount:
      '<span class="{className}">{count} {count, plural, =1 {workload} other {workloads}}</span> in {scopeType} {mode}',
    WorkloadsInScopeDesc: {
      Full: 'All traffic to and from these workloads is blocked unless allowed by Rule.',
      Idle: 'VEN does not control workload firewalls.',
      Mode: 'mode',
      Selective: 'Traffic that matches the Deny Rule is blocked unless allowed by a Rule. Unmatching is not blocked.',
      SupportEnforcement:
        '{context} <span class="{className}">These workloads must be set to Selective Enforcement to support Deny Rule</span>',
      Total:
        '<span class="{className}">{count} {count, plural, =1 {workload} other {workloads}}</span> are within the scope of the Deny Rule',
      Visibility: 'No traffic is blocked by policy.',
    },
  },
  EnforcementBoundary: {
    AddNew: 'Add a new Deny Rule',
  },
  Error: {
    AppGroupTimeout: 'The App Group configuration is calculating. Wait a few minutes, then refresh the page.',
    DataStoreUnavailable: 'Traffic Datastore is in maintenance mode',
    NetworkTimeout: 'The selected Illumination map is generating. Wait a few minutes, then refresh the page.',
    ProvideErrorId: 'Provide this Error-ID when contacting your support team',
    Unknown: 'Unknown Error',
  },
  ErrorsAPI,
  EssentialServiceRules: {
    Egress: {
      DHCPv4: 'Required for outbound DHCPv4',
      DHCPv6: 'Required for outbound DHCPv6',
      DNS: 'Required for outbound DNS',
      EventService: 'Required for real-time communication between the Workload and the PCE',
      ICMPv6: 'Required for outbound ICMPv6',
      PCE: 'Required for HTTPS communication from the Workload to the PCE',
      Traceroute: 'Required for outbound traceroute',
    },
    Inbound: 'Essential Service Rules - Inbound',
    Ingress: {
      DHCPv4: 'Required for DHCPv4 replies',
      DHCPv6: 'Required for DHCPv6 replies',
      ICMPv6: 'Required for inbound ICMPv6',
      Traceroute: 'Required for inbound traceroute',
    },
    IPSec: 'Enabled for SecureConnect and Machine Authentication connections',
    Modal: {
      ConfirmationMessage:
        'Essential Service Rules are programmed on the VEN to allow normal network functionality and communication with the PCE. Improperly editing a rule could cause a disruption of service. Are you sure you want to continue?',
      ConfirmationTitle: 'Editing Essential Service Rule',
      Notification: {
        DHCP: 'Use extreme caution when editing this rule. Misconfiguring this rule, or not maintaining this rule could impact the ability of a workload to receive an IP address.',
        DNS: 'Use extreme caution when editing this rule. Misconfiguring, or not maintaining, this rule could impact the ability of the VEN to communicate with the PCE and to DNS - undoing this rule may require manual intervention to re-establish connectivity to the PCE and to DNS. Use this function with extreme caution!',
        ICMPv6:
          'Use extreme caution when editing this rule. If this rule is misconfigured, or if it is not maintained, Workloads will be unable to use IPv6.',
        Traceroute:
          'Use extreme caution when editing this rule. If this rule is configured incorrectly, traceroute may not be available.',
      },
      Purpose: 'Purpose',
      SourcePort: 'Source Port',
      Title: 'Edit Essential Service Rule',
    },
    Outbound: 'Essential Service Rules - Outbound',
  },
  Events: {
    AddRepository: 'Add Repository',
    Address: 'Address',
    AllowedTraffic: 'Allowed Traffic',
    APIEndpoint: 'API Endpoint',
    APIMethod: 'API Method',
    AuditableEvents: 'Auditable Events',
    ByEvent: 'by Event',
    ByGenerated: 'by Generated',
    BySeverity: 'by Severity',
    ByTimestamp: 'by Timestamp',
    CEF: 'CEF',
    Changes: 'Changes',
    ChangeType: 'Change Type',
    Config: {
      Errors: {
        Format: 'Needs to be one of {format}',
        PCE: 'Needs to be one of these {pce}',
        Severity: 'Needs to match one of these {severity}',
        TLS: 'Needs to be {tls}',
      },
    },
    Configuration: 'Event Configuration',
    DaysCount: '{count, plural, =1 {# day} other {# days}}',
    DaysSetting: '{count, plural, =1 {day} other {days}}',
    Deactivated: 'Deactivated',
    DuplicateError: 'Repository update error',
    DuplicateMessage: 'A repository with the same address, port and protocol already exists',
    EditEventInformation: 'Changes to settings may take up to 5 minutes to take effect',
    EditRepository: 'Edit Repository',
    EmptyMessage: 'No matching Events to display',
    EncryptedDesc: 'Forwarded event data is encrypted using TLS',
    EventForwarding: 'Event Forwarding',
    Format: 'Event Format',
    Forwarding: 'Forwarding',
    Hostname: 'Hostname: {hostname}',
    HTTPStatusCode: 'HTTP Status Code',
    InvalidAddress: 'Invalid address',
    LandingMessage:
      'Select filter parameters then click "Go" to display filtered events. <br />Click "Go" without filter parameters to display most recent events. <br />Click one of preset filters below to display filtered events.',
    LastPairedAt: 'Last paired at',
    LEEF: 'LEEF',
    LocalRepositoryDesc: 'Forward events to local syslog service',
    ManagedSince: 'Managed Since',
    NotEncryptedDesc: 'Forwarded event data is not encrypted',
    OrganizationalEvents: 'Organizational Events',
    PotentiallyBlockedTraffic: 'Potentially Blocked Traffic',
    Process: 'Process: {process}',
    QuickFilter: 'Quick Filter',
    QuickFilterClear: 'Clear filter',
    QuickFilterInfo: 'Quick filter is applied to the most recent 1000 events',
    RemoveRepository: 'Remove Repository',
    Repository: 'Repository',
    RepositoryDeleteConfirm:
      'Are you sure you want to delete the selected {count, plural, =1 {repository} other {# repositories}}?',
    ResourceChange: 'Resource Change',
    RetentionPeriod: 'Retention Period',
    RetentionPeriodDesc: 'Audit events older than this are purged',
    RulesetHref: 'Ruleset Href',
    SecurityPrincipalHref: 'Security Principal Href',
    SecurityPrincipalType: 'Security Principal Type',
    SecuritySettings: {
      ContainersPolicyScopes: 'Containers Policy Scopes:',
      IPForwardingScopes: 'IP Forwarding Scopes:',
      LoopbackScopes: 'Loopback Scopes:',
      RejectedScopes: 'Rejected Scopes:',
      ScopeHref: 'Scope Href',
      StaticScopes: 'Static Scopes:',
    },
    ServiceAccount: 'Service Account',
    ServiceAccountHref: 'Service Account Href',
    Severity: 'Event Severity',
    SeverityDesc: 'Only audit events of this severity or higher are saved',
    StatusLogs: 'Status Logs',
    SyslogDestUpdateError: 'Syslog destination settings update error',
    SystemHealthMessages: 'System Health Messages',
    TLS: 'TLS',
    TlSConfigureMessage: 'Once TLS is configured, TLS setting cannot be modified',
    TLSHelp: 'Please create a new repository, if TLS needs to be disabled',
    Types: {
      Organizational: 'Organizational',
    },
    UpdateError: 'Event setting update error',
    ValidTLS: "Ensure that TLS peer's server certificate is valid",
    Yesterday: 'Yesterday',
  },
  EventUtils: {
    AccessRestrictionCreate: 'Create access restriction',
    AccessRestrictionDelete: 'Delete access restriction',
    AccessRestrictionUpdate: 'Update access restriction',
    AdminRecalc: 'Admin forced recalculation of policy',
    APIKeyCreated: 'API key created',
    APIKeyDeleted: 'API key deleted',
    APIKeyUpdated: 'API key updated',
    AuthenticationSettingsUpdated: 'Authentication settings updated',
    ClusterCreated: 'Cluster created',
    ClusterDeleted: 'Cluster deleted',
    ClusterUpdated: 'Cluster updated',
    ComputePolicyForUnmanagedWorkloads: 'Compute policy for unmanaged workloads',
    ContainerClusterChangeRate: 'Container Cluster Change Rate',
    ContainerClusterCreated: 'Container cluster created',
    ContainerClusterDeleted: 'Container cluster deleted',
    ContainerClusterDuplicateMachineID: 'Container cluster duplicate machine ID',
    ContainerClusterLabelMapUpdated: 'Label Map Updated',
    ContainerClusterRegionMismatch: 'Container cluster region mismatch',
    ContainerClusterSecurityPolicyAcksUpdated: 'Container Cluster Security Policy Acks Updated',
    ContainerClusterServicesProvisioned: 'Container cluster services provisioned',
    ContainerClusterUpdated: 'Container cluster updated',
    ContainerWorkloadInvalidPairingConfig: 'Container workload invalid pairing config',
    ContainerWorkloadNotCreated: 'Container workload not created',
    ContainerWorkloadProfileCreated: 'Container workload profile created',
    ContainerWorkloadProfileDeleted: 'Container workload profile deleted',
    ContainerWorkloadProfilesUpdated: 'Container workload profiles updated in bulk',
    ContainerWorkloadProfileUpdated: 'Container workload profile updated',
    ContainerWorkloadUpdateServices: 'Container Workload Update Services',
    CorporateIpsSettingsCreate: 'Create new corporate IPs for the org',
    CorporateIpsSettingsDelete: 'Delete the corporate IPs instance',
    CorporateIpsSettingsUpdate: 'Update the corporate IPs instance',
    CreateGroup: 'Create a group',
    DatabaseCleanupCompleted: 'Database cleanup completed',
    DatabaseCleanupStarted: 'Database cleanup started',
    DeleteExpireServiceAccountApiKeys: 'Delete expired service account API keys',
    DeleteGroup: 'Group Deleted',
    DeleteOldCachedPerspectives: 'Delete old cached perspectives',
    DeletePairingProfileKeys: 'Delete all pairing keys of a pairing profile by ID',
    DenyRuleCreated: 'Deny Rule created',
    DenyRuleDeleted: 'Deny Rule deleted',
    DenyRuleUpdated: 'Deny Rule updated',
    DestinationCreated: 'Syslog destination created',
    DestinationDeleted: 'Syslog destination deleted',
    DestinationUpdated: 'Syslog destination updated',
    DomainCreated: 'Domain created',
    DomainDeleted: 'Domain deleted',
    DomainUpdated: 'Domain updated',
    EndpointOfflineCheck: 'Endpoint marked offline',
    EnforcementPointCreate: 'Enforcement point created',
    EnforcementPointDelete: 'Enforcement point deleted',
    EnforcementPointUpdate: 'Enforcement point updated',
    EssentialServiceRulesUpdated: 'Essential Service Rules updated',
    EventConfigUpdated: 'Event configuration updated',
    EventPruningCompleted: 'Event pruning completed',
    ExpireServiceAccountApiKeys: 'Service account API keys expiration successful',
    FirewallTampered: 'Firewall tampered',
    GlobalPolicySettingsUpdated: 'Global policy settings updated',
    HardLimitExceeded: 'Object creation hard limit exceeded',
    HardLimitRecoveryCompleted: 'Hard limit recovery completed',
    IPListCreated: 'IP list created',
    IPListDeleted: 'IP list deleted',
    IPListsDeleted: 'IP lists deleted',
    IPListUpdated: 'IP list updated',
    IPTablesRuleCreated: 'IP tables rules created',
    IPTablesRuleDeleted: 'IP tables rule deleted',
    IPTablesRuleUpdated: 'IP tables rule updated',
    JobDeleted: 'Job Deleted',
    KubernetesWorkloadsBulkCreated: 'Kubernetes Workloads Bulk Created',
    KubernetesWorkloadsBulkDeleted: 'Kubernetes Workloads Bulk Deleted',
    KubernetesWorkloadsBulkUpdated: 'Kubernetes Workloads Bulk Updated',
    LabelCreated: 'Label created',
    LabelDeleted: 'Label deleted',
    LabelDimensionCreated: 'Label dimension created',
    LabelDimensionDeleted: 'Label dimension deleted',
    LabelDimensionUpdated: 'Label dimension updated',
    LabelGroupCreated: 'Label group created',
    LabelGroupDeleted: 'Label group deleted',
    LabelGroupUpdated: 'Label group updated',
    LabelsDeleted: 'Labels deleted',
    LabelUpdated: 'Label updated',
    LDAPConfigCreated: 'LDAP config created',
    LDAPConfigDeleted: 'LDAP config deleted',
    LDAPConfigUpdated: 'LDAP config updated',
    LDAPConfigVerifyConnection: 'LDAP config verify connection',
    LicenseDeleted: 'License deleted',
    LicenseUpdated: 'License updated',
    LocalUserAuthenticated: 'Local user authenticated',
    LocalUserDeletedForInactivity: 'Local user account deleted for inactivity',
    LocalUserPasswordChanged: 'Local user password changed',
    LocalUserProfileCreated: 'Local user profile created',
    LocalUserProfileDeleted: 'Local user profile deleted',
    LocalUserReinvited: 'Local user reinvited',
    LocalUserUnableToDeleteForInactivity: 'Unable to Delete Inactive Local User',
    LoginMSPTenantCreated: 'Login MSP tenant created',
    LoginMSPTenantDeleted: 'Login MSP tenant deleted',
    LoginMSPTenantUpdated: 'Login MSP tenant updated',
    LoginUserSessionCreated: 'Login user session created',
    LoginUserSessionTerminated: 'Login user session terminated',
    // The agent that has been lost for extended period of time sent another request
    LostAgentFound: 'Lost agent found',
    ModifyGroup: 'Modify a group by ID',
    NetworkCreated: 'Network created',
    NetworkDeleted: 'Network deleted',
    NetworkDeviceAckEnforcementInstructionsApplied: 'Network device Ack enforcement instructions Applied',
    NetworkDeviceAssignWorkload: 'Network device assign workload',
    NetworkDeviceCreate: 'Network device create',
    NetworkDeviceDelete: 'Network device delete',
    NetworkDeviceDeleteWorkload: 'Network device delete workload',
    NetworkDevicesAckMultiEnforcementInstructionsApplied: 'Network devices Ack multi-enforcement instructions applied',
    NetworkDeviceUpdate: 'Network device update',
    NetworkDeviceUpdateWorkload: 'Network device update workload',
    NetworkDeviceWorkloadsBulkCreate: 'Integration workload bulk create',
    NetworkDeviceWorkloadsBulkUpdate: 'Integration workload bulk update',
    NetworkEndpointCreate: 'Network endpoint create',
    NetworkEndpointDeleted: 'Network endpoint delete',
    NetworkEndpointUpdate: 'Network endpoint update',
    NetworkEnforcementNodeActivate: 'Network Enforcement Node activate',
    NetworkEnforcementNodeClearConditions: 'Clear Network Enforcement Node conditions',
    NetworkEnforcementNodeDeactivated: 'Network Enforcement Node deactivated',
    NetworkEnforcementNodeDegraded: 'Network Enforcement Node Degraded',
    NetworkEnforcementNodeMissedHeartBeats: 'Network Enforcement Node missed heartbeats',
    NetworkEnforcementNodeMissedHeartBeatsCheck: 'Network Enforcement Node missed heartbeats check',
    NetworkEnforcementNodePolicyAck: 'Network Enforcement Node Policy Ack',
    NetworkEnforcementNodeRequestPolicy: 'Network Enforcement Node request policy',
    NetworkEnforcementNodeUpdate: 'Network Enforcement Node update',
    NetworkFuncCntlrActivated: 'Network function controller activated',
    NetworkFuncCntlrDeactivated: 'Network function controller deactivated',
    NetworkFuncCntlrDiscoveryComp: 'Network function controller discovery completed',
    NetworkFuncCntlrPolicyStatusUpdated: 'Network function controller policy status updated',
    NetworkFuncCntlrSLBStateUpdated: 'Network functional controller load balancer state updated',
    NetworkUpdated: 'Network updated',
    OrgApiKeyDelete: 'API key deletion',
    OrgExtractCreate: 'Org extract created for Migration',
    OrgExtractDelete: 'Org extract deleted for Migration',
    OrgExtractUpdate: 'Org extract updated for Migration',
    OrgInfoCreated: 'Organization information created',
    OrgInfoUnmanaged: 'Organization changed to unmanaged',
    OrgInfoUpdated: 'Organization information updated',
    OrgLoadCreate: 'Org load created for Migration',
    OrgLoadDelete: 'Org load deleted for Migration',
    OrgLoadUpdate: 'Org load updated for Migration',
    OrgsVENCount: 'Get active VEN count for a list of orgs',
    OrgUnpairVENs: 'Unpair all VENs from org',
    PairingProfileCreated: 'Pairing profile created',
    PairingProfileDeleted: 'Pairing profile deleted',
    PairingProfileKeyGenerated: 'Pairing profile pairing key generated',
    PairingProfilesDeleted: 'Pairing profiles deleted',
    PairingProfileUpdated: 'Pairing profile updated',
    PasswordPolicyCreated: 'Password policy created',
    PasswordPolicyDeleted: 'Password policy deleted',
    PasswordPolicyUpdated: 'Password policy updated',
    PCESessionCreated: 'PCE user session created',
    PCESessionTerminated: 'PCE user session terminated',
    PCESupportReportRequestCreate: 'PCE support report request created',
    PCESupportReportRequestDelete: 'PCE support report request deleted',
    ProtectionSchemaCreated: 'Protection Schema Created',
    ProtectionSchemaDeleted: 'Protection Schema Deleted',
    ProtectionSchemaGroupingsCreated: 'Protection Schema Groupings Created',
    ProtectionSchemaUpdated: 'Protection Schema Updated',
    RADIUSAuthChallenge: 'RADIUS auth challenge issued',
    RADIUSConfigDeleted: 'RADIUS configuration deleted',
    RADIUSConfigsCreated: 'RADIUS configurations created',
    RADIUSConfigSecretVerified: 'RADIUS config shared secret verified',
    RADIUSConfigUpdated: 'RADIUS configuration updated',
    RansomwareTimeseriesStatisticsCreated: 'Ransomware Timeseries Statistics created',
    RBACAuthSecPrincipalCreated: 'RBAC auth security principal created',
    RBACAuthSecPrincipalDeleted: 'RBAC auth security principal deleted',
    RBACAuthSecPrincipalUpdated: 'RBAC auth security principal updated',
    RBACPermissionCreated: 'RBAC permission created',
    RBACPermissionDeleted: 'RBAC permission deleted',
    RBACPermissionUpdated: 'RBAC permission updated',
    RemoteSyslogReachable: 'PCE external audit server is reachable',
    RemoteSyslogUnreachable: 'PCE external audit server is not reachable',
    RemoveStaleZoneSubnets: 'Stale zone subnets removed',
    RequestAuthenticationFailed: 'Request authentication failed',
    RequestAuthorizationFailed: 'Request authorization failed',
    RequestInternalServer: 'API request failed due to internal server error',
    RequestInvalid: 'Request Invalid',
    RequestServiceUnavailable: 'API request failed due to unavailable service',
    RequestUnknownServer: 'API request failed due to unknown server error',
    ResourceCreated: 'Login resource created',
    ResourceDeleted: 'Login resource deleted',
    ResourceUnmanaged: 'Login resource changed to unmanaged',
    ResourceUpdated: 'Login resource updated',
    RulesetCreated: 'Rule set created',
    RulesetDeleted: 'Rule set deleted',
    RulesetsDeleted: 'Rule sets deleted',
    RulesetUpdated: 'Rule set updated',
    SAMLACSUpdated: 'SAML assertion consumer service  updated',
    SAMLConfigCreated: 'SAML configuration created',
    SAMLConfigDeleted: 'SAML configuration deleted',
    SAMLConfigPceSigningCert: 'Saml config pce signing cert has been generated/rotated',
    SAMLConfigUpdated: 'SAML configuration updated',
    SAMLSPCreated: 'SAML Service Provider created',
    SAMLSPDeleted: 'SAML Service Provider deleted',
    SAMLSPUpdated: 'SAML Service Provider updated',
    SecPoliciesCreated: 'Security policies created',
    SecPolicyPendingDeleted: 'Pending security policy deleted',
    SecPolicyRestored: 'Security policy restored',
    SecPolicyRestoredNotification: 'Restored security policy version',
    SecPolicyRuleCreated: 'Security policy rules created',
    SecPolicyRuleDeleted: 'Security policy rule deleted',
    SecPolicyRulesDeleted: 'Security policy rules deleted',
    SecPolicyRuleUpdated: 'Security policy rule updated',
    SecPrincipalDeleted: 'RBAC security principal deleted',
    SecPrincipalsBulkCreated: 'RBAC security principals bulk created',
    SecPrincipalsCreated: 'RBAC security principals created',
    SecPrincipalUpdated: 'RBAC security principal updated',
    SecureConnectGatewayCreated: 'Secure connect gateways created',
    SecureConnectGatewayDeleted: 'Secure connect gateway deleted',
    SecureConnectGatewayUpdated: 'Secure connect gateway updated',
    ServiceAccountCreated: 'Service Account created',
    ServiceAccountDeleted: 'Service Account deleted',
    ServiceAccountUpdated: 'Service Account updated',
    ServiceBindingCreated: 'Service binding created',
    ServiceBindingDeleted: 'Service binding deleted',
    ServiceBindingsDeleted: 'Service bindings deleted',
    ServiceCreated: 'Service created',
    ServiceDeleted: 'Service deleted',
    ServicesDeleted: 'Services deleted',
    ServiceUpdated: 'Service updated',
    SetServerSyncCheck: 'Set server synced',
    SettingsUpdate: 'Settings Update',
    SetupActivePceCreate: 'Active PCE created',
    SetupStandbyPceCreate: 'Standby PCE created',
    SLBCreated: 'Server load balancers created',
    SLBDeleted: 'Server load balancer deleted',
    SLBUpdated: 'Server load balancer updated',
    SoftLimitExceeded: 'Object creation soft limit exceeded',
    SourceIP: 'Source IP',
    SupportReportRequestCreate: 'Support report request created',
    SupportReportRequestDelete: 'Support report request deleted',
    SupportReportUpload: 'Support report',
    TempTableAutocleanupCompleted: 'Temp Table Auto Cleanup Completed',
    TempTableAutocleanupStartup: 'Temp Table Auto Cleanup Started',
    TLSChannelEstablished: 'TLS channel established',
    TLSChannelTerminated: 'TLS channel terminated',
    TrafficCollectorSettingCreate: 'Traffic Collector Setting Created',
    TrafficCollectorSettingDelete: 'Traffic Collector Setting Deleted',
    TrafficCollectorSettingUpdate: 'Traffic Collector Setting Updated',
    TrustedProxyIpsUpdated: 'Trusted Proxy IPs Updated',
    UserAcceptInvitation: 'User accepted invitation',
    UserAuthTokenCreated: 'User auth token created',
    UserCreated: 'User created',
    UserCSRFValidationFailed: 'User CSRF validation failed',
    UserDeleted: 'User deleted',
    UserInactiveLocalUsersPruneCheck: 'User inactive local users prune check',
    UserInvited: 'User invited',
    UserLogin: 'User login',
    UserLoginFailed: 'User login failed',
    UserLogout: 'User logout',
    UserPasswordChanged: 'User password changed',
    UserPasswordChangedAt: 'User password changed at',
    UserPasswordChangeFailure: 'User password change failed',
    UserPasswordComplexityNotMet: 'User password complexity not met',
    UserPasswordExpired: 'User expired password used',
    UserPasswordResetCompleted: 'User password reset completed',
    UserPasswordResetRequested: 'User password reset requested',
    UserPasswordUpdated: 'User password updated',
    UserResetPassword: 'User reset password',
    UserSignIn: 'User session created',
    UserSignOut: 'User session terminated',
    UserSynced: 'User synced',
    UserUpdated: 'User updated',
    UserVerifyMFA: 'Verify two-factor authentication code',
    VacuumDeactivatedAndDeletedWorkloads: 'Deactivated and deleted workloads have been vacuumed',
    VENAuthenticationMode: 'VEN in authentication recovery mode',
    VENClasAuthTokenRequested: 'VEN CLAS Authentication JWT Token requested',
    VENClearAuthRecoveryCondition: 'Clear VEN authentication recovery condition',
    VENClearConditions: 'Clear VEN conditions',
    VENCloneActivated: 'VEN clone activated',
    VENCloneDetected: 'VEN clone detected',
    VENCompatibilityCheckReportUpdate: 'VEN compatibility check report updated',
    VENFetchedPolicy: 'VEN fetched policy',
    VENFirewallStateTableThresholdExceeded: "VEN's firewall statetable's threshold has been exceeded",
    VENGoodbye: 'Workload shutdown',
    VENHasTooManyInterfacesReported: 'VEN reported more interfaces than the PCE supports',
    VENHostUnknown: 'VEN host: unknown',
    VENMachineIdentifiers: 'VEN machine identifiers updated',
    VENMaintenanceTokenGenerated: 'VEN tampering protection token has been generated',
    VENMaintenanceTokenRequested: 'VEN tampering protection token requested',
    VENMissedHeartbeatsCheck: 'VEN missed a few heartbeats',
    VENMissingHeartbeatAfterUpgrade: 'VEN missing heartbeat after upgrade',
    VENOfflineCheck: 'VEN marked offline',
    VENPaired: 'VEN paired',
    VENPolicyDeployedSuccessfully: 'VEN policy deployed successfully',
    VENPolicyDeployFailed: 'VEN policy deploy failed',
    VENProcessFailure: 'VEN process failure',
    VENPropertiesUpdated: 'VEN properties updated',
    VENRefreshToken: 'VEN refreshed token',
    VENReleaseDeleted: 'VEN release deleted',
    VENReleaseDeployed: 'VEN release deployed',
    VENRequestUpgrade: 'VEN request upgrade',
    VENRotateAuthSecret: 'VEN rotate authentication secret',
    VENsAuthenticationMode: 'VENs in authentication recovery mode',
    VENSelfSignedCertsCheck: 'VEN self-signed certificate housekeeping check',
    VENSelfSignedExpired: 'VEN self-signed certificate expired',
    VENSelfSignedGracePeriod: 'VEN self signed certificate in grace period',
    VENServiceUnavailable: 'VEN reported a service not running',
    VENSetDefaultVersion: 'Set a default VEN version',
    VENSettingsInvalidationErrorStateCheck: 'VEN settings invalidation error state check',
    VENSettingsUpdate: 'VEN settings updated',
    VENsUnpaired: 'VENs unpaired',
    VENSuspended: 'VEN suspended',
    VENTampering: 'VEN firewall tampered',
    VENUnauthenticatedClone: 'VEN unauthenticated clone detecte',
    VENUninstallTimeout: 'VEN uninstall timeout',
    VENUnpaired: 'VEN unpaired',
    VENUnsuspended: 'VEN Unsuspended',
    VENUpdateInteractiveUsers: 'VEN interactive users updated',
    VENUpdateIpTables: 'VEN updated existing iptables href',
    VENUpdateRunningContainers: 'VEN updated running containers',
    VENUpgradeRequested: 'VEN upgrade requested.',
    VENUpgradeSuccessful: 'VEN Upgrade Successful',
    VENUpgradeTimeOut: 'VEN Upgrade timed out.',
    VENUploadedIptablessRules: 'VEN uploaded iptables rules',
    VirtualServerDeleted: 'Virtual server deleted',
    VirtualServersCreated: 'Virtual servers created',
    VirtualServerUpdated: 'Virtual server updated',
    VirtualServiceCreated: 'Virtual Service created',
    VirtualServiceDeleted: 'Virtual Service deleted',
    VirtualServiceNotCreated: 'Virtual Service not created',
    VirtualServicesCreated: 'Virtual services bulk created',
    VirtualServicesUpdated: 'Virtual services bulk updated',
    VirtualServiceUpdated: 'Virtual Service updated',
    VulnerabilityCreated: 'Vulnerability created',
    VulnerabilityDeleted: 'Vulnerability deleted',
    VulnerabilityReportDeleted: 'Vulnerability report deleted',
    VulnerabilityReportUpdated: 'Vulnerability report updated',
    VulnerabilityUpdated: 'Vulnerability updated',
    WorkloadApplyPolicy: 'Workload apply pending policy',
    WorkloadDuplicateInterfaceReported: 'Workload duplicate interface reported',
    WorkloadFlowReportingFreq: 'Workload flow reporting frequency changed',
    WorkloadFlowReportingFrequencyUpdated: 'Workload flow reporting frequency updated',
    WorkloadInterfaceDeleted: 'Workload interface deleted',
    WorkloadInterfacesCreated: 'Workload interfaces created',
    WorkloadInterfacesDeleted: 'Workload interfaces deleted',
    WorkloadInterfacesUpdated: 'Workload interfaces updated',
    WorkloadLabelsApplied: 'Workload labels applied',
    WorkloadMismatchedInterfaces: 'Workload updated its mismatched interfaces',
    WorkloadNatRulesPresent: 'Workload has NAT rules present',
    WorkloadNetworkRedetect: 'Workload network redetected',
    WorkloadOfflineVenGoodbye: 'Workload offline after ven goodbye',
    WorkloadOnline: 'Workload came back online, after being offline',
    WorkloadPartialPolicyDelivered: 'Workload partial policy delivered',
    WorkloadPolicyRecalc: 'Workload policy recalculated',
    WorkloadsBulkCreated: 'Workloads bulk created',
    WorkloadsBulkDeleted: 'Workloads bulk deleted',
    WorkloadsBulkUpdated: 'Workloads bulk updated',
    WorkloadServiceReportsUpdated: 'Workload service reports updated',
    WorkloadSettingsUpdated: 'Workload settings updated',
    WorkloadsLabelsRemoved: 'Workloads labels removed',
    WorkloadsUnpaired: 'Workloads unpaired',
    WorkloadsUpdated: 'Workloads updated',
    WorkloadUpdated: 'Workload updated',
  },
  Explorer: {
    AcrossDenyRules: 'across Deny Rules',
    AddressesExisting:
      'The following {count, plural, =0 {No addresses} =1 {# address} other {# addresses}} were not saved, because they are used by existing Workloads:',
    AddressHelp: 'Enter an IP Address or CIDR',
    AllowAllConnections: 'Allow All Connections...',
    AllowAllConnectionsMessage:
      '{count, plural, =0 {No connections} =1 {# connection} other {# connections}} will be blocked by the Deny Rule when it is provisioned. {count, plural, =0 {} other {To allow all of these connections, click <span class="{className}">Allow All Connections</span>.}}',
    AllowFilteredConnections: 'Allow Filtered Connections',
    AllowFilteredConnectionsMessage:
      '{count, plural, =0 {No filtered connections} =1 {# filtered connection} other {# filtered connections}} will be blocked by the Deny Rule when it is provisioned. {count, plural, =0 {} other {To allow all of these connections, click <span class="{className}">Allow Filtered Connections</span>.}}',
    AllowSelectedConnections: 'Allow Selected Connections...',
    AllPolicyDecisions: 'All Policy Decisions',
    AppGroupDisabled: 'App Group Type must be set before the Explorer can be used',
    BlockedFlows: 'Blocked Flows',
    ByDenyRules: 'by Deny Rules',
    ByRule: 'by Rule',
    CalculateBasedOnLabelsDescription: 'Aggregate connections based on Consumer/Provider Labels.',
    CalculateBasedOnLabelsDescriptionDraft: 'Determine Policy Decisions using Label-set rules only.',
    CalculateBasedOnLabelsDraft: 'Quick Draft Rules',
    CalculateBasedOnUnmanagedIPDescription: 'Show Unmanaged IPs from the connections.',
    CalculateWorkloadBasedRulesDescription: 'Show connections between individual Consumer/Provider pairs.',
    CalculateWorkloadBasedRulesDescriptionDraft: 'Perform deep rule analysis to determine Policy Decisions.',
    CalculateWorkloadBasedRulesDraft: 'Deep rule analysis',
    CannotGetTrafficData: 'Cannot get traffic data',
    CheckboxLabelToRemoveItems: 'Check to select items to remove from Map and Traffic',
    ClearFilters: 'Clear Filters',
    ClearQuery: 'Clear Query',
    ClickGo: 'Then click "Go" to display traffic.',
    ClickRun: 'Then click "Run" to display traffic.',
    ClickToStartWritingRules: 'Click to start writing Rules',
    Closed: 'Closed',
    CommonSetOfLabels: 'Configure Common Set Of Labels',
    ConnectionsDisplayed: '({connections, number} displayed)',
    ConsumerApp: 'Consumer App',
    ConsumerApplications: 'Consumer Applications',
    ConsumerCount: '{count, plural, =0 {no Consumers} =1 {# Consumer IP} other {# Consumer IPs}}',
    ConsumerEnforcement: 'Consumer Enforcement',
    ConsumerEnv: 'Consumer Env',
    ConsumerEnvironments: 'Consumer Environments',
    ConsumerHostname: 'Consumer Hostname',
    ConsumerIP: 'Consumer IP',
    ConsumerIPAddresses: 'Consumer IP Addresses',
    ConsumerIPList: 'Consumer IPList',
    ConsumerIs: 'Consumer is',
    ConsumerIsNot: 'Consumer is not',
    ConsumerLabels: 'Consumer Labels',
    ConsumerLoc: 'Consumer Loc',
    ConsumerLocations: 'Consumer Locations',
    ConsumerName: 'Consumer Name',
    ConsumerNames: 'Consumer Names',
    ConsumerOrProviderIs: 'Consumer OR Provider is',
    ConsumerOrProviderIsNot: 'Consumer OR Provider is not',
    ConsumerProcessService: 'Consumer Process/Service',
    ConsumerRole: 'Consumer Role',
    ConsumerRoles: 'Consumer Roles',
    ConsumerSelections: 'Consumer Selections:',
    CreateNewService: 'Create a new Service',
    CreateVirtualService: 'Create Virtual Service',
    CreateVSComplete: 'Creating Virtual Services Complete',
    CreateWorkloads: 'Create Unmanaged Workloads...',
    CreateWorkloadsComplete: 'Creating Workloads Complete',
    CustomRange: 'Custom Range',
    DashboardTitle: 'Inbound Traffic for Group:',
    DaysAgo: '{count, plural, =0 {no Days} =1 {# Day} other {# Days}} Ago',
    DeepDraft: 'Individual Connections',
    DeletePolicyMessage: 'Are you sure you want to delete this Policy?',
    DeletePolicyTitle: 'Delete Policy',
    DeprecationNoticePart1: 'We plan to deprecate Explorer and encourage users to access the new',
    DeprecationNoticePart2:
      'that incorporates the latest improvements which includes support for new label types, table, map and graph view',
    DestinationHostname: 'Destination Hostname',
    DestinationIPAddresses: 'Destination IP Addresses',
    DestinationName: 'Destination Name',
    DestinationNames: 'Destination Names',
    DestinationProcessService: 'Destination Process/Service',
    DestinationRoles: 'Destination Roles',
    DestinationSelections: 'Destination Selections:',
    Disabled: 'Explorer is Disabled',
    DisplayedInExplorer: 'Displayed in Explorer',
    DisplayedInTraffic: 'Displayed in Traffic',
    EditInParenthesis: '(Edit)',
    EmptyFilter: 'Empty Filter',
    EnableRuleWritingWithDraftAndLabels: 'Choose Draft View and Connections to enable Rule writing',
    EnableRuleWritingWithDraftView: 'Choose Draft View to enable Rule writing',
    EnableRuleWritingWithLabelBased: 'Choose Connections to enable Rule writing',
    Encap: 'Encap',
    EnterName: 'Enter a name for the saved filter',
    ExcludeConsumers: 'Exclude in Consumers',
    ExcludeConsumersOrProviders: 'Exclude Consumers Or Providers',
    ExcludeProviders: 'Exclude in Providers',
    ExcludeServices: 'Exclude in Services',
    ExcludeWorkloadsFromIpList: 'Exclude Workloads from IP List Query',
    ExportConnectionsDraft:
      'Export {flowsCount, number} connections in CSV format.\nInclude Reported and Draft policy decisions.',
    ExportConnectionsReported:
      'Export {flowsCount, number} connections in CSV format.\nInclude Reported policy decision.',
    FilterAlreadyExists: 'This filter property already exists in the filter. Try adding a different one',
    FirstDetected: 'First Detected',
    FlowCount: '{count, plural, =0 {No flows} =1 {1 Flow} other {# Flows}}',
    Format: 'Format',
    FQDNNotSupported: 'The IP List select contains an FQDN which is not supported as a Consumer Filter',
    HoursAgo: '{count, plural, =0 {no Hours} =1 {# Hour} other {# Hours}} Ago',
    ICMPCode: 'ICMP Code: {code}',
    ICMPType: 'ICMP Type: {type}',
    IncludeConsumers: 'Include in Consumers',
    IncludeConsumersOrProviders: 'Include Consumers Or Providers',
    IncludeProviders: 'Include in Providers',
    IncludeServices: 'Include in Services',
    IncludeWorkloads: 'Include Workloads',
    IncompleteResults: 'Incomplete Results',
    IncompleteTrafficFlows: 'Incomplete Traffic Flows',
    LabelGroupWarning:
      'The list of blocked connections may be incomplete due to the size or complexity of the Label Groups.',
    LastDay: 'Last Day',
    LastDays: 'Last {count, plural, =0 {no Days} =1 {24 Hours} other {# Days}}',
    LastHours: 'Last {count, plural, =0 {no Hours} =1 {Hour} other {# Hours}}',
    LastMonths: 'Last {count, plural, =0 {no Months} =1 {Month} other {# Months}}',
    LastWeeks: 'Last {count, plural, =0 {no Weeks} =1 {Week} other {# Weeks}}',
    LoadFilter: 'Load Filter',
    LoadingDraftPolicy: 'Loading draft policy',
    LoadResults: 'Load Results',
    MaxNumberOfConnections: 'Maximum Number of Connections',
    MemberShowsPartialPCEData:
      'Results based on data for this PCE only. To see results from all members, please run the query on the Leader.',
    MonthsAgo: '{count, plural, =0 {no Months} =1 {# Month} other {# Months}} Ago',
    MoreItems: '+ {numLists, number} More',
    NoBlockedConnections: 'No Blocked Connections',
    NoInboundTraffic: 'No Inbound Traffic for this Group',
    NoRule: 'no Rule',
    NoTraffic: 'No Traffic available for selected filters.',
    NoTrafficForTimeInterval: 'No Traffic for selected time interval',
    NumFlows: 'Num Flows',
    NumOfMatchingFlowsExceedsConfMax: '* Number of matching connections exceeds the configured maximum',
    OrExcludeCP: 'Excluded Consumers Or Providers',
    OrExcludePC: 'Excluded Providers Or Consumers',
    OrIncludeCP: 'Included Consumers Or Providers',
    OrIncludePC: 'Included Providers Or Consumers',
    ParallelCoordinates: 'Parallel Coordinates',
    Policy: 'Policy',
    PolicyDecisions: 'Policy Decisions',
    PortsExclude: 'Excluded Services',
    PortsInclude: 'Included Services',
    ProcessService: 'Process/Service',
    ProviderApp: 'Provider App',
    ProviderApplications: 'Provider Applications',
    ProviderCount: '{count, plural, =0 {no Providers} =1 {# Provider IP} other {# Provider IPs}}',
    ProviderEnforcement: 'Provider Enforcement',
    ProviderEnv: 'Provider Env',
    ProviderEnvironments: 'Provider Environments',
    ProviderHostname: 'Provider Hostname',
    ProviderIP: 'Provider IP',
    ProviderIPAddresses: 'Provider IP Addresses',
    ProviderIPList: 'Provider IPList',
    ProviderIs: 'Provider is',
    ProviderIsNot: 'Provider is not',
    ProviderLabels: 'Provider Labels',
    ProviderLoc: 'Provider Loc',
    ProviderLocations: 'Provider Locations',
    ProviderName: 'Provider Name',
    ProviderNames: 'Provider Names',
    ProviderProcessService: 'Provider Process/Service',
    ProviderRole: 'Provider Role',
    ProviderRoles: 'Provider Roles',
    ProviderSelections: 'Provider Selections:',
    QuickDraft: 'Label-Set Connections',
    Recent: 'Recent',
    RefreshDraft: 'Refresh Draft Policy',
    RefreshPolicy: 'Refresh Policy',
    ReportedBy: 'Reported by',
    Reporter: 'Reporter',
    ResolveUnknownFqdns: 'Resolve Unknown FQDNs',
    Results: 'Results',
    ResultsAvailable: 'Results Available',
    ResultsSettings: 'Results Settings',
    ResultsSettingsInfoMessage:
      'For queries returning a large number of matches, increasing the maximum number of connections provides more complete results at the expense of slower performance. If the number of connections returned from the database exceeds the maximum displayed in the traffic view, all connections can be viewed by stepping through the results.',
    ResultsSettingsInfoMessageExplorer:
      'For queries spanning a long time period, increasing the maximum number of connections provides more complete results at the expense of slower performance. If the number of connections returned from the database exceeds the maximum displayed in Explorer, all connections can be viewed in the exported CSV file.',
    ReturnedFromDB: 'Returned from Database{numOfClusters, plural, =0 {} =1 {} other { per Region}}',
    RunAt: 'Run At',
    Saved: 'Saved',
    SaveFilter: 'Save Filter',
    SaveQuery: 'Save Query',
    SaveQueryAs: 'Save Query As',
    ScopedUserWarning: 'The data has been filtered to show only traffic applicable to your scope.',
    ScopedUserWarningExplorer: 'The Explorer data has been filtered to show only traffic applicable to your scope.',
    SelectConnectionsToStartWritingRules: 'Select Connections to start writing Rules',
    SelectFilters: 'Select filter parameters.',
    SelectIpsToStartCreatingWorkloads: 'Select Unmanaged IP Addresses to start creating workloads',
    SelectNewFilters: 'Select new filter parameters.',
    ServiceDoesNotExist: 'If a Service does not exist for a port/protocol',
    SortByNumberOfFlows: 'Sort By Number of Flows',
    SortByPortNumber: 'Sort By Port Number',
    SortByProcessName: 'Sort By Process Name',
    SourceExclude: 'Excluded Consumers',
    SourceInclude: 'Included Consumers',
    SourceIPAddress: 'Source IP Address',
    SourceIPAddresses: 'Source IP Addresses',
    SourceIPList: 'Source IP List',
    SourceName: 'Source Name',
    SourceNames: 'Source Names',
    SourceProcessService: 'Source Process / Service',
    SourceRoles: 'Source Roles',
    SourceSelections: 'Source Selections:',
    SuperClusterWarning: 'Incomplete results were received from member PCE "{region}"',
    SwapConsumersProviders: 'Swap Providers and Consumers',
    Table: 'Table',
    TargetExclude: 'Excluded Providers',
    TargetInclude: 'Included Providers',
    Time: 'Time',
    TimedOut: 'Timed Out',
    TooManyFlowsDoYouWantToContinue:
      'Calculating the complete deep draft view may take a long time, do you want to continue?',
    TopPorts: 'Top Ports',
    TopSources: 'Top Sources',
    TotalConnections: 'Total Connections',
    TrafficDatabaseStorage: 'Traffic Database Storage',
    TrafficDatabaseStorageDaysAvailable: '{days, number} days of data available for queries',
    UnmanagedIPConnections: 'Connections with unknown IPs',
    ValidRange: 'Valid range: {min, number} - {max, number}',
    ViewAllTraffic: 'View All Traffic',
    ViewPolicy: 'View Policy',
    VSCreated: '{count, plural, =0 {No Virtual Services} =1 {# Virtual Service} other {# Virtual Services}} Created',
    WeeksAgo: '{count, plural, =0 {no Weeks} =1 {# Week} other {# Weeks}} Ago',
    WorkloadsCreated:
      '{count, plural, =0 {No Workloads} =1 {# Unmanaged Workload} other {# Unmanaged Workloads}} Created',
    WrongValidRange: 'Number is not in valid range',
  },
  Exports: {
    ContainingAll: 'Containing All',
    Delete: 'Remove {count, plural, =1 {Export} other {Exports}}',
    DeleteConfirm:
      'Are you sure you want to remove the {count, plural, =1 {selected Export} other {# selected Exports}}?',
    DeletedSuccessfully:
      'The following {count, plural, =1 {Export has} other {# Exports have}} been successfully deleted',
    FileName: 'File name',
    Formatted: 'Formatted as',
    NewExport: 'New Export',
    NoData: 'No Exports to display',
    PageName: 'Exports',
    ViewAllExports: 'View All Exports',
  },
  Firewall: {
    Coexistence: {
      AddScopeForFirewallCoexistence: 'Add Scope for Firewall Coexistence',
      AllConfirmation: "Adding 'All Workloads' will remove all other coexistence Scopes.",
      AllOnlyWarning: "'All Workloads' cannot coexist with other Scopes and Labels",
      BuildTest: 'Build / Test',
      BuildTestEnforced: 'Build / Test / Enforced',
      DefaultMode: 'Illumio Core is the exclusive firewall',
      EditScopeForFirewallCoexistence: 'Edit Scope for Firewall Coexistence',
      IllumioAsPrimaryFirewall: 'Illumio Core is Primary Firewall',
      ModeDesc:
        'Illumio Core is the exclusive firewall by default.\nConfigure firewall coexistence as needed for all workloads and/or specific labels.',
      NoData: 'Illumio is the exclusive firewall. There are no label settings for firewall coexistence',
      SetIllumioAsNotPrimaryFirewall: 'Do not set Illumio Core as the Primary Firewall',
      SetIllumioAsPrimaryFirewall: 'Set Illumio Core as the Primary Firewall',
      Title: 'Firewall Coexistence',
    },
    CoexistenceAllError: "Scope cannot be 'All Workloads' when selecting Enforced or Illuminated policy state.",
    DuplicatedScopesAndLabelsForFirewallCoexistence:
      'Cannot create another Firewall Coexistence Mode with the overlap Scope, irrespective of the Primary Firewall option selected',
    DuplicatedScopesAndLabelsForPolicyState: 'Static Policy with the same Scope has already been configured',
    Mode: 'Firewall Coexistence Mode',
    NoMatch: 'No matching Scopes to display',
    PolicyStateAllError: 'At least one named label must be selected to make Workload Static',
    ScopeRequired: 'Scope is required',
    StaticPolicyAllConfirmation: "Adding 'All Workloads' will remove all other Static Policy Scopes.",
  },
  FlowCollectionFilters: {
    Aggregate: 'Aggregate',
    Delete: 'Remove {count, plural, =1 {Flow Collection Filter} other {Flow Collection}}',
    DeleteConfirm: 'Are you sure you want to remove {count, plural, =1 {flow collection} other {# flow collections}}?',
    DeletedSuccessfully:
      'The following {count, plural, =1 {Flow Collection Filter has} other {# Flow Collection have}} been successfully deleted',
    DestinationIP: 'Destination IP Address',
    Drop: 'Drop',
    Errors: {
      Create: 'Unable to Create Flow Collection',
      Edit: 'Unable to Edit Flow Collection',
    },
    InvalidPortNo: 'Invalid Port Number',
    IPAddressHint:
      'Select an IP Address in the format 255.255.255.255<br>Class E IP addresses (240.0.0.0-255.255.255.254) are not permitted<br>For Any IP Address type 0.0.0.0/0',
    MatchCondition: 'When traffic matches the following conditions:',
    MatchingAggregate:
      'If a broadcast or multicast traffic flow is received by multiple workloads, aggregate all reported instances of that flow into a single record in the traffic database, and discard the destination workload information',
    MatchingDrop: 'Do not store matching flow information',
    NoFilters: 'No Flow Collection',
    PortHint: 'Select a port between 0 and 65535. Enter -1 for Any Port',
    ProtocolHint:
      'Protocol can be either TCP, UDP, ICMP or ICMPv6, TCP protocol is not compatible with multicast and broadcast transmissions',
    ProtocolRequired: 'Protocol is required for drop action',
    RemoveResultTitle: 'Result of {count, plural, =1 {flow collection} other {# flow collections}} removal',
    TakeAction: 'Take the following Action:',
    TypeIPOrCIDR: 'Type IP address or CIDR block',
    TypePort: 'Type Port',
  },
  Forms: {
    SelectOption: 'Select an option',
  },
  GlobalNetwork: {
    CorporateNatPublicIpsDesc:
      "When using Network Address Translation, this set of IPs appear for communication between Workloads of the corporate network and services on the Internet. This information is required to identify the interfaces on Endpoint Workloads that are connected to the corporate network vs. interfaces that connect to the Internet via an external network (e.g. home or public network) when the information cannot be obtained directly from the Endpoint Workload's operating system.",
    Delete: 'Remove Corporate Public IPs',
    GlobalIPs: 'Corporate Public IPs',
    RemoveConfirm: 'Are you sure you want to remove the selected Corporate Public IPs',
    RemoveResultTitle: 'Result of Corporate Public IPs removal',
    RemoveSingleConfirm: 'Are you sure you want to remove the Corporate Public IPs',
    RemoveSuccess: '{count, {Corporate Public IPs}} been successfully removed',
  },
  Groups: {
    GroupDashboardHelpTextSubtitle: 'Displays the Groups created and the inbound traffic for the selected Group.',
    GroupHelpTextSubtitle: 'Displays the Groups, their provision status, and the number of VENs.',
    GroupWizardHelpTextSubtitle: 'Helps create group and define security rules.',
    PairingProfiles: {
      TimeAndUseLimited: 'Time and Use Limited',
      TimeLimited: 'Time Limited',
      UseLimited: 'Use Limited',
    },
  },
  Health: {
    Ago: '{time} ago',
    Complete: 'Complete',
    CompletedCount: '{count} Completed',
    CoordinatorNode: 'Coordinator Node {role, select, primary {(Primary)} replica {(Replica)} other {}}',
    CoordinatorNodeHealth: 'Coordinator Node Health',
    CoordinatorNodes: 'Coordinator Nodes',
    CoreNode: 'Core Node',
    CoreNodeHealth: 'Core Node Health',
    CPUUsage: 'CPU Usage',
    DatabaseReplication: 'Database Replication',
    DatabaseReplicationLag: 'Database Replication Lag',
    DataNodeHealth: 'Data Node Health',
    Days: '{days, plural, =0 {} =1 {DAY} other {DAYS}}',
    DaysHrsMinSec:
      '{days, plural, =0 {} =1 {# day} other {# days}} {hours, plural, =0 {} =1 {# hour} other {# hours}} {minutes, plural, =0 {} =1 {# minute} other {# minutes}} {seconds, plural, =0 {} =1 {# second} other {# seconds}}',
    DegradedServices: 'Degraded Services',
    DiskUsage: 'Disk Usage',
    DiskUsageLocation: '{val, number, percent} {location}',
    ErrorUnableToGetToNodeStatus: 'Error: Unable to get node status',
    FailedOptionalServices: 'Failed Optional Services',
    FailedServices: 'Failed Services',
    HideDetails: 'Hide Details',
    Hours: '{hours, plural, =0 {} =1 {HOUR} other {HOURS}}',
    LastSuccessfulSync: 'Last Successful Sync',
    LastSyncSuccessful: 'Last sync successful',
    LastSyncUnsuccessful: 'Last sync unsuccessful',
    LastUnsuccessfulSync: 'Last Unsuccessful Sync',
    Leader: 'Leader',
    ListenOnlyMode: 'Listen Only Mode',
    Member: 'Member',
    MemoryUsage: 'Memory Usage',
    Metrics: {
      TrafficDBMigrationBody: 'Some traffic flows may be missing from Explorer.',
      TrafficDBMigrationDetails: 'See PCE Health for more details.',
      TrafficDBMigrationTitle: 'Traffic database migration in progress',
    },
    Minutes: '{minutes, plural, =0 {} =1 {MIN} other {MINS}}',
    Node: 'Node {role, select, primary {(Primary)} replica {(Replica)} other {}}',
    NodeRunlevel: 'Node Runlevel',
    NodeStatus: 'Node Status',
    NodeUptime: 'Node Uptime',
    Normal: 'Normal',
    Notifications: {
      NodeCpuStatus: '{node} CPU is in {status} status',
      NodeDiskStatus: '{node} disk is in {status} status',
      NodeMemoryStatus: '{node} memory is in {status} status',
      NodeNetworkReplicationStatus: 'Network Replication is in {status} status',
      NodeRunlevelNotFive: '{node} not running at runlevel 5',
      NodeServicesStatus: '{node} services are in {status} status',
    },
    NotRunning: 'Not running',
    OptionalServicesNotRunning: '{count, plural, =1 {# Optional service} other {# Optional services}} not running',
    Partial: 'Partial',
    PCEFunction: 'PCE Function',
    PCEHealth: 'PCE Health',
    PceInListenOnlyMode: 'PCE has been in Listen Only mode since {time}',
    PceInRollingUpgrade: 'Supercluster upgrade in progress',
    PCENodeHealth: 'PCE Node Health',
    PCEService: 'PCE Service',
    PCEServiceAlerts: 'PCE Service Alerts',
    PendingCount: '{count} Pending',
    Percent: '{val, number, percent}',
    PolicyDatabase: 'Policy Database',
    Primary: 'Primary',
    Replica: 'Replica',
    ReplicationLag: 'Replication Lag',
    ReportingDatabase: 'Reporting Database',
    RequiredServicesNotRunning: '{count, plural, =1 {# Required service} other {# Required services}} not running',
    RollingUpgrade: 'Health unavailable until upgraded',
    Seconds: '{seconds, plural, =0 {# seconds} =1 {# second} other {# seconds}}',
    SecondsBehindCluster:
      '{seconds, plural, =0 {# seconds} =1 {# second} other {# seconds}} behind <strong>{cluster}</strong> {unreachable, select, true {(Unreachable)} false {}}',
    Secs: '{seconds, plural, =0 {} =1 {SEC} other {SECS}}',
    ServicesDegraded: '{count, plural, =1 {# Service} other {# Services}} Degraded',
    ShowDetails: 'Show Details',
    SNC: 'SNC',
    Supercluster: 'Supercluster',
    SuperclusterHealth: 'Supercluster Health',
    SuperclusterIlluminationSync: 'Supercluster Illumination Sync',
    SuperclusterReplication: 'Supercluster Replication',
    SyncStatus: 'Sync Status',
    TrafficDatabase: 'Traffic Database',
    UnableToGetLastGeneratedTS: 'Unable to get last health check timestamp',
    UpgradeStatus: 'Upgrade Status',
    UpSinceDate: 'Up since {when, date, L_hh_mm_ss}',
    ViewPCEHealth: 'View PCE Health',
    VirtualAppliance: 'Virtual Appliance',
    Weeks: '{weeks, plural, =0 {} =1 {WEEK} other {WEEKS}}',
    WorkerNode: 'Worker Node {role, select, primary {(Primary)} replica {(Replica)} other {}}',
    WorkerNodeHealth: 'Worker Node Health',
    WorkerNodes: 'Worker Nodes',
  },
  Help: {
    Desc: {
      AcceptedCoreService:
        'Displays the list of core service recommendations that were accepted. Accepted recommendations are labeled. If the accepted service is an unknown IP address, an unmanaged workload is created automatically.',
      AccessConfiguration: 'PCE supports LDAP authentication with OpenLDAP and Active Directory.',
      AccessRestrictions:
        'As an admin, you can restrict access to API Keys that are used to automate the PCE management tasks. Restrictions are based on IP addresses and are used to block API requests coming in from non-allowed IP addresses.',
      ActiveVersion: 'Displays the policy currently enforced by the PCE.',
      AllowSelectedConnections:
        'Connections that you designate as being allowed to cross a Deny Rule. After creating a Deny Rule, you can select some or all connections that cross the boundary. The PCE web console then displays proposed rules for these connections that you can accept as is or modify.',
      APIKeys: 'Allow authentication with Illumio for making API calls.',
      APIKeySettings:
        'Set the expiration time for service account API keys, from 1 day to Never Expires. Specify how long expired service account API keys are retained in the PCE database, from 0 to 365 days.',
      AppGroup: 'Traffic organized as a collection of Labels.',
      AppGroupDetailsRules:
        'Allow communication between multiple applications or entities in different Scopes or the same Scope.',
      AppGroupExplorer:
        "Queries the PCE's traffic database to analyze traffic flows for auditing, reporting, and troubleshooting purposes.",
      AppGroupList: 'View and edit App Groups.',
      AppGroupMapSeverity:
        'You can select which severity level to consider when showing which traffic is going to vulnerable ports.',
      AppGroupPolicyGenerator:
        'Simplifies the policy creation process by recommending the optimal security policy for your App Groups.',
      AppGroupSummaryReport:
        'Reports designed for application owners (for example, members of your business applications group like your Oracle or ServiceNow app admins) or other people in your organization who need to understand the security of you applications, such as IT security auditors (for example, auditors of PCI or HIPA systems).',
      AppGroupType: 'Configure App Groups by selecting two or three matching Labels.',
      ApplicationLabel: 'Identifies the application instance of the Workload.',
      Attributes: 'Details about the Workload, such as VEN version, hostname, and uptime.',
      Blocked: 'No Rules in the active policy allow the traffic and Workload is in Enforced policy state.',
      CCServices: 'Kubelink creates a virtual service in the PCE for services in the container cluster.',
      ConnectedGroup: 'A collection of Workloads that communicates with another collection of Workloads.',
      Connection: 'Traffic summaries grouped by Role for traffic on different destination ports & protocols.',
      ConsumingAppGroups: 'Applications that use Services and can initiate connections.',
      ContainerClusters: 'An object used to store all the information about a container cluster in the PCE.',
      ContainerInheritHostPolicy:
        'Enforces host policy for all the docker containers running on the host when enabled.',
      ContainerWorkloadInIllumination:
        'After deploying containers, you can view container workloads and traffic associated with them in Illumination, App Group Map, Vulnerability Map, and Explorer.',
      ContainerWorkloads: 'Represents a Pod in the PCE that is being secured by Illumio Core.',
      CoreServices:
        'Identify core services (such as DNS, Domain Controllers, NTP, and LDP) and use the suggested labels for them.',
      CoreServicesDetector:
        'A feature to detect and label workloads running core services based on the PCE analysis of traffic and process data.',
      CustomiptablesRules: 'Preserve any iptables configured on Linux hosts and add them to your policy as a Rule.',
      CustomLabelType: 'Any user-defined type created by editing the Label Settings.',
      CVEIds:
        'Unique public identifiers (designated by the NCF organization) for Common Vulnerabilities and Exposures (CVEs)',
      Destination: 'Workloads, services, or IP ranges that can initiate a connection to a destination.',
      DetectionModel:
        'The method that the PCE uses to detect core services: port matching, port-based machine learning (ML), or process-based ML. The methods are not configurable and the algorithms run continuously. Click the detection method to view more information.',
      DraftVersion: 'Displays the proposed changes that must be provisioned before they are applied to the policy.',
      DraftView: 'Previews expected policy coverage for unprovisioned changes.',
      DuplicateRuleset: 'Copy an existing Ruleset.',
      Enforcement:
        'Three states (Idle, Visibility Only, or Enforced) that determine whether the policy is applied and at what level.',
      EnforcementBoundaries:
        'Sets of rules that determine where rules apply. Deny Rules can block traffic from communicating with workloads you specify, while still allowing you to progress toward a Zero Trust environment. Unlike firewall policies, boundaries provide a simple policy model that does not depend on rule order.',
      EnforcementBoundaryBlockedConnections:
        'Page that displays the traffic flows blocked between workloads due to a Deny Rule. Review the blocked connections and add rules for connections that must traverse the boundary.',
      EnforcementBoundaryWorkflow:
        'After creating a Deny Rule, the PCE web console guides you through the process to review blocked connections and add any required rules (allowlist policy) to traverse the boundary.',
      EnvironmentLabel: 'Identifies the network environment of the Workload (such as Staging or Production).',
      EssentialServiceRules:
        'Essential Service Rules are programmed on the VEN to allow normal network functionality and communication with the PCE.',
      EventConfiguration: 'Configure the Severity, Retention Period, and Format for the events to be logged.',
      Events:
        'Provides an ongoing log of all Organization events that occur in the PCE, which you can filter by severity, status, event type, timestamp and also export the list.',
      EWExposure:
        '"East-West Exposure": A count of workloads that can use a vulnerable port with the currently provisioned rules',
      ExecutiveSummaryReport:
        'High-level reports designed to provide information to decision makers, such as an organization’s CISCO or VP if IT, about the overall deployment of Illumio within the organization’s computing environment.',
      Explorer:
        'Analyze traffic flows for auditing, reporting, and troubleshooting purposes. You can view traffic by time, port, consumers, providers, and services. Explorer is not an interactive tool, so you cannot write rules using it.',
      ExplorerSearchFormats:
        'View search results in the form of Parallel Coordinates, Table, or Unmanaged IP Addresses.',
      ExposedVulnerabilities: 'A list of vulnerabilities which will have exposure based on this rule',
      ExposureScore: 'The E/W Exposure Score combined with the Northern Exposure',
      ExtraScope: 'Allow communication between different App Groups.',
      ExtraScopeBlocked: 'These vulnerabilities will have no Extra-Scope E/W Exposure after creating this policy',
      ExtraScopeConstrained:
        'These vulnerabilities will have some limited Extra-Scope E/W Exposure after creating this policy',
      ExtraScopeRules: 'Allow communication between applications.',
      ExtraScopeVulnerabilityExposure:
        'The number of vulnerabilities which have either some extra-scope E/W Exposure or no intra-scope E-W Exposure.',
      FirewallCoexistence:
        "Supplement Illumio's firewall with your organization's firewalls using Firewall Coexistence.",
      GlobalRoles:
        'Uses the ‘All Applications, All Environments, and All Locations’ scope. The scope for the Global roles cannot be changed.',
      HighestVEScore: 'The highest individual Vulnerability-Exposure score for this Workload',
      IKECertificates:
        "The IKE Certificates may display either the full or partial Distinguished Name that appears in the Issuer's field in an end-entity certificate. This filter is used to identify the proper end-entity certificate in a list of certificates. To configure Admin Access, you must indicate a valid IKE Certificate issuer.",
      IlluminationMap: 'Visualizes traffic flows between Workloads.',
      IlluminationPlus:
        'Analyze traffic flows for auditing, reporting, and troubleshooting purposes. You can view traffic by time, port, consumers, providers, and services. Illumination Plus is an interactive tool. You can write rules from the Table View and connections tab in the Map View.',
      IlluminationPlusSearchFormats: 'View search results in the form of Table, Map or Mesh.',
      InternetBlocked: 'These vulnerabilities will have no Northern Exposure after creating this policy',
      InternetConstrained: 'These vulnerabilities will have some Northern Exposure after creating this policy',
      InternetExposure:
        'Indicates whether a vulnerable port is exposed to traffic from an IP List. Northern Exposure is enabled by rules allowing inbound traffic on that port.',
      InternetVulnerabilityExposure:
        'The number of vulnerabilities which have either some Northern Exposure or no Northern Exposure.',
      IntraScope: 'Allow communication within an App Group.',
      IntraScopeBlocked: 'These vulnerabilities will have no Intra-Scope E/W Exposure after creating this policy',
      IntraScopeConstrained:
        'These vulnerabilities will have some limited Intra-Scope E/W Exposure after creating this policy',
      IntraScopeRules: 'Allow communication within a specific Scope.',
      IntraScopeVulnerabilityExposure:
        'The number of vulnerabilities which have either some intra-scope E/W Exposure or no intra-scope E-W Exposure.',
      IPList: 'Set of IP addresses, an IP range, or CIDR blocks used to define an allowlist.',
      Kubelink:
        'A software component provided by Illumio for making integration between the PCE and the orchestration platform easier.',
      LabelAndLabelGroupsExcept:
        'In Labels and Label Groups Except, select labels that define roles, applications, environments, and locations you want to exclude from the policy.',
      LabelGroup:
        'A collection of Labels that can be used in a Rule or Scope to apply the policy to all the Workloads using those Labels.',
      LoadBalancers: 'Distributes network or application traffic across servers.',
      LocationLabel: 'Identifies the physical location of the Workload (such as AWS or US).',
      Managed: 'Resources that are paired with the PCE and have a VEN.',
      Microsegmentation:
        'Allows all Workloads within an App group to communicate with other Workloads within the same App group across all ports.',
      MyManagedTenants:
        'My Managed Tenants allows Managed Services Providers (MSPs) to onboard their customers into Illumio Edge (SaaS) and then manage and administer Edge on their behalf.',
      MyProfile: 'Set your profile and change your account password.',
      Nanosegmentation:
        'Allows Workloads with a specific Role Label to communicate with Workloads that have other Role Labels on ports where there is observed traffic between the Roles.',
      Networks:
        'A PCE provides visibility and policy enforcement for workloads that live on isolated networks with overlapping IP address space.',
      NewUI:
        'Illumio is excited to announce a new user interface. Our New PCE UI is designed to maximize user productivity and enable intuitive platform administration. Customers control the pace at which they adopt the New UI. To enable, flip the <strong>New UI</strong> toggle in the top right corner.',
      NoHelp: 'There is no help for this page. Click the link below to visit the support portal.',
      OfflineTimers: 'Set VEN Disconnect and Goodbye timers.',
      OpenShift:
        'Enterprise container-based PaaS built on top of Docker and Kubernetes, which adds additional capabilities such as, software defined networking.',
      OrganizationalOutboundPolicy:
        'Allow and deny rules at the organizational level (apply to all groups) for outbound traffic.',
      OrgEvents: 'A log of all user actions that occur in the PCE, such as login/logout.',
      PairingProfile:
        'Configuration that allows you to apply properties such as Labels or policy state to Workloads paired with the PCE.',
      PasswordPolicy:
        'Set password policies such as password length, composition (required number and types of characters), and password expiration, re-use, and history.',
      PCEHealth:
        'Monitor the PCE Health status by clicking the Health icon at the top of the page. The PCE Health page indicates whether the PCE is in a Normal, Warning, or Critical state of health. Tabs categorize the health information by Node, Application, Database Replication, and Supercluster.',
      PCESupportBundles:
        'Generate diagnostic information for this PCE. Displays individual node status until complete, then makes an aggregate support bundle available for download.',
      PolicyCheck:
        'Determines if Rules that allow communication between Workloads or a Workload and an IP address exist.',
      PolicyGeneratorSeverity:
        'You can select which severity level to consider when mitigating vulnerabilities by selecting Auto Level in the Rule Configuration.',
      PolicySettings:
        'When Display Scopes in Rulesets is set to No, each ruleset applies to all workloads. When it is set to Yes, rulesets apply only in specified environments, locations, or applications. Provisioning Setting describes context before provisioning rules. By default, it is set to ‘No’. On changing it to ‘Yes’, makes providing a ‘Provision Note’ mandatory before provisioning rules. Also allows the order of Provider and Consumer columns to be changed across the UI.',
      PolicySync: 'Applies recent active policy configurations to the Workload.',
      PortAndProtocol:
        'A port is an endpoint for TCP or UDP communications. A protocol is the set of rules used to send or receive communications.',
      PortBased: 'Machine learning detection method based on connections to specific ports.',
      PortMatch: 'Rule-based detection method based on connections to specific ports.',
      PotentiallyBlocked: 'No Rules in the active policy allow the traffic and Workload is in Test policy state.',
      ProcessBased: 'Machine learning detection method based on processes running on the servers.',
      ProvidedTraffic:
        'Traffic that has been observed into a vulnerable port, and if it was allowed, blocked or potentially blocked',
      ProvidingAppGroups: 'Applications that provide Services and cannot initiate connections.',
      ProvidingService:
        'Resource defined by a Rule that allows specific types of communication (such MySQL) between providers and consumers.',
      Provision:
        'Enacting any changes made to policy objects (Rulesets, IP Lists, Services, Label Groups, and security settings) by recalculating policy on the PCE and transmitting the changes to all VENs.',
      Provisioning:
        'If you have made any changes to provisionable objects, such as rulesets, IP lists, services, label groups, and security settings, you need to provision those changes before they can take effect. The orange badge on the Provision button indicates the number of changes that need to be provisioned. When you provision updates, the PCE recalculates any changes made to rulesets, IP lists, services, label groups, and security settings, and then transmits those changes to all VENs installed on your workloads.',
      ProvisionStatus: 'Displays if changes have been made but not provisioned.',
      RansomwareProtectionDashboard:
        'Provides detailed protection information for your managed server workloads regarding ransomware protection readiness, risk exposure, and protection coverage statistics. Endpoints and container workloads are not included in this dashboard.',
      RecommendedCoreService:
        'PCE displays detected core services as recommendations for creating policy along with suggested labels. You can modify the policy and suggested labels.',
      RecurringReports: 'Reports that are repeatedly generated on the following schedule: daily, weekly, or monthly',
      RefreshDashboard:
        'The PCE updates the information in this dashboard at regular intervals. To force an update, click <strong>Refresh</strong>.',
      RejectConnections: 'Workload instructed by the PCE to reject the blocked connection.',
      RejectedCoreService:
        'Displays the list of core service recommendations that were rejected. When you reject a core service recommendation, that IP address is no longer recommended as a provider of the requested core service.',
      ReportedView: 'Shows current policy coverage as reported by Workloads.',
      Reporting:
        'Generate, download, and manage two types of recurring reports: Executive Summary reports and App Group Summary reports.',
      ReportRecurrence:
        'Removing a report from the Recurrence tab stops the report from running again. Select the report you want to stop generating and click Remove.',
      ReportRetentionPeriod:
        'Configure globally how long the PCE retains the PDF files generated for each report you add.',
      Reports:
        'Useful when you want to share data with team members who do not have access to the PCE. You can download PCE objects in JSON and CSV formats.',
      ReportType: 'Either an Executive Summary or App Group Summary report.',
      Revert: 'Undoing any provisionable changes before they are provisioned.',
      RoleLabel: 'Defines the function of the Workload (for example, Web or Database).',
      Rule: 'Allows communication between multiple applications or entities providing Services and the applications or entities using the Services.',
      RuleCoverage: 'Percentage of total connections that are allowed by Rules.',
      RuleSearch: 'Search, analyze, and download Rules.',
      Rulesets: 'A collection of Rules that define allowed communications between Workloads based on their Scope.',
      Scope:
        'Applies Rules to a collection of Workloads that are identified by Label type (Application, Environment, & Location).',
      ScopedRoles: 'Assign users to roles with specific scopes.',
      SecureConnect: 'Dynamically encrypts Workload communications using IPsec.',
      SecureGateways:
        'Encrypts communications between managed workloads and the unmanaged workloads on the private subnet protected by the VPN gateway at the edge of the data center.',
      SecuritySettings: 'Organization-wide configuration for features such as DHCP.',
      SegmentationTemplate:
        'Allows you to quickly write a security policy to allow traffic for commonly used applications using a pre-defined set of Services, Rulesets, Labels, Label Groups, and IP Lists.',
      SelectiveEnforcementState:
        'Rules are enforced directionally for selected services when a workload is within the scope of a Deny Rule. To apply a Deny Rule to workloads, you must move the required workloads into the Selective Enforcement state.',
      ServiceAccounts:
        'Service Accounts include API keys by default so that you can use them to perform programmatic operations. A user account is not required to use a Service Account. Any permissions assigned to a Service Account apply to its API key.',
      Services:
        'Ports, protocols, or processes discovered by the VEN or defined by the user that specify the Service type and communication methods used by Workloads.',
      Severity: 'Represents a range of Vulnerability Score values.',
      SeverityCritical: '9.1 - 10 = Critical',
      SeverityHigh: '7.1 to 9.0 = High',
      SeverityInfo: '0 = Info',
      SeverityLow: '0.1 to 4.0 = Low',
      SeverityMedium: '4.1 - 7.0 = Medium',
      SID: 'Security identifier, a unique identification associated with each user or user group in the Active Directory.',
      Source: 'Workloads, services, or IP ranges that can initiate a connection to a source.',
      SSOConfig:
        'Configure a single sign-on (SSO) identity provider in the PCE to validate usernames and passwords against your own user management system.',
      StaticPolicy:
        'Control when updates to OS-level firewall rules are applied to your Workloads by adding static policy.',
      Status: 'Displays whether the Rule is enabled or disabled.',
      SupportReports:
        'Generate diagnostic information for the selected Workload. The report can be downloaded from the Support Reports page.',
      TotalAppGroupVEScore: 'The sum of all Vulnerability-Exposure scores for this App Group',
      TotalWorkloadVEScore: 'The sum of all Vulnerability-Exposure scores for this Workload',
      TrafficLines: 'Represents traffic that is blocked (red) or allowed (green) or unknown (gray).',
      Unmanaged: 'Allow you to write policy for resources that are not paired with the PCE and that do not have a VEN.',
      UserActivity: 'View the audit trail of user’s activity along with the permission and authentication details.',
      UserGroup:
        'Collection of users with access to the PCE that can be mapped to an Active Directory or used to control outbound access for specific Workloads.',
      UserProfile:
        'Contains the stored settings for your Illumio Core user account. A user can update information about their account, such as their preferred timezone, how to view policy in the UI, generate REST API keys, and view the permissions they have for managing security policy in Illumio Core.',
      UsersAndGroups: 'Add or remove local users, external users, and external groups.',
      UseWorkloadSubnets:
        'Select Use Workload Subnets if you want the peers to get the workloads’ subnets instead of the individual IPs. The subnets will be determined by the IP address and netmask reported by the VEN.',
      VenActions: 'Upgrade, unpair, refresh, and generate support bundles.',
      VENConnectivity: 'Whether VEN is online or offline.',
      VENDashboard:
        'Provides an operational dashboard to give customers broad, visual information about VEN statistics. The VEN Dashboard aggregates various system data and helps customers focus on important insights into their managed workloads.',
      VenInfo: 'Installed on each workload and provides information about the Workload and enforces policy rules.',
      VenPairingProfiles: 'Allows you to apply certain properties to Workloads as they pair with the PCE.',
      VenPairingScripts:
        'Contains a unique pairing key at the end of the script that identifies the VEN securely, so it can authenticate with the PCE.',
      Vens: 'PCE can be a repository for distributing, installing and upgrading the VEN software. PCE-based deployment of VENs replaces the external VEN repository.',
      Versions: 'Restore to any previous version of the Ruleset.',
      VEScore: 'A calculated value based on the Vulnerability Score and the Exposure Score.',
      VEScoreAppGroupAggregation: 'For an App Group it is a summation of all V-E Scores in the App Group',
      VEScoreMultipleAggregation:
        'It can be shown for an individual vulnerability on a port for a single workload or as a summation of all the V-E Scores for a Workload, Role, or App Group.',
      VEScoreWorkloadAggregation: 'For a Workload it is a summation of all V-E Scores on the Workload',
      VirtualServers: 'A server shared by multiple owners.',
      VirtualService:
        'Allow you to label processes or services on workloads. Illumination, Policy Generator, and Explorer support virtual services.',
      VirtualServices:
        'Allow you to associate a Label or set of Labels with processes or Services on Workloads to dynamically calculate Rules in your policy.',
      VulnerabilityName: 'The name of the vulnerability',
      VulnerabilityPortProtocol: 'The port and protocol associated with the vulnerability',
      VulnerabilityScore:
        'The summation of severities of the vulnerabilities for an App Group, Role, or Workload where the individual vulnerability scores range between 0 and 10',
      Workload:
        'A discrete operating system instance running on a physical device, virtualization platform, or a cloud instance.',
      WorkloadCount: 'Number of workloads in the App Group with this vulnerability',
    },
    Menu: {
      HelpMenu: 'Help Menu',
      ShowMore: 'Show More',
      VisitSupportPortal: 'Visit Support Portal',
    },
    Title: {
      ActiveVersion: 'Active Version',
      AllowSelectedConnections: 'Allow All or Allow Selected Connections',
      Connection: 'Connection',
      CustomiptablesRules: 'Custom iptables Rules',
      DraftVersion: 'Draft Version',
      EnforcementBoundaryWorkflow: 'Deny Rule Workflow',
      Microsegmentation: 'Microsegmentation',
      Nanosegmentation: 'Nanosegmentation',
      NoHelp: 'No Help Found',
      OrganizationalOutboundPolicy: 'Organizational Outbound Policy',
      Rule: 'Rule',
      RuleCoverage: 'Rule Coverage',
      SelectiveEnforcement: 'Selective Enforcement State',
      TrafficLines: 'Traffic lines',
    },
    TooltipInfo: {
      DenyRules:
        'Deny Rules allow setting up base network hygiene across your organization e.g. blocking risky ports, separating Production vs Non-Production. Deny Rules only apply to workloads in Selective Enforcement and you can create Rulesets to explicitly override Deny Rules.',
      ExtraScopeRule:
        'An Extra-Scope Rule allows communication between Group of Workloads (for example, Group to Group communication).',
      IntraScopeRule:
        'An Intra-Scope Rule allows communication within a Group of Workloads as defined by the scope(set of labels).',
      PairingProfile:
        'Configuration that controls the pairing process of a Workload. A Pairing Profile contains a pairing script with a unique pairing key, Label and policy state assignment, command line restrictions, as well as limits on how many times the pairing script can be used and for how long.',
      Scope:
        'Determines which Workloads receive the rules of the Ruleset. If Workloads share the same Labels as defined in a Ruleset, then those Workloads receive those Rules.',
      SecureConnect:
        'Enables users to encrypt communication between Workload services dynamically using IPsec protocol.',
      VEN: 'Virtual Enforcement Node (VEN) is a local control point of the Illumio Core installed on each workload. VEN provides information about the workload and enforces policy rules by controlling the Linux iptables or Windows Filtering Platform (WFP) tables on a workload.',
      VirtualServer:
        'A PCE configuration that allows you to write a Policy for Virtual Servers whose traffic is managed by Load Balancers in your environment.',
      VirtualService:
        'A Service that originates from a Workload but is labeled separately and can be used in a Rule. Virtual services allow you to label processes or services on Workloads.',
    },
  },
  IlluminationMap: {
    AddRingFenceRuleTooltip: 'Allow all workloads in this group to communicate',
    AllGroupWorkloads: 'All Group Workloads',
    AssignedForUnmanagedWorkloads:
      'Assign Labels for {count} {count, plural, =1 {Unmanaged Workload} other {Unmanaged Workloads}}',
    AxisValue: 'Axis Value',
    BasicModeSearch: 'type to find anything',
    CheckFiltersExclude: 'Check to select items to exclude',
    CircularLayout: 'Make good use of available space.',
    ClearBrush: 'Clear Brush',
    ClickToViewDetailPanel: 'Click to view details',
    CollapsedGroup: 'Collapsed Group',
    CommonLabels: 'Common Set of Labels',
    ConsumerAndOrProvider:
      'Consumer {or, select, true {OR} false {AND}} Provider {not, select, true {is not} false {}}',
    ConsumerOrProvider: 'Consumer or Provider{not, select, true {is not} false {}}',
    CreatedForUnmanagedWorkloads:
      '{count} {count, plural, =1 {Unmanaged Workload} other {Unmanaged Workloads}} Created. Do you want to recalculate the table without the newly created workloads?',
    CreateUnmanagedWorkloadInstruction: 'To create unmanaged workloads, first select an IP List.',
    CustomizeAxes: 'Customize Axes',
    DefaultFilters: 'Default Filters',
    DefaultGraph: 'Default Graph',
    DefaultGroupByRole: 'Default Group by:',
    DestinationIPs: 'Destination IPs',
    DoNotRecalculate: "Don't Recalculate",
    FilteringTips: 'Filtering Tips',
    FQDNExample: 'e.g. www.example.com',
    GlobalFilters: 'Global Filters',
    IlluminationClassic: 'Illumination Classic',
    IPAddressOrCIDRExample: 'e.g. {ipAddress} or {cidrBlock}',
    IPListExample: 'Input the name of an IP list',
    LabelSet: 'Label Set',
    LandingPageInstructions: 'Discover the topology of your organization',
    MapWarning:
      'User-defined Label Types are not supported in the Illumination Map. Use Illumination Plus to see a graph with user-defined Label Types',
    Mesh: 'Mesh',
    NodeExamples: 'Node Examples',
    NumberOfFlows: 'Number of Flows',
    OrganicLayout: 'Reduces overlaps in Label Sets, groups and traffic lines.',
    PaginationNextPrevInfo: 'Load {direction, select, next {older} previous {more recent}} connections',
    PaginationPageInfo: '{offsetStart, number} - {offsetEnd, number} of {flowsCount, number} connections',
    PaginationPageInfoShort: '{offsetStart, number} - {offsetEnd, number} of {flowsCount, number}',
    ParallelCoordinatesInfo1: 'Click on an axis value to drill down.',
    ParallelCoordinatesInfo2: 'Drag along an axis (brush) to filter on that dimension.',
    ParallelCoordinatesInfo3: 'Drag an axis title to reorder the axes.',
    PolicyDecisionsByDenyRules: 'Policy Decisions by Deny Rules',
    PolicyGeneratorWarning:
      'User-defined Label Types are not supported in the Policy Generator. Use Illumination Plus to create policy with user-defined Label Types',
    SaveFilterNameExists: 'A filter with that name already exists. This will overwrite the existing filter.',
    SaveFilterNamePlaceholder: 'Input a name',
    SelectedGroupTrafficFilters: 'Selected Group Traffic Filter',
    SetAsDefault: 'Save as default Group By',
    ShowExclusionFilters: 'Show Exclusion Filters',
    ShowReportedPolicyDecisionFilter: 'Show Reported Policy Decision Filter',
    SortBy: 'Sort By',
    SourceIPs: 'Source IPs',
    TieredLayout: 'Highlights the source/destination relationship between Label Sets and groups.',
    Top: 'Top',
    TransmissionExample: 'Broadcast, Multicast, Unicast',
    UnmanagedConsumerIP: 'Unmanaged Consumer IP',
    UnmanagedProviderIP: 'Unmanaged Provider IP',
    UnmanagedWorkloadError:
      'An unexpected error occurred. Could not create {count, plural, =1 {unmanaged workload} other {unmanaged workloads}}.',
    ViewDetailPanel: 'View Details (left-click again)',
    ViewTable: 'View Table',
    ViewUnmanagedIPAddresses: 'View Unmanaged IP Addresses',
    WorkloadsFromMap: 'Workloads From Map',
  },
  Illumio: {
    About: {
      Core: 'About Illumio Core',
      Xpress: 'About Illumio Xpress',
    },
    Copyright: 'Copyright © 2013-2023 Illumio, Inc. All Rights Reserved.',
    Logo: 'Illumio Logo',
    Name: 'Illumio',
    Patent: 'This product may be subject to one or more U.S. or foreign patents listed at <a>{link}</a>',
    Privacy: 'Illumio Privacy <a>{link}</a>',
    Terms: 'Illumio Terms and Agreements <a>{link}</a>',
    Welcome: 'Welcome to Illumio',
    Welcometitle: 'Welcome',
  },
  InstantSearch: {
    ClearAll: 'Clear All',
    RecentSearches: 'Recent Searches',
    SearchByFilter: 'Search {filter} by name',
    TabToFilter: 'Tab to filter',
  },
  IPLists: {
    AddNew: 'Add a new IP List',
    Any: 'Any (0.0.0.0/0 and ::/0)',
    AutomaticDisableValidation:
      'Disable validation of IP addresses and FQDNs is automatically enabled when a total count of {count} IP addresses and FQDNs is reached.',
    CIDRBlock: 'CIDR block',
    Delete: 'Remove {count, plural, =1 {IP List} other {# IP Lists}}',
    DeleteConfirm: 'Are you sure you want to remove this IP List?',
    EnableValidation: 'Disable validation of IP addresses and FQDNs',
    EnableValidationTooltip:
      'For performance reasons, it is recommended to disable real-time IP Address and FQDN validation when working with large sets of IP Addresses and FQDNs.',
    Errors: {
      Create: 'Unable to Create IP List',
      Edit: 'Unable to Edit IP List',
      Experimental: 'Experimental Class E not allowed',
      FQDN: 'Invalid FQDN Entry',
      Ip4: 'Invalid IPv4',
      Ip4Fqdn: 'Invalid IPv4 or DNS Entry',
      NoLeadingZero: 'The first octet cannot be 0',
      RequestFacetFail: 'Request to check against IP allowed names is failing. Please try again',
    },
    Exists: 'IP List <a>{name}</a> already exists',
    InvalidMessage: 'Please add a valid Name and IP Range.',
    IPAddresses: 'IP Addresses',
    IpName: 'New IP Name',
    List: {
      DuplicateErrorLineMessage: '{duplicateMessage} duplicate at line {duplicateAt}',
      Error:
        '{count, plural, =1 {<span class="{className}">#</span> Error} other {<span class="{className}">#</span> Errors}} (Hover over an Error to see details)',
      Exclusion:
        '{count, plural, =1 {<span class="{className}">#</span> Exclusion} other {<span class="{className}">#</span> Exclusions}}',
      Label: 'IP Address/Domain',
      Message: {
        CannotDeleteAny: 'This is a default IP List and cannot be edited or removed.',
        CannotDeleteBoundToEnforcementBoundary:
          'Cannot remove {count, plural, =1 {IP List that is} other {# IP Lists that are}} referenced by an existing Deny Rule',
        CannotDeleteBoundToRules:
          'Cannot remove {count, plural, =1 {IP List that is} other {# IP Lists that are}} referenced by an existing Rule',
        CannotDeleteBoundToSCG:
          'Cannot remove {count, plural, =1 {IP List that is} other {# IP Lists that are}} referenced by an existing SecureConnect Gateway',
        CannotEditLargeLists: 'This IP List is too large to be edited',
        RemoveConfirm: 'Are you sure you want to remove the selected IP {count, plural, =1 {List} other {Lists}}?',
        RemoveResultTitle: 'Result of {count, plural, =1 {IP List} other {IP Lists}} removal',
        RemoveSuccess: '{count, plural, =1 {IP List has} other {# IP Lists have}} been successfully removed',
      },
      OverlappingAddresses: 'Overlapping Addresses',
      OverlappingFQDN: 'Overlapping FQDN',
      OverlappingInterfaces: 'Overlapping Interfaces',
      Range: 'Must add a containing IP range',
      Warning:
        '{count, plural, =1 {<span class="{className}">#</span> Warning} other {<span class="{className}">#</span> Warnings}}',
      ZeroValidity: "0.0.0.0 is valid address, but it's not used as Any (0.0.0.0/0) and ::/0)",
    },
    Mixin: {
      CIDRBlock: 'CIDR Block',
      Comments: 'Comments',
      Excludes: 'Excludes',
      ExclusionInclusionText: 'The excluded IP address(es) must be within the included IP range.',
      ExclusionTip:
        'Use an exclamation point to <span class="{className}">exclude</span> an IP address, CIDR block or IP range.',
      FQDN: 'Fully Qualified Domain Name',
      HashTip: 'Use a hash symbol to indicate a line comment',
      HyphenTip: 'Use a hyphen to indicate an IP range',
      InstructionsExample: {
        CIDR: '192.168.100.0/24',
        CIDRV6: '2620:0:860:2::/64',
        Comment: '23.4.55.6 #Comment Text',
        Exclusion: '!192.168.100.0/30',
        ExclusionV6: '!3ffe:1900:4545:3:200:f8ff:fe21:67cf',
        IllumioFQDN: 'www.illumio.com',
        Range: '10.0.0.0-10.255.255.255',
        Single: '127.0.0.1',
        SingleV6: '2001:0db8:0a0b:12f0:0000:0000:0000:0001',
        Wildcard: '*.illumio.com',
      },
      IPListDescription: 'Type a description for the new IP List',
      IPListName: 'Type a name for the new IP List',
      IPVersions: 'IPv4 or IPv6',
      Ranges: 'IP Ranges',
      SingleIP: 'Single IP',
      SlashTip: 'Use a slash to indicate a CIDR Block',
      WildcardMatches: 'Wildcard Matches',
    },
    NoData: 'No IP Lists to display',
    NoMatchData: 'Not matching IP Lists to display',
    Remove: 'Remove IP List',
    TypeOrPasteAndEnter: 'Type or paste in one or more IP Addresses or wildcard FQDNs and then press enter',
    TypeOrPasteIPs: 'Type or paste IP addresses',
    TypeOrPasteQualifiedName: 'Type or paste fully qualified names',
  },
  KubernetesWorkloads: {
    Kind: 'Kind',
  },
  LabelGroups: {
    AddMembers: 'Add new members',
    AddNew: 'Add a new Label Group',
    Create: {
      Placeholder: {
        LabelGroupDescription: 'Type a description for the new Label Group',
        LabelGroupName: 'Type a name for the new Label Group',
        LabelGroupType: 'Choose a type for the new Label Group',
      },
    },
    CreateError: 'Cannot Create Label Group',
    Delete: 'Remove {count, plural, =1 {Label Group} other {# Label Groups}}',
    DeleteConfirm: 'Are you sure you want to remove this Label Group?',
    DeleteMembers: 'Remove Label Group Members',
    DeleteMembersConfirm: 'Are you sure you want to remove the selected members from the Label Group?',
    List: {
      DeleteSelectedLabelGroups: 'Are you sure you want to remove the selected Label Groups?',
      RemoveResultTitle: 'Result of {count, plural, =1 {Label Group} other {Label Groups}} removal',
      RemoveSuccess: '{count, plural, =1 {Label Group has} other {# Label Groups have}} been successfully removed',
    },
    MemberExist: 'Label Group Members exists',
    Members: 'Label Group Members',
    New: 'New Label Group',
    NoMembers: 'No Members',
    Types: 'Label Group Types',
    UpdateError: 'Cannot Update Label Group',
  },
  Labels: {
    AddNew: 'Add a new Label',
    AddScopeForStaticPolicy: 'Add Scope for Static Policy',
    AllowLabelsHnt: 'Allow any',
    AlreadyDeleted: 'The label is already deleted',
    Application: 'Application Label',
    ApplicationHint: 'Select an Application',
    Assign: 'Assign Labels',
    AssignVS: 'Create Virtual Service - Assign Labels',
    ClearMessage:
      'All {labelNames} Labels for {count, plural, =1 {Workload} other {<strong>#</strong> Workloads}} will be cleared.',
    ConsumerEdit: 'Edit Consumer Labels',
    Create: {
      Placeholder: {
        LabelName: 'Type a name for the new Label',
        LabelType: 'Select a type for the new Label',
      },
    },
    CreateVSPreview: 'Create Virtual Service - Preview',
    CurrentLabelsInfo: 'The current labels are showed in gray',
    CustomLabelType: 'Custom Label Type',
    Delete: 'Remove Label',
    DeleteConfirm: 'Are you sure you want to remove this Label?',
    Deleted: 'The label is deleted',
    DeleteSeveral: 'Remove Labels',
    Detail: {
      CreatedAt: 'Created at',
    },
    Edit: 'Edit Labels',
    EditScopeForStaticPolicy: 'Edit Scope for Static Policy',
    Environment: 'Environment Label',
    EnvironmentHint: 'Select an Environment',
    Errors: {
      Create: 'Unable to Create Label',
      Edit: 'Unable to Edit Label',
      UnableToRemove: 'Unable to Remove Label',
    },
    Excluded: 'All {type} except',
    Exists: 'Label <a>{name}</a> already exists',
    Group: 'Label Group',
    GroupExists: 'Label Group <a>{name}</a> already exists',
    Groups: 'Label Groups',
    GroupsCount: '{count, plural, =1 {# Label Group} other {# Label Groups}}',
    InUse: 'Labels in Use',
    InvalidLabel: 'Invalid Label',
    InvalidLabelCantSave: 'Cannot save invalid Label',
    List: {
      DeleteConfirm: 'Are you sure you want to delete the selected {count, plural, =1 {Label} other {Labels}}?',
      RemoveSuccess: '{count, plural, =1 {Label has} other {# Labels have}} been successfully removed',
    },
    Location: 'Location Label',
    LocationHint: 'Select a Location',
    ModifyForUnmanagedWorkloads:
      'Assign Labels for {count, plural, =1 {Unmanaged Workload} other {<strong>#</strong> Unmanaged Workloads}}.',
    ModifyForWorkloads:
      'Modify Label assignments for {count, plural, =1 {Workload} other {<strong>#</strong> Workloads}}.',
    NamePlaceholder: 'Label Name',
    New: 'New Label',
    NoData: 'No Labels to display',
    NoDataGroup: 'No Label Groups to display',
    NoMatchData: 'No matching Labels to display',
    NoMatchGroupData: 'No matching Label Groups to display',
    ProviderEdit: 'Edit Provider Labels',
    RoleHint: 'Select a Role',
    Select: 'Select {multiple, select, false {Label} true {Labels}}',
    SelectByName: 'Type or select a Label by name',
    SelectLabelTypes: 'Select all Label Types',
    SelectToFilterViews: 'Labels to filter',
    SelectType: 'Select {type} Label',
    TooManySelected: 'Too Many Selected Workloads',
    Type: 'Label type',
    UsedInContainerCluster: 'In use by one or more Container Clusters',
  },
  LabelSettings: {
    BackgroundColor: 'Background Color',
    BlankSpaceError: 'This field can not be blank space',
    CreateNewType: 'Create New Label Type',
    DisplayNamePlural: 'Display Name (plural)',
    DisplayNameSingular: 'Display Name (singular)',
    DragAndDropTooltip:
      'Reordering of the label types causes the labels to appear in that order across the UI. Reordering is supported only when the table is not sorted by any other column.',
    EditType: 'Edit Label Type',
    ForegroundColor: 'Foreground Color',
    Icon: 'Icon',
    IconPrefix: 'Icon + Prefix',
    IconSuffix: 'Icon + Suffix',
    InitialDuplicated: 'Label Type Initial already exists',
    InitialLetter: 'Initial Letter',
    InitialLimitError: 'Valid range 1-2 characters and must be unique',
    InitialRequired: 'Please enter a valid Label Type Initial',
    LabelGroupInUse: 'Label Group In Use',
    LabelIdentifier: 'Label Identifier',
    LabelInitial: 'Label Type Initial',
    LabelInitialDescription:
      'The label type initial is used to create a unique icon for the labels.\n Maximum character limit (2 characters).',
    LabelInUse: 'Label In Use',
    LabelKeyDescription: 'Enter a unique key for the new Label Type. (used internally)',
    LabelKeyDuplicated: 'Key already exists',
    LabelKeyRequired: 'Please enter a valid Key',
    LabelKeyTooLong: 'Key must not be more than 64 characters',
    LabelLabelGroupTypes: 'Label and Label Group Types',
    LabelNameDuplicated: 'Name already exists',
    LabelNameRequired: 'Please enter a valid Name',
    ReachLimitNumberNotification: 'You have reached the limit of {count} label types',
    RemoveLabelType: 'Remove Label Type',
    RemoveResultTitle: 'Result of {count, plural, =1 {Label Type} other {# Label Types}} removal',
    RemoveSuccessfully:
      'The following {count, plural, =1 {Label Type has} other {# Label Types have}} been successfully removed',
    RemoveWarningMessage:
      'Removing a label type decouples it from everywhere and not visible. Are you sure you want to remove the selected Label type?',
    SelectIcon: 'Select Icon',
  },
  Landing: {
    InviteUsers: 'Invite Users',
    ViewDocumentation: 'View Documentation',
  },
  LoadBalancers: {
    AddNew: 'Add a new Load Balancer',
    Create: {
      CreateDescription: 'Type a description',
      CreateManagement: 'Type the management IP or FQDN',
      CreateName: 'Type a name',
      CreatePassword: 'Type the Password',
      CreatePort: 'Type the Port Number',
      CreateUsername: 'Type the Username',
      HostTooLong: 'Hostname must contain fewer than {len, number} characters.',
      ManagementSame: 'Management IP/FQDN cannot be the same for both devices.',
      PasswordNotSame: 'Password is not the same',
      PasswordTooLong: 'Password must contain fewer than {len, number} characters.',
      UsernameTooLong: 'Username must contain fewer than {len, number} characters.',
    },
    Delete: 'Remove Load {count, plural, =1 {Balancer} other {Balancers}}',
    DeleteConfirm:
      'Are you sure you want to remove the selected Load {count, plural, =1 {Balancer} other {Balancers}}?',
    Detail: {
      BadCertificate: 'Certificate could not be verified',
      BadCredentials: 'Incorrect Username or Password',
      BadRequest: 'Bad Request',
      CannotConnect: 'Cannot Connect',
      CannotResolve: 'Cannot Resolve',
      DeviceManagement: 'Management IP or FQDN',
      DupDevice: 'Duplicate Device',
      Manage: 'Manage',
      NumberOfDevices: 'Number of Devices',
      Software: 'Software',
      Successful: 'Successful',
      TLSVerified: 'TLS Verified',
    },
    DeviceNumber: '{count, plural, =1 {# (Standalone)} other {# (HA Pair)}}',
    Errors: {
      Create: 'Unable to Create Load Balancer',
      Edit: 'Unable to Edit Load Balancer',
    },
    FirstDeviceProperties: 'First Device',
    List: {
      NotSupportedDesc:
        'Load balancer functionality is not enabled. Before you add a load balancer, pair a Network Enforcement Node with the load balancer functionality enabled with this PCE. For more information, see the <a>PCE Web Console Users Guide</a>',
      RemoveSuccess: '{count, plural, =1 {Load Balancer has} other {# Load Balancers have}} been successfully removed',
    },
    NoMatchData: 'No matching Load Balancer to display',
    Properties: 'Properties',
    SecondDeviceProperties: 'Second Device',
  },
  Map: {
    AppGroupConfigurationNotComplete: 'App Group configuration is not complete',
    AppGroupDisabled: 'App Group Type must be set before App Groups can be generated and displayed',
    AssignLabelsCluster:
      'To write a security policy for this connection, first navigate to Group detail page and assign Scope Labels to all Workloads.',
    AssignLabelsTraffic:
      'To write a security policy for this connection, first navigate to Group detail page and assign all Labels to source and target Workloads.',
    AssignRoleOnLeveledMap: 'It may take a few minutes for this change to reflect correctly on the Map',
    CantChangeLabelsOnContainers: 'Cannot change the labels of Container Workloads.',
    CantPerformOperation: 'Cannot Perform Operation',
    CantPerformOperationMessage: 'Your user account does not have sufficient privileges to perform this operation.',
    CollapseConnectedGroupData: 'Collapse Connected Group',
    CollapseTrafficLinks: 'Collapse Traffic Link',
    ConnectedGroup: 'Connected Group',
    ConnectedGroups: 'Connected Groups',
    ConsumingAppGroups: 'Consuming App Groups',
    Count: 'Count',
    CreateRuleset: 'Create Ruleset',
    CreateUnmanagedWorkload: 'Create Unmanaged Workload',
    CreateUnmanagedWorkloads: 'Create Unmanaged Workloads',
    Data: 'Illumination Map Data',
    DataAge: 'The Illumination map refreshed its data: {time}. To refresh the map now click Refresh.',
    DataGenerated: 'Map data refreshed: {time}',
    DataRefresh:
      'Refreshing the map can take several minutes. Reload your browser to see updates while the map refreshes its data.',
    DataTooLarge: 'Map data is too large and has been truncated.',
    Discovered: 'Discovered',
    DiscoveredGroup: 'Discovered Group',
    DoNotShowAgain: 'Do not show this again',
    DraftView: 'Draft View',
    DraftViewAllowed: 'Draft View > Allowed',
    DraftViewBlocked: 'Draft View > Blocked',
    EditPolicyState: 'Edit Policy State',
    EditPolicyStateWorkloadsOnly:
      'Editing the Policy State affects only the Workloads in this group. The Policy State of Workloads bound to Virtual Services must be edited separately.',
    EnterAppGroup: 'Enter an App Group in the Search bar to view a map of connections',
    ExpandTrafficLinks: 'Expand Traffic Link',
    ExtraScope: 'Extra Scope',
    ExtraScopeConsumers: 'Extra Scope Consumers',
    FlowVisibility: {
      BlockAllowed: 'VEN logs connection information for allowed, blocked and potentially blocked traffic',
      BlockAllowedEdge: 'Illumio Edge logs and display traffic information for allowed and potentially blocked traffic',
      BlockPlusAllowed: 'Blocked + Allowed',
      BlockTraffic: 'VEN logs connection information for blocked and potentially blocked traffic only',
      EnhancedDataCollection: 'Enhanced Data Collection',
      EnhancedDataCollectionDesc:
        'VEN logs byte counts in addition to connection details for allowed, blocked, and potentially blocked traffic',
      HighDetail: 'High Detail',
      HighDetailDesc:
        'VEN logs allowed and blocked connection details (source IP, destination IP, protocol and source port and destination port)',
      IllumioEnhancedDataContact: 'Contact your Illumio sales representative',
      IllumioEnhancedDataDesc:
        'Enhanced Data Collection is a premium feature that allows you to monitor the amount of data transferred between workloads',
      LowDetail: 'Low Detail',
      LowDetailDesc:
        'VEN logs only blocked connection details (source IP, destination IP, protocol and source port and destination port), including all dropped packets',
      MixedDetail: 'Mixed Detail',
      NoDetail: 'No Detail',
      NoDetailDesc: 'VEN does not log traffic connection information',
      NoneDetail: 'VEN does not collect connection details',
    },
    Fqdn: {
      AddPolicy: 'Add Policy FQDN',
      DescriptionPlaceholder: 'Type a description for this Wildcard FQDN',
      Examples: 'hostname.illumio.com, *.illumio.com',
      Hint: 'When used in a rule, allow connections to the following Wildcard FQDN definition',
      Manage: 'Manage FQDN',
      Placeholder: 'Type a Wildcard FQDN name',
    },
    Illumination: 'Illumination map',
    JustNow: 'Just Now',
    LegendPanel: {
      BlockedOrNotVulnerabile: 'Blocked or not Vulnerable',
      Discovered: 'Cannot write Rules for Discovered Groups',
      Legend: 'Legend',
      LoadingData: 'Loading rule data',
      PartiallyAllowed: 'Partially allowed by Rules',
      PotentiallyBlockedVulnerableTraffic: 'Potentially Blocked Vulnerable Traffic',
      UnknownRuleStatus: 'Rules not calculated',
      VulnerableTraffic: 'Vulnerable Traffic',
    },
    LoadConnectedGroupData: 'Expand Connected Group',
    LoadingData: 'Loading map data',
    Locations: {
      LocationGroupsContains: 'This location contains {count} groups.',
      LocationGroupsFindGroup: "Use 'Search Groups' to view the map for a specific group.",
      LocationGroupsLimits: 'Only 200 groups are shown here.',
      LocationsExceedsMaximum:
        'The number of locations exceeds the maximum that can be displayed in Illumination. Please use the Search bar to select a location for viewing.',
    },
    NewerDataIsAvailable: 'More up-to-date Map data is available.',
    NoAppGroupsInScope: 'No App Groups are fully within your scope',
    NoIPAddressesForAny: 'No addresses available for Any(0.0.0.0/0)',
    NoLabeledWorkloads: 'App Groups will be generated as Workloads are Labeled',
    NoPermission: 'The applicable rule is outside your user scope.',
    NoTraffic: 'No traffic has been reported between workloads. Try again later.',
    OtherConnectedGroups: 'Other Connected Groups',
    OverwriteEndpoint: 'Merge Rule - Overwrite {endpoint}',
    OverwriteEndpointMessage:
      "{endpoint} in the existing Rule will be overwritten because you have selected 'All Workloads'.",
    Policy: 'Policy Map',
    PolicyVersionPanel: {
      DraftViewDetails: 'Visualize draft policy to see what will happen when you provision changes',
      PolicyViewDetails: 'Show when traffic is blocked or allowed by policy',
      ReportedViewDetails: 'Visualize policy coverage as reported by your Workloads',
      VulnerabilityViewDetails:
        'Show severity and exposure of workload vulnerabilities and when traffic is inbound to a vulnerable port.',
    },
    PortVulnerabilities: '{count} Port {count, plural, =1 {Vulnerability} other {Vulnerabilities}}',
    ProvidingAppGroups: 'Providing App Groups',
    RecalculatingData: 'Calculating map data',
    RecentlyViewedAppGroups: 'Recently Viewed App Groups',
    ReportedView: 'Reported View',
    RequestLimit: 'Already Using Increased Update Rate',
    RequestLimitMessage:
      'It has been less than {minutes, plural, =1 {one minute} other {# minutes}} since the Workload update rate was increased for this Group. Increased update rate is still in effect.',
    Search: {
      ContainerWorkload: 'Container Workload: {workload}',
      Group: 'Group: {group}',
      Location: 'Location: {location}',
      VirtualServer: 'Virtual Server: {virtualServer}',
      VirtualService: 'Virtual Service: {virtualService}',
      Workload: 'Workload: {workload}',
      WorkloadWithRole: 'Workload: {workload} ({role})',
    },
    SearchAppGroups: 'Choose an App Group',
    SearchConnectedGroups: 'Search Connected Groups',
    SearchConsumingAppGroups: 'Search Consuming App Groups',
    SearchGroups: 'Search Groups',
    SearchIllumination: 'Search Illumination',
    SearchIpAddresses: 'Search IP Addresses',
    SearchProvidingAppGroups: 'Search Providing App Groups',
    SuperClusterDataRefresh: 'This will also sync data from all Supercluster members.',
    TooManyConnectedGroups:
      'Exceeded limit of the number of Connected Groups that can be displayed.\n Open the App Group Map to view the Connected Groups.',
    Traffic: {
      Broadcast: 'Broadcast',
      ClearCounters: 'Clear Traffic Counters',
      ClearForApp: 'For all endpoints in this Group',
      ClearForLocation: 'For all endpoints in this Location',
      ClearForMissingRole: 'For all endpoints without a Role label in this Group',
      ClearForOrg: 'For all endpoints in this Organization',
      ClearForRole: 'For all endpoints with matching Role labels in this Group',
      ClearTrafficMessage: 'Permanently delete traffic data for all links',
      CoreServices: 'DNS/DHCP/IPsec',
      Filter: {
        AllowedByRule: 'Allowed by Rule',
        AllowExtraGroup: 'Extra-Group',
        AllowIntraGroup: 'Intra-Group',
        BlockedNoRule: 'Blocked (No Rule)',
        FilterByConnections: 'Filter by number of connections',
        IgnoreServices: 'Ignore Selected Services',
        NoResult: 'No results. Try different filters.',
        PotentiallyBlockedNoRule: 'Potentially Blocked (No Rule)',
        SinceLastProvision: 'Since Last Provision',
      },
      Multicast: 'Multicast',
      ServiceCount: '{count, plural, =1 {Service} other {Services}}',
      TopServices: 'Top Services',
      Unicast: 'Unicast',
    },
    TrafficLinks: 'Traffic Links',
    TryAgain: 'Try again after viewing the Detail Map.',
    ViewBoundContainerWorkloads: 'View Bound Container Workloads',
    ViewBoundWorkloads: 'View Bound Workloads',
    ViewConnectedGroups: 'View Connected Groups',
    Vulnerability: 'Vulnerability Map',
    Workloads: {
      AddToGroup: 'Add Workload to Group',
      AddToGroupMessage:
        "When a Workload is added to a Group, the Group's Application, Environment, and Location Labels are applied to the Workload.",
      AddToOtherGroup: 'Move Workloads to Other Group',
      AddToOtherGroupMessage:
        'This will impact the Rules associated with the Workloads. And, all the Workloads will acquire the Application, Environment, and Location Labels of the new Group.',
      AddVirtualServerToGroup: 'Add Virtual Server to Group',
      AddVirtualServerToGroupMessage:
        "When a Virtual Server is added to a Group, the Group's Application, Environment, and Location Labels are applied to the Virtual Server.",
      AddVirtualServiceToGroup: 'Add Virtual Service to Group',
      AffectedCount: '{count, plural, =0 {No Workloads} =1 {1 Workload} other {# Workloads}} affected',
      AppGroupTooManyToDisplay: 'Too many Workloads in this App Group to display',
      CantDiscovery: 'Cannot Add or Remove Workloads',
      CantDiscoveryMessage: 'You cannot add or remove Workloads from Groups in the Discovered state.',
      GroupTooManyToDisplay: 'Too many Workloads in this group to display',
      Limit: 'Too Many Workloads',
      LimitMessage:
        'The Workload update rate cannot be increased for this Group because it contains too many Workloads.',
      MustHaveLocationLabels: 'Workloads must have Location Labels to be seen in this view',
      RemoveFromGroup: 'Remove Workloads from Group?',
      RemoveFromGroupMessage:
        'This will impact the Rules associated with the Workloads. And, all the Workloads’ Application, Environment, and Location Labels will be removed',
      RemoveFromGroupNewRole: 'You can optionally select a new Role Label for the Workloads.',
      RemoveOneFromGroup: 'Remove Workload from Group',
      RemoveOneFromGroupMessage:
        'When a Workload is removed from a Group, the Application, Environment, and Location Labels are removed from the Workload.',
      RemoveVirtualServerFromGroup: 'Remove Virtual Server from Group',
      RemoveVirtualServerFromGroupMessage:
        'When a Virtual Server is removed from a Group, the Application, Environment, and Location Labels are removed from the Virtual Server.',
      RemoveVirtualServiceFromGroup: 'Remove Virtual Service from Group',
      RemoveVirtualServiceFromGroupMessage:
        'When a Virtual Service is removed from a Group, the Application, Environment, and Location Labels are removed from the Virtual Service.',
      Role: '{count, plural, =0 {}  =1 {1 Role} other {# Roles}}',
      RoleContainerWorkloads: '{count, plural, =0 {}  =1 {1 Container Workload} other {# Container Workloads}}',
      RoleTotalWorkloads: '{count, plural, =0 {}  =1 {1 Workload} other {# Total Workloads}}',
      RoleTotalWorkloadsOfTotal: '{count, plural, =0 {}  =1 {1 Workload} other {# of {total} Total Workloads}}',
      RoleVirtualServices: '{count, plural, =0 {}  =1 {1 Virtual Service} other {# Virtual Services}}',
      RoleWorkloads: '{count, plural, =0 {}  =1 {1 Workload} other {# Workloads}}',
      RoleWorkloadsOfTotal: '{count, plural, =0 {}  =1 {1 Workload} other {# of {total} Workloads}}',
      TooManyToDisplay: 'Too many Workloads to display',
    },
  },
  Menu: {
    AlarmsAndEvents: 'Alarms and Events',
    ContainerClusters: '{multiple, select, false {Container Cluster} true {Container Clusters}}',
    Infrastructure: 'Infrastructure',
    LoadBalancers: 'Load Balancers',
    Navigation: 'Navigation menu',
    Networks: '{multiple, select, false {Network} true {Networks}}',
    Switch: 'Switch',
    Switches: 'Switches',
  },
  MSP: {
    ActiveCoreVENs: 'Active Core VENs',
    ActiveEdgeVENs: 'Active Edge VENs',
    ActiveTenants: 'Active Tenants',
    ActiveVENs: 'Active VENs',
    AddNewTenant: 'Add New Tenant',
    AddProduct: 'Add {product}',
    Count: '{count, number}',
    CustomerDomain: 'Customer Domain',
    DeleteConfirm:
      'Removing a tenant deletes all associated resources and unpairs any VENs that are paired.<br/>Are you sure you want to remove selected {count, plural, =1 {Tenant} other {# Tenants}}?',
    DeletedSuccessfully: '{count, plural, =1 {tenant has} other {# tenants have}} been successfully removed',
    DeleteTitle: 'Remove {count, plural, =1 {Tenant} other {Tenants}}',
    EndpointVENs: 'Endpoint VENs',
    Errors: {
      Create: 'Unable to Create tenant',
      Edit: 'Unable to Edit tenant',
    },
    Hints: {
      CompanyURL: "The customer's company website URL",
      CustomerDomain: 'Must be a globally unique name in the form of a domain (example.com)',
    },
    ManagedTenants: 'Managed Tenants',
    ManageTenant: 'Manage Tenant',
    MyManagedTenant: 'My Managed Tenant',
    MyManagedTenants: 'My Managed Tenants',
    MySubscription: 'My Subscription',
    NoData: 'No tenants to display',
    Placeholder: {
      Address: 'Type an address for the new tenant',
      City: 'Type a city for the new tenant',
      CompanyURL: "Type a company's URL for the new tenant",
      Country: 'Select a country',
      Domain: 'Type a domain for the new tenant',
      Name: 'Type a name for the new tenant',
      PostalCode: 'Type a postal code for the new tenant',
      Province: 'Type a state, province, or territory for the new tenant',
      State: 'Select a state',
      ZIPCode: 'Type a ZIP code for the new tenant',
    },
    ProductTenant: '{product} Tenant',
    ServerVENs: 'Server VENs',
    TenantManagement: 'Tenant Management',
    Users: {
      GlobalAdminSublabel: 'Administrative access to managed tenants.',
      GlobalOwnerSublabel: 'Manage tenants and users.',
      ReadOnlySublabel: 'Read-only access to events.',
    },
    WelcomeMessage: 'You can create, view and manange tenants in this portal',
    WelcomeMessageLearnMore:
      "If you want to learn more functionality of the Illumio's Multi-tenant portal, please visit the help portal.",
    WelcomeTitle: 'Welcome to Illumio Multi-tenant Portal',
  },
  MultilineInput: {
    ShiftDeleteToDeleteRow: 'Use "shift-delete" to delete a row',
    TypeOrPaste: 'Type or Paste Input and press enter',
  },
  NavigationAlert: {
    Details: 'Navigation error details',
    Error: 'Navigation has been cancelled due to an error',
    ErrorPage: 'The page failed to load due to an error',
    NoConnection: 'Navigation has been cancelled due to inability to establish connection to the PCE Server',
    NoConnectionPage: 'The page failed to load due to inability to establish connection to the PCE Server',
    ProvideRequestId: 'Provide this Request-ID when contacting your support team',
    Timeout:
      'The PCE server seems too busy to be reached, you could try again later or use another page in the meantime',
    Warning:
      'The next page seems to be taking longer than usual to load, continue waiting or press stop to cancel navigation',
    WarningInitial: 'This page seems to be taking longer than usual to load, please continue waiting',
  },
  Network: {
    Delete: 'Remove {count, plural, =1 {Network} other {# Networks}}',
    Errors: {
      Create: 'Unable to Create Network',
      Edit: 'Unable to Edit Network',
      NoScope: 'User defined network should have at least one scope with label(s)',
    },
    IPType: 'IP Type',
    LinkLocal: 'Link Local',
    Message: {
      CannotDeleteBoundToVirtualService: 'Cannot delete Network while referenced by an active or draft Virtual Service',
      CannotDeleteBoundToWorkflowInterface: 'Cannot delete Network while referenced by a Workload Interface',
      RemoveConfirm: 'Are you sure you want to remove the selected {count, plural, =1 {Networks} other {Networks}}?',
      RemoveResultTitle: 'Result of {count, plural, =1 {Network} other {# Network}} removal',
      RemoveSuccess: '{count, plural, =1 {Network has} other {# Networks have}} been successfully removed',
      ScopeCannotBeEmpty: 'Network scope cannot be empty. This scope will be removed after saving.',
      SystemDefaultIsReadOnly: 'This is a default Network and cannot be edited or removed.',
    },
    NetworkSummary: 'Network Summary',
    RemoveConfirm: 'Are you sure you want to remove the Network?',
    ReservedNetworkNames: 'The names "Private" and "Internet" are reserved for System use.',
    SelectIPVersionFirst: 'First, Select an IP Version.',
    Subnets: 'Subnets',
    SystemDefined: 'System Defined',
    UserDefined: 'User Defined',
  },
  ObjectLimit: {
    ContactIllumioSupport: 'Contact Illumio support.',
    HardLimitReached: '{object} hard limit reached',
    IllumioHardLimitReached: 'Illumio {object} hard limit reached',
    RequestId: 'Request-ID: {id}',
  },
  ObjectSelector: {
    AdvancedOptions: 'Advanced Options(cmd+a)',
    FilteringTipsContent: "Use 'in' to search in a category",
    FilteringTipsContentLabels:
      'Use prefix to search by label type. For example,\na: Application\ne: Environment\nLabel prefixes can be found in the Label Settings',
    FilteringTipsLabel: 'Filtering Tips({os, select, mac {cmd} windows {ctrl}}+i)',
    LoadingValues: 'Loading Values...',
    ManyMatchCount: '{name} – {count, number} of {total, number}+ Total',
    MatchCount: '{name} – {count, number} of {total, number} Total',
    MatchedCount: '{name} – {matchedDisplayed, number} of {matchedTotal, number} Matched',
    MatchingResult: 'Matching Result',
    MatchingResults: 'Matching Results',
    MatchingResultsDisabled: 'Cannot match *',
    NoMatchingResults: 'No matching results',
    NumMatchingResults: '{count, number} Matching Results',
    RecentlyUsed: 'Recently Used. Type to find more...',
    SearchAllCategories: 'Search All Categories',
    ShowAll: 'Show all...',
    ShowAllCategories: 'Show All Categories',
    ShowAllMatches: 'show all matches',
    TypeToSearchObject: 'Type to search {object}',
    TypeToShowMore: 'Type to show more',
    TypeToShowMoreObject: 'Type to show more {object}',
    TypeToShowObject: 'Type to show {object}',
  },
  OfflineTimers: {
    CleanUpQuarantineWorkloads1Hour:
      'After waiting 1 hour without receiving a heartbeat, consider the server disconnected, and push an updated policy to remove the IP addresses of the disconnected servers from their peer workloads',
    CleanUpQuarantineWorkloadsEndpoint:
      'After waiting 1 day without receiving a heartbeat, consider the endpoint disconnected, and push an updated policy to remove the IP addresses of the disconnected endpoints from their peer workloads',
    CleanUpQuarantineWorkloadsEndpointSpecificTime:
      'After waiting for the specified time without receiving a heartbeat, consider the endpoint disconnected, and push an updated policy to remove the IP addresses of the disconnected endpoints from their peer workloads',
    CleanUpQuarantineWorkloadsSpecificTime:
      'After waiting for the specified time without receiving a heartbeat, consider the server disconnected, and push an updated policy to remove the IP addresses of the disconnected servers from their peer workloads',
    ConfirmChanges: 'Confirm Timer Setting Changes',
    CustomDisconnectError: 'This setting must be higher than the value of the Disconnect and Quarantine Warning',
    CustomNotificationError: 'This setting must be less than the value of the Disconnect and Quarantine Timer',
    CustomNotificationRequirement:
      'This setting must be greater than 5 minutes (300 seconds) and less than the value of the Disconnect and Quarantine Timer',
    CustomTimeout: 'Custom timeout',
    CustomUninstallWaitTimeMaxError: 'Valid range {min} hour - {count} {unit}',
    CustomWaitTimeError: 'Wait time must be a minimum of {count, plural, =1 {# second} other {# seconds}}',
    CustomWaitTimeErrorHours: 'Wait time must be a minimum of {count, plural, =1 {# hour} other {# hours}}',
    CustomWaitTimeMaxError: 'Valid range {min} seconds - {count} {unit}',
    DaysNumber: 'Custom timeout - {count, plural, =1 {# day} other {# days}}',
    DecommissionIpCleanupEndpointNote: 'Note: The default option of 1 day (86400 seconds) offers higher security.',
    DecommissionIpCleanupNote: 'Note: The default option of 15 minutes (900 seconds) offers higher security.',
    DecommissionSideNote:
      'Time period to wait (default = 15 minutes) after a managed workload sends a goodbye message to mark it offline.',
    DecommissionSideNoteEdge:
      'Time period to wait (default = 1 day) after a managed workload sends a goodbye message to mark it offline.',
    DecommissionTimer: 'Decommission and IP Cleanup Timer',
    DescTitle: 'With this setting the PCE will:',
    DisableEndpoint: 'Disabled - {default}',
    Disconnect: 'Disconnect and Quarantine Timer',
    DisconnectNotification: 'Disconnect and Quarantine Warning',
    DisconnectNotificationSideEndpointNote:
      'Time period to wait (default = Disabled) with no heartbeat before a warning is emitted',
    DisconnectNotificationSideNote:
      'Time period to wait (default = one-quarter of the Disconnect and Quarantine Timer) with no heartbeat before a warning is emitted',
    DisconnectQuarantineNote: 'Note: The default option of 1 hour (3600 seconds) offers higher security.',
    DisconnectSideNote:
      'Time period to wait (default = 1 hour) with no heartbeat before a managed workload is marked offline.',
    DisconnectSideNoteEdge:
      'Time period to wait (default = 1 day) with no heartbeat before a managed workload is marked offline.',
    DisconnectTimer20minOrLess:
      'If the Disconnect and Quarantine Timer is set to 20 minutes or less, the warning will be emitted 5 minutes after the previous VEN heartbeat. In no case will a warning be emitted fewer than 5 minutes since the previous VEN heartbeatbefore emitting a warning event indicating that the endpoint VEN has missed heartbeats. The endpoint VEN will appear in a warning state on the VEN pages',
    EndpointMessage: 'The offline timer settings apply to all workloads that are endpoints',
    Errors: {
      CustomDisconnectNotificationUnit: 'Needs one of these null, -1, custom',
      CustomDisconnectUnit: 'Needs one of these 900, -1, custom',
      DisconnectNotification: 'Needs one of these -1, custom',
      DisconnectTimeout: 'Needs one of these 3600, -1, custom',
      GoodByeTimeout: 'Needs one of these seconds, minutes, hours, days, months',
      UninstallTimeout: 'Needs one of these 168, 1, custom',
    },
    FifteenMinutes: 'Wait 15 minutes - High Security {default}',
    FifteenMinutesCleanup:
      'After waiting for 15 minutes, push an updated policy to remove the IP addresses of the servers that have sent a goodbye message from their peer workloads',
    HoursNumber: 'Custom timeout - {count, plural, =1 {# hour} other {# hours}}',
    IgnoreGoodbyeMessages: 'Ignore goodbye messages from the server VEN',
    IgnoreGoodbyeMessagesEndpoint: 'Ignore goodbye messages from the endpoint VEN',
    KeepAllIPsAndNeverAutoCleanup:
      'After the server VEN is stopped or the host is gracefully shut down, continue to keep the previously reported IP addresses in policy',
    KeepAllIPsAndNeverAutoCleanupEndpoint:
      'After the endpoint VEN is stopped or the host is gracefully shut down, continue to keep the previously reported IP addresses in policy',
    KeepAllIPsAndNeverAutoRemove: 'IP addresses of disconnected VENs can be removed by unpairing the VENs',
    ListenGoodbye: 'Listen for goodbye messages from the server VEN',
    ListenGoodbyeEndpoint: 'Listen for goodbye messages from the endpoint VEN',
    MinDisconnect: 'Minimum is {count, plural, =1 {# second} other {# seconds}}',
    MinGoodbye: 'Minimum is 0 seconds',
    MinUninstall: 'Minimum is 1 hour and Maximum is 185 days.',
    MinutesNumber: 'Custom timeout - {count, plural, =1 {# minute} other {# minutes}}',
    Months: 'Months',
    MonthsNumber: 'Custom timeout - {count, plural, =1 {# month} other {# months}}',
    MustManuallyUninstallVenAfterSevenDays:
      'After 7 days, the VEN record may be completely removed from the PCE. All API requests from this VEN will fail, and the VEN must be manually uninstalled',
    NeverCleanUp: 'Never remove IP addresses - Highest Availability',
    NeverCleanUpOption: 'Never Clean up',
    NeverDisconnectOrQuarantine:
      'Keep the previously reported IP addresses in policy, even if the server VEN has not heartbeat for an extended time',
    NeverDisconnectOrQuarantineEndpoint:
      'Keep the previously reported IP addresses in policy, even if the endpoint VEN has not heartbeat for an extended time',
    NeverTimeoutOption: 'Never Timeout or Quarantine',
    NeverTimeoutOrQuarantine:
      'If the Disconnect and Quarantine Timer is set to "Never Timeout or Quarantine", a warning event will be emitted if 15 minutes have passed since the previous VEN heartbeat',
    NotificationBodyHint:
      '- selecting a longer warning timer may reduce the time between when the warning is emitted and when the workload is removed from policy, leading to potential connectivity issues.\nIllumio recommends that the warning is set to at least 10 minutes (2 VEN heartbeat intervals) so that transient connectivity issues between the PCE and VEN do not result in a large number of warning events.',
    OfflineTimers: 'Offline Timers',
    OneDayCleanup:
      'After waiting for 1 day, push an updated policy to remove the IP addresses of the endpoints that have sent a goodbye message from their peer workloads',
    OneDayWait: 'Wait 1 day - High Security {default}',
    OneHourWait: 'Wait 1 hour - High Security {default}',
    OneQuarterWait: 'Wait one-quarter of the Disconnect and Quarantine Timer - {default}',
    RequiresManualRemoval: 'IP addresses of decommissioned VENs can be removed by unpairing the VENs',
    SecondsNumber: 'Custom timeout - {count, plural, =1 {# second} other {# seconds}}',
    ServerMessage: 'The offline timer settings apply to all workloads that are servers',
    SevenDayUninstallWait: 'Wait 7 days {default}',
    SpecifiedTimeVenRecordRemovedSideNote:
      'After the specified time period, the VEN record may be completely removed from the PCE. All API requests from this VEN will fail, and the VEN must be manually uninstalled',
    SpecifiedVenHeartbeatIntervalSideNote:
      'If the VEN heartbeats anytime within the specified interval, the VEN will be instructed to uninstall itself. Other API requests from this VEN in this time interval will fail',
    Title: 'PCE to Workloads Global Offline Timers',
    UninstallBodyHintWarning:
      '- after the specified time period, API requests from VENs unpaired from the PCE will fail, leading to frequent “authentication failed” errors. The VEN must be manually uninstalled from the host. Use a longer timeout if VENs are often unpaired from the PCE but hosts are offline for an extended time before coming back online.',
    UninstallIfHeartBeatInSevenDays:
      'If the VEN heartbeats anytime within the 7-day interval, the VEN will be instructed to uninstall itself. Other API requests from this VEN in this time interval will fail',
    UninstallSideNote: 'Time period to wait (default = 7 days) with no heartbeat before a VEN Uninstalls itself',
    UninstallTimeout: 'VEN Uninstall Timeout',
    UninstallTimer: 'VEN Uninstall Timers',
    Wait1Day: 'Wait for 1 day for the endpoint VEN to heartbeat',
    Wait1Hour: 'Wait for 1 hour for the server VEN to heartbeat',
    WaitForSpecifiedAfterUnpairedNotification:
      'Wait for the specified time period after a VEN is unpaired from the PCE before timing out the VEN unpair request',
    WaitForSpecifiedNotification:
      'Wait for the specified time period for the server VEN to heartbeat before emitting a warning event indicating that the server VEN has missed heartbeats. The server VEN will appear in a warning state on the VEN pages',
    WaitForSpecifiedNotificationEndpoint:
      'Wait for the specified time period for the endpoint VEN to heartbeat before emitting a warning event indicating that the endpoint VEN has missed heartbeats. The endpoint VEN will appear in a warning state on the VEN pages',
    WaitForSpecifiedTime: 'Wait for the specified time period for the server VEN to heartbeat',
    WaitForSpecifiedTimeEndpoint: 'Wait for the specified time period for the endpoint VEN to heartbeat',
    WaitForSpecifiedTimeGoodbye:
      'After waiting for the specified time period, push an updated policy to remove the IP addresses of the servers that have sent a goodbye message from their peer workloads',
    WaitForSpecifiedTimeGoodbyeEndpoint:
      'After waiting for the specified time period, push an updated policy to remove the IP addresses of the endpoints that have sent a goodbye message from their peer workloads',
    WaitOneQuarter:
      'Wait one-quarter of the Disconnect and Quarantine Timer for the server VEN to heartbeat before emitting a warning event indicating that the server VEN has missed heartbeats. The server VEN will appear in a warning state on the VEN pages',
    WaitTime: 'Wait Time',
    WaitTimeRequired: 'Wait time field is required',
    Warning:
      '- Selecting the {option} option or choosing a very high custom timeout degrades the security posture because the PCE will not remove IP addresses that belonged to workloads that have been disconnected from those that were allowed to communicate with the disconnected workloads. You will need to remove the disconnected workloads from the PCE to ensure that their IPs are removed from the policy.',
    WillWaitSevenDaysUninstall:
      'Wait 7 days after a VEN is unpaired from the PCE before timing out the VEN unpair request',
  },
  PairingProfiles: {
    AddNew: 'Add a new Pairing Profile',
    CommandLineOverrides: 'Command Line Overrides',
    CreateWithVEN:
      'Install the selected VEN version. If not selected, installs the default VEN version. When using the default VEN version, VENs paired using the pairing line may have different versions depending on the default VEN version at pairing time. If no default VEN version is specified, VENs paired using the pairing line will use the latest compatible VEN version.',
    CustomTimeRange: 'Custom Time Range',
    Delete: 'Remove Pairing {count, plural, =1 {Profile} other {Profiles}}',
    DeleteConfirm:
      'Are you sure you want to remove {count, plural, =1 {the selected Pairing Profile} other {# Pairing Profiles}}?',
    Detail: {
      AllowRequests: 'Allow pairing requests from all valid keys generated by this Pairing Profile',
      DenyRequests: 'Deny pairing requests from all keys generated by this Pairing Profile',
      URI: 'URI',
      VENTypeWarning:
        'VEN version {version} is supported only on server VENs. This pairing profile cannot be used to pair endpoint VENs',
    },
    Errors: {
      Create: 'Unable to Create Pairing Profile',
      Edit: 'Unable to Edit Pairing Profile',
      Start: 'Unable to Start Pair',
      Stop: 'Unable to Stop Pair',
    },
    InitialVenVersion: 'Initial VEN Version',
    Install: 'Install the selected VEN version',
    Key: 'Key',
    KeyGen: 'Generate key',
    KeyMaxAge: 'Maximum Key Age',
    KeyUsage: 'Key Usage & Lifespan',
    LabelAssignment: 'Label Assignment',
    LastUse: 'Last Used On',
    List: {
      Message: {
        DeleteConfirm:
          'Are you sure you want to remove the selected Pairing {count, plural, =1 {Profile} other {Profiles}}?',
      },
    },
    Mixin: {
      AddValidLifespan: 'Please add a valid Key Lifespan.',
      CanSetLabel: '{labelTypeName} Label can be set',
      KeyLifespan: 'Key Lifespan',
      Placeholder: {
        PairingProfileDescription: 'Type a description for the new Pairing Profile',
        PairingProfileName: 'Type a name for the new Pairing Profile',
      },
      UsesPerKey: 'Uses Per Key',
    },
    NoData: 'No Pairing Profiles to display',
    NoMatchData: 'No matching Pairing Profiles to display',
    OneUse: 'One Use',
    OneWorkloadCanPaired: 'Only one Workload can be paired using this Pairing Profile',
    Pair: 'Pair',
    Pairing: 'Pairing',
    PairingKeys: 'Pairing Keys (active)',
    Pause: 'Pause Pairing',
    Profile: 'Pairing Profile',
    Profiles: 'Pairing Profiles',
    Resume: 'Resume Pairing',
    SinglePairing: 'Single pairing',
    Start: 'Start Pairing',
    Status: 'Pairing Status',
    Stop: 'Stop Pairing',
    TLSWarning:
      'If you are pairing an older OS that does not support TLS 1.2, then the pairing script may fail to communicate with the PCE. In case of failure, you may upgrade your OS to support TLS 1.2 or use alternate means of pairing',
    Unlimited: 'unlimited',
    UnlimitedTime: 'No time limit set for pairing Workloads with this Pairing Profile',
    UnlimitedUses: 'Unlimited Uses',
    UnlimitedWorkloadCanPaired: 'Unlimited Workloads can be paired using this Pairing Profile',
    VenRelease: 'Current Default - {venVersion} ({venType})',
    VenReleaseDefault: 'Use the default {venType} version ({venVersion} is the current default)',
    VenReleaseNoDefault: 'Use the latest {venType} version ({venVersion})',
    VENType: {
      EndpointSub:
        'Activate endpoint VENs with pairing keys generated via this pairing profile. VENs that do not support endpoint mode cannot be activated using this pairing profile.',
      Legacy: 'Specified during VEN activation',
      LegacyCreatePage: 'Specified during VEN activation (deprecated legacy option)',
      LegacySub: 'Specify that a VEN should run in endpoint mode via command-line option.',
      ServerSub:
        'Activate server VENs with pairing keys generated via this pairing profile. VENs that do not support server mode cannot be activated using this pairing profile. VENs cannot be activated using a command-line option to specify endpoint mode with this pairing profile.',
    },
    VENVersionWarningMessage: 'The selected VEN version supports only server VENs and may not be used on endpoints.',
    VersionErrorMessage: 'VEN release {version} is supported only on endpoints',
    WhenKeyGenerated: 'Starting from when the key is generated',
    WindowsVersionErrorMessage:
      'VEN release {version} is supported only on endpoints. The “-endpoint true” option must be specified in the pairing script prior to installation',
    Workloads: {
      AIX: 'AIX OS Pairing Script',
      AllowCustomLabels: 'Allow custom Labels',
      AllowCustomLabelsDesc: 'Allow the following Workload Label assignment during script execution',
      AllowPolicyAssignmentDesc: 'Allow Enforcement to be set during script execution',
      Dependencies: 'Dependencies',
      GeneratedOn: 'Generated On',
      InitialState: 'Initial Workload State',
      Lifespan: 'Lifespan',
      Linux: 'Linux OS Pairing Script',
      LockLabelAssignment: 'Lock Label assignment',
      LockLabelAssignmentDesc: 'Deny Workload Label assignment during script execution',
      LockPolicyAssignmentDesc: 'Deny Enforcement changes during script execution',
      LockSomeLabels: 'Lock some of the Labels',
      LockSomeLabelsDesc: 'Deny Label assignment changes to the following Labels: {labels}',
      MacOS: 'macOS Pairing Script',
      OperatingSystems: 'Operating Systems',
      PairingKeySettings: 'Pairing Key Settings',
      PairingScripts: 'Pairing Scripts',
      PickProfile: 'Pick a Pairing Profile',
      RemainingUses: 'Remaining Uses',
      RequiredPackages: 'Required OS Packages',
      SourcePairingProfile: 'Source Pairing Profile',
      SupportedVersions: 'Supported Versions',
      Windows: 'Windows OS Pairing Script',
    },
  },
  PasswordPolicy: {
    CharactersAndPositions: 'characters and positions from current password',
    CharactersLower: 'characters',
    ComplexityChars: {
      Lowercase: 'a-z',
      Number: '0-9',
      Symbol: '!@#$%^&*<>?',
      Uppercase: 'A-Z',
    },
    ContinueSession: 'Continue Session',
    DaysLower: 'days',
    Errors: {
      Integer: 'Value must be an integer',
      Number: 'Value must be a number',
      Required: 'Value cannot be empty',
    },
    ExpirationDescription: 'The password will expire...',
    Logout: 'Log Out',
    LogoutWarningDescription:
      'You will be logged out automatically{seconds, plural, =0 {} =1 { in <s>#</s> second} other { in <s>#</s> seconds}}',
    MinutesLower: 'minutes',
    PasswordChanges: 'password changes',
    PasswordExpirationRetention: 'Password expiration and reuse',
    PasswordPolicy: 'Password Policy',
    PasswordPolicyEdit: 'Password Policy Edit',
    PasswordRequirements: 'Password requirements',
    PolicyKeys: {
      CharacterCategories: 'Character categories',
      Expiration: 'Expiration',
      MinimumCharacters: 'Minimum characters per category',
      MinimumLength: 'Minimum length',
      ReuseHistory: 'Reuse History',
      Similarity: 'Similarity',
      Timeout: 'Timeout',
    },
    RequirementIncreasedWarning:
      'Password requirements increased. All Users will need to reset their password at next login.',
    ReuseHistoryDescription: 'Do not allow password to be reused until after...',
    SessionTimeout: 'Session Timeout',
    SessionTimeoutDescription: 'Session will timeout...',
    SessionTimeoutWarning: 'Session Timeout Warning',
    SimilarityDescription: 'Do not allow a password unless it changes...',
    ValidHint: {
      RangeXtoY: 'Valid range {rangeStart, number} - {rangeEnd, number}',
      RequiredXorMore: 'Required {count, number} or more',
    },
  },
  PCE: {
    FQDN: 'FQDN',
    ItemUnderDifferentPCE: 'This is managed by a different PCE.',
    Started: 'PCE started',
    Stopped: 'PCE stopped',
  },
  PCESupportBundles: {
    BasicContent: 'Basic Content',
    DeleteConfirm:
      'Are you sure you want to remove {count, plural, =1 {this PCE support bundle} other {# selected PCE support bundles}}?',
    DeletedSuccessfully:
      '{count, plural, =1 {PCE support bundle has} other {# PCE support bundles have}} been successfully removed',
    DeleteTitle: 'Remove {count, plural, =1 {PCE Support Bundle} other {PCE Support Bundles}}',
    Errors: {
      GenerateSupportReport: 'Unable to generate PCE support bundle',
    },
    FilterEventsBy: 'Filter events by:',
    GeneratePCESupportBundle: 'Generate PCE Support Bundle',
    LogCollection: 'Log Collection',
    LogCollectionDesc: 'Collect PCE software logs for analysis by Illumio',
    OptionalContent: 'Optional Content',
    PageName: 'PCE Support Bundles',
    PCEHealthCheck: 'PCE Health Check',
    PCEHealthCheckDesc: 'Includes information on system health and environment health',
    PCEHostSystemInventory: 'PCE Host and System Inventory',
    PCEHostSystemInventoryDesc: 'Includes basic hardware and software information',
    Status: {
      Blocked: 'Unable to transfer report. Check connectivity to fileserver.',
      Busy: 'Unmanaged support report in progress.',
      Cancelled: 'This request has been cancelled.',
      Corrupt: 'Archive damaged. Check disk space.',
      Error: 'Unable to run support report command. Check for installation or resource issues.',
      Failed: 'Unable to complete bundle. Check disk space against expected bundle size.',
      Missing: 'Unable to find report. Check disk space.',
      Partial: 'Incomplete support bundle ready for download.',
      Pending: 'Waiting for all operations to complete.',
      Purged: 'This file has been removed.',
      Rejected: 'Unable to transfer report. Check report size.',
      Retry: 'Issue creating file UUID. Please try again.',
      Unknown: 'Unable to decode status.',
    },
  },
  Pill: {
    Tooltip: {
      UpdateTypeTooltip:
        '{object, select, service {Service} iplist {IP List} labelGroup {Label Group} virtualService {Virtual Service} virtualServer {Virtual Server}} is in {updateType, select, create {Addition} update {Modification} delete {Deletion}} pending status',
      UserGroupIsDeleted: 'User Group is deleted',
      WorkloadIsDeleted: 'Workload is deleted or unpaired',
    },
  },
  Policy: {
    ActiveVersion: 'Active version',
    AdvancedPolicy: 'Advanced Policy',
    BindingsWithoutProvision: 'Bindings can be changed without having to provision the changes',
    BuildDesc: 'Build Rules without events',
    ChangeFailed: 'Failed to apply policy changes',
    Check: 'Policy Check',
    Configuration: 'Policy Configuration',
    ConsumerFirst: 'Display Consumer Column First',
    DestinationFirst: 'Display Destination Column First',
    Drafts: 'Drafts',
    DraftsAndPolicyVersions: 'Drafts & Versions',
    EditDraftVersion: 'Edit the draft version.',
    IdleDesc: "The VEN is installed but no changes have been made to Workload's firewall or iptables settings",
    IPListHasUnsavedChangesPending: 'The IP list has unsaved changes pending',
    ManagedStateChangeErrorWorkloadProfile:
      'Unable to Edit {count, plural, =1 {# Container Workload Profile} other {# Container Workload Profiles}}',
    ManagedStateChangeMessageWorkloadProfile:
      '<c>{count, plural, =1 {# Container Workload Profile} other {# Container Workload Profiles}}</c> will be changed to <t>{title}</t> mode',
    ManagedStateChangeMessageWorkloads:
      '<c>{count, plural, =1 {# Workload} other {# Workloads}}</c> will be changed to <t>{title}</t> mode',
    Mixed: 'Mixed',
    Objects: 'Policy Objects',
    OldVersion: 'Old version',
    OldVersionDesc: 'You are viewing version {version}',
    Organization: 'Organization Policy',
    Organizational: 'Organizational Policy',
    PolicyUpdatesExist: 'There are changes to the policy or referenced policy objects',
    ProviderConsumerOrder: 'Provider & Consumer Order',
    ProviderFirst: 'Display Provider Column First',
    ReadOnlyVersion: 'Read Only version',
    RequiredProcessesNotRunning: 'Required processes not running',
    RequireProvisionNote: 'Require Provision Note',
    RulesetHasUnsavedChangesPending: 'The ruleset has unsaved changes pending',
    SecureConnectionFailed: 'Failed to establish one or more SecureConnect connections',
    SecurityPolicyStageFailed: 'Security policy stage failed',
    Settings: 'Policy Settings',
    SourceDestinationOrder: 'Source & Destination Order',
    SourceFirst: 'Display Source Column First',
    State: 'Policy State',
    StateChangeCant: 'Cannot Change Policy State',
    StateChangeConfirmWorkload: 'Confirm Workload Enforcement Change',
    StateChangeConfirmWorkloadProfile: 'Container Workload Profile Enforcement Change',
    StateChangeMessage: '{count} of {total} will change to <b>{title} {visibilityLevel}</b>',
    SuspendedMessage: 'VEN Suspended',
    TamperingRevertFailed: 'Failed to revert policy tampering',
    TestDesc: 'Test Rules and log events',
    UIColumnOrder: 'UI Column Order',
    UnknownDataCenterAndLocation: 'Unnamed Datacenter, Unknown Location',
    UnknownLocation: 'Unknown Location',
    UnmanagedDesc: 'Unmanaged Workloads do not have a VEN installed. Workload state does not apply.',
    UnmanagedWorkloadsNotChangeCount:
      '{unmanagedCount, plural, =1 {<b>#</b> Unmanaged Workload} other {<b>#</b> Unmanaged Workloads}} will not change.',
    UnnamedDataCenter: 'Unnamed Datacenter',
    UpdateMode: 'Policy Update Mode',
    Versions: 'Versions',
    ViewActiveVersion: 'View the active version.',
    ViewDraftVersion: 'View the draft version.',
    ViewingActiveVersion: 'This list shows only active Rules',
    ViewingDraftVersion: 'Viewing draft version',
    VisibilityChangeConfirm: 'Confirm Workload Visibility Change',
    YouViewEditVService: 'You are editing Virtual Service',
    YouViewingActiveVersion: 'You are viewing the active version',
    YouViewingActiveVersionVServer: 'You are viewing the active version of Virtual Server',
    YouViewingActiveVersionVService: 'You are viewing the active version of Virtual Service',
    YouViewingDraftVersion: 'You are viewing the draft version',
    YouViewingDraftVersionVServer: 'You are viewing the draft version of Virtual Server',
    YouViewingDraftVersionVService: 'You are viewing the draft version of Virtual Service',
  },
  PolicyGenerator: {
    Addresses: 'Addresses',
    AddScopes: 'Add Scopes',
    AllExcluded: 'If all connections are excluded, no Rules will be written.',
    AllTrafficNotAllowed: 'Not All Traffic Allowed',
    AllTrafficNotAllowedDescription:
      'Some traffic intended to be allowed by this rule will not be covered by this Service',
    AppGroupDisabled: 'App Group Type must be set before the Policy Generator can be used',
    AppGroupLevel: 'App Group Level',
    AutoDescription: 'Automatically generated using the Illumio Policy Generator',
    BroadServiceWarning: 'This could result in rules for traffic outside the included connections',
    CalculationInProgress: 'Calculation in Progress',
    ClickToViewAddresses: 'Click to view IP Addresses',
    ClickToViewVulnerabilites: 'Click to view exposed Vulnerabilities',
    ConfigureRules: 'Configure Rules',
    ConfirmMergeRules:
      'Editing this Service allows you to merge rules. Do you want to proceed? To reverse the merge, refresh your browser and edit the Services again.',
    Connections: '{count, plural, =0 {0 Connections} =1 {1 Connection} other {# Connections}}',
    Constrained: 'Constrained',
    DeletedRulesetsInstructions:
      'The following {count, plural, =1 {Ruleset is} other {Rulesets are}} <strong>deleted</strong>, <br/> and must be Provisioned or Reverted to add new Rules:',
    DisabledNotice: 'Policy Generator is Disabled',
    DisabledRulesets: 'Rulesets Deleted or Disabled',
    DisabledRulesetsInstructions:
      'The following {count, plural, =1 {Ruleset is} other {Rulesets are}} <strong>disabled</strong>, <br/> and must be enabled to add new Rules:',
    EditService: 'Edit Service',
    EditServiceUseAlways: 'Apply Changes to all matching ports',
    ExcludeAll: 'Exclude All',
    Excluded: 'Excluded',
    ExcludedConnections: 'Excluded  {count, plural, =1 {Connection} other {Connections}}',
    ExcludeSome: 'Exclude {count}',
    ExistingRules: 'Connections with Existing Rules',
    ExtraScope: {
      ChooseExtraScope: 'Choose Consuming',
      ConfigureExtraScope: 'Configure Extra-Scope',
      DeleteExtraScopeRules: '{count, plural, =1 {Delete 1 Extra-Scope Rule} other {Delete # Extra-Scope Rules}}',
      NewExtraScopeRules: '{count, plural, =1 {1 New Extra-Scope Rule} other {# New Extra-Scope Rules}}',
      NumConsumingAppGroup: '{count, plural, =1 {1 Consuming App Group} other {# Consuming App Groups}}',
      Options: {
        Title: '1. Choose Extra-Scope Rule Configuration for {count, plural, =1 {1 App Group} other {# App Groups}}',
      },
    },
    ExtraScopeConnections: 'Extra Scope Connections',
    ExtraScopeRules: 'Extra-Scope Rules',
    FilterIpPlaceholder: 'Type to Search for Labels, Ports, Protocols, IP Addresses, or Transmission Type',
    FilterPlaceholder: 'Type to Search for Labels, Ports, Protocols, or Transmission Type',
    Find: 'Find',
    Grid: {
      CompleteCoverage: '100% Covered',
      Connection: 'connections',
      Connections: '{count, plural, =1 {1 Connection} other {# Connections}}',
      ConnectionWithRules: '{count, plural, =1 {1 Connection} other {# Connections}} with Rules',
      Coverage: 'Coverage',
      CoveredByOtherRuleset:
        'All connections are allowed by the Rules in Rulesets that are not created using the Policy Generator.',
      ExposedVulnerabilityCount: '{count, plural, =1 {# Exposed Vulnerability} other {# Exposed Vulnerabilities}}',
      More: 'More',
      MorePortProtocol: '+ {numPortsProtocols, number} More',
      MoreRules: '{count, plural, =1 {1 More Rule} other {# More Rules}}',
      NoFilter: 'At least one transmission type must be selected',
      NumberSelected: '{subtitle} ({count, number} selected)',
      PortsProtocolsProcesses: 'Ports + Protocols + Processes',
      RulesetInclusion: 'Ruleset Inclusion',
      Sessions: '{count, plural, =1 {1 Flow} other {# Flows}}',
      Subtitle: 'Rules will be generated for',
      Title: '2. Review Connections',
      TitleDetails: {
        AppAll: '(Organized by App Group)',
        AppSpec: '(Organized by Port + Protocol)',
        RoleAll: '(Organized by Role and App Group)',
        RoleSpec: '(Organized by Role + Port and Protocol)',
      },
    },
    IllumioGenerated: 'Illumio Generated',
    IncludeAll: 'Include All',
    Included: 'Included',
    IncludedConnections: 'Included {count, plural, =1 {Connection} other {Connections}}',
    IncludeSome: 'Include {count}',
    IncompleteCoverage: 'Incomplete Coverage',
    IntraScope: {
      ConfigureIntraScope: 'Configure Intra-Scope',
      DeleteIntraScopeRules: '{count, plural, =1 {Delete 1 Intra-Scope Rule} other {Delete # Intra-Scope Rules}}',
      IntraScopeConnections: 'Intra-Scope Connections',
      NewIntraScopeRules: '{count, plural, =1 {1 New Intra-Scope Rule} other {# New Intra-Scope Rules}}',
      Options: {
        AutoLevel: {
          Body: 'Vulnerability Mitigation: Eliminate or reduce the exposure of vulnerable ports',
          Title: 'Auto Level',
        },
        Microsegmentation: {
          Body: 'Nanosegmentation: Divide Workloads by Role and specific Services',
          Title: 'Role Level - Specified Services',
        },
        Ringfencing: {
          Body: 'Microsegmentation: Allow all Workloads to talk across all Services',
        },
        TierToTier: {
          Body: 'Divide Workloads by Role and allow them to talk on all Services',
          Title: 'Role Level - All Services',
        },
        Title: '1. Choose Intra-Scope Rule Configuration',
      },
    },
    IntraScopeRules: 'Intra-Scope Rules',
    IpList: {
      ChooseIpList: 'Choose IP List',
      ConfigureIpList: 'Configure IP List',
      IpListChoose: 'IP List Selection',
      NumIpLists: '{count, plural, =1 {1 IP List} other {# IP Lists}}',
      Options: {
        Title: '1. Choose IP List Rule Configuration',
      },
    },
    IpListConnections: 'IPList Scope Connections',
    IpListCreationWarning: '{count, plural, =0 {No Ip Lists} =1 {1 Ip List} other {# Ip Lists}} will be saved',
    LargeServices: 'Large Number of Services',
    LargeServicesContinue: 'Are you sure you want to continue? Or would you like to write All Services Rules?',
    LargeServicesWarning: 'The large number of specified Services will result in a large number of Rules.',
    LastCalculated: 'Last Calculated',
    MakePolicyGeneratorRuleset: 'Make Default Policy Generator Ruleset',
    MergeConsumers: 'Merge rules with common Provider and Service',
    MergeServices: 'Merge rules with common Provider and Consumer',
    MissingLabels: 'Incomplete Labels',
    MustHaveRoleLabel: 'Workloads must have a Role Label to write Rules',
    NewIpLists:
      '{count, plural, =0 {<strong>0</strong> New Ip Lists} =1 {<strong>1</strong> New Ip List} other {<strong>#</strong> New Ip Lists}}',
    NewRules: '{count, plural, =1 {<strong>1</strong> New Rule} other {<strong>#</strong> New Rules}}',
    NewRulesets:
      '{count, plural, =0 {<strong>0</strong> Rulesets} =1 {<strong>1</strong> Ruleset} other {<strong>#</strong> Rulesets}}',
    NewServices:
      '{count, plural, =0 {<strong>0</strong> New Services} =1 {<strong>1</strong> New Service} other {<strong>#</strong> New Services}}',
    NoConnections: 'No Connections Available',
    Options: {
      AllServices: {
        Body: 'Write rules for all services by {level, select, auto {Auto} role {Role} appgroup {App Group}}',
      },
      SpecifiedServices: {
        Body: 'Write rules for individual services by {level, select, auto {Auto} role {Role} appgroup {App Group}}',
        Title: 'Specified Services',
      },
    },
    PolicyGenerator: 'Policy Generator',
    PolicyGeneratorRuleset: 'Default Policy Generator Ruleset',
    PolicyGeneratorTag: '(Policy Generator)',
    PortProtocolProcess: '{port} {protocol} {process, select, false {} unknown {} other {- {process}}}',
    PortServices: 'Use the port/protocol in a rule',
    PreviewRules: 'Preview Rules',
    ProvisionDeletedRulesets: 'Provision All Deleted Rulesets',
    ResetRules: 'Reset Rules',
    ResetRulesReason:
      'This service covers multiple flows, to edit this service you still need to reset your edits and start again.',
    RevertDeletedRulesets: 'Revert All Deleted Rulesets',
    RingFence: {
      ChooseAppGroups: 'Choose App Groups',
      Contents: '1 Scope and 1 Intra-Scope Rule',
      CreatingRulesets: 'Creating Rulesets',
      Description: 'Allows all Workloads to talk across all Services within each App Group',
      Multiple: 'Multiple',
      NewRulesets: '{count, plural, =1 {# Ruleset} other {# Rulesets}} each with',
      NumAppGroups: '{count, plural, =1 {1 App Group} other {# App Groups}}',
    },
    RuleConstruction: 'Rule Construction',
    RuleMerging: 'Rule Merging',
    Rules:
      '{count, plural, =0 {<strong>0</strong> Rules} =1 {<strong>1</strong> Rule} other {<strong>#</strong> Rules}}',
    RulesetScope: 'Ruleset Scope',
    RulesetsWillBeSaved: '{count, plural, =0 {No Rulesets} =1 {1 Ruleset} other {# Rulesets}} will be saved',
    RulesWillBeDeleted: '{count, plural, =0 {No Rules} =1 {1 Rule} other {# Rules}} will be deleted',
    RulesWillBeSaved: '{count, plural, =0 {No Rules} =1 {1 Rule} other {# Rules}} will be saved',
    SaveRules: 'Save Rules?',
    Scopes:
      '{count, plural, =0 {<strong>0</strong> Scopes} =1 {<strong>1</strong> Scope} other {<strong>#</strong> Scopes}}',
    SegmentMultipleAppGroups: 'Segment {count, plural, =0 {} =1 {App Group} other {Multiple App Groups}}',
    Select: {
      Intro: {
        Body: 'The <span class="{className}">Illumio Policy Generator</span> allows you to write <span class="{className}">Rules</span> for uncovered <span class="{className}">connections</span> of <span class="{className}">traffic</span> in your <span class="{className}">App Groups</span>',
      },
      SearchAppGroup: 'Search App Group',
      SelectAppGroup: 'Select an App Group to build Rules',
      Steps: {
        Configure: 'Configure Intra-Scope Or/And Extra-Scope Rules',
        Secured: 'App Groups are secured',
        Select: 'Select the App Group',
      },
    },
    SelectAppGroup: 'Select App Group',
    ServiceAutoDescription:
      'Automatically generated using the Illumio Policy Generator, based on traffic seen for "{processName}" process "{serviceName}" service.',
    ServiceCreationWarning: '{count, plural, =0 {No Services} =1 {1 Service} other {# Services}} will be saved',
    ServiceName: 'Service - {port} {protocol}',
    ServiceNameProtocol: 'Service - {protocol}',
    ServiceRuleConfig: 'Service Rule Configuration',
    Services:
      '{count, plural, =0 {<strong>0</strong> Services} =1 {<strong>1</strong> Service} other {<strong>#</strong> Services}}',
    ServiceSubstitution: 'If a Service cannot be substituted for a port/protocol',
    ServiceUse: 'If a Service exists for a port/protocol, the Service is used in a rule',
    ServiceWaitTime: 'It will take approximately {count, plural, =1 {1 minute} other {# minutes}} to complete',
    Spinner: {
      CalculatingFlowData: 'Calculating Flow Data',
      CalculatingRuleCoverage: 'Calculating Rule Coverage',
      CalculatingVulnerabilityData: 'Calculating Vulnerability Data',
      CreatingIpLists: 'Creating IpLists',
      CreatingRules: 'Creating Rules',
      CreatingServices: 'Creating Services',
      ExploringAsyncData:
        'Please wait to view results. You can start another query before this one is complete. Click Results to view or export the results of completed queries within 24 hours.',
      ExploringData: 'Loading Traffic',
      FindingConnections: 'Finding Connections',
      GeneratingRuleCoverage: 'Generating Rule Coverage',
      GeneratingRules: 'Generating Rules {percent, select, 0 {} other {{percent, number}%}}',
      LocatingAppGroups: 'Locating App Groups',
      RecalculatingFlowData: 'Recalculating Flow Data',
    },
    StartPolicyGenerator: 'Start Policy Generator',
    TooManyConnections: 'Too many connections to generate rules.',
    TooManyConnectionsSelectFewer: 'Too many connections to generate rules, please select fewer Extra-Scope App Groups',
    TooManyRules: 'Too Many Rules',
    TooManyRulesMessage:
      'The number of Rules exceeds the number allowed for a Ruleset. We recommend using more broad options.',
    UseAllServices: 'Allow traffic on all Services',
    UseBroadServices: 'Allow the use of Services that include more than one port/protocol',
    WindowsServiceHelp: '"c:\\\\<ADD PATH HERE>\\\\{process}"',
    WindowsServiceInstructions: 'Cut and paste the following to use the process or service:',
    WindowsServiceName: 'Windows Service - {port} {protocol}',
    WindowsServiceNameProtocol: 'Windows Service - {protocol}',
    WithConnections: 'With Connections',
    WithoutConnections: 'Without Connections',
  },
  Port: {
    CIDRBlockPlaceHolder: 'E.g. 192.168.100.1/20',
    ConsumerProcess: 'Consumer Process',
    EmptyProcessName: 'Empty process name',
    EmptyProcessorService: 'Empty process or service',
    Errors: {
      Invalid: 'Needs to be {port}',
    },
    InvalidCodeValue: 'Invalid code value',
    InvalidPortPortRange: 'Invalid Port/Port Range',
    InvalidPortValue: 'Invalid port value',
    InvalidProcess: 'Invalid process',
    InvalidRange: 'Invalid port range',
    InvalidServiceName: 'Invalid service name',
    InvalidTypeCodeValue: 'Invalid type/code value',
    InvalidTypeValue: 'Invalid type value',
    InvalidValue: 'Invalid value',
    IpAddressPlaceHolder: 'E.g. 192.168.100.1',
    Missing: 'Missing port',
    Overrides: 'Override ports',
    OverridesOptional: 'Override ports (Optional)',
    Port: 'Port',
    PortProcess: 'Provider Port/Process',
    PortProcessOnly: 'Port/Process',
    PortProtocolPlaceHolder: 'E.g. 22, 514 UDP, ICMP',
    PortProtocolProcessService: 'Services Name',
    PortProtocolProcessServiceIsNot: 'Services Name is not',
    PortRange: 'Port Range',
    PortRangePlaceHolder: 'E.g. 71-74, 80-90 TCP',
    Ports: 'Ports',
    PortStatus: 'Port Status',
    ProcessNamedSchedule: 'Schedule',
    ProcessNamePlaceHolder: 'E.g. c:\\windows\\myprocess.exe',
    ProcessPath: 'Process path',
    ProcessServiceRequiresQuotes: 'Process or service requires quotes',
    Protocol: 'Port/Protocol',
    ProtocolInvalid: 'Invalid port/protocol combination',
    ProtocolProcess: 'Provider Port/Protocol/Process',
    ProviderPortProcessService: 'Provider Port Process',
    ProviderProcess: 'Provider Process',
    ServiceNameRequiresQuotes: 'Service name requires quotes',
    ToAddPortProtocol: 'To add ports and protocols',
    ToAddPortProtocolProcess: 'To add ports, protocols, and processes/services:',
    ToAddProcessAndWindowsService:
      'To add process and Windows services, use quotation marks and the full path for processes',
    WindowsServiceExample: 'c:\\windows\\myprocess.exe',
    WindowsServiceExampleUNC: '\\\\windows\\process\\myprocess.exe',
    WindowsServiceExampleWithSystemEnvVariable: '%systemroot%\\system32\\myprocess.exe',
  },
  Protocol: {
    AH: 'AH',
    Any: 'Any',
    CFTP: 'CFTP',
    DCCP: 'DCCP',
    ESP: 'ESP',
    GRE: 'GRE',
    ICMP: 'ICMP',
    ICMPv6: 'ICMPv6',
    IGMP: 'IGMP',
    IPIP: 'IPIP',
    IPPC: 'IPPC',
    IPv4: 'IPv4',
    IPv6: 'IPv6',
    KRYPTOLAN: 'KRYPTOLAN',
    OSPF: 'OSPF',
    RDP: 'RDP',
    RVD: 'RVD',
    SATEXPAK: 'SAT-EXPAK',
    SCTP: 'SCTP',
    SMP: 'SMP',
    TCP: 'TCP',
    UDP: 'UDP',
  },
  Provision: {
    Added: 'Added',
    All: 'Provision All',
    AllChangesActiveCurrent: 'All policy changes are active and current.',
    CannotProvision:
      'Not Acceptable. One or more specified objects either do not exist, or do not have a draft version.',
    Changes: 'Provision Changes',
    Confirm: 'Confirm & Provision',
    ConfirmChanges: 'Confirm ruleset changes',
    ConfirmChangesWillBeSaved: 'Are you sure? Your changes to the ruleset will be saved',
    ConfirmSave: 'Confirm & Save',
    ContinuingInBackgroundMsg: 'Provisioning will continue in the background. Track progress on the <a>VENs</a> page.',
    Dependencies: 'Provision Dependencies',
    DraftChangeCount: 'Draft Changes ({count, number})',
    DraftChanges: 'Draft Changes',
    EntitiesNumber: '{count, plural, =1 {# Entity} other {# Entities}}',
    FollowingItemsAlso: 'The following items will also be provisioned',
    FollowingItemsNeed:
      'The selected items have dependencies that need to be provisioned before your items can be provisioned. Please contact an Admin to resolve this issue.',
    Item: 'Item',
    ListenOnlyMember: 'Members PCEs in listen only mode will not receive updated policy.',
    ListenOnlyModeMessage: 'PCE Listen Only mode is enabled.',
    ListenOnlyModePolicyChanges:
      'Policy changes for {count, plural, =1 {one Workload} other {# Workloads}} will be synchronized when Listen Only mode is disabled.',
    ListenOnlyModeSynchronizeCount: '{count, plural, =1 {1 Workload} other {# Workloads}} pending synchronization',
    Modified: 'Modified',
    Note: 'Provision Note',
    Now: 'Provision Now',
    Pending: 'Pending Provision',
    PendingAddition: 'Addition Pending',
    PendingDeletion: 'Deletion Pending',
    PendingModification: 'Modification Pending',
    PendingNote: 'This is the draft policy. Provision to make it the active version.',
    PendingProvisioning: 'Changes Pending Provisioning',
    ProvisioningComplete: 'Provisioning complete',
    ProvisioningCompleteSynced: 'All policy changes are active and current',
    ProvisioningDidNotAffect: 'Changes that were provisioned did not affect any Workloads',
    ProvisioningInfoMessage:
      'During this process, you can navigate to another page and policy synchronization will continue.',
    ProvisioningInProgress: '{ellipsis, select, false {Provisioning in progress} true {Provisioning in progress ...}}',
    ProvisioningNoProgress: 'No provisioning in progress',
    ProvisioningPushesToVEN: 'The PCE recalculates policy and sends it to impacted VENs when you provision.',
    ProvisioningPushesToVENWithoutDependencies:
      'You cannot partially provision resources with more than 500 dependencies. All changes must be provisioned at the same time.',
    ProvisioningStatus: 'Provisioning Status',
    RequiredBy: 'Required by',
    Revert: {
      All: 'Revert All',
      Dependencies: 'Revert Dependencies',
      DiscardChangesToItems: 'Discard changes to the selected items',
      DiscardIPListChanges: 'Discard IP list changes',
      DiscardRulesetChanges: 'Discard ruleset changes',
      FollowingItems: 'The following items will also be reverted',
      Now: 'Revert Now',
      RemoveOrSelectDependencies: 'Either remove the dependencies, or go back and select those dependencies to revert.',
      Review: 'Revert Review',
      SelectedItemsHaveDependencies: 'The selected items have dependencies that you did not select to revert.',
      Unable: 'Unable to Revert',
    },
    RevertingDiscardDraft: 'Reverting will discard all draft changes',
    Review: 'Provision Review',
    SavingComplete: 'Saving complete',
    SavingInProgress: '{ellipsis, select, false {Saving in progress} true {Saving in progress ...}}',
    SelectedItems: 'Provision selected items',
    Settings: 'Provisioning',
    Status: 'Provision Status',
    SynchronizingCount: '{count, plural, =1 {1 Workload} other {# Workloads}} synchronizing...',
    SynchronizingPolicyChanges:
      'Synchronizing policy changes for {count, plural, =1 {one Workload} other {# Workloads}}...',
    TallyLabel: {
      IPLists: '{count, plural, =1 {IP List} other {IP Lists}}',
      LabelGroups: '{count, plural, =1 {Label Group} other {Label Groups}}',
      Rulesets: '{count, plural, =1 {Ruleset} other {Rulesets}}',
      SecureConnect: '{count, plural, =1 {Secure Connect Gateway} other {Secure Connect Gateways}}',
      Settings: '{count, plural, =1 {Setting} other {Settings}}',
      VirtualServices: '{count, plural, =1 {Virtual Service} other {Virtual Services}}',
    },
    Unable: 'Unable to Provision',
    UnsavedIPListConfirmMessage: 'Are you sure? Your changes to the IP list will be saved',
    UnsavedIPListConfirmTitle: 'Confirm IP list changes',
    UpToDate: 'Up to date',
    Versions: '{multiple, select, false {Policy Version} true {Policy Versions}}',
    ViewLastCommit: 'View the last commit',
    ViewPolicyVersions: 'View Policy Versions',
  },
  RansomwareDashboard: {
    ClickToEdit: 'Click to edit the asset count',
    DraftPolicy: 'Draft Policy',
    EnforcementChange: '{workload} will be changed to {enforcement} mode.',
    EnforcementChangeError: 'Errors changing {workload} to {enforcement} mode.',
    InfoTooltip:
      '<span class="{className}">Ransomware protection assesses the exposure of server workloads to ransomware exploits. A server workload should first have a VEN installed and paired with PCE to make the workload Protection Ready. Then a server workload is assessed with its exposure to the common services exploited by ransomware.</span><br/><span class="{className}">The risk of each service is classified into four severities - critical, high, medium, low. User can find the ransomware risky services and their corresponding risk severities on Services page. Workload is protected for the service when 1) the service is blocked by Deny Rule in selective enforcement or 2) the workload is in full enforcement, whether there is rule or no rule for that service.</span><br />Workload exposure level is classified with the highest severity of services that the workload is still open to. Protection Coverage Score ranges from 0% to 100% and measures how well the policies can reduce the attack surface against ransomware. Several ransomware protection widgets (indicated with a timer icon) are calculated on a regular time interval and are not refreshed when you click the Refresh button. The remaining two widgets refresh when Refresh is clicked.',
    LegacyPorts: 'Legacy',
    NonRealTime: 'Data was last updated at {time}, auto-refreshed every 4 hours',
    OSExposure: 'OS Exposure',
    PortCategory: '{port} Ports',
    PortCategoryCount: '{port} Ports Count: {count}',
    PortType: 'Port Type',
    ProtectedAllowed: 'Protected (Allowed by Policy)',
    ProtectedBlocked: 'Protected (Blocked)',
    ProtectedWorkloadsTooltip:
      'Protected workloads are server workloads that have policies enforced for all ransomware risky services.',
    ProtectionColumnTooltip:
      'Protected when the port is blocked or allowed (with policy) for only trusted sources. Unprotected otherwise.',
    ProtectionCoverageScore: 'Protection Coverage Score',
    ProtectionCoverageScoreTooltip:
      'Protection Coverage Score measures the network exposure to ransomware reduced by security policies compared to the total possible network exposure.',
    ProtectionReadyWorkloads: 'Protection Ready Workloads',
    ProtectionReadyWorkloadsTooltip:
      'Protection Ready Workloads are server workloads which have a VEN installed and can be configured to enforce Illumio security policies.',
    ProtectionReadyWorkloadTrendTooltip:
      'Protection Ready Workload trend shows the historical data of the Protection Readiness by day, week, month and quarter.',
    RansomwareExposure: 'Ransomware Exposure',
    RansomwareExposureTooltip:
      'Workload is classified by their risk exposure to ransomware - critical, high, medium, low and protected.',
    RansomwareProtection: 'Ransomware Protection',
    RansomwareProtectionCoverage: 'Ransomware Protection Coverage',
    RansomwareProtectionDashboard: 'Ransomware Protection Dashboard',
    RansomwareProtectionForServers: 'Ransomware Protection for Servers',
    RansomwareRisky: 'Ransomware Risky',
    RansomwareWorkloadsProtected: 'Protected Workloads',
    Recommendations: 'Recommendations',
    RiskyPortsBySeverity: 'Risky Ports by Severity',
    RiskyPortsBySeverityTooltip:
      'This shows the number of ransomware risky ports, categorized by their severity - critical, high, medium and low in the system.',
    RiskyPortsByType: 'Risky Ports by Type',
    ServiceColumnTooltip: 'These service ports are risky for ransomware exploits and should be protected.',
    ShowReadiness: 'Show Readiness',
    Unprotected: 'Unprotected',
    Workload: 'workload',
    WorkloadExposureByGranularity: 'Workload Exposure',
    WorkloadsByRansomwareExposure: 'Workloads by Ransomware Exposure',
    WorkloadsRequiringProtection: 'Workloads Requiring Protection',
    WorkloadsRequiringProtectionInstruction:
      "To calculate Ransomware Protection Readiness, you must enter the number of server workloads requiring protection. If you don't know the exact number, an estimate will do. This number can be edited at anytime.",
  },
  RBAC: {
    AccessManagement: 'Access Management',
    AccessWizard: 'Access Wizard',
    AddNewPrincipalAndRole: 'Add a new Principal and Role',
    AddPrincipals: 'Add Principals',
    CantHaveAllScope: 'Some or all of the selected roles cannot have All scope',
    ChooseScope: 'Choose a Scope',
    ConfirmGrantedAccess: 'Confirm Granted Access',
    ConfirmLeaveTitle: 'Confirm Leave',
    DefaultReadOnly: 'Default Viewer',
    EditScope: 'Edit Scope',
    ExternalGroup: {
      AddNew: 'Add a new External Group',
      NoData: 'No External Groups to display',
      NoMatchData: 'No Matching External Groups to display',
    },
    ExternalUser: {
      AddNew: 'Add a new External User',
      NoData: 'No External Users to display',
      NoMatchData: 'No Matching External Users to display',
    },
    GlobalRoles: 'Global Roles',
    GrantAccess: 'Grant Access',
    GrantedAccess: 'Granted Access',
    LocalUser: {
      AddNew: 'Add a new Local User',
      NoData: 'No Local Users to display',
      NoMatchData: 'No Matching Local Users to display',
    },
    ManageWarning: 'You will not be able to manage this workload after this action.',
    NoPermissionChange: 'All the permissions you selected already exist. No permission changes will be made.',
    NoPrincipalsAndRolesData: 'No Principals and Roles to display',
    NoRolesToDisplay: 'No Roles to display',
    NotValidScope: 'Not a valid scope',
    PartialAppGroupScope:
      'Currently, the App Group Type is set to “{type}”. Because this RBAC scope includes a label type that is outside the App Group Type, principals assigned to this scope will be unable to view any App Groups. To resolve this, remove the outside label types from the RBAC scope.\nIf you require RBAC scopes with additional label types, principals can still use Illumination Plus to view App Groups.',
    PartialAppGroupScopeLoc:
      'Currently the App Group Type is set to “{type}”. Because this RBAC scope includes a specific location label, principals assigned to this scope will be unable to view any App Groups. To resolve this, set the RBAC scope Location to “All Locations”.',
    PermissionsRemove: {
      NumPrincipals: '{count, plural, =1 {1 Principal} other {# Principals}}',
    },
    PleaseChangePrincipal: 'Please select a new principal',
    PleaseChangeRole: 'Please make a role change',
    PleaseRemoveLocationLabel:
      'If you require RBAC scopes with specific locations, go to the App Groups List page and set the App Group Type to “Application | Environment | Location”.',
    PleaseSelectPrincipal: 'Please select a principal',
    PleaseSelectRole: 'Please select a role',
    PleaseSelectScope: 'Please select a scope',
    Principals: 'Principals',
    PrincipalsAndRoles: 'Principals and Roles',
    PrincipalsTypeCount: {
      ExternalGroups: '{count, plural, =0 {}  =1 {1 External Group} other {# External Groups}}',
      ExternalUsers: '{count, plural, =0 {}  =1 {1 External User} other {# External Users}}',
      LocalUsers: '{count, plural, =0 {}  =1 {1 Local User} other {# Local Users}}',
    },
    RemoveAccess: 'Remove Access',
    RemoveAccessToScopes: 'Remove Access to Scopes',
    RemoveAccessToScopesWarning: 'All access to the scopes will be removed',
    RemovePermissions: {
      Success: '{count, plural, =1 {Permission has} other {# Permissions have}} been successfully removed',
    },
    ScopeAccessRemoved: 'The access to the scopes was removed',
    ScopeChangeWarning: 'The principals will no longer have access to the previous scope',
    ScopedRole: {
      AddNew: 'Add a new Scoped Role',
      NoData: 'No Scoped Roles to display',
    },
    ScopesAndRoles: 'Scopes and Roles',
    SelectAPrincipal: 'Select a principal',
    SelectRoles: 'Select Roles',
    ServiceAccounts: {
      AddNew: 'Add a new Service Account',
      APIKeysExpiresIn: 'API Key Expires in',
      CannotHaveAllScope: "{role} cannot have 'All' scope",
      CanOnlyHaveAllScope: "Global role can only have 'All' scope",
      DeleteConfirm:
        'Are you sure you want to remove {count, plural, =1 {this Service Account} other {# selected Service Accounts}}?',
      DeletedAPIKeysSuccessfully: '{count, plural, =1 {API Key has} other {# API Keys have}} been successfully removed',
      DeletedSuccessfully:
        '{count, plural, =1 {Service Account has} other {# Service Accounts have}} been successfully removed',
      DeleteTitle: 'Remove {count, plural, =1 {Service Account} other {Service Accounts}}',
      DuplicatePermissions: 'Cannot have duplicate Roles and Scopes',
      Errors: {
        APIKeyCreate: 'Unable to Create API Key',
      },
      ExpirationTimeText: 'Expiration cannot exceed the organization setting',
      NoMatchingServiceAccountsData: 'No matching Service Accounts to display',
      NoServiceAccountsData: 'No Service Accounts to display',
      Placeholder: {
        ServiceAccountDescription: 'Type a description for the new Service Account',
        ServiceAccountName: 'Type a name for the new Service Account',
      },
      RolesAndScopes: 'Roles and Scopes',
    },
    UsersActivity: {
      Detail: {
        NoDataToDisplay: 'No Permissions to display',
      },
      Duration: 'Duration',
      EffectiveGroups: 'Effective Groups',
      LastLogin: 'Last Login',
      LoginIP: 'Login IP',
      LoginTime: 'Login Time',
      LoginType: 'Login Type',
      NoData: 'No User Activities to display',
      UserActivity: 'User Activity',
    },
    WorkloadManager: 'Manage Workloads and Pairing Profiles that match scope.',
  },
  RBSuccessMessage: {
    AddedToDraftRules: 'Added to Draft Rules',
    ContinueWithGroup: 'Continue with App Group',
    Desc: 'Rules have been successfully generated and added to your Draft Rules. You must provision these Rules to apply them to Workloads.',
    GoToAppGroupMap: 'Go to App Group Map',
    GoToDraftRules: 'Go to Draft Rules',
    PolicyGeneratorSuccessful: 'Policy Generator Successful',
    SelectAnAppGroup: 'Select An App Group',
    SelectAnotherAppGroup: 'Select Another App Group',
  },
  Reports: {
    AddReport: 'Add Report',
    AppGroupSummary: 'App Group Summary',
    AppGroupSummaryReport: 'App Group Summary Report',
    Daily: 'Daily',
    Downloads: 'Downloads',
    EmailCopyTo: 'Email Copy To',
    ExecutiveSummary: 'Executive Summary',
    ExecutiveSummaryReport: 'Executive Summary Report',
    ExplorerExportInstructionsPart1: 'You can run a previously saved',
    ExplorerExportInstructionsPart2:
      'filter and export the results to a CSV file on a recurring schedule. If you edit the filter, subsequent recurrences of the exported file will continue to use the original version of the filter.',
    Monthly: 'Monthly',
    NextScheduledTime: 'Next Scheduled Time',
    NoData: 'No reports to display',
    NoSavedFilters: 'No Saved Filters.',
    Quarterly: 'Quarterly',
    Recurrence: 'Recurrence',
    RecurringReports: 'Recurring Reports',
    RemoveSchedule: 'Remove Schedule',
    RemoveScheduleConfirm:
      'Are you sure you want to remove the selected schedule? The associated report will no longer be generated.',
    ReportEmailCheckbox: 'Email me a copy of the report',
    ReportEmailCheckboxCSV: 'Email me a copy of the CSV file',
    ReportRecurrence: 'Report Recurrence',
    ReportRetentionPeriod: 'Report Retention Period',
    ReportType: 'Report Type',
    Retention: 'Retention',
    RetentionDays: 'Days (Maximum {days})',
    RunQuery: 'Run Query',
    SavedFilter: 'Saved Filter',
    SavedFilterErrorMessage: 'A filter is required',
    ScheduledTime: 'Scheduled Time',
    ScheduledTimeErrorMessage: 'The selected time occurs in the past.',
    Schedules: 'Schedules',
    SchedulesNoData: 'No schedules to display',
    SelectFilter: 'Select a filter',
    TimeRange: 'Time Range',
    TrafficExport: 'Traffic Export',
    Weekly: 'Weekly',
  },
  Role: {
    Admin: 'Admin',
    ClickToSelect: 'Click to Select Role',
    Collapse: 'Collapse Role',
    Expand: 'Expand Role',
    FullRulesetManager: 'Full Ruleset Manager',
    GlobalAdmin: 'Global Administrator',
    GlobalAdminSubtext: 'Manage all resources and security settings. Cannot manage users and roles',
    GlobalOrgOwner: 'Global Organization Owner',
    GlobalOrgOwnerSubtext: 'Manage all resources, users, and Security Settings',
    GlobalPolicyObjectProvisioner: 'Global Policy Object Provisioner',
    GlobalReadOnly: 'Global Viewer',
    GlobalReadOnlySubtext:
      'Read-only access to all resources. Additional Ruleset modification and provisioning capabilities may be added by adding roles to the user.',
    GroupCollapse: 'Collapse Group Roles',
    GroupExpand: 'Expand Group Roles',
    Label: 'Role Label',
    LimitedRulesetManager: 'Limited Ruleset Manager',
    Owner: 'Owner',
    RulesetManagement: 'Ruleset Management',
    RulesetManager: 'Ruleset Manager',
    RulesetProvisioner: 'Ruleset Provisioner',
    RulesetViewer: 'Ruleset Viewer',
    Set: 'Set Role',
    WorkloadManager: 'Workload Manager',
  },
  Rule: {
    Add: 'Add Rule',
    Merge: 'Merge Rules',
    MergeMessage:
      'A Rule exists with the same Service but different {endpoint}. This Rule will be merged with the existing Rule.',
    Validation: {
      BothVirtualServicesOnlyVirtualServicesWorkloads:
        "Cannot use 'Uses Virtual Services and Workloads' and 'Uses Virtual Services only' together for Consumers.",
      CannotUseWorkloadSubnetsWithRuleOptions:
        "SecureConnect and Machine Auth cannot be used with 'Use Workload Subnets'",
      CantUseMachineAuthWithNonDefaultNetwork:
        "'Non-Corporate Networks (Endpoints Only)' or 'All Networks' cannot be used with 'Machine Authentication'",
      CantUseSecureConnectWithNonDefaultNetwork:
        "'Non-Corporate Networks (Endpoints Only)' or 'All Networks' cannot be used with 'SecureConnect'",
      ConsumerIpListVirtualServiceVirtualServerWorkload:
        "Cannot use IP Lists, Virtual Services, or Workloads along with 'Uses Virtual Services and Workloads' for Consumers.",
      ConsumerIpListWithMachineAuth:
        'Machine Authentication is not supported for Rules that use IP Lists for Consumers.',
      ConsumerIpListWorkload:
        "Cannot use IP Lists or Workloads if you select 'Uses Virtual Services only' for a Consumer.",
      ConsumerMustHaveOnlyIPLists:
        "Consumers must have only IP List(s) with 'Non-Corporate Networks (Endpoints Only)' or 'All Networks'",
      ConsumerWithVirtualService:
        "To use Virtual Services for Consumers, you must first select 'Uses Virtual Services only'.",
      ContainerHostItself: "Cannot use 'Container Host' along with any other Consumer.",
      ContainerHostNonTcpService: "Cannot use non TCP Port or Port Range with 'Container Host'.",
      ContainerHostProviders:
        "Only Labels, Workloads, or All Workloads can be used as Providers if 'Container Host' is the Consumer.",
      ContainerHostSecureConnectMachineAuthStateless:
        "Cannot use SecureConnect, Stateless Rules, or Machine Authentication in a Rule if 'Container Host' is the Consumer.",
      ContainerHostWindowsService: "Cannot use Windows services with 'Container Host'.",
      CorrectAllErrors: 'Cannot save. Correct all errors in the Rules.',
      DuplicateScopesNotAllowed: 'Duplicate Scopes are not allowed',
      InvalidChainName: 'Ip table rules contains Invalid chain name',
      InvalidCharacter: 'Ip table rules contains Invalid character',
      InvalidFormat: 'Invalid format',
      InvalidParameter: 'Ip table rules contains Invalid parameter',
      InvalidTableName: 'Ip table rules contains Invalid table name',
      IPListsCantBeProviderAndConsumer: 'Cannot use IP Lists for both Providers and Consumers.',
      LabelWithWarning:
        'The highlighted labels or label groups are used in the ruleset scope and in this rule. This may result in the rule being excluded from the policy.',
      MachineAuthWithAllServices: 'Machine Authentication is not supported for Rules that use All Services',
      MachineAuthWithNullService:
        'Machine Authentication is not supported for Rules that use From Providers for Services',
      MachineAuthWithNullUbService:
        'Machine Authentication is not supported for Rules that use IP Lists, ‘Uses Virtual Services and Workloads’, or ‘Uses Virtual Services only’ for Providers or Consumers.',
      NamedAppLabelCantBeUsed: "Cannot use a named Application Label in a Rule unless the Scope uses ''{use}''.",
      NamedAppLabelIsUsed: "A named Application Label is used in a Rule. This Scope must use ''{use}''.",
      NamedEnvLabelCantBeUsed: "Cannot use a named Environment Label in a Rule unless the Scope uses ''{use}''.",
      NamedEnvLabelIsUsed: "A named Environment Label is used in a Rule. The Scope must use ''{use}''.",
      NamedLocLabelCantBeUsed: "Cannot use a named Location Label in a Rule unless the Scope uses ''{use}''.",
      NamedLocLabelIsUsed: "A named Location Label is used in a Rule. The Scope must use ''{use}''.",
      NetworkTypeMustBeDefaultCorporate:
        "'Non-Corporate Networks (Endpoints Only)' or 'All Networks' cannot be used with the 'Use Virtual Services only' option as Consumers or Providers",
      NonCorporateMustUseIpList:
        "'Non-Corporate Networks (Endpoints Only)' or 'All Networks' is only supported for Rules that contain IP List(s) as Consumers or Providers",
      OverlappingRules: 'Ip table rules contains Overlapping rules',
      ProcessServicesCantWithIPListProvider: 'Process-based Services cannot be used with IP List providers',
      ProviderIpListVirtualServiceVirtualServerWorkload:
        "Cannot use IP Lists, Virtual Services, Virtual Servers, or Workloads along with 'Uses Virtual Services and Workloads' for Providers.",
      ProviderIpListWindowsService: 'Cannot use Windows Services as a Providing Service when IP Lists is the Provider.',
      ProviderIpListWithMachineAuth:
        'Machine Authentication is not supported for Rules that use IP Lists for Providers.',
      ProviderMustHaveOnlyIPLists:
        "Providers must have only IP List(s) with 'Non-Corporate Networks (Endpoints Only)' or 'All Networks'",
      ProviderWithIpListScopedUser:
        'Users with Scoped Roles cannot create Extra-Scope Rules which have IP Lists as Providers.',
      ProviderWithIpListWorkload:
        "Cannot use IP Lists or Workloads if you select 'Uses Virtual Services only' for a Provider.",
      ProviderWithVirtualService:
        "To use Virtual Services or Virtual Servers for Providers, you must first select 'Uses Virtual Services only'.",
      ProvidingServicesVirtualServices: "Cannot use a Providing Service if 'Uses Virtual Services only' is a Provider.",
      RemoveUnpairedWorkloads: 'Cannot save. Remove unpaired Workloads in red.',
      RemoveUnpairedWorkloadsInRed: 'Remove unpaired Workloads in red.',
      RequiresLabel: 'Cannot save. A Custom iptables Rule requires a Label.',
      RequiresProviderConsumer:
        'Select {type, select, providers {Providers} consumers {Consumers}} except IP Lists or named Workloads',
      RequiresProviderServiceConsumer: 'Cannot save. A Rule requires a Provider, Service, and Consumer.',
      ScopeLabelInRulesetWithAllLabel:
        "Cannot create a Scope with a named Label when a Scope already exists with a corresponding 'All' Label.\nYou can edit the existing Scope to include a named Label in place of 'All'.",
      ScopeRequiresLabels: 'Cannot save. A Scope requires 3 Labels or Label Groups.',
      SecureConnectCantWithIPListBoundVirtual:
        "SecureConnect is not supported for Rules that use IP Lists, 'Uses Virtual Services and Workloads', or 'Uses Virtual Services only' for Providers or Consumers.",
      SecureConnectWithNullUbService:
        "SecureConnect is not supported for Rules that use IP Lists, 'Uses Virtual Services and Workloads', or 'Uses Virtual Services only' for Providers.",
      SecureConnectWithSecurityPrincipal: 'SecureConnect cannot be used with Security Principal.',
      StatelessInvalidConsumers:
        'A Stateless Rule can only have the following types of Consumer: Any IP List plus All Workloads, or a Label (maximum one of a specific type) , or an individual item',
      StatelessWithIPList: 'IP Lists in Providers with a Stateless Rule is not supported',
      StatelessWithLabelGroup: 'Label Group in Consumers with a Stateless Rule is not supported',
      StatelessWithMachineAuth: 'Machine Authentication is not supported for Stateless Rules.',
      StatelessWithSecureConnect: 'SecureConnect is not supported with Stateless Rules.',
      UserGroupsCantWithIPListConsumer: 'Cannot use User Groups if IP Lists is a Consumer.',
      UserGroupsWithLabelOrWorkload: 'You must use a Label, Label Group, or Workload with a User Group',
      UseWorkloadSubnetWithLabelsOrAllWorkloads:
        "You must use a Label, Label Group, or 'All Workloads' with 'Use Workload Subnets'",
    },
    View: 'View Rule',
  },
  RuleSearch: {
    Access: 'Access',
    ActiveRules: 'Active Rules',
    Advanced: 'Advanced',
    AdvancedDescription: 'Search by Provider, Consumer, or both',
    AllResults: 'All Results',
    AllResultsDescription: 'Any match of selected filters',
    Basic: 'Basic',
    BasicDescription: 'Search all attributes',
    Columns: 'Columns',
    CreatedAtUc: 'Created At',
    CreatedIn: 'Created in the',
    CustomCreatedAtRange: 'Custom Created at range',
    CustomUpdatedAtRange: 'Custom Updated at range',
    DraftRules: 'Draft Rules',
    Editable: 'Editable',
    ExactResults: 'Exact Results',
    ExactResultsDescription: 'Exact match of selected filters',
    Extra: 'Extra',
    LandingMessage: 'Select filter parameters then click "Run" to display filtered rules',
    MaxEntitiesError: 'Up to 8 filter attributes allowed per search.',
    Placeholders: {
      Consumers: 'Filter by Consumers',
      Providers: 'Filter by Providers and Rule attributes',
      ProvidersOrConsumers: 'Filter by Labels and Rule attributes',
    },
    ReadOnly: 'Read-only',
    ResetFilters: 'Reset Filters',
    RulesetName: 'Ruleset name',
    UpdatedAtUc: 'Updated At',
    UpdatedIn: 'Updated in the',
    WindowsProcessName: 'Windows process name',
    WindowsServiceName: 'Windows service name',
  },
  Rulesets: {
    ActionDiscardPendingChanges: 'Taking this action will discard pending changes.',
    Add: 'Add Ruleset',
    AddNew: 'Add a new Ruleset',
    AddRulesetFromTemplate: 'Add Ruleset from Template',
    AddRulesTo: 'Add Rules to Ruleset',
    AddTo: 'Add to Ruleset',
    AllowRules: 'Allow Rules',
    AndRules: 'Rulesets and Rules',
    AndRulesSideNav: 'Rulesets & Rules',
    CreateNew: 'Create New Ruleset',
    CreateOrSelect: 'Select or Create a new Ruleset',
    CreatePage: {
      CopyOf: 'Copy of {name}',
      DuplicateName: 'Duplicate Ruleset Name',
      Placeholder: {
        AppScope: 'Choose an Application Label for the new Ruleset Scope',
        EnvScope: 'Choose an Environment Label for the new Ruleset Scope',
        LocScope: 'Choose a Location Label for the new Ruleset Scope',
        RulesetDescription: 'Type a description for the new Ruleset',
        RulesetName: 'Type a name for the new Ruleset',
      },
    },
    Delete: 'Remove {count, plural, =1 {Ruleset} other {# Rulesets}}',
    DeleteConfirm: 'Are you sure you want to remove the selected Rulesets?',
    DeleteRuleset: 'Are you sure you want to remove this Ruleset?',
    DeleteSeveral: 'Remove Rulesets',
    DenyRule: 'Deny Rule',
    DenyRules: 'Deny Rules',
    DenyRulesAuto: '{count, plural, =1 {Deny Rule} other {Deny Rules}}',
    Disabled: 'Disabled Ruleset',
    DisableRuleset: 'Disable Ruleset',
    DuplicateRuleset: 'Duplicate Ruleset',
    EditRuleset: 'Edit Ruleset',
    EnableRuleset: 'Enable Ruleset',
    EnforcementBoundaryRuleset: 'Proposed Deny Rule Ruleset',
    FilterScopes: 'Filter Scopes',
    NewProposedBoundaryRuleset:
      'The Deny Rule Ruleset contains rules to allow blocked connections to cross the Boundary. Rules can be edited prior to saving the Ruleset.',
    NewProposedRuleset:
      'Rules allowing the connections will be added to the selected Ruleset. Rules can be edited prior to saving the Ruleset.',
    NoData: 'No Rulesets to display',
    NoMatchData: 'No matching Rulesets to display',
    PartOfApplication: 'One or more of the selected Rulesets is part of an application',
    PolicySettingsPage: {
      DisplayScopesInRulesets: 'Display Scopes in Rulesets',
      DisplayScopesSettingInfo:
        "Rulesets apply to all workloads unless a scope is specified. Scope will limit the Ruleset's rules to only apply to workloads that match a selected set of labels",
      ScopesInRulesets: 'Scopes in Rulesets',
      ScopesInRulesetsNo:
        'Rulesets apply to all workloads by default. Scope selection is still available in the Ruleset Action menu',
      ScopesInRulesetsYes: 'Optional scope selection in Rulesets is available by default',
    },
    ProposedRuleset: 'Proposed Ruleset',
    ProvisionRuleset: 'Provision Ruleset',
    RemoveResultTitle: 'Result of {count, plural, =1 {Ruleset} other {Rulesets}} removal',
    RemoveRuleset: 'Remove Ruleset',
    ReorderRules: 'Reorder Rules',
    RevertRuleset: 'Revert Ruleset',
    RoleLabelNotSupported:
      'The Role Label is not supported for Scopes. Use the Application, Location, and Environment Labels only.',
    Rules: {
      Added: 'ADDED',
      AddedScope: 'Added Scope',
      AdditionPending: 'ADDITION PENDING',
      AdditionProposed: 'Addition Proposed',
      AddNote: 'Add Note',
      AddServiceConfirmation: "Adding a new Service replaces 'All Services'. Are you sure?",
      AllNetworks: 'All Networks',
      ConfirmRemoveScopes: 'Are you sure you want to remove the selected Scopes?',
      ConfirmRuleDelete: 'Are you sure you want to remove the selected {count, plural, =1 {Rule} other {Rules}}?',
      ConsumerServices: 'The Source and Services are common',
      ConsumingProcess: 'Consuming Process',
      ConsumingService: 'Consuming Service',
      ConsumingUsername: 'Consuming Username',
      CorporateNetwork: 'Corporate Network',
      Deleted: 'DELETED',
      DeleteNote: 'Delete Note',
      DeleteProposed: 'Delete Proposed',
      DeleteRules: 'Remove {count, plural, =1 {Rule} other {Rules}}',
      DeleteScopes: 'Delete Scopes',
      DeletionPending: 'DELETION PENDING',
      DerivedFromProviderVirtualServices: 'Derived from Provider Virtual Services',
      DestinationService: 'Destination Service',
      DestinationServices: 'Destination Services',
      DestinationUsername: 'Destination Username',
      DuplicateRuleNotif: 'This will duplicate an existing Rule',
      EditNote: 'Edit Note',
      ExtraScope: {
        Add: 'Add Extra-Scope Rule',
        AddDesc: 'Allow traffic between Groups or entities',
        IPListWarning:
          'To allow outbound access to IP Lists, create an Intra-Scope Rule to prevent the Rule from being applied to a larger set of Workloads.',
        NoData: 'No Extra-Scope Rules to display',
        NoMatch: 'No matching Extra-Scope Rules',
        Rules: '{count, plural, =0 {No Extra-Scope Rules} =1 {1 Extra-Scope Rule} other {# Extra-Scope Rules}}',
        Title: 'Extra-Scope Rule',
      },
      ExtraToIntra: 'Rule changed from Extra-Scope to Intra-Scope.',
      FilterRules: 'Filter Rules',
      Header: {
        NoMatchingScopes: 'No matching Scopes',
      },
      Inbound: 'Inbound Rules',
      IndexColumnLabel: 'No.',
      IntraScope: {
        Add: 'Add Intra-Scope Rule',
        AddDesc: 'Allow tier-to-tier, Role-to-Role, or IP List traffic',
        NoData: 'No Intra-Scope Rules to display',
        NoMatch: 'No matching Intra-Scope Rules',
        Rules: '{count, plural, =0 {No Intra-Scope Rules} =1 {1 Intra-Scope Rule} other {# Intra-Scope Rules}}',
        Title: 'Intra-Scope Rule',
      },
      IntraToExtra: 'Rule changed from Intra-Scope to Extra-Scope.',
      IpTables: {
        Actors: 'Receivers',
        Add: 'Add Custom iptables Rule',
        AddDesc: 'Integrate existing Linux iptables in a Ruleset',
        Form: {
          IpVersion: 'IP Version',
          StatementsPlaceholder: 'Type or paste a custom iptables Rule',
        },
        Grid: {
          Statements: 'iptables Rules applied to Scope',
        },
        NoData: 'No custom iptables Rules to display',
        NoMatch: 'No matching custom iptables Rules',
        Rules:
          '{count, plural, =0 {No Custom iptables Rules} =1 {1 Custom iptables Rule} other {# Custom iptables Rules}}',
      },
      LabelAndLabelGroups: 'Labels and Label Groups',
      LabelAndLabelGroupsExcept: 'Labels and Label Groups Except',
      MergeCommon: 'Automatically merge similar rules together when',
      Modified: 'MODIFIED',
      ModifiedPending: 'MODIFICATION PENDING',
      ModifiedProposed: 'MODIFICATION PROPOSED',
      NoCustomRulesWorkload: 'No Custom Iptables Rules apply to this workload',
      NoEntityUsed:
        'No {object, select, workloads {Workloads} services {Services} iplists {IP Lists} userGroups {User Groups} labels {Labels and Label Groups} virtualServices {Virtual Services} virtualServers {Virtual Servers} other {{object}}} in this Ruleset Rules',
      NoForwardRulesWorkload: 'No Forward Rules apply to this workload',
      NoInboundRules: 'No Inbound Rules to display',
      NoInboundRulesWorkload: 'No Inbound Rules apply to this workload',
      NoMatchingRules:
        'There are no {type, select, intrascope {Intra-Scope} extrascope {Extra-Scope} iptables {Custom iptables} overrideDeny {Override Deny}} Rules that match the filter criteria. Clear filter to see all the rules',
      NonCorporateNetworks: 'Non-Corporate Networks (Endpoints Only)',
      NoOutboundRules: 'No Outbound Rules to display',
      NoOutboundRulesWorkload: 'No Outbound Rules apply to this workload',
      NoRules: 'No Rules in the Ruleset',
      NoRulesDefined:
        'There are no {type, select, intrascope {Intra-Scope} extrascope {Extra-Scope} iptables {Custom iptables} overrideDeny {Override Deny}} Rules defined',
      NotePlaceholder: 'Type a note for the Rule',
      Outbound: 'Outbound Rules',
      PortOrPortRange: 'Port/Port Range',
      Proposed: 'Proposed',
      ProviderConsumer: 'The Source and Destination are common',
      ProviderServices: 'The Destination and Services are common',
      ProvidingProcess: 'Providing Process',
      ProvidingService: 'Providing Service',
      ProvidingUsername: 'Providing Username',
      ReadonlyService: 'Rules cannot be edited in the UI for inline services without port or port-ranges defined.',
      RemoveAllScopes: 'Rulesets must contain at least one Scope. Add a second Scope and delete the first.',
      RemovedScope: 'Removed Scope',
      ResourceDeleted:
        'The rule is referring to a {object, select, labels {Label} label_groups {Label Group} services {Service} virtual_services {Virtual Service} virtual_servers {Virtual Server} ip_lists {IP List} other {{object}}} {resourceName} that is deleted. Edit the rule to remove references to {resourceName}',
      ReverseError:
        'Rule reversal is not supported for Rules with Container Host, Virtual Services, User Groups, or Virtual Servers.',
      RuleOptions: 'Rule Options',
      SelectApplication: 'Select Application Label',
      SelectEnvironment: 'Select Environment Label',
      SelectingContainerHostConfirmation: 'Selecting Container Host replaces any other Consumer. Are you sure?',
      SelectingLabelConfirmation:
        '{selectingInclude, select, true {Including} false {Excluding}} {typeName} Label replaces {selectingInclude, select, true {Excluded} false {Included}} {typeName} Labels. Are you sure?',
      SelectingReplacesConfirmation: 'Selecting {resourceName} replaces {selectedResourcesNames}. Are you sure?',
      SelectLocation: 'Select Location Label',
      SelectRuleOptions: 'Select Rule Options',
      SelectService: 'Select Service',
      SourceService: 'Source Service',
      SourceUsername: 'Source Username',
      TooManyRules:
        'For Rulesets with more than 500 Rules, changes to Rules are not highlighted inline. For best display results, we recommend splitting Rulesets with more than 500 Rules into multiple Rulesets.',
      UnchangedScope: 'Unchanged Scope',
      UpdateProposed: 'Update Proposed',
      UseAdvancedOptions:
        "To add Label exclusion (All Labels and Label Groups except), Virtual Services, Virtual Servers and related options use 'Advanced Options'.",
      UseWorkloadSubnets: 'Use Workload Subnets',
    },
    RulesetActions: 'Ruleset Actions',
    RulesetDeletedHeader: 'Ruleset is pending deletion',
    RulesetDeletedInstructions:
      'You have two options to continue with rule generation: (1) Provision the ruleset. The existing ruleset will be deleted and a new ruleset created. (2) Revert the ruleset to its last provisioned state.',
    RulesetDisabledHeader: 'Ruleset is disabled',
    RulesetDisabledInstructions: 'To continue with rule generation, you must enable the ruleset',
    RulesForTemplate: 'Rules for {template}',
    SaveOrder: 'Save Order',
    ScopedUserRuleset:
      'You can only view Rulesets that match the scopes assigned to you. Rules outside your scope that affect your applications can be viewed in the <a>Rule Search</a> page.',
    Scopes: {
      AddScope: 'Add Scope',
      DuplicateScope: 'Duplicate Scope',
      DuplicateScopeText: 'The scope is a duplicate. Select a different {labelNames}',
      EmptyScopeSubtitle: 'Add a scope to limit the ruleset to apply to workloads that match a selected set of labels',
      RemoveLastScope: 'Remove Last Scope',
      RemoveLastScopeWarning:
        'Removing the last scope will result in the rules potentially applying across all managed wokloads. Are you sure?',
      ScopeModifiedDetails: 'Scope modified - Details',
      ScopesCannotBeAdded: 'Scopes cannot be added currently since the rules defined refer to all label types',
      ScopesCount:
        '{count, plural, =1 {# Scope} other {# Scopes}} {matched, select, true {Matched } false {}}- Each scope must include <span class="{className}">{labelNames}</span> Labels',
      ScopesCreatedByRuleBuilder:
        'The ruleset is generated by Illumination Map/Policy Generator. Scopes cannot be added',
    },
    SearchAllRulesets: 'Search All Rulesets',
    ServiceCreation: 'Service Creation',
    ServiceSubstitution: 'If no existing Service matches a port/protocol',
    SettingsNotification:
      'During rule generation, rules with common elements are merged to reduce the number of rules. Options selected below are used to perform these optimizations.',
    SomeSelectedFlowsAreNotCoveredByRules:
      'Some selected flows are not covered by Rules because they are not within the scope of the Ruleset.',
    Summary: 'Ruleset Summary',
    TypeName: 'Type Name of New Ruleset',
    UpdateResultTitle: 'Result of {count, plural, =1 {Ruleset} other {Rulesets}} status update',
    UpdateSuccess: '{count, plural, =1 {Ruleset has} other {# Rulesets have}} been successfully updated',
    View: 'View Rulesets',
  },
  RulesetTemplatesWizard: {
    ErrorMessageToContactSupport: '{errorMessage}. If the issue persists, contact <a>{link}</a> for more information.',
    PreviewAndSave: 'Preview and Save',
    SelectTemplate: 'Select Template',
  },
  SecureConnect: {
    MustBeUnique: 'All rules must be unique.',
    NoData: 'No SecureConnect Gateways to display',
  },
  SecureGateway: {
    AccessibleBy: 'Accessible by',
    AddIdleTimeoutMin: 'Please enter a valid Idle Timeout Duration Value',
    AddNew: 'Add SecureConnect Gateway',
    AddValidAuthMode: 'Please enter a valid Authentication Mode',
    AddValidCertificateAuthID: 'Please enter a valid Issuer DN',
    AddValidPreSharedKey: 'Please enter a valid pre-shared key (PSK) for the gateway',
    CertificateAuthorityID: 'Certificate Authority ID',
    CertificateIssuerNameExample: 'Example: "C=US, O=Acme Inc., CN=Acme Inc. CA"',
    CertificateRsaSig: 'Certificate (rsa-sig)',
    EnterCertificateIssuerName: 'Enter the Certificate Issuer Name using the following format',
    EnterSubjectName:
      'Enter the Subject Name for the Gateway certificate using the following format for IKE peer authentication.',
    EnterWorkloadDN:
      'Enter the DN to identify the certificate used on the Workloads (when using multiple certificates for the Workloads).',
    GatewayIPAddress: 'Gateway IP Address',
    GatewaySubjectDN: 'Gateway Subject DN',
    IdleTimeout: 'Idle Timeout (Minutes)',
    IPsecConfiguration: 'IPsec Configuration',
    IssuerDistinguishedName: 'Issuer Distinguished Name (DN)',
    LinuxIKEv2DPD: 'Linux IKEv2 only DPD',
    LinuxIKEv2NAT: 'Linux IKEv2 only NAT-T',
    LinuxVersion: 'Linux Version',
    List: {
      Message: {
        RemoveResultTitle:
          'Result of {count, plural, =1 {SecureConnect Gateway} other {SecureConnect Gateways}} removal',
        RemoveSuccess:
          '{count, plural, =1 {SecureConnect Gateway has} other {# SecureConnect Gateways have}} been successfully removed',
      },
    },
    LocalID: 'Local ID',
    Name: '{name}',
    PreSharedKey: 'Pre-shared Key',
    ProtectedIPList: 'Protected IP List',
    PublicKey: 'public key',
    RemoteID: 'Remote ID',
    RulesEditInfo:
      'Rules below include Protected IP Lists. When not restricted to specific labels the scope expands to all workloads.',
    SecureConnect: 'SecureConnect Gateways',
    SecureConnectDelete: 'Remove {count, plural, =1 {SecureConnect Gateway} other {SecureConnect Gateways}}',
    SecureConnectDeleteConfirm:
      'Are you sure you want to remove {count, plural, =1 {this SecureConnect Gateway} other {# selected SecureConnect Gateways}}?',
    SubjectNameExample: 'Example: "C=US, CN=vpn.acmeinc.com"',
    TunnelMode: 'Tunnel mode',
    TypeGatewayDesc: 'Type the description of the Gateway',
    TypeGatewayName: 'Type the name of the Gateway (required)',
    TypeGatewaySubjectDN: 'Type the Gateway Subject DN',
    TypeIdleTimeout: 'Type the idle timeout duration (in minutes; must be a positive integer)',
    TypeIPAddress: 'Type the IP address for the Gateway (required)',
    TypeIssuerDN: 'Type the Issuer DN (Required)',
    TypePreSharedKey: 'Type the pre-shared key (PSK) for the Gateway (required)',
    TypeWorkloadSubjectDN: 'Type the Workload Subject DN',
    VPNConfiguration: 'VPN Configuration',
    WindowsVersion: 'Windows Version',
    WorkloadSubjectDN: 'Workload Subject DN',
    WorkloadSubjectDNExample: 'Example: "C=US, O=Acme Inc."',
  },
  SegmentationTemplates: {
    BadAttempt: 'Please select a file or choose "Cancel"',
    BadFileType: 'File type not supported. Please select a .json file or choose "Cancel"',
    CancelInstallation: 'Cancel Installation',
    Content: 'Content:',
    Dependencies: 'Dependencies and Prerequisites',
    Edited: 'edited',
    EULAAcceptance: 'EULA Acceptance',
    EULAAcceptanceCheck:
      'By checking this box, you acknowledge that you have read, understand, and agree to be bound by the',
    EULALink: 'Illumio, Inc. End User License Agreement.',
    EULAText1:
      'Illumio Segmentation Templates are designed to provide useful functionality for the deployment and operation of the Policy Compute Engine.',
    EULAText2: 'Illumio Segmentation Templates are provided as is, with limited support.',
    EULAText3:
      'Illumio is not responsible for, and expressly disclaims all liability for or damages of any kind arising out of use, reference to, or reliance on these tools.',
    FileError: 'The selected file is not a valid Segmentation Template. Please choose another file.',
    ForLinux: 'For Linux',
    ForWindows: 'For Windows',
    Install: 'Install',
    Installing: 'Installing',
    InvalidVersion: 'Because a newer version of this template is installed, this template is read-only.',
    IpListCount: '{ipListCount, plural, =0 {} =1 {# IP list} other {# IP lists}}',
    LabelCount: '{labelCount, plural, =0 {} =1 {# label} other {# labels}}',
    LabelGroupCount: '{labelGroupCount, plural, =0 {} =1 {# label group} other {# label groups}}',
    LastUpdated: 'Last Updated:',
    Load: 'Load',
    LoadFile: 'Load File',
    LogIn: 'Log In to Support Portal',
    NamingConflict:
      'The selected template cannot be installed because objects with the following names already exist. Rename these objects and try again.',
    New: 'NEW',
    Overwrite: 'Overwrite',
    PreserveChanges: 'Preserve Changes',
    ReadOnlyError:
      'The following objects have been deleted and are read only. You must either revert or provision these changes before installing this template.',
    RulesetCount: '{rulesetCount, plural, =0 {} =1 {# ruleset} other {# rulesets}}',
    SegmentationTemplate: 'Segmentation Template',
    SegmentationTemplates: 'Segmentation Templates',
    ServiceCount: '{serviceCount, plural, =0 {} =1 {# Service} other {# Services}}',
    SupportLoginConfirmation:
      'To use Segmentation Templates, log in to the Illumio Support Portal or load a Segmentation Templates file.',
    UndeletableObjects:
      'Some policy objects were not deleted during Uninstall because they are in use or are dependencies of other objects. These objects can be deleted manually later.',
    Uninstall: 'Uninstall',
    UninstallTemplate: 'Uninstall Segmentation Template',
    UninstallWarning:
      'Uninstalling this Segmentation Template will permanently delete all associated Labels, Label Groups, Services, Rule Sets, and IP Lists',
    Universal: 'Universal',
    Update: 'Update',
    UploadText1:
      'To use this feature, first download the Segmentation Templates file to your computer from the Illumio Support Portal. (An internet connection and login credentials are required.)',
    UploadText2: 'Then open the file in your browser. Click "Choose File" below to select the file and click "Load".',
    Version: 'Version:',
    WhatsNew: "What's New",
  },
  Services: {
    AddNew: 'Add a new Service',
    AddSuggested: 'Add a suggested Service',
    AddValidNameAndService: 'Please add a valid Name and Service.',
    Create: 'Create Service',
    Delete: 'Remove {count, plural, =1 {Service} other {# Services}}',
    DeleteConfirm: 'Are you sure you want to remove this Service?',
    DeleteSeveral: 'Remove Services',
    DeleteSeveralConfirm: 'Are you sure you want to remove the selected Service(s)?',
    DuplicateMessage:
      "A service of this name already exists. It's recommended to use a different name for a new service",
    Edit: {
      Info: {
        ICMPInstruction: 'To add ICMP with type and code, use <type> / <code>:',
        InstructionsExample: {
          ProcessName: 'myprocess\nMy Schedule',
        },
        PortInstruction: 'To add ports, use a hyphen to indicate the port range:',
        ProcessNameInstruction: 'To add process name, use the Windows process name without .exe or path:',
        ProcessPathInstruction: 'To add process path, use the full path or % for wildcard:',
        ProtocolInstruction: 'To add protocol, use one of the supported protocol names:',
      },
      Message: 'Please add a valid Name and Port Range before saving.',
      RansomwareRiskyWarning:
        'This service is used in ransomware protection reporting. Changes to the service definition may change the reported results.',
    },
    Errors: {
      Create: 'Unable to Create Service',
      Edit: 'Unable to Edit Service',
    },
    List: {
      CannotDeleteAny: 'This is a default Service and cannot be edited or removed.',
      CannotDeleteBoundToBPS:
        'Cannot remove {count, plural, =1 {Service that is} other {# Services that are}} referenced by an existing Virtual Service',
      CannotDeleteBoundToEnforcementBoundary:
        'Cannot remove {count, plural, =1 {Service that is} other {# Services that are}} referenced by an existing Deny Rule',
      CannotDeleteBoundToRules:
        'Cannot remove {count, plural, =1 {Service that is} other {# Services that are}} referenced by an existing Rule',
      RansomwareRiskyWarning:
        'One or more selected services are used in ransomware protection reporting. Removing them may change the reported results.',
      RemoveConfirm: 'Are you sure you want to remove the selected {count, plural, =1 {Service} other {Services}}?',
      RemoveSuccess: '{count, plural, =1 {Service has} other {# Services have}} been successfully removed',
    },
    Mixin: {
      AtLeastOnePort: 'At least one port is required.',
      AtLeastOneProcess: 'At least one port or process is required.',
      Instructions: 'When the Service is used in a Rule, allow the following:',
      Os: {
        All: {
          Subtitle: 'Specify ports and protocols',
          Title: 'All Operating Systems: Port-Based',
        },
        Instructions:
          'Specify the operating systems this Service must be running on in order for it to take effect when used in a Rule',
        Title: 'Operating System',
        Windows: {
          Subtitle: 'Specify ports and protocols, processes, or Windows services',
          Title: 'Windows Inbound: Process/Service-Based',
        },
        WindowsOutbound: {
          Subtitle: 'Specify process name or process path',
          Title: 'Windows Outbound: Process/Service-Based',
        },
      },
      Placeholder: {
        ServiceDescription: 'Type a description for the new Service',
        ServiceName: 'Type a name for the new Service',
        ServicePorts:
          'Type or paste a port and protocol, a process or service, or a port and protocol with a process or service',
      },
      PortOrProcessIsRequired: 'Port or Process is required',
      PortsAndProtocols: 'Ports & Protocols',
      PortsProtocolsAndProcesses: 'Ports, Protocols, Processes and Services',
      ProcessIsRequired: 'Process is required',
    },
    NoServices: 'No Services to display. Try a different filter.',
    OverlappingPorts: 'Overlapping Ports',
    OverlappingServiceDefinitions: 'Overlapping Service Definitions',
    OverlappingTypeCode: 'Overlapping Type/Code',
    PolicyServices: 'Policy Services',
    ServiceDefinitions: 'Service Definitions',
    WindowsService: 'Windows Service',
  },
  Settings: {
    AuthenticationType: 'Authentication Type',
    BlockAction: {
      AddScopeForRejectConnections: 'Add Scope for Reject Connections',
      AllConfirmation: "Adding 'All Workloads' will remove all other Reject Connections Scopes.",
      BlockedConnectionAction: 'Blocked Connection Action',
      BlockedConnectionActionDesc:
        'The default blocked connection action is drop. Workloads that match these labels will reject blocked inbound connections.',
      DuplicatedScopesAndLabels: 'Reject Connections with the same scope has already been configured',
      EditScopeForRejectConnections: 'Edit Scope for Reject Connections',
    },
    CertificateExample: 'C=US, O=Illumio, OU=UX, CN=issuer@foo.com',
    CertificateIssuer: 'Certificate Issuer',
    CertificateSecondaryText: 'Fields in the name of Certificate Issuer that will be matched',
    Containers: {
      AddScopeForContainerInheritHostPolicy: 'Add Scope for Container Inherit Host Policy',
      AllConfirmation: "Adding 'All Workloads' will remove all other Containers Policy Scopes.",
      AnnotationLabelHint:
        'Any container annotation label is accepted by default. You can choose to restrict container annotations to one or more labels if needed, or assign your own label',
      AnnotationLabelTooltip:
        '"Use Container Annotations" gives the ability to container platform administrators to set the Labels on pods and services using annotations in the manifest files, within the allowed list defined by the Illumio admin.\n\n"Assign Label" unidirectionally sets the Label selected on pods and services within this namespace/project. This label cannot be overwritten with container annotations.',
      ConfigAllError: 'At least one named label must be selected to make containers inherit',
      ConfigInstruction: 'You need to select at least one named label to make containers inherit',
      ContainerInheritHostPolicy: 'Container Inherit Host Policy',
      ContainerInheritHostPolicyDesc:
        'Illumio Core enforces the host policy for all the docker containers running on the host if enabled.',
      ContainerSettings: 'Container Settings',
      ContainersPolicy: 'Containers Policy',
      DuplicatedScopesAndLabels: 'A Container Inherit policy with the same Scope has already been configured',
      EditScopeForContainerInheritHostPolicy: 'Edit Scope for Container Inherit Host Policy',
      ManagedDesc: 'Container workloads in their namespace are managed by PCE',
      UnmanagedDesc: 'Container workloads in this namespace are ignored by PCE',
    },
    Edit: {
      AllowIPv6Traffic: 'Allow IPv6 traffic',
      BlockIPv6Traffic: 'Block IPv6 traffic',
      IPv6TrafficAllAllowed: 'All IPv6 traffic allowed',
      IPv6TrafficAllowedBasedOnPolicy: 'Allowed based on policy',
      IPv6TrafficBlockedInEnforced: 'Blocked only in Enforced state. Always allowed on AIX and Solaris Workloads',
      ModifyScope: {
        Errors: {
          PolicyStateOption: 'Needs at least one of these: all, enforced, illuminated',
          PrimaryFirewall: 'Needs at least one of these: yes, no',
        },
        PrimaryFirewallRequired: 'Primary Firewall is required',
      },
      PolicyConfiguration: {
        PolicyUpdateModeDesc:
          'Adaptive applies policy changes to the Workload as soon as you Provision. Static stages policy changes on the Workload, but does not apply them until you decide to manually push them.',
      },
      SelectValuesBeforeSaving: 'Please select values for the settings before saving.',
      UpdateError: 'Check your Scopes configuration for overlapping Scopes and try again.',
      UpdateErrorTitle: 'Error updating Settings',
    },
    ESPEncriptionAndIntegrity: 'ESP Encryption and Integrity',
    EssentialServiceRule: 'Essential Service Rule',
    EssentialServiceRules: 'Essential Service Rules',
    EssentialServiceRulesDesc:
      'The following Essential Service Rules are programmed on every VEN to allow normal network functionality and communication with the PCE.',
    GenerateMaintenanceToken: 'Generate Maintenance Token',
    IKEAuth: 'IKE Auth',
    IKEAuthentication: 'IKE Authentication',
    IKECertificates: 'IKE Certificates',
    IKEv1L2TP: 'IKEv1/L2TP',
    IKEv2: 'IKEv1/IKEv2',
    IKEv12DHGroup: 'IKEv1/2 DH Group',
    IKEv12Integrity: 'IKEv1/2 Integrity',
    IKEv12PRF: 'IKEv1/2 PRF',
    IKEVersion: 'IKE version',
    IntervalSecondaryText: 'Minimum interval is 7 Days',
    IPForwarding: {
      AddScope: 'Add Scope for IP Forwarding',
      AllConfirmation: "Adding 'All Workloads' will remove all other IP Forwarding Scopes.",
      Description:
        'IP forwarding is blocked by default unless the host is part of a Container Cluster. Linux Workloads that match these scopes will allow all forwarded traffic. Illumio Core does not provide visibility of forwarded traffic.',
      DuplicatedScopesAndLabels: 'IP Forwarding with the same scope has already been configured',
      EditScope: 'Edit Scope for IP Forwarding',
      NoData: 'There are no scopes set up to enable IP forwarding',
      Title: 'IP Forwarding',
    },
    IPv6Traffic: 'IPv6 Traffic',
    LoopbackInterfaces: {
      AddScopeForLoopbackInterfaces: 'Add Scope for Loopback Interfaces Policy',
      AllConfirmation: "Adding 'All Workloads' will remove all other Loopback Interfaces Scopes.",
      ConfigAllError: 'At least one named label must be selected',
      DuplicatedScopesAndLabels: 'A Loopback Interface Policy with the same Scope has already been configured',
      EditScopeForLoopbackInterfaces: 'Edit Scope for Loopback Interfaces Policy',
      LoopbackInterfacesDescription:
        "Scopes where Loopback Interfaces participate in the policy Workloads that match the scope will apply policy on Loopback Interfaces. Loopback Interface's IP addresses will be distributed to peers.",
      NoData: 'There are no scopes set up to enable Loopback Interfaces',
      Title: 'Loopback Interfaces',
    },
    NATDetection: 'NAT Detection',
    NotNetworkDetectionMode:
      'You are using advanced cloud network detection settings. Contact Illumio Support for more information.',
    OrgDataCenterIsDisabled: 'Your Organization data center is <span class="{className}">disabled</span>.',
    PKI: 'PKI',
    PrivateDataCenter: 'Private Data Center or Public Cloud with 1:1 NAT (Default)',
    PrivateDataCenterDescription:
      'For Workloads in a known public cloud (e.g. AWS or Azure), the public IP address of the Workload as seen by the PCE will be distributed along with the IP addresses of interfaces on the Workload. Use this setting only if there are no shared SNAT IP address(es) for egress traffic from public cloud Workloads.',
    PSK: 'PSK',
    PublicCloudConfiguration: 'Public Cloud Configuration',
    PublicCloudWithSNAT:
      'Public Cloud with SNAT / NAT Gateway (recommended setting if using a NAT gateway in AWS or Azure or the default outbound access in Azure)',
    PublicCloudWithSNATDescription:
      'The PCE will ignore the public IP address of the Workload in policy computation. This setting should be used in environments where Workloads in a known public cloud (e.g. AWS or Azure) that connect to other Workloads or the PCE (outside the VPC or cloud) via an SNAT IP address or SNAT pool (one example is the NAT Gateway in AWS), as the public IP seen by the PCE is not specific to any Workload. Only the IP addresses of network interfaces on the Workload (which are usually private IP addresses) will be distributed in the policy.',
    RotationInterval: 'Self Signed Certificate Rotation Interval',
    SAIdleTimeout: 'SA Idle Timeout',
    SAIdleTimeoutDuration: '3 to 5 minutes',
    SALifetime: 'SA Lifetime',
    SecureConnect: 'Secure Connect',
    TamperProtectionInfo:
      'When set to Yes, the VEN will require a maintenance token to perform operations such as stop, suspend, and unpair. You can generate a VEN maintenance token for all VENs on the VEN List page or for any specific VEN on the VEN Detail page.',
    TamperProtectionMaintenanceToken: 'Require VEN Maintenance Token',
    TamperProtectionTitle: 'Tampering Protection',
    TransportMode: 'Transport Mode',
    UseCertificatesForAuthentication: 'Use Certificates for authentication:',
    UsePreShared: 'Use Pre-Shared Keys for authentication',
    ValidCertificateValues:
      '<span class="{className}">Can contain any of the following short name formats of object identifiers:</span></br>DC or domainComponent, C or countryName, O or organizationName, OU or organizationalUnitName, CN or commonName.',
    VenOperationSetting: 'Settings - VEN Operations',
    VensIKEAuthenticationInfo:
      'VENs will report and use the certificate whose Issuer Name matches the following for IKE Authentication',
    VensRecoveryHelpFirstLine:
      'VENs are protected from tampering by a maintenance token to perform operations like Stop, Suspend, Unpair. Tampering Protection can be disabled in the VEN Operations Settings',
    VensRecoveryHelpSecondLine: 'Clicking the button Generate Maintenance Token generates a token for all VENs',
    VensRecoveryInfo:
      "For a high level of security, it's recommended to set the Self Signed Certificate Rotation Interval to 30 Days or less. This setting applies only to VENs that were paired using VEN version 21.5.0 or higher",
    VensRecoveryTitle: 'Certificate Rotation',
    VensRotationIntervalError: 'The minimum Self Signed Certificate Rotation Interval is 7 Days',
    VensRotationIntervalIntegerError: 'Self Signed Certificate Rotation Interval should be an integer',
    VensVersionsHigher: 'VENs (versions 20.2.0 and higher)',
    VensVersionsLower: 'VENs (versions lower than 20.2.0)',
    WorkloadsHostedInCloud:
      'Your Workloads are hosted by a <span class="{className}">cloud service provider</span> that does not provide a private network.',
    WorkloadsHostedInCloudWithPrivate: 'Custom setting: <span class="{className}">Cloud Private Network</span>.',
    WorkloadsHostedInPrivate:
      'Your Workloads are hosted by a <span class="{className}">private data center</span>, or with a cloud service provider that provides a private network',
    WorkloadsHostedInPrivateOnly: 'Custom setting: <span class="{className}">Single Network</span>.',
  },
  States: {
    AK: 'Alaska',
    AL: 'Alabama',
    AR: 'Arkansas',
    AS: 'American Samoa',
    AZ: 'Arizona',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DC: 'District Of Columbia',
    DE: 'Delaware',
    FL: 'Florida',
    FM: 'Federated States Of Micronesia',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    IA: 'Iowa',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    MA: 'Massachusetts',
    MD: 'Maryland',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MI: 'Michigan',
    MN: 'Minnesota',
    MO: 'Missouri',
    MP: 'Northern Mariana Islands',
    MS: 'Mississippi',
    MT: 'Montana',
    NC: 'North Carolina',
    ND: 'North Dakota',
    NE: 'Nebraska',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NV: 'Nevada',
    NY: 'New York',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    PW: 'Palau',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VA: 'Virginia',
    VI: 'Virgin Islands',
    VT: 'Vermont',
    WA: 'Washington',
    WI: 'Wisconsin',
    WV: 'West Virginia',
    WY: 'Wyoming',
  },
  Static: {
    FourOhFour: {
      CouldNotFindPage: 'We could not find the page you are looking for.',
      LetUsKnow: 'If you have reached this page, please let us know at <a>{email}</a> so we can fix it.',
      PageNotFound: '404 Page not found',
    },
    ResourceNotFound: {
      CodeResourceNotFound: '404 Resource not found',
      ResourceNotFound: 'Resource not found.',
      StartOver: 'Start over.',
    },
  },
  StatusCodes: {
    BadGateway: '502 Bad Gateway',
    GatewayTimeout: '504 Gateway Timeout',
    HttpVersionNotSupported: '505 HTTP Version Not Supported',
    InsufficientStorage: '507 Insufficient Storage',
    InternalServerError: '500 Internal Server Error',
    LoopDetected: '508 Loop Detected',
    NetworkAuthenticationRequired: '511 Network Authentication Required',
    NotExtended: '510 Not Extended',
    NotImplemented: '501 Not Implemented',
    ServiceUnavailable: '503 Service Unavailable',
    VariantAlsoNegotiates: '506 Variant Also Negotiates',
  },
  SupportBundles: {
    SupportBundles: 'Support Bundles',
  },
  SupportReports: {
    Failed: 'Support Bundle Failed',
    Generating: 'Generating ...',
    NewReportOverwritesExistingReport:
      'Generating a new bundle will overwrite the existing one. Do you want to continue?',
    NoData: 'Select a VEN to generate a VEN support bundle',
    PageName: 'VEN Support Bundles',
    RequestedByAt: 'Requested by {user} at {when, date}',
    SupportReport: 'VEN Support Bundle',
    SupportReportBeingGenerated: 'The support bundle is being generated.',
    SupportReports: "Bundle can't be Generated",
    SupportReportWasGenerated: 'The support bundle has been successfully generated.',
  },
  Switches: {
    AclGeneratedAt: 'ACL Generated at',
    AclGenerationPending: 'ACL Generation Pending',
    AddInterfaces: 'Add Interfaces',
    AddNew: 'Add a new Switch',
    AppliedAt: 'Applied At',
    CannotDelete: 'The following {count, plural, =1 {Switch is} other {# Switches are}} cannot be deleted.',
    Delete: 'Remove {count, plural, =1 {Switch} other {# Switches}}',
    DeleteConfirm: 'Are you sure you want to remove the {count, plural, =1 {Switch} other {# Switches}}?',
    DeletedSuccessfully:
      'The following {count, plural, =1 {Switch has} other {# Switches have}} been successfully deleted',
    DownloadACLs: 'Download ACLs',
    EnforcementNode: 'Enforcement Node',
    Form: {
      IfIndexPlaceholder: 'Type ifIndex',
      InterfaceTitle: 'Interface {count, number}',
      MonitorTraffic: 'Monitor Traffic',
      SelectWorkloads: 'Select Workloads',
      SwitchIpPlaceholder: '0.0.0.0',
      SwitchNamePlaceholder: 'Type a switch name',
      TotalInterfaces: 'Total Interfaces',
    },
    GenerateACLs: 'Generate ACLs',
    List: {
      Message: {
        DeleteConfirm: 'Are you sure you want to remove the selected {count, plural, =1 {Switch} other {Switches}}?',
        WorkloadsManagedDeleted: 'All workloads managed by the switch will be removed as well.',
      },
    },
    Manufacturer: 'Manufacturer',
    MarkApplied: 'Mark Applied',
    Modals: {
      CreateErrorTitle: 'Create switch error',
      EditInterfacesError: 'Edit interfaces error',
    },
    Model: 'Model',
    NENHostname: 'NEN hostname',
    NoData: 'No Switches to display',
    NoInterfaceData: 'No Interfaces to display',
    NoMatchData: 'No matching Switches to display',
    SwitchIp: 'Switch IP',
    SwitchIPMustBeUnique: 'Switch IP must be unique',
    SwitchName: 'Switch Name',
    SwitchNameMustBeUnique: 'Switch Name must be unique',
  },
  SystemSettings: {
    Events: 'System Events',
    InterfaceName: 'Interface Name',
    KeyValue: 'Only key-value format is supported',
    LogForwarding: {
      Severity: {
        Info: 'Informational',
      },
    },
    Network: 'Network',
    NetworkEdit: {
      LogBackIn: 'You will be required to log back in.',
      NetworkSettingsApplied: 'Your network settings have been applied.',
    },
    TestEmailSentSuccess:
      'Test email was sent to  <span class="{className}">{email}</span>, check your email for validation.',
  },
  Tooltip: {
    ConnectedWorkloadCount:
      'Connected {count, plural, =1 {Workload} other {Workloads}}: <span class="{className}">{count}</span>',
    ContainerWorkload: 'Container Workload:',
    ContainerWorkloadCount:
      '{count, plural, =1 {Container Workload} other {Container Workloads}}: <span class="{className}">{count}</span>',
    EndpointCountHighlight:
      '{count, plural, =1 {<span class="{className}">{type}:</span>} other {<span class="{className}">{type}s:</span>}} {count}',
    Enforcement: 'Enforcement:',
    EnforcementCount: '{count, plural, =0 {} other {{count} {type}}}',
    Fqdns: 'FQDNs:',
    Group: 'Group:',
    GroupBy: 'Group by',
    GroupCount: '{count, plural, =1 {Group} other {Groups}}: <span class="{className}">{count}</span>',
    IPList: 'IP List:',
    Location: 'Location:',
    NoLabelData: 'No Label Data',
    OSWorkload: 'OS Workload:',
    ParentGroup: 'Parent Group',
    PolicyState: 'Policy State:',
    Role: 'Role:',
    RoleCount: '{count, plural, =1 {Role} other {Roles}}: <span class="{className}">{count}</span>',
    Services: 'Services :',
    VirtualServer: 'Virtual Server:',
    VirtualServerCount:
      '{count, plural, =1 {Virtual Servers} other {Virtual Servers}}: <span class="{className}">{count}</span>',
    VirtualService: 'Virtual Service:',
    VirtualServiceCount:
      '{count, plural, =1 {Virtual Service} other {Virtual Services}}: <span class="{className}">{count}</span>',
    WorkloadCount: '{count, plural, =1 {Workload} other {Workloads}}: <span class="{className}">{count}</span>',
  },
  TrafficEvents: {
    AllEvents: 'All Events',
    BlockedEvents: 'Blocked Events',
    Direction: 'Direction',
    FirstTime: 'First Time',
    FlowCount: 'Flow Count',
    LastTime: 'Last Time',
    List: 'Traffic Events',
    PotentiallyBlockedEvents: 'Potentially Blocked Events',
    Singular: 'Traffic Event',
    TrafficBlockedByEnforcement: 'Traffic that was blocked by policy enforcement.',
    TrafficBlockedWhenEnforced: 'Traffic that will be blocked when policy is enforced.',
    TrafficEventsCount: '{count, plural, =0 {no Traffic Events} =1 {# Traffic Event} other {# Traffic Events}}',
  },
  TrafficParameters: {
    AddTimeToTimestamp: 'Add Time to TS(Temp)',
    AlwaysRebuildWorkloads: 'Rebuild Role Expansion',
    AnyRuleCoverage: 'Any Rule coverage',
    AppGroups: '{count, plural, =0 {no App Groups} =1 {# App Group} other {# App Groups}}',
    CollapsedThreshold: 'Collapsed Role Threshold',
    ConfigTraffic: 'Configurable Traffic Parameters',
    Days: '{count, plural, =0 {no Days} =1 {# Day} other {# Days}}',
    DefaultConnectedLinks: 'Default ({count, plural, =0 {no Links} =1 {# Link} other {# Links}})',
    DefaultDnsIplistMapping: 'Default (Map All IP List flows with FQDNs as IP Lists)',
    DefaultLinksForRules: 'Default  ({count, plural, =0 {no Links} =1 {# Link} other {# Links}})',
    DefaultMaxAppGroupRuleCoverage: 'Autoload Rule Coverage < 200 App Groups',
    DefaultNumberOfAddresses: 'Default ({count, plural, =0 {no Addresses} =1 {# Address} other {# Addresses}})',
    DefaultNumberOfClusters: 'Default ({count, plural, =0 {no Groups} =1 {# Group} other {# Groups}})',
    DefaultNumberOfIPLists: 'Default ({count, plural, =0 {no IP Lists} =1 {# IP List} other {# IP Lists}})',
    DefaultNumberOfLocations: 'Default ({count, plural, =0 {no Locations} =1 {# Location} other {# Locations}})',
    DefaultNumberOfPorts: 'Default ({count, plural, =0 {no Ports} =1 {# Port} other {# Ports}})',
    DefaultNumberOfRoles: 'Default ({count, plural, =0 {no Roles} =1 {# Role} other {# Roles}})',
    DefaultNumberOfWorkloads: 'Default ({count, plural, =0 {no Workloads} =1 {# Workload} other {# Workloads}})',
    DefaultPercentOfRuleCover: 'Default ({proportion, number, percent} Rule coverage)',
    DefaultRebuildStale: 'Rebuild Stale Data for < 50 workloads',
    DefaultServicesPerRule: 'Default ({count, plural, =1 {# Service} other {# Services}})',
    DefaultShowConnectionState: 'Default (Do not show Connection State)',
    DefaultShowExplorerServices: 'Default (Show Explorer PolicyServices)',
    DefaultTime: 'Default (No time added)',
    DefaultTruncationLimit: 'Default ({count, plural, =0 {no Nodes} =1 {# Node} other {# Node}})',
    DnsIplistMapping: 'FQDN Mapping',
    DoNotAlwaysRebuild: 'Do not Always Rebuild',
    DoNotRebuildStale: 'Rebuild Only On Demand',
    DoNotShowExplorerServices: 'Do Not Show Explorer Policy Services',
    ExplorerSearchFormats: 'Explorer Search Formats',
    ExplorerServices: 'Explorer Services',
    FreshData: 'Fresh Data',
    IlluminationPlusSearchFormats: 'Search Formats',
    KiloNumber: '{count, number}K',
    LightGreenThreshold: 'Light Green Line Threshold',
    LocationLevel: 'Always Location Level',
    LocationThreshold: 'Location View Threshold',
    MapAllFqdnsToDomains: 'Map All IP List flows with FQDNs as FQDNs',
    MaxAddressesPerLink: 'Max Addresses Per IP Link',
    MaxAppGroupsForRuleCoverage: 'App Group Rule Coverage',
    MaxClustersForLinks: 'Max Groups For Links',
    MaxClustersPerLocation: 'Max Groups Per Location',
    MaxExplorerLinksForDeepRules: 'Max Explorer Links for Deep Rules',
    MaximumRolesExpandable: 'Maximum Roles Expandable',
    MaxLinksForConnectedAppGroupRuleCoverage: 'Draft Connected App Group Links',
    MaxLocations: 'Max Locations',
    MaxNodesBeforeTruncation: 'Max Nodes before truncation',
    MaxOverlappingIP: 'Max Overlapping IP Lists',
    MaxPortsPerLink: 'Max Ports Per Link',
    MaxServicesPerRule: 'Max Services Per Rule',
    MaxWorkloads: 'Max Workloads Displayed',
    MaxWorkloadsPerGroup: 'Max Workloads Expanded Per Group',
    MaxWorkloadsPerRole: 'Max Workloads Expanded Per Role',
    Months: '{count, plural, =0 {no Months} =1 {# Month} other {# Months}}',
    NoLimit: 'No Limit',
    NumClusters: '{count, plural, =0 {no Groups} =1 {# Group} other {# Groups}}',
    NumLocations: '{count, plural, =0 {no Locations} =1 {# Location} other {# Locations}}',
    RebuildAlways: 'Always Rebuild (Up to 100 Workloads)',
    RebuildStale: 'Rebuild Stale Data',
    ResetPositions: 'Reset Positions',
    ShowConnectionState: 'Show Connection State',
    ShowExplorerConnectionState: 'Explorer Connection State',
    StaleData: 'Stale Data',
    TrafficParameters: 'Traffic Parameters',
    ViewLevel: 'View Level Threshold',
    Weeks: '{count, plural, =0 {no Weeks} =1 {# Week} other {# Weeks}}',
    Years: '{count, plural, =0 {no Years} =1 {# Year} other {# Years}}',
  },
  TrustedProxy: {
    Add: 'Add Trusted Proxy IPs',
    ConfigurationInfo: 'Proxy Configuration - cannot add more than {max} Trusted Proxy IPs',
    Edit: 'Edit Trusted Proxy IPs',
    Errors: {
      Edit: 'Unable to Edit',
    },
    List: {
      Delete: 'Remove {count, plural, =1 {Trusted Proxy IP} other {# Trusted Proxy IPs}}',
      Empty: 'No Proxy IPs',
      RemoveConfirm: 'Are you sure you want to remove the selected Trusted Proxy IPs?',
      RemoveResultTitle: 'Result of {count, plural, =1 {Trusted Proxy IP} other {# Trusted Proxy IPs}} removal',
    },
    RegionsAvailable: '{region} ({groupedRegions} of {maxTrustedListRegions}) Available',
    TrustedProxy: 'Trusted Proxy',
    TrustedProxyIp: 'Trusted Proxy IPs',
  },
  User: {
    AccessError: 'Access not authorized',
    AccessForbidden: 'Access denied or Access not authorized',
    Empty: 'PCE user/domain not yet configured',
    EmptyOrgs: 'User <b>{fullName}</b> (<b>{authName}</b>) does not have any permissions in this Organization',
  },
  UserGroups: {
    Add: 'Add User Group',
    AddNew: 'Add a new User Group',
    Create: {
      Placeholder: {
        UserGroupName: 'Type a name for the new User Group',
        UserGroupSID: 'Type a SID for the new User Group',
      },
      SIDExample: 'Use the format S-<unique number> to type in an SID',
      Validation: {
        InvalidName: 'User Group must have a name defined',
        InvalidSIDFormat: 'User Group SID format is invalid',
        InvalidSIDLength: 'User Group SID is too long',
      },
    },
    Errors: {
      Create: 'Unable to Create User Group',
      Edit: 'Unable to Edit User Group',
    },
    Instructions: 'User Groups allow user-based access to specific entities',
    NoData: 'No User Groups to display',
    NoMatchData: 'No matching User Groups to display',
    Remove: 'Remove {count, plural, =1 {User Group} other {User Groups}}',
    RemoveConfirm:
      'Are you sure you want to remove the selected {count, plural, =1 {User Group} other {# User Groups}}?',
    RemoveModal: {
      Confirm:
        'Are you sure you want to remove the {count, plural, =1 {selected User Group} other {# selected User Groups}}?',
      TitleConfirmation: 'Remove {count, plural, =1 {User Group} other {# User Groups}}',
    },
    SID: 'SID',
  },
  Users: {
    Accessibility: 'Accessibility',
    AccessRoles: 'Access Roles',
    AccessScopesRoles: 'Access Scopes and Roles',
    Add: 'Add User',
    AddExternalGroup: 'Add External Group',
    AddExternalUser: 'Add External User',
    AddGlobalRole: 'Add Global Role',
    AddLocalUser: 'Add Local User',
    AddRole: 'Add Role',
    AddScopedRole: 'Add Scoped Role',
    AlreadyExists: 'User already exists',
    APIKeys: {
      AuthenticationUsername: 'Authentication Username',
      CanManageCredentials: 'You can manage and recreate these credentials at any time.',
      Create: 'Create API Key',
      Created: 'API Key Created',
      CredentialsDownload: 'Download Credentials',
      Delete: 'Remove {count, plural, =1 {API Key} other {API Keys}}',
      DeleteConfirm:
        'Are you sure you want to remove the {count, plural, =1 {selected API Key} other {# selected API Keys}}? If the API key is deleted, the API authentication cannot be used.',
      DeletedSuccessfully:
        'The following {count, plural, =1 {API Key has} other {# API Keys have}} been successfully deleted',
      Edit: 'Edit API Key',
      Explorer: 'API Explorer',
      HereCredentialsAvailableToDownload: 'This is the only time these credentials will be available to download.',
      KeyID: 'Key ID',
      MyAPIKeys: 'My API Keys',
      Name: 'API Keys',
      NoKeys: 'No API Keys',
      OrgId: 'Org ID',
      RemoveResultTitle: 'Result of {count, plural, =1 {API Key} other {# API Keys}} removal',
      UserID: 'User ID',
    },
    CannotEditExternalGroup: 'Cannot edit external group',
    CannotEditUser: 'Cannot Edit User',
    CannotRemoveUser: 'Cannot Remove User',
    CantLockExternalUser: 'This external user can not be locked because they have not logged into the PCE yet.',
    ColorDeficient: {
      Message: 'Optimize the color palette for Deuteranopia, Protanopia, and Tritanopia vision',
      Title: 'Color vision deficiency',
    },
    ColorMode: 'Color Mode',
    ColorNormal: {
      Message: 'Optimize the color palette for normal vision',
      Title: 'Normal vision',
    },
    Convert: 'Convert User',
    ConvertExternalUserMessage:
      '{username} is currently an external user. Do you want to re-invite this user as a local user?',
    ConvertLocalUserWarning: 'The user will be converted to an external user and keep their granted access to scopes',
    ConvertLocalUserWarningWithoutScope: 'The user will be converted to an external user',
    ConvertSuccessExternalUser:
      'User <span class="{className}">{username}</span> was successfully converted to an external user.',
    DefaultPolicyVersion: 'Default Policy Version',
    EditExternalGroup: 'Edit External Group',
    EditExternalUser: 'Edit External User',
    EditGroup: 'Edit Group',
    EditRole: 'Edit Role',
    EditUser: 'Edit User',
    EmailOrUsername: 'Email or Username',
    EmailOrUsernameRequired: 'Email or Username is required',
    EmailRequired: 'Email is required',
    ExternalGroup: 'External Group',
    ExternalGroupAddFormHint:
      "Must match the group's memberOf attribute set in your IdP\nExamples: Sales or CN=Sales,OU=West,DC=MyDomain,DC=com",
    ExternalGroupRequired: 'External Group is required',
    ExternalGroups: 'External Groups',
    ExternalUsers: 'External Users',
    FilterByEmailUsername: 'Filter by Email or Username',
    GlobalAdminSublabel: 'Manage all resources and Security Settings. Cannot manage users and roles.',
    GlobalOwnerSublabel: 'Manage all resources, users and Security Settings.',
    GlobalPolicyObjectProvisionerSublabel:
      'Provision Services, IP Lists, Label Groups, and Security Settings. Read-only access to all other resources.',
    Invite: {
      AlreadyInOrg: 'This user already exists in the Organization',
      GroupAlreadyInOrg: 'This group already exists in the Organization',
      Link: 'Invitation Link',
      LinkDirectlyToUser: 'This link can be sent directly to the user',
      Reinvite: 'Re-Invite',
      Reinvited: 'User <span class="{className}">{username}</span> was successfully re-invited.',
      ReinviteUser: 'Re-Invite User',
      ReinviteWarning: 'A new invitation will be sent to the user',
      UserAlreadyInOrg:
        "You've invited a user that is already part this organization. A new invitation will be sent to this user.",
      UserInDifferentOrg:
        "You've invited a user that is part of a different organization within Illumio. This is currently not supported. Try again with a different email address.",
    },
    LimitedRuleWritingSublabel:
      'Manage Rulesets that match the scope and Rules where the Provider and Consumer match the scope.',
    LocalUsers: 'Local Users',
    LockExternalUser: 'Lock External User',
    LockLocalUser: 'Lock Local User',
    LockToggleSuccess: 'User <u>{username}</u> was successfully <s>{lock, select, true {Locked} false {Unlocked}}</s>.',
    LockUserWarning: 'Locking a user prevents them from logging in but does not change their granted access',
    LoginFailedCountExceeded: 'User login failure count exceeded',
    Logout: 'Logout',
    ManagingOrganization: 'Managing Organization',
    ManagingOrganizationId: 'Managing Organization ID',
    MyOrganization: 'My Organization',
    MyOrganizationId: 'My Organization ID',
    MyProfile: 'My Profile',
    MyRoles: 'My Roles',
    NameRequired: 'Name is required',
    NoAllLabels: 'The Scope is too broad. Scopes that have no Labels are not supported.',
    PassClickToChange: 'Click to change your user account password',
    Personal: 'Personal',
    ReAddUserMessage: 'User {username} was removed. Do you want to re-invite this user as a local user?',
    ReAddUserTitle: 'Re-add removed user',
    ReadOnlySublabel: 'Global read-only access to all resources',
    ReadOnlyUser: 'Read Only User',
    ReadOnlyUserDescOff: 'All users without role membership cannot log in',
    ReadOnlyUserDescOn: 'All users without role membership have read-only access',
    Remove: 'Remove User',
    RemoveExternalGroupPermissions: 'Remove External Group Permissions',
    RemoveExternalGroupsWarning:
      "{count, number} External Group's permissions will be removed and revoked from this organization",
    RemoveExternalUser: 'Remove External User',
    RemoveExternalUserPermissions: 'Remove External User Permissions',
    RemoveExternalUsersWarning:
      "{count, number} External User's permissions will be removed and revoked from this organization",
    RemoveLocalUserPermissions: 'Remove Local User Permissions',
    RemoveLocalUsersWarning:
      "{count, number} Local User's permissions will be removed and revoked from this organization",
    ResetPassword: 'Reset Password',
    RoleChangedSuccess:
      'The role for user <span class="{className}">{username}</span> was successfully changed to <span class="{className}">{rolename}</span>.',
    RulesetManagerSublabel: 'Manage Rulesets that match the scope and Rules where the Provider matches the scope.',
    RulesetProvisionerSublabel: 'Provision Rulesets that match the scope.',
    RulesetViewerSublabel:
      'Read-only access to Rules that match the scope. Does not permit editing of Rulesets and Rules.',
    SessionsExceeded: 'Maximum users reached',
    SessionTimeOut: 'Your session has timed out. Log in to continue.',
    SSOConfig: {
      ActiveDirectory: 'Active Directory Federation Services',
      AssertionConsumerUrl: 'Assertion Consumer URL',
      AuthenticationMethod: 'Authentication Method',
      AzureAD: 'Azure AD',
      DownloadCertificate: 'Download Certificate',
      ForceReauthentication: 'Force Re-authentication',
      GenerateCertificate: 'Generate Certificate',
      IdentityProvidersDesc:
        'You can use one of the following identity providers for authenticating users with the PCE',
      InfoForIdentityProvider: 'Information for Identity Provider',
      InfoFromIdentityProvider: 'Information from Identity Provider',
      IssuerUrl: 'Issuer URL',
      LogoutLandingURL: 'Logout Landing URL',
      LogoutUrl: 'Logout URL',
      Name: 'SSO Config',
      NameIDFormat: 'NameID Format',
      Okta: 'Okta',
      OneLogin: 'OneLogin',
      PasswordProtected: 'Password Protected Transport',
      PCESAMLCertificate: 'PCE SAML Certificate',
      PCESAMLCertificateExpiresAt: 'PCE SAML Certificate Expires At',
      PingIdentity: 'Ping Identity',
      RemoteLoginURL: 'Remote Login URL',
      Rotate: 'Rotate',
      RotateCertificate: 'Rotate Certificate',
      RotateCertificateMessage:
        'When you rotate PCE SAML Certificate, it needs to be updated in your Identity Provider. Please log in to your Identity Provider and update your certificate',
      SAMLIdentityProviderCertificate: 'SAML Identity Provider Certificate',
      SAMLVersion: 'SAML Version',
      SignSAMLRequest: 'Sign SAML Request',
      SSOConfig: 'Single Sign-On Configuration',
      SSOMethod: 'SSO method',
      Unspecified: 'Unspecified',
    },
    UnlockExternalUser: 'Unlock External User',
    UnlockLocalUser: 'Unlock Local User',
    User: 'User',
    UserAdded: 'User <span class="{className}">{username}</span> was successfully added.',
    UserRemovedFromOrg: 'User <span class="{className}">{username}</span> was successfully removed from organization.',
    UsersAndGroups: 'Users and Groups',
  },
  VEN: {
    ActivationType: 'Activation Type',
    Architecture: 'Architecture',
    CaCertificate: 'CA Signed Certificate',
    Condition: 'Health Condition',
    ContainerCluster: 'Container Cluster',
    CVEN: 'Containerized VEN (C-VEN)',
    DisconnectedBefore: 'Disconnected Before',
    Distribution: 'Distribution',
    EditError: 'Unable to Edit VEN',
    EndpointOnly: 'Endpoint VEN only',
    EnforcementNodeType: 'Enforcement Node Type',
    EVEN: 'Endpoint VEN',
    Filename: 'VEN Filename',
    Filter: {
      InvalidVersionRange: 'Invalid version range selected',
      Max: 'Max',
      Min: 'Min',
      SelectAVersion: 'Select a Version',
    },
    Health: 'Health',
    Host: 'Host',
    IndividualEndpoints: 'Individual endpoints in your environment',
    Kerberos: 'Kerberos',
    LastHeartbeat: 'Last Heartbeat',
    Library: 'VEN Library',
    NoData: 'No VENs to display',
    NoMachingData: 'No matching VENs to display',
    OSVersion: 'OS Version',
    PairingScript: 'Pairing Script',
    PairWithPairingProfile: 'Add with Pairing Profile',
    RecoveryModeDescription:
      'Authentication Recovery Mode is typically used when restoring VENs from backup and poses an elevate security risk by allowing the VEN to provide old credentials.',
    RecoveryModeDuration: 'Recovery Mode Duration',
    RecoveryModeSecondaryPlaceholder: '1 Hour to 30 Days',
    RecoveryModeSecondaryText: 'Duration can be 1 Hour to 30 Days',
    RecoveryModeSecondDescription: 'Are you sure you want to Set Authentication Recovery Mode for the selected VEN(s)?',
    SelfCertificate: 'Self Signed Certificate',
    ServerEndpoint: 'Server + Endpoint VENs',
    ServerOnly: 'Server VEN only',
    SetRecoveryMode: 'Set Authentication Recovery Mode',
    SetRecoveryModeDayWarning: 'Recovery Mode duration needs to be in the range of  1 to 30 days.',
    SetRecoveryModeError: 'Unable to Set Authentication Recovery Mode',
    SetRecoveryModeHourWarning: 'Recovery Mode duration needs to be in the range of  1 to 720 hours.',
    SetRecoveryModeInteWarning: 'Recovery Mode duration should be an integer',
    SetRecoveryModeReqWarning:
      'Recovery Mode duration needs to be in the range of  1 hr to 30 Days. Enter a suitable duration',
    Supported: 'Supported OS Versions',
    Suspend: 'Suspend',
    Unpaired: 'Unpaired',
    Upgrade: {
      ConfirmUpgrade: 'Confirm Upgrade',
      Days: 'Day(s)',
      Hours: 'Hour(s)',
      Minutes: 'Minute(s)',
      Title: 'Upgrade VENs',
      UpgradeAll: 'Upgrade All',
      UpgradeFiltered: 'Upgrade Filtered',
      UpgradeInProgress: 'Upgrading',
      UpgradePending: 'Upgrade Pending',
      UpgradeSelected: 'Upgrade Selected',
      UpgradeStartedOnDate: 'VEN Upgrade of {count, plural, =1 {# VEN} other {# VENs}} started on {datetime}.',
      UpgradeWarningMessage:
        'VEN release {version} is supported only on endpoints and cannot be used to upgrade server VENs',
      VensMatchFilter: '{n} VENs match the selected filter criteria',
      VensSelected: '{n} VENs are selected',
      VensThatQualify: 'VENs that qualify for an upgrade to {version} release',
      VensWillBeUpgraded: '{n, plural, =0 {No VENs} =1 {# VEN} other {# VENs}} will be upgraded.',
      VENTypeError: "VENs are not supported by the selected VEN version's VEN type",
      VENUpgradeExpiration: 'VEN Upgrade Expiration',
      VENUpgradeExpirationWarning: 'Value must be between 15 minutes and 180 days.',
      VENUpgradeExpireAt: 'Expires at {expire}',
      VENUpgradeExpireDescription:
        'Once the expiration time passes, the PCE stops trying to upgrade the VEN. The expiration time must be between 15 minutes and 180 days. Illumio recommends 1 day for server VENs and 7 days for endpoint VENs.',
      VENUpgradeTo: 'Pending upgrade to {version}',
      ZeroVenReleaseBundles: 'No VEN release bundles',
    },
    VEN: 'Server VEN',
    VENLibraryWarningMessage:
      'VEN release {version} is supported only on endpoints and should not be installed on servers',
    VenMaintenanceTokenExpire: 'Token Expiration',
    VenMaintenanceTokenModalDescription: 'Maintenance Token for {name} has been generated at {time}',
    VenMaintenanceTokenModalExpire: 'The Token expires on {time}',
    VENOperation: 'VEN Operations',
  },
  Version: {
    Detail: {
      NoRulesInScope: 'None of the provisioned objects in this Policy Version are within your assigned scopes',
      TallyLabel: {
        ChangedEnforcementBoundaries: '{count, plural, =1 {Changed Deny Rule} other {Changed Deny Rules}}',
        ChangedIPLists: '{count, plural, =1 {Changed IP List} other {Changed IP Lists}}',
        ChangedLabelGroups: '{count, plural, =1 {Changed Label Group} other {Changed Label Groups}}',
        ChangedRulesets: '{count, plural, =1 {Changed Ruleset} other {Changed Rulesets}}',
        ChangedSecureConnect:
          '{count, plural, =1 {Changed Secure Connect Gateway} other {Changed Secure Connect Gateways}}',
        ChangedServices: '{count, plural, =1 {Changed Service} other {Changed Services}}',
        ChangedSettings: '{count, plural, =1 {Changed Setting} other {Changed Settings}}',
        ChangedVirtualServers: '{count, plural, =1 {Changed Virtual Server} other {Changed Virtual Servers}}',
        ChangedVirtualServices: '{count, plural, =1 {Changed Virtual Service} other {Changed Virtual Services}}',
      },
      Version: 'Version #{version}',
      VersionVsPrevious: 'Version #{currentVersion} vs. Version #{previousVersion}',
      WorkloadsAffected: '{count, plural, =1 {Workload Affected} other {Workloads Affected}}',
    },
    ProvisionedBy: 'Provisioned By',
    ProvisionedOn: 'Provisioned On',
    Restore: {
      ExistingDraftAction: 'Revert draft policies before restoring a prior version.',
      ExistingDraftMessage: 'Existing objects in the draft policy queue.',
      PastVersionsToBeRevertedTitle: 'Past versions that will be reverted by restoring version {version}',
      SaveAsDraft: 'Save as Draft',
      UnableToSaveToDraft: 'Unable to save to draft',
    },
    WorkloadsAffected: 'Workloads Affected',
    WorkloadsAffectedCount: '{count, plural, =1 {# Workload Affected} other {# Workloads Affected}}',
  },
  VersionMismatch: {
    Header: 'Incompatible PCE UI Version',
    However:
      'However, the PCE UI version must match the base version of the PCE software; for example, {uiVersion} must match PCE {pceVersion}',
    Incompatible: 'The PCE UI Version is incompatible with the PCE software',
    PCE: 'PCE Version',
    SeeGuide: 'See the PCE Operations Guide',
    UI: 'UI Version',
    Upgrade:
      'You can upgrade the PCE UI that appears in the PCE web console independently of the nodes in the PCE cluster.',
    UpgradeInfo: 'Upgrade the PCE UI or PCE software so that the versions are compatible',
  },
  VirtualServers: {
    All: 'All Virtual Servers',
    ByCount: '{count, plural, =1 {Virtual Server} other {Virtual Servers}}',
    ConfirmManageServer:
      'Are you sure you want to Manage {count, plural, =1 {this Virtual Server} other {# Virtual Servers}}?',
    ConfirmUnmanageServer:
      'Are you sure you want to stop managing {count, plural, =1 {this Virtual Server with this Load Balancer} other {# Virtual Servers with following Load Balancers}}?',
    Detail: {
      EditMembers: 'Edit Members',
      EnforcedDesc: 'All traffic flows to Virtual Server not allowed by Rules are blocked',
      LabelDefinedMembers: 'Label-defined Members',
      ManagementState: 'Management State',
      ManageVirtualServer: 'Manage Virtual {count, plural, =1 {Server} other {# Servers}}',
      NoMembersDefined: 'No Members Defined',
      NotEnforcedDesc: 'All traffic flows to Virtual Server are allowed',
      RoutingMethod: 'Routing Method',
      ServerLoadBalancer: 'Server Load Balancer',
      ServerLoadBalancers: 'Server Load Balancers',
      UnassociatedDesc: 'Connectivity to Server Load Balancer has been lost',
      UnmanageVirtualServer: 'Unmanage Virtual {count, plural, =1 {Server} other {# Servers}}',
    },
    Errors: {
      Edit: 'Unable to Edit Virtual Server',
      Remove: 'Unable to Unmanage Virtual Server',
    },
    List: {
      BoundToRules:
        'The following {count, plural, =1 {Virtual Server is} other {# Virtual Servers are}} bound to rules',
      SLB: 'SLB',
      UnmanagedSuccess:
        'The following {count, plural, =1 {Virtual Server has} other {# Virtual Servers have}} been unmanaged successfully',
    },
    ModeManagedEnforced: 'Managed: Enforced',
    ModeManagedNotEnforced: 'Managed: Not Enforced',
    NoData: 'No Virtual Servers to display',
    ProvidersEdit: {
      AllLabelsError:
        'At least one Label must be a resource other than "All." Selecting "All" for all four Label types is not supported.',
      SelectAppPlaceholder: 'Select an Application for the Virtual Server Members',
      SelectEnvPlaceholder: 'Select an Environment for the Virtual Server Members',
      SelectLocPlaceholder: 'Select a Location for the Virtual Server Members',
      SelectRolePlaceholder: 'Select a Role for the Virtual Server Members',
      SpecifyAllLabels: 'All four Labels must be specified',
    },
    SLBDevice: 'SLB Device',
    SummaryEdit: {
      PolicyEnforced: 'The virtual server policy is enforced',
      PolicyNotEnforced: 'The virtual server policy is not enforced',
    },
    Total: 'Total Virtual Servers',
    Unassociated: 'Unassociated',
    VIP: 'VIP',
    VIPPort: 'VIP Port Number',
    VIPProto: 'VIP Protocol',
    VirtualServerName: 'Type a name for the new Virtual Server',
    WorkloadsAllowedTraffic:
      'Workloads matching selected Labels are allowed to receive traffic as members of this Virtual Server. Note, Workloads columns showing these labels are hidden by default.',
  },
  VirtualServices: {
    Add: 'Add Virtual Service',
    AddFqdn: 'Add FQDN',
    AddFqdns: 'Add FQDNs',
    AddIPAddressesOrFqdn: 'Add IP Addresses or Wildcard FQDNs',
    AddIpv4: 'Add IPv4',
    AddIPv4Addresses: 'Add IPv4 Addresses',
    AddIpv6: 'Add IPv6',
    AddIPv6Addresses: 'Add IPv6 Addresses',
    Additional: 'Additional Virtual Services',
    AddNew: 'Add a new Virtual Service',
    AddressPool: 'Address Pool',
    AddWorkloads: {
      Description:
        'Select a Workload to bind to the Virtual Service. If the Service has a Port/Protocol, you can specify which ports to override for those Workloads when a connection is initiated by a Consumer.',
      InvalidPortError: 'Invalid port. Enter a valid port override or uncheck ‘Override ports’ checkbox.',
      InvalidPortRangeErrror:
        'Invalid port range. Enter a valid port range override or uncheck ‘Override ports’ checkbox.',
      PortsOverrideDesc: 'Cannot override entries that has all Ports/Protocols or no Port.',
      SubTitle: 'Bind Workloads',
      UniquenessError: 'Cannot save a Port Override for an existing Virtual Service’s binded Workload.',
    },
    BoundContainerWorkloads: 'Bound Container Workloads',
    BoundToRules:
      'The following {count, plural, =1 {Virtual Service is} other {# Virtual Services are}} bound to rules',
    BoundToWorkloads:
      'The following {count, plural, =1 {Virtual Service is} other {# Virtual Services are}} bound to workloads',
    BoundWorkload: 'Bound Workload',
    BoundWorkloads: 'Bound Workloads',
    ConnectionServicesOrPorts: 'Connection Service Or Ports',
    Containers: {
      Delete: 'Remove Virtual Service Container',
      DeleteConfirm: 'Are you sure you want to remove the selected Virtual Service Container?',
    },
    Delete: 'Remove Virtual {count, plural, =1 {Service} other {Services}}',
    DeleteConfirm:
      'Are you sure you want to remove the {count, plural, =1 {selected Virtual Service} other {# selected Virtual Services}}?',
    DeletedSuccessfully:
      'The following {count, plural, =1 {Virtual Service has} other {# Virtual Services have}} been successfully deleted',
    DeleteSingleVirtualService: 'Are you sure you want to remove the Virtual Service?',
    DomainNameDuplicateError: 'Duplicated domain names: {list}',
    DomainNameExistError: 'Domain name has been configured: {list}',
    DomainNameFormattingError: 'Domain name formatting error: {list}',
    DomainsAreRequired: 'Domains are required',
    EditAddIPAddressesOrFqdn: 'Edit/Add IP Addresses or Wildcard FQDNs',
    EditDomainNames: 'Edit FQDN',
    EditIPv4Addresses: 'Edit IP Address',
    EditIPv6Addresses: 'Edit IPv6 Address',
    Errors: {
      Create: 'Unable to Create Virtual Service',
      Edit: 'Unable to Edit Virtual Service',
    },
    HostOnly: 'Host Only (Default)',
    InternalBridgeNetwork: 'Internal Bridge Network',
    IpAddresses4FormattingError: 'IPv4 Addresses formatting error: {list}',
    IpAddresses6FormattingError: 'IPv6 Addresses formatting error: {list}',
    IPAddressesAndFqdns: 'IP Addresses and FQDNs',
    IpAddressesAreRequired: 'IP Addresses are required',
    IpAddressesDuplicateError: 'Duplicated IP Addresses: {list}',
    IpAddressesExistError: 'IP Address with current network has been configured: {list}',
    ManagedBy: {
      ContainerCluster: 'This Virtual Service is managed by a Container Cluster',
      ContainerClusterDeletedAndOnLeader:
        'This Virtual Service was managed by a Container Cluster that is now deleted. You may delete the Virtual Service.',
      ContainerClusterDeletedAndOnMember:
        'This Virtual Service was managed by a Container Cluster that is now deleted. You may delete the Virtual Service from the leader PCE.',
    },
    NetworkForIps: 'Network for IPs',
    NoData: 'No Virtual Services to display',
    NoMatchData: 'No matching Virtual Services to display',
    OptionalCommentAboutTheEntry: 'Optional Comment about the entry',
    PortOverride: 'Port Override',
    SeeAll: 'View All {count, plural, =1 {Workload} other {# Workloads}}',
    SelectNetworkHint: 'Select a Network',
    ServiceOrPorts: 'Service or Ports',
    ServiceOrPortsIsRequired: 'Service or Ports is required',
    ServicePorts: 'Service / Ports',
    ShowFewer: 'Show Fewer Workloads',
    SingleBoundToRules: 'The Virtual Service is bound to rules',
    SingleBoundToWorkloads: 'The Virtual Service is bound to workloads',
    Total: 'Total Virtual Services',
    TypeFqdnAddresses: 'Type or paste one or more FQDNs (wildcards allowed)',
    TypeIPAddresses: 'Type or paste one or more IP Addresses',
    TypeIPAddressOrFqdn: 'Type or paste IP Address or FQDN (wildcards allowed)',
    View: 'View Virtual Services',
    Workloads: {
      CannotBind: 'Unable to Bind Workloads',
      Delete: 'Remove Virtual Service Workload',
      DeleteConfirm: 'Are you sure you want to remove the selected Virtual Service Workload?',
      Label: {
        Bind: 'Bind',
      },
      RemoveRecommand: 'Cannot override "Unidentified" port - removing the port override is recommended.',
      WorkloadDeleted: 'Removing the non-existing Workload is recommended.',
    },
  },
  Vulnerability: {
    After: 'After',
    All: 'All Vulnerabilities',
    Before: 'Before',
    BlockedTraffic: 'Blocked Vulnerable Traffic',
    BoxInfo:
      '{state, select, open {Reduced} closed {Eliminated}} {type, select, intra {Intra-Scope} extra {Extra-Scope} ipList {Internet}} exposure from {count} "{severity, select, 0 {Info} 1 {Low} 2 {Medium} 3 {High} 4 {Critical}}" severity vulnerabilities',
    Calculating: 'Calculating...',
    CVEIds: 'CVE-IDs',
    Eliminated: 'Eliminated',
    EWExposure: 'E/W Exposure',
    Exposed: 'Exposed Vulnerability',
    ExposedVulnerabilities: 'Exposed Vulnerabilities',
    ExposureScore: 'Vulnerability Exposure Score',
    ExtraScopeExposure: 'Extra-Scope Exposure',
    ExtraScopeMitigation: 'Extra-Scope Vulnerability Mitigation',
    HighestVEScore: 'Highest V-E Score',
    HighestVulnerability: 'Highest Vulnerability',
    ImportTime: 'Import Time',
    InternetExposure: 'Northern Exposure',
    InternetMitigation: 'Northern Vulnerability Mitigation',
    IntraScopeExposure: 'Intra-Scope Exposure',
    IntraScopeMitigation: 'Intra-Scope Vulnerability Mitigation',
    MaxSeverity: 'Max Severity',
    MitigatedVulnerabilities:
      '{count, plural, =0 {Mitigated Vulnerability} =1 {1 Mitigated Vulnerability} other {# Mitigated Vulnerabilities}}',
    MitigateVulnerabilities: 'Mitigate Vulnerabilities',
    ProvidedTraffic: 'Provided Traffic (Reported)',
    Reduced: 'Reduced',
    Score: 'Vulnerability Score',
    TotalVEScore: 'Total V-E Score',
    Traffic: 'Vulnerability Traffic',
    VEScore: 'V-E Score',
    Vulnerabilities: '{count, plural, =0 {No Vulnerabilities} =1 {1 Vulnerability} other {# Vulnerabilities}}',
    VulnerabilityExposureScore: 'Vulnerability Exposure Score (V-E Score)',
    Workloads: 'Vulnerable Workloads',
  },
  WhatsNew: {
    IsReleased: '{product} {version} is released',
    NewAnnouncement: 'New Announcement!',
    RulesForNonDomainAdapters:
      'This PCE now supports policy enforcement on endpoints for non-corporate network traffic. <a>Ruleset</a> - Click to view new ruleset added to preserve compatibility with policies written on 22.2 and earlier PCEs.',
    SeeWhatsNew: "See What's New!",
    WhatsNewIn: "What's New in {product}",
  },
  Workloads: {
    ActiveSyncing: 'Active (Syncing)',
    AdaptiveWorkloads: 'Adaptive Workloads',
    AddUnmanaged: 'Add Unmanaged Workload',
    AKI: 'Authority Key Identifier',
    All: 'All Workloads',
    ApplyPolicy: 'Apply Policy',
    ByCount: '{count, plural, =1 {Workload} other {Workloads}}',
    Clear: 'Clear',
    ClearSuspension: 'Clear Suspension',
    ClearSuspensionForWorkload: 'Clear the VEN Suspension Error for this Workload:',
    Compatible: 'Compatible',
    Configuration: 'Workload Configuration',
    ConfirmVENSuspension: 'Confirm VEN Suspension',
    ConfirmVENSuspensionClear: 'Confirm VEN Suspension Clear',
    Create: {
      Placeholder: {
        MachineAuthenticationId: 'Type a Machine Authentication ID',
        PKICertificateId: 'Type a PKI Certificate ID to authenticate VEN using an X.509 certificate',
        SPN: 'Type a SPN to authenticate VEN using Kerberos',
        WorkloadHostName: 'Type a Hostname',
        WorkloadLocation: 'Type a Location',
        WorkloadName: 'Type a name for the new Unmanaged Workload',
        WorkloadOSFamily: 'Type an OS Family',
        WorkloadPublicIP: 'Type a Public IP. Can be a Load Balancer or Router connected to the host',
        WorkloadRelease: 'Type an OS Release',
      },
    },
    Created: 'Workload created',
    DefaultGateway: 'Default Gateway',
    Deleted: 'Workload deleted',
    Edit: 'Edit Workloads',
    EditLabels: {
      MultipleLabels: 'Multiple {type} Labels',
      WorkloadsCannotModified: '{count, plural, =1 {# Workload} other {# Workloads}} cannot be modified: {message}',
      WorkloadsModified: '{count, plural, =1 {# Workload was} other {# Workloads were}} successfully modified',
    },
    EditLabelsWarning: {
      DisplayWarning: 'Display warning when editing labels',
      EditLabelsForEnforcementMode: 'Warning Setting',
      WarningDisabled:
        'Do not display a warning when editing labels of enforced workloads or when labels are in use by rulesets',
      WarningEnabled:
        'Display a warning when editing labels of enforced workloads or when labels are in use by rulesets',
    },
    Enforcement: 'Enforcement: {enforcement}',
    EnforcementBoundariesActiveVersion: 'This list shows only active Deny Rules',
    EnforcementBoundariesIdle:
      'Idle Workloads do not receive Deny Rules. Change the Enforcement Mode of the Workload to receive Deny Rules',
    EnforcementBoundariesInboundNoFound: 'No Inbound Deny Rules found',
    EnforcementBoundariesOutboundNoFound: 'No Outbound Deny Rules found',
    EnforcementBoundariesSelectedInbound:
      'Rules are enforced only for selected inbound services when workload is within scope of a Deny Rule',
    EnforcementBoundariesVersionLow:
      'The workload is not managed by a VEN that supports Deny Rules. Upgrade the VEN to version 21.2.0 or above for Deny Rules',
    EnforcementSettingFull: 'Workload enforcement is set to Full. Maximum protection is in place.',
    EnforcementSettingIdle:
      'Workload enforcement is set to Idle. Improve protection by setting enforcement to Selective or Full.',
    EnforcementSettingSelective:
      'Workload enforcement is set to Selective. Improve protection by setting enforcement to Full.',
    EnforcementSettingVisibilityOnly:
      'Workload enforcement is set to Visibility Only. Improve protection by setting enforcement to Selective or Full.',
    Errors: {
      Create: 'Unable to Create Workload',
      Edit: 'Unable to Edit Workload',
    },
    ErrorState:
      'VEN Suspension Mode places the Workload in the Error state and prevents the PCE from disabling communication with other Workloads',
    ErrorSuspensionTotal:
      '{error, plural, =1 {# Workload} other {# Workloads}} in Error, {suspended, plural, =1 {# Workload} other {# Workloads}} in Suspension.',
    ErrorTotal: '{count, plural, =1 {# Workload} other {# Workloads}} in Error',
    Full: 'Full',
    FullEnforcement: 'Full Enforcement',
    FullEnforcementInbound:
      'Rules are enforced for all inbound and outbound services. Traffic not allowed by a Rule is blocked',
    HeartbeatLastReceived: 'Heartbeat Last Received',
    Ignored: 'Ignored',
    InboundEnforcement: 'Inbound Enforcement',
    IncompatibilityTypes: {
      GroupPolicy: 'Group policy',
      Ip6TablesEnabled: 'ip6tables enabled',
      Ip6TablesRuleCount: 'ip6tables rule count',
      IPSec: 'IPSec',
      IpTablesEnabled: 'iptables enabled',
      IpTablesRuleCount: 'iptables rule count',
      IPv4Enabled: 'IPv4 enabled',
      IPv4Forwarding: 'IPv4 forwarding enabled',
      IPv4ForwardingPacketCount: 'IPv4 forwarding packet count',
      IPv4GlobalScope: 'IPv4 global scope',
      IPv6ActiveConnectionCount: 'IPv6 active connections count',
      IPv6Enabled: 'IPv6 enabled',
      IPv6ForwardingEnabled: 'IPv6 forwarding enabled',
      IPv6GlobalScope: 'IPv6 global scope',
      MtuSize: 'MTU size',
      MulticastIncomingPacketCount: 'Multicast incoming packet count',
      MulticastOutgoingPacketCount: 'Multicast outgoing packet count',
      RoutingTableConflict: 'Routing table conflict',
      TeredoTunnelingEnabled: 'Teredo tunneling enabled',
      UnwantedNics: 'Unsupported NICs',
      UnwantedRoles: 'Unsupported roles',
      UnwantedSoftware: 'Non-Illumio security software',
      UnwantedWFProviders: 'Non-Illumio WFP providers',
    },
    InterfacePlaceholder: 'E.g. eth0.public',
    Interfaces: 'Interfaces',
    InvalidStateToApplyPolicy:
      'Unable to update policy state for workloads in Idle. Move workload into a group to assign policy state.',
    IPCIDR: 'IP/CIDR',
    IPCIDRPlaceholder: 'E.g. 10.0.10.1/24',
    IssuerName: 'Issuer Name',
    LastAppliedPolicy: 'Last Applied Policy',
    LastHeartbeatReceived: 'Last Heartbeat Received',
    List: {
      AddWorkloadWithoutVEN: 'Add Workload without installing VEN',
      AskAgainCheck: "Don't ask me again",
      ConfirmAffectMultipleWorkloads:
        'This operation will affect multiple Workloads. Are you sure you want to continue?',
      EditMultipleLabels: 'Edit Labels for Multiple Workloads',
      PairInstallVEN: 'Pair Workload and install VEN',
      PairWithPairingProfile: 'Pair Workload with Pairing Profile',
    },
    MachineAuthenticationExample: 'Machine Authentication ID Example:',
    MachineAuthenticationId: 'Machine Authentication ID',
    MajorIncompatibilities: 'Major incompatibilities found',
    ManagedIgnored:
      'Ignored interfaces will NOT be included in policy configuration provided by the PCE. Traffic will continue to flow through the interface uninterrupted.',
    ManagedInterfaces: 'Managed interfaces will be included in policy configuration provided by PCE',
    NoChanges: 'No changes will be made to the VEN itself',
    NoDomainInterfaces: 'No Domain Interfaces',
    NoIncompatibilities: 'No incompatibilities',
    NoInterfaces: 'No Interfaces',
    NoMatchData: 'No matching Workloads to display',
    NotValidAfter: 'Not Valid After',
    NotValidBefore: 'Not Valid Before',
    OSRelease: 'OS Release',
    OutboundEnforcement: 'Outbound Enforcement',
    Pair: 'Pair Workloads',
    PCEAction: 'PCE Action',
    PolicyLastApplied: 'Policy Last Applied',
    PolicyLastReceived: 'Policy Last Received',
    PolicySync: 'Policy Sync',
    PossibleIncompatibilities: 'Possible incompatibilities found',
    ProcessesNotification: 'These are processes that are listening for incoming connections - Last updated at {time}',
    ProcessName: 'Process Name',
    ProtectedPorts: 'Protected {count, plural, =1 {Port} other {Ports}}',
    Protection: 'Protection',
    PublicIP: 'Public IP',
    RecalculatePolicy:
      'If the VEN remains in Suspension Mode, the PCE may mark this Workload as Offline and recalculate policy accordingly',
    RefreshThePageToSeeTheUpdatedPolicySyncStatus: 'Refresh the page to see the updated Policy Sync status.',
    Release: 'Release',
    Remove: {
      Confirm:
        'Are you sure you want to remove the {count, plural, =1 {selected Unmanaged Workload} other {# selected Unmanaged Workload}}? Policy will no longer be calculated for {count, plural, =1 {that Workload} other {these Workloads}}',
      Success: '{count, plural, =1 {Workload has} other {# Workloads have}} been successfully removed',
      TitleConfirmation: 'Remove {count, plural, =1 {Unmanaged Workload} other {# Unmanaged Workloads}}',
      TitleResult: 'Result of {count, plural, =1 {Unmanaged Workload} other {# Unmanaged Workloads}} removal',
    },
    RemoveDialog: {
      Content: {
        Actions: {
          Idle: 'Remove the VEN and make no other changes',
          KerberosPKI: 'Keep in PCE as unmanaged with labels after unpairing VEN',
          PairingKey: 'Remove entirely from PCE after unpairing and uninstalling VEN',
          Unmanaged: 'Remove entirely from PCE',
        },
        Options: {
          First: {
            Title: 'Remove Illumio policy (recommended)',
          },
        },
        SelectedCount: '{selected, plural, =1 {# workload} other {# workloads}} selected',
        SelectedUninstall:
          '{selected, plural, =1 {# VEN managed workload} other {# VEN managed workloads}} to be uninstalled.',
        SelectFirewallState: 'Select the uninstall firewall state:',
        Type: {
          Header: 'Workload Type',
        },
      },
      RemoveNow: 'Remove Now',
      Title: 'Remove and Unpair Workloads',
    },
    RemoveSuspensionOnPCE: 'This action only removes this Workload from VEN Suspension Mode on the PCE',
    RulesIdle:
      'Idle Workloads do not receive Segmentation Rules. Change the Enforcement Mode of the Workload to receive Segmentation Rules',
    SaveWarning: {
      ConfirmProceed: 'Do you still want to proceed?',
      LabelsRemovedAreInUseByRulesets:
        'One or more labels removed are in use by {count, plural, =1 {ruleset} other {rulesets}}: {rulesets}.',
      SelectiveOrFullEnforcementLabelRemovedMultiple:
        'One or more workloads are in selective or full enforcement mode. Changing the labels will change the policies enforced.',
      SelectiveOrFullEnforcementLabelRemovedSingle:
        'This workload is in {enforcementMode} enforcement mode. Changing the labels will change the policies enforced.',
    },
    Selective: 'Selective',
    SetEnforcement: 'Set enforcement',
    StagedPolicyAllStaticPolicyWorkloads: 'Staged Policy will be applied immediately on all static policy Workloads',
    StagedPolicyWillBeApplied:
      'Staged Policy will be applied immediately on {count, plural, =-1 {all Selected Workloads} =0 {0 Selected Workloads} =1 {1 Selected Workload} other {# Selected Workloads}}',
    StagedPolicyWillBeAppliedWithLabel:
      'Staged Policy will be applied immediately on all static policy Workloads with the following labels',
    StaticWorkloads: 'Static Workloads',
    Status: {
      ErrorAndSuspended: 'Error and Suspended',
      ErrorCount: '{count, plural, =1 {Error} other {Errors}}',
      NotInstalled: 'Not Installed',
      Offline: 'Offline',
      Suspended: 'Suspended',
      Syncing: 'Syncing',
      Uninstalling: 'Uninstalling',
      UnmanagedNotInstalled: 'Unmanaged (VEN not installed)',
      WarningAndSuspended: 'Warning and Suspended',
      WarningCount: '{count, plural, =1 {Warning} other {Warnings}}',
    },
    SubjectName: 'Subject Name',
    Summary: {
      ErrorMessage: 'Error Message',
      ForwardRules: 'Forward Rules',
      KerberosServicePrincipalName: 'Kerberos Service Principal Name',
      Machine: 'Machine',
      RemotePeers: 'Remote Peers',
      TenMinuteWait: 'Your bundle has been requested. This may take up to 10 minutes.',
      Uptime: 'Uptime',
      VENVersion: 'VEN Version',
    },
    Suspend: 'Mark as Suspended',
    SuspensionTotal: '{count, plural, =1 {# Workload} other {# Workloads}} in Suspension',
    ThisPCE: '{fqdn} (this PCE)',
    ThisProcessCanTakeAFewMinutesToTakeEffect: 'This process can take a few minutes to take effect.',
    Total: 'Total Workloads',
    UnableToCommunicate: 'The VEN will be unable to communicate with the PCE until the VEN comes out of Suspension',
    UnavailableCheckHost: 'Unavailable. Check {workloadPce}.',
    UnavailableViewableOn: 'Unavailable. Viewable on {workloadPce}.',
    UnmanagedCantUnpair: 'Cannot Unpair Unmanaged Workloads',
    UnmanagedCantUnpairDesc: 'Unmanaged Workloads can only be managed using the Illumio REST API',
    Unpair: {
      Action: 'Uninstalls the selected VEN(s).',
      Actions: {
        FirewallChoice: 'Policies are configured into the host firewall based on the option selected below.',
        Header: 'Remove or Unpair Actions',
        Idle: 'No changes to the host firewall.',
        KerberosPKI: 'The associated workloads will become unmanaged and keep labels ana IP addresses.',
        PairingKey: 'Removes policy for the associated workloads.',
      },
      IdleNotApply: '-(does not apply to Idle workloads)',
      Mode: {
        Type: 'Visibility Only/Selective/Full',
      },
      Options: {
        First: {
          AIXSolarisDesc: 'Removes Illumio policy and reverts firewall rules to the pre-pairing state.',
          LinuxDesc: 'Removes Illumio policy and retains the coexistent firewall rules.',
          Title: 'Remove Illumio policy - Default',
          WindowsDesc: 'Removes Illumio WFP filters and activates Windows Firewall.',
        },
        OperatingSystems: {
          AIXSolaris: 'AIX / Solaris',
          All: 'All operating systems',
          Linux: 'Linux',
          LinuxAIXSolaris: 'Linux / AIX / Solaris',
          LinuxMacOS: 'Linux / MacOS',
          Windows: 'Windows',
        },
        Second: {
          Desc: 'Leaves all ports open.',
          Title: 'Open all ports',
        },
        Third: {
          LinuxDesc: 'Temporarily allows only SSH/22 until system is rebooted.',
          Title: 'Close all ports except remote management',
          WindowsDesc: 'Allows only RDP/3389 and WinRM/5985, 5986.',
        },
      },
      SelectFirewallState: 'Select final firewall status',
      Title: 'Unpair {count, plural, =1 {VEN} other {# VENs}}',
      Type: {
        Header: 'Pairing Method',
        KerberosPKI: 'Paired using Kerberos or PKI',
        PairingKey: 'Paired using pairing key',
      },
    },

    UnprotectedPorts: 'Unprotected {count, plural, =1 {Port} other {Ports}}',
    UpdateAllWorkloads: 'Update All Static Workloads',
    UpdateSelectedWorkloads: 'Update Selected Workloads',
    VENConnectivity: 'VEN Connectivity',
    VENStillSuspended: 'This does not remove the VEN itself from Suspension Mode',
    VenSuspensionNotice: 'This Workload will be marked as in VEN Suspension Mode on the PCE:',
    VenToPCEAuthentication: {
      PKICertificateId: 'PKI Certificate ID',
      PKICertificateIdExamples: 'PKI Certificate ID Examples:',
      PKICertificateIdExampleWithDN: 'Distinguished Name (DN) in the subject field of the certificate:',
      PKICertificateIdExampleWithSAN: 'DNS name in the Subject Alternative Name (SAN) field of the certificate:',
      Title: 'VEN to PCE Authentication',
    },

    View: 'View Workloads',
    ViewEnforcementBoundaries: 'View Deny Rules',
    ViewEnforcementBoundary: 'View Deny Rule',
    VisibilityOnlyReports: 'No traffic is blocked by policy',
    WithCount: '{count} {count, plural, =1 {Workload} =0 {Workload} other {Workloads}}',
    WorkloadName: 'Workload Name',
    WorkloadsNumber: '{count, plural, =0 {no Workloads} =1 {One Workload} other {# Workloads}}',
    WorkloadToEnforcementNotification: 'Workload will be moved into <b>{enforcement}</b> and <b>{visibility}</b>',
  },
};
