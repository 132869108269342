/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import cx from 'classnames';
import {Navigation} from 'react-router';
import actionCreators from '../../actions/actionCreators';
import {ToolBar, ToolGroup} from '../../components/ToolBar';
import Constants from '../../constants';
import {TimeFilter} from '../../components/Explorer';
import {ExplorerUtils} from '../../utils/Explorer';
import {StoreMixin, UserMixin, PolicyGeneratorMixin} from '../../mixins';
import {GeneralStore, OrgStore, DNSStore, ProvisionStore, ExplorerStore, SessionStore} from '../../stores';
import {GridDataUtils, RestApiUtils, ServiceUtils, ProviderConsumerUtils} from '../../utils';
import {
  ConfirmationDialog,
  Grid,
  SpinnerOverlay,
  Pagination,
  Button,
  Banner,
  NotificationGroup,
  BlockedTrafficPicker,
  Label,
  Icon,
} from '../../components';

const MAX_RESULTS_PER_PAGE = 50;

const providerSortingItems = () => ({
  dst_role: {
    key: 'dst_role',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ProviderRoles')}</div>,
  },
  dst_app: {
    key: 'dst_app',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ProviderApplications')}</div>,
  },
  dst_env: {
    key: 'dst_env',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ProviderEnvironments')}</div>,
  },
  dst_loc: {
    key: 'dst_loc',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ProviderLocations')}</div>,
  },
});

const consumerSortingItems = () => ({
  src_role: {
    key: 'src_role',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ConsumerRoles')}</div>,
  },
  src_app: {
    key: 'src_app',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ConsumerApplications')}</div>,
  },
  src_env: {
    key: 'src_env',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ConsumerEnvironments')}</div>,
  },
  src_loc: {
    key: 'src_loc',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ConsumerLocations')}</div>,
  },
});

const providerWorkloadSortingItems = () => ({
  dst_workload: {
    key: 'dst_workload',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ProviderNames')}</div>,
  },
  dst_ip: {
    key: 'dst_ip',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ProviderIPAddresses')}</div>,
  },
});

const consumerWorkloadSortingItems = () => ({
  src_workload: {
    key: 'src_workload',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ConsumerNames')}</div>,
  },
  src_ip: {
    key: 'src_ip',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ConsumerIPAddresses')}</div>,
  },
});

const defaultQuery = () => ({
  destinations: {include: [], exclude: []},
  end_date: new Date().toISOString(),
  max_results: 1000,
  policy_decisions: ['potentially_blocked', 'blocked'],
  services: {include: [], exclude: []},
  sources: {include: [], exclude: []},
  start_date: intl.utils.subtractTime(new Date(), 'd', 1),
});

function getStateFromStores() {
  return {
    blockedTraffic: ExplorerStore.getBlockedTraffic(),
    count: ExplorerStore.getCount(),
    status: ExplorerStore.getStatus(),
    dnsAddresses: DNSStore.getAllIPAddresses(),
    enabled: SessionStore.isExplorerEnabled(),
    asyncEnabled: SessionStore.isAsyncExplorerEnabled(),
    asyncStatus: ExplorerStore.getNewQueryStatus(),
  };
}

export default Object.assign(
  React.createClass({
    mixins: [
      Navigation,
      UserMixin,
      PolicyGeneratorMixin,
      StoreMixin([DNSStore, ExplorerStore, OrgStore, ProvisionStore], getStateFromStores),
    ],

    getInitialState() {
      const sorting = GeneralStore.getSorting('blockedTrafficList');
      const filter = GeneralStore.getFilter('blockedTrafficList');
      const timeFilters = localStorage.getItem('blocked_traffic_time');
      const defaultTime = intl('Explorer.LastDays', {count: 1});

      return {
        sorting: sorting || [{key: 'lastDetected', direction: true}],
        filter: filter || 'all',
        currentPage: 1,
        query: {},
        time: !timeFilters || timeFilters === intl('Explorer.CustomRange') ? defaultTime : timeFilters,
        providerConsumerOrder: OrgStore.providerConsumerOrder(),
      };
    },

    componentDidMount() {
      if (!SessionStore.isFlowAnalyticsEnabled() || SessionStore.isUserWithReducedScope()) {
        return;
      }

      const {asyncEnabled, blockedTraffic} = this.state;

      this.getBlockedTraffic(defaultQuery(), asyncEnabled);
      this.getDnsValues(blockedTraffic);
    },

    componentDidUpdate(prevProps, prevState) {
      if (!_.isEqual(prevState.blockedTraffic, this.state.blockedTraffic)) {
        this.setState({currentPage: 1}, () => {
          this.getDnsValues(this.state.blockedTraffic);
        });
      }
    },

    componentWillUnmount() {
      if (this.queryPollingId) {
        clearTimeout(this.queryPollingId);
      }
    },

    async getBlockedTraffic(query, asyncEnabled) {
      if (asyncEnabled) {
        const response = await RestApiUtils.trafficFlows.async.create({
          ...query,
          query_name: intl('BlockedTraffic.Name'),
        });
        const queryHref = response?.body?.href;

        if (queryHref) {
          ExplorerUtils.pollQuery(queryHref, this.setQueryPollId, [
            {
              force: true,
              type: 'blockedTraffic',
            },
          ]);
        }
      } else {
        RestApiUtils.trafficFlows.get(query, true, 'blockedTraffic');
      }
    },

    /**
     * Used to save the polling timer id
     */
    setQueryPollId(id) {
      this.queryPollingId = id;
    },

    handleChangeFilter(items, labels) {
      if (labels || items) {
        const query = {...defaultQuery()};
        const workloadFilters = [];

        if (labels && !_.isEmpty(labels)) {
          Object.values(labels).forEach(label => {
            workloadFilters.push({label: {href: label}});
          });
        }

        if (items[intl('Workloads.WorkloadName')]) {
          Object.values(items[intl('Workloads.WorkloadName')]).forEach(workload => {
            workloadFilters.push({workload: {href: workload.href}});
          });
        }

        if (!_.isEmpty(workloadFilters)) {
          query.destinations = {include: [workloadFilters], exclude: []};
          query.sources = {include: [workloadFilters], exclude: []};
          query.sources_destinations_query_op = 'or';
        }

        if (
          items &&
          items[intl('BlockedTraffic.TrafficStatus')] &&
          items[intl('BlockedTraffic.TrafficStatus')].length
        ) {
          query.policy_decisions = [
            items[intl('BlockedTraffic.TrafficStatus')][0] === intl('Common.Blocked')
              ? 'blocked'
              : 'potentially_blocked',
          ];
        }

        this.setState({query});
      }
    },

    handleGo() {
      const {query, asyncEnabled} = this.state;

      this.setState({currentPage: 1});

      const workloadFilters = query.workload_filters && (query.workload_filters.hrefs || query.workload_filters.labels);

      if (workloadFilters || this.state.query.policy_decisions) {
        this.getBlockedTraffic(
          {
            ...query,
            start_date: ExplorerUtils.getStartDate(this.state.time).toISOString(),
            end_date: ExplorerUtils.getEndDate(this.state.time).toISOString(),
          },
          asyncEnabled,
        );
      } else {
        this.getBlockedTraffic(
          {
            ...defaultQuery(),
            start_date: ExplorerUtils.getStartDate(this.state.time).toISOString(),
            end_date: ExplorerUtils.getEndDate(this.state.time).toISOString(),
          },
          asyncEnabled,
        );
      }
    },

    handlePageChange(page) {
      this.setState({
        currentPage: page,
      });
    },

    handleRemove() {
      actionCreators.openDialog(
        <ConfirmationDialog
          title={intl('BlockedTraffic.List.DeleteServices')}
          message={intl('BlockedTraffic.List.ConfirmDeleteServices')}
          onConfirm={this.handleForceRemove}
        />,
      );
    },

    getDnsValues(links, page, sorting) {
      const visibleLinks = this.getVisibleLinks(links, page, sorting);
      const dnsAddresses = this.state.dnsAddresses;

      const addresses = visibleLinks.reduce((addresses, link) => {
        if (!link.src_workload && !link.src_ip_lists && !dnsAddresses[link.src_ip]) {
          addresses.push(link.src_ip);
        }

        if (!link.dst_workload && !link.dst_ip_lists && !dnsAddresses[link.dst_ip]) {
          addresses.push(link.dst_ip);
        }

        return addresses;
      }, []);

      if (addresses.length) {
        _.defer(() => {
          RestApiUtils.dns.dnsReverseLookup({ips: _.uniq(addresses)});
        });
      }
    },

    getVisibleLinks(links, page, sorting) {
      const sort = (sorting || this.state.sorting)[0];
      const offset = ((page || this.state.currentPage) - 1) * MAX_RESULTS_PER_PAGE;

      let visibleLinks = [];

      if (links) {
        // if link[sort.key] is '' or undefined, regard it as the largest order (\uFFFF)
        // isNaN true: 'ABC', undefined
        // isNaN false: '', ' ', 123, '123', '123.123'
        visibleLinks = _.sortBy(links, link =>
          isNaN(link[sort.key]) && link[sort.key] !== undefined
            ? link[sort.key].toLocaleLowerCase()
            : link[sort.key] || '\uFFFF',
        );

        if (sort.direction) {
          visibleLinks.reverse();
        }

        visibleLinks = visibleLinks.slice(offset, offset + MAX_RESULTS_PER_PAGE);
      }

      return visibleLinks;
    },

    handleSort(key, direction) {
      const sorting = [];

      if (key) {
        sorting.push({key, direction});
      }

      this.getDnsValues(this.state.blockedTraffic, this.state.currentPage, sorting);

      actionCreators.updateGeneralSorting('blockedTrafficList', sorting);
      this.setState({sorting});
    },

    handleGoToPolicyGenerator() {
      this.setAppGroup();
      this.transitionTo('policygenerator');
    },

    handleIpAddress(value, evt) {
      if (value.workloads || value.virtual_servers) {
        return;
      }

      evt.stopPropagation();
      this.transitionTo('workloadCreate', null, {address: value.ip_address});
    },

    rowClass(data) {
      if (data.update_type) {
        return `Grid-row--${data.update_type}`;
      }
    },

    formatPortProcess(row, direction, showIndicator, arrowDirection) {
      const blocked = row.policy.length < 8;
      const atSource = row.flow_direction === 'outbound';

      let portProtocol = `${ServiceUtils.getPort(row) || ''} ${row.protocol}`;
      let processName = row.processName;
      let windowsService = row.windowsService;

      if (direction === 'outbound') {
        portProtocol = '';
      }

      if (!processName || direction !== row.flow_direction) {
        processName = '';
      }

      if (!row.windowsService || direction !== row.flow_direction) {
        windowsService = '';
      }

      const service = (
        <div className="BlockedTrafficList-serviceName">{`${portProtocol} ${processName} ${windowsService}`}</div>
      );

      const arrowClass = cx('BlockedTrafficList-arrow', {
        'BlockedTrafficList-arrow-blocked': blocked,
        'BlockedTrafficList-arrow-warning': !blocked,
      });

      const empty = <Icon name="p-blocked" styleClass="BlockedTrafficList-empty" />;
      let indicator = <Icon name="p-blocked" styleClass="BlockedTrafficList-warning" />;

      if (blocked) {
        indicator = <Icon name="blocked" styleClass="BlockedTrafficList-blocked" />;
      }

      if (arrowDirection) {
        return (
          <div className="BlockedTrafficList-service">
            {arrowDirection === 'left'
              ? !atSource && showIndicator
                ? indicator
                : empty
              : atSource && showIndicator
              ? indicator
              : empty}
            <span className={arrowClass}>
              <Icon name={`arrow-${arrowDirection}-long`} size="xlarge" />
            </span>
            {arrowDirection === 'left'
              ? atSource && showIndicator
                ? indicator
                : empty
              : !atSource && showIndicator
              ? indicator
              : empty}
          </div>
        );
      }

      return <div className="BlockedTrafficList-service">{service}</div>;
    },

    handleTimeChange(time) {
      localStorage.setItem('blocked_traffic_time', time);

      this.setState({time});
    },

    render() {
      const {dnsAddresses, providerConsumerOrder, status, asyncStatus} = this.state;
      const loading = status.includes(Constants.STATUS_BUSY) || asyncStatus === Constants.STATUS_BUSY;

      if (!this.state.enabled) {
        return (
          <div className="ListPage BlockedTrafficList" data-tid="page-service-list">
            {loading ? <SpinnerOverlay /> : null}
            <Banner type="notice" header={intl('BlockedTraffic.List.DisabledNotice')} />
          </div>
        );
      }

      const providerLabel = intl('Explorer.ProviderLabels');
      const consumerLabel = intl('Explorer.ConsumerLabels');

      const sortingItems = {
        provider: providerSortingItems()[this.state.sorting[0].key] || {
          key: 'dst_loc',
          label: <div className="Explorer-Table-Header">{providerLabel}</div>,
        },
        consumer: consumerSortingItems()[this.state.sorting[0].key] || {
          key: 'src_loc',
          label: <div className="Explorer-Table-Header">{consumerLabel}</div>,
        },
      };

      const workloadSortingItems = {
        provider: providerWorkloadSortingItems()[this.state.sorting[0].key] || {
          key: 'dst_ip',
          label: (
            <div className="Explorer-Table-Header">
              {[intl('Common.Destination'), <br />, intl('Common.IPAddress')]}
            </div>
          ),
        },
        consumer: consumerWorkloadSortingItems()[this.state.sorting[0].key] || {
          key: 'src_ip',
          label: (
            <div className="Explorer-Table-Header">{[intl('Common.Source'), <br />, intl('Common.IPAddress')]}</div>
          ),
        },
      };

      const finalColumns = [
        {
          key: 'policy',
          label: intl('Common.TrafficType'),
          style: 'blockedTraffic-detail',
          sortable: true,
          format: value => {
            let type = null;
            const description = null;

            switch (value) {
              case intl('Common.PotentiallyBlocked'):
                type = <div className="Explorer-potentially-blocked">{intl('Common.PotentiallyBlocked')}</div>;
                break;
              case intl('Common.Blocked'):
                type = <div className="Explorer-blocked">{intl('Common.Blocked')}</div>;
                break;
              case intl('Common.Allowed'):
                type = <div className="Explorer-allowed">{intl('Common.Allowed')}</div>;
                break;
            }

            return (
              <div>
                {type}
                <div className="BlockedTrafficList-by">{description}</div>
              </div>
            );
          },
        },
        ...ProviderConsumerUtils.setProviderConsumerWithLabelsColumnOrder(
          {
            key: workloadSortingItems.provider.key,
            style: 'hostname-provider',
            label: intl('Common.Destination'),
            format: (value, row) => {
              let label = null;

              switch (row.dst_type) {
                case intl('Common.VirtualServices'):
                  label = (
                    <div className="Explorer-name Explorer-ip">
                      <Label icon="virtual-service" text={row.dst_virtual_service || dnsAddresses[row.src_ip]} />
                    </div>
                  );
                  break;
                case intl('Common.VirtualServers'):
                  label = (
                    <div className="Explorer-name Explorer-ip">
                      <Label icon="virtual-server" text={row.dst_virtual_server || dnsAddresses[row.src_ip]} />
                    </div>
                  );
                  break;
                case intl('Common.Workloads'):
                  label = (
                    <div className="Explorer-name Explorer-ip">
                      <Label icon="workload" text={row.dst_workload || dnsAddresses[row.src_ip]} />
                    </div>
                  );
                  break;
                default:
                  label = <div className="Explorer-name Explorer-ip">{dnsAddresses[row.dst_ip]}</div>;
              }

              return [
                label,
                row.dst_domain ? (
                  <div className="Explorer-domain" title={row.dst_domain}>
                    {row.dst_domain}
                  </div>
                ) : (
                  <div className="Explorer-ip">{row.dst_ip}</div>
                ),
                <div className="Explorer-ip">{row.dst_transmission}</div>,
                row.dst_ip_lists && row.dst_ip_lists.length
                  ? row.dst_ip_lists.map(ipList => (
                      <div key={ipList.name} className="Explorer-ip">
                        <Label icon="ip-list" text={ipList.name} />
                      </div>
                    ))
                  : null,
              ];
            },
            sortValue: value => (value || '\uFFFF').toLocaleLowerCase(),
            sortingItems: Object.values(providerWorkloadSortingItems()),
            sortable: true,
          },
          {
            key: sortingItems.provider.key,
            label: sortingItems.provider.label,
            style: 'table-labels-provider',
            format: (value, row) => [
              row.dst_role ? <Label text={row.dst_role} type="role" /> : null,
              row.dst_app ? <Label text={row.dst_app} type="app" /> : null,
              row.dst_env ? <Label text={row.dst_env} type="env" /> : null,
              row.dst_loc ? <Label text={row.dst_loc} type="loc" /> : null,
            ],
            sortingItems: Object.values(providerSortingItems()),
            sortValue: (value, row) => row[sortingItems.provider.key].toLocaleLowerCase() || '\uFFFF',
            sortable: true,
          },
          {
            key: 'port',
            label: intl('Rulesets.Rules.ProvidingService'),
            style: 'service',
            format: (value, row) => this.formatPortProcess(row, 'inbound', true),
            sortable: true,
          },
          {
            key: 'consumer_to_provider_arrow',
            style: 'consumerToProviderArrow',
            format: (value, row, arrowDirection) => this.formatPortProcess(row, 'inbound', true, arrowDirection),
          },
          {
            key: workloadSortingItems.consumer.key,
            style: 'hostname',
            label: intl('Common.Source'),
            format: (value, row) => {
              let label = null;

              switch (row.src_type) {
                case intl('Common.VirtualServices'):
                  label = (
                    <div className="Explorer-name Explorer-ip">
                      <Label icon="virtual-service" text={row.src_virtual_service || dnsAddresses[row.src_ip]} />
                    </div>
                  );
                  break;
                case intl('Common.VirtualServers'):
                  label = (
                    <div className="Explorer-name Explorer-ip">
                      <Label icon="virtual-server" text={row.src_virtual_server || dnsAddresses[row.src_ip]} />
                    </div>
                  );
                  break;
                case intl('Common.Workloads'):
                  label = (
                    <div className="Explorer-name Explorer-ip">
                      <Label icon="workload" text={row.src_workload || dnsAddresses[row.src_ip]} />
                    </div>
                  );
                  break;
                default:
                  label = <div className="Explorer-name Explorer-ip">{dnsAddresses[row.src_ip]}</div>;
              }

              return [
                label,
                <div className="Explorer-ip">{row.src_ip}</div>,
                row.src_ip_lists && row.src_ip_lists.length
                  ? row.src_ip_lists.map(ipList => (
                      <div key={ipList.name} className="Explorer-ip">
                        <Label icon="ip-list" text={ipList.name} />
                      </div>
                    ))
                  : null,
              ];
            },
            sortValue: value => (value || '\uFFFF').toLocaleLowerCase(),
            sortingItems: Object.values(consumerWorkloadSortingItems()),
            sortable: true,
          },
          {
            key: sortingItems.consumer.key,
            label: sortingItems.consumer.label,
            style: 'table-labels',
            format: (value, row) => [
              row.src_role ? <Label text={row.src_role} type="role" /> : null,
              row.src_app ? <Label text={row.src_app} type="app" /> : null,
              row.src_env ? <Label text={row.src_env} type="env" /> : null,
              row.src_loc ? <Label text={row.src_loc} type="loc" /> : null,
            ],
            sortingItems: Object.values(consumerSortingItems()),
            sortValue: (value, row) => row[sortingItems.consumer.key].toLocaleLowerCase() || '\uFFFF',
            sortable: true,
          },
          {
            key: 'processName',
            label: intl('Rulesets.Rules.ConsumingService'),
            style: 'consumer-service',
            format: (value, row) => this.formatPortProcess(row, 'outbound', true),
            sortable: true,
          },
          providerConsumerOrder,
        ),

        {
          key: 'numFlows',
          style: 'blockedTraffic-totalFlows',
          label: intl('Common.TotalFlows'),
          sortable: true,
        },
        {
          key: 'lastDetected',
          format: value => [intl.date(value, 'L'), ' ', intl.date(value, 'HH_mm_ss')],
          style: 'date-wrap',
          label: <div className="Explorer-Table-Header">{intl('BlockedTraffic.List.LastDetected')}</div>,
          sortable: true,
        },
      ];

      let blockedTraffic = this.state.blockedTraffic;

      switch (this.state.filter) {
        case 'blocked':
          blockedTraffic = _.filter(blockedTraffic, item => item.flow_status.indexOf('potentially') !== 0);
          break;
        case 'allowed':
          blockedTraffic = _.filter(blockedTraffic, item => item.flow_status.indexOf('potentially') === 0);
          break;
      }

      const notyBoldClass = 'BlockedTrafficList-description-bold';
      const notyMessage1 = intl(
        'BlockedTraffic.List.Description',
        {
          status: intl('Common.PotentiallyBlocked'),
          state: intl('Common.VisibilityOnly'),
          className: notyBoldClass,
        },
        {html: true},
      );
      const notyMessage2 = intl(
        'BlockedTraffic.List.Description',
        {
          status: intl('Common.Blocked'),
          state: intl('Workloads.FullEnforcement'),
          className: notyBoldClass,
        },
        {html: true},
      );
      const notyMessage3 = intl(
        'BlockedTraffic.List.DescriptionSelective',
        {
          state: intl('EnforcementBoundaries.SelectiveEnforcement'),
          status1: intl('Common.Blocked'),
          status2: intl('Common.PotentiallyBlocked'),
          className: notyBoldClass,
        },
        {html: true},
      );
      const notifications = [
        {
          type: 'instruction',
          message: (
            <span>
              {notyMessage1} {notyMessage2} {notyMessage3}
            </span>
          ),
        },
      ];

      if (this.isSuperclusterUser()) {
        notifications.push({
          type: 'warning',
          message: <span>{intl('Common.SuperClusterBlockedTrafficDataWarning')}</span>,
        });
      }

      return (
        <div className="ListPage BlockedTrafficList" data-tid="page-service-list">
          {loading ? <SpinnerOverlay /> : null}
          <NotificationGroup
            notifications={GridDataUtils.getNotifications({
              page: this.state.currentPage,
              totalRows: blockedTraffic.length,
              count: this.state.count,
              isFiltered: this.state.filter !== 'all',
            })}
          />
          <NotificationGroup notifications={notifications} />
          <ToolBar>
            <ToolGroup>
              {this.isUserReadOnly(false) ? null : (
                <Button
                  tid="policygenerator"
                  type="strong"
                  text={intl('PolicyGenerator.StartPolicyGenerator')}
                  onClick={this.handleGoToPolicyGenerator}
                />
              )}
            </ToolGroup>
            <ToolGroup tid="pagination">
              {blockedTraffic.length ? (
                <Pagination
                  page={this.state.currentPage}
                  totalRows={blockedTraffic.length}
                  count={this.state.count}
                  isFiltered={this.state.filter !== 'all'}
                  pageLength={MAX_RESULTS_PER_PAGE}
                  onPageChange={this.handlePageChange}
                />
              ) : null}
              <Button icon="refresh" content="icon-only" type="secondary" onClick={this.handleGo} tid="refresh" />
            </ToolGroup>
          </ToolBar>
          <div className="BlockedTrafficPicker">
            <BlockedTrafficPicker onChange={this.handleChangeFilter} />
            <div className="ExplorerFilter-TimeBar" key="time">
              <TimeFilter onChange={this.handleTimeChange} time={this.state.time} noCustom={true} />
            </div>
            <Button text={intl('Common.Go')} onClick={this.handleGo} type="primary" tid="go" />
          </div>
          <Grid
            columns={finalColumns}
            data={this.state.blockedTraffic}
            sorting={this.state.sorting}
            sortable={true}
            lastSelected={this.state.lastSelected}
            allowShiftSelect={true}
            idField="href"
            onSort={this.handleSort}
            rowClass={this.rowClass}
            resultsPerPage={MAX_RESULTS_PER_PAGE}
            currentPage={this.state.currentPage}
          />
          <ToolBar>
            {blockedTraffic.length > MAX_RESULTS_PER_PAGE ? (
              <ToolGroup tid="pagination">
                <Pagination
                  page={this.state.currentPage}
                  totalRows={blockedTraffic.length}
                  count={this.state.count}
                  isFiltered={this.state.filter !== 'all'}
                  pageLength={MAX_RESULTS_PER_PAGE}
                  onPageChange={this.handlePageChange}
                />
              </ToolGroup>
            ) : null}
          </ToolBar>
        </div>
      );
    },
  }),
  {
    viewName: () => intl('BlockedTraffic.Name'),
    isAvailable: () => SessionStore.isExplorerEnabled(),
  },
);
