/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import cx from 'classnames';
import fileSaver from 'file-saver';
import {Navigation} from 'react-router';
import {
  DNSStore,
  ExplorerFilterStore,
  ExplorerStore,
  GeneralStore,
  GraphStore,
  IpListStore,
  OrgStore,
  SessionStore,
  UserStore,
} from '../../stores';
import StoreMixin from '../../mixins/StoreMixin';
import {ExplorerUtils, ExportUtils} from '../../utils/Explorer';
import RestApiUtils from '../../utils/RestApiUtils';
import ServiceUtils from '../../utils/ServiceUtils';
import actionCreators from '../../actions/actionCreators';
import {ToolBar, ToolGroup} from '../ToolBar';
import {EditLabelsDialog, ModeAlert} from '../../modals';
import {GraphDataUtils, GridDataUtils, ProviderConsumerUtils} from '../../utils';
import {Select} from '../FormComponents';
import ViewRulePanel from '../CommandPanel/AddRule/ViewRulePanel';
import ExplorerActions from '../../actions/ExplorerActions';
import {
  Button,
  ButtonDropdown,
  Checkbox,
  ConfirmationDialog,
  Dialog,
  ExpandableGridDataList,
  Grid,
  HoverMenu,
  Icon,
  Label,
  Pagination,
  Spinner,
  Tooltip,
} from '..';

const MAX_RESULTS_PER_PAGE = 50;
const MAX_EDIT_LABELS = 100;

let MAX_DRAFT_FLOWS = localStorage.getItem('max_links_for_rules') || 10_000;

const providerMenuItems = () => [
  {enTranslation: intl('Explorer.IncludeProviders'), type: 'providerInclude', tid: 'includeProviders'},
  {enTranslation: intl('Explorer.ExcludeProviders'), type: 'providerExclude', tid: 'excludeProviders'},
  {enTranslation: intl('Explorer.IncludeConsumers'), type: 'consumerInclude', tid: 'includeConsumers'},
  {enTranslation: intl('Explorer.ExcludeConsumers'), type: 'consumerExclude', tid: 'excludeConsumers'},
];

const consumerOrProviderMenuItems = () => [
  {
    enTranslation: intl('Explorer.IncludeConsumersOrProviders'),
    type: 'consumerOrProviderInclude',
    tid: 'includeConsumersOrProviders',
  },
  {
    enTranslation: intl('Explorer.ExcludeConsumersOrProviders'),
    type: 'consumerOrProviderExclude',
    tid: 'excludeConsumersOrProviders',
  },
];

const portProtocolMenuItems = () => [
  {enTranslation: intl('Explorer.IncludeServices'), type: 'portsInclude', tid: 'includeServices'},
  {enTranslation: intl('Explorer.ExcludeServices'), type: 'portsExclude', tid: 'excludeServices'},
];

const transmissionAndFQDNMenuItems = () => [
  {enTranslation: intl('Explorer.IncludeProviders'), type: 'providerInclude', tid: 'includeProviders'},
  {enTranslation: intl('Explorer.ExcludeProviders'), type: 'providerExclude', tid: 'excludeProviders'},
];

const transmissionOrMenuItem = () => [
  {
    enTranslation: intl('Explorer.ExcludeConsumersOrProviders'),
    type: 'consumerOrProviderExclude',
    tid: 'excludeConsumersOrProviders',
  },
];

const coreProviderSortingItems = () => ({
  dst_role: {
    key: 'dst_role',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ProviderRoles')}</div>,
  },
  dst_app: {
    key: 'dst_app',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ProviderApplications')}</div>,
  },
  dst_env: {
    key: 'dst_env',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ProviderEnvironments')}</div>,
  },
  dst_loc: {
    key: 'dst_loc',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ProviderLocations')}</div>,
  },
});

const coreConsumerSortingItems = () => ({
  src_role: {
    key: 'src_role',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ConsumerRoles')}</div>,
  },
  src_app: {
    key: 'src_app',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ConsumerApplications')}</div>,
  },
  src_env: {
    key: 'src_env',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ConsumerEnvironments')}</div>,
  },
  src_loc: {
    key: 'src_loc',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ConsumerLocations')}</div>,
  },
});

const providerWorkloadSortingItems = () => ({
  dst_workload: {
    key: 'dst_workload',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ProviderNames')}</div>,
  },
  dst_ip: {
    key: 'dst_ip',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ProviderIPAddresses')}</div>,
  },
  dst_domain: {
    key: 'dst_domain',
    label: <div className="Explorer-Table-Header">{intl('Common.ProviderFqdn')}</div>,
  },
});

const consumerWorkloadSortingItems = () => ({
  src_workload: {
    key: 'src_workload',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ConsumerNames')}</div>,
  },
  src_ip: {
    key: 'src_ip',
    label: <div className="Explorer-Table-Header">{intl('Explorer.ConsumerIPAddresses')}</div>,
  },
});

const policySortingItems = () => ({
  policy: {
    key: 'policy',
    label: <div className="Explorer-Table-Header">{intl('Common.ReportedPolicy')}</div>,
  },
  rules: {
    key: 'rules',
    label: <div className="Explorer-Table-Header">{intl('Common.DraftPolicy')}</div>,
  },
});

const draftOptionMap = () => ({
  labels: intl('Explorer.QuickDraft'),
  workloads: intl('Explorer.DeepDraft'),
});

const draftOptionReverseMap = () => ({
  [intl('Explorer.QuickDraft')]: 'labels',
  [intl('Explorer.DeepDraft')]: 'workloads',
});

export const modeMap = {
  idle: intl('Common.Idle'),
  selective: intl('EnforcementBoundaries.SelectiveEnforcement'),
  visibility_only: intl('Common.VisibilityOnly'),
  full: intl('Workloads.FullEnforcement'),
};

const draftOptions = () => [
  {
    key: 'labels',
    value: intl('Explorer.QuickDraft'),
    label: (
      <div data-tid="labels" className="ButtonDropdown-item">
        <div data-tid="button-dropdown-title" className="ButtonDropdown-title Explorer-format-bold">
          {intl('Explorer.QuickDraft')}
        </div>
        <div className="ButtonDropdown-subtitle">{intl('Explorer.CalculateBasedOnLabelsDescription')}</div>
      </div>
    ),
  },
  {
    key: 'workloads',
    value: intl('Explorer.DeepDraft'),
    label: (
      <div data-tid="labels" className="ButtonDropdown-item">
        <div data-tid="button-dropdown-title" className="ButtonDropdown-title Explorer-format-bold">
          {intl('Explorer.DeepDraft')}
        </div>
        <div className="ButtonDropdown-subtitle">{intl('Explorer.CalculateWorkloadBasedRulesDescription')}</div>
      </div>
    ),
  },
];

const actionMap = () => ({
  reported: intl('Map.ReportedView'),
  draft: intl('Common.DraftAll'),
});

function getStateFromStores() {
  const orValue =
    (JSON.parse(localStorage.getItem('tx_filters')) && JSON.parse(localStorage.getItem('tx_filters')).Or) ||
    (_.cloneDeep(ExplorerStore.getFilters()) && _.cloneDeep(ExplorerStore.getFilters()).Or) ||
    false;

  return {
    dnsAddresses: DNSStore.getAllIPAddresses(),
    addresses: this.getUnresolvedDnsAddresses(this.props.links, DNSStore.getAllIPAddresses()),
    andOrValue: orValue ? 'or' : 'and',
    providerConsumerOrder: OrgStore.providerConsumerOrder(),
    asyncEnabled: SessionStore.isAsyncExplorerEnabled(),
  };
}

export default React.createClass({
  mixins: [Navigation, StoreMixin([DNSStore], getStateFromStores)],

  getDefaultProps() {
    return {
      exportable: true,
      draft: true,
      fqdn: true,
      onEdit: null,
    };
  },

  getInitialState() {
    const sorting = GeneralStore.getSorting('linkTable') || JSON.parse(localStorage.getItem('linkTable'));
    let selection = GeneralStore.getSelection('linkTable');

    selection = selection && selection.id === this.props.href ? selection.selection : [];

    const providerWorkloads = this.getWorkloads(selection, 'provider');
    const consumerWorkloads = this.getWorkloads(selection, 'consumer');
    const disableServices = localStorage.getItem('disable_explorer_services');

    let policy =
      JSON.parse(sessionStorage.getItem('overwrite_explorer_policy')) ||
      sessionStorage.getItem(`explorer_policy_${this.props.type}`) ||
      actionMap()[UserStore.getDefaultPolicyVersion()] ||
      intl('Map.ReportedView');

    if (policy === intl('Map.DraftView')) {
      policy = intl('Common.DraftAll');
    }

    if (this.props.blockedDraft) {
      policy = this.props.blockedPolicy || intl('Map.DraftView');
    }

    if (sorting) {
      if (policy === intl('Map.ReportedView') && sorting[0].key === 'rules') {
        sorting[0] = {...sorting[0], key: 'policy'};
      } else if (policy !== intl('Map.ReportedView') && sorting[0].key === 'policy') {
        sorting[0] = {...sorting[0], key: 'rules'};
      }
    }

    return {
      sorting: sorting || [{key: 'src_ip', direction: false}],
      currentPage: localStorage.getItem('explorer-page') || 1,
      dnsResolveInProcess: false,
      dnsResolved: false,
      selection: selection || [],
      providerWorkloads,
      consumerWorkloads,
      workloadEdit:
        !SessionStore.isSuperclusterMember() && SessionStore.isWorkloadEditEnabled() && !this.props.noWorkloadEdit,
      policy,
      expanded: new Set(),
      disableServices,
    };
  },

  componentDidMount() {
    ExplorerStore.addAndOrActionListener(this.handleAndOrSelection);
    ExplorerStore.ruleChangeActionListener(this.handleRuleChange);
    ExplorerFilterStore.addDownloadActionListener(this.handleRuleChange);

    if (!IpListStore.getAnyIpList()) {
      _.defer(() => {
        RestApiUtils.ipLists.getInstance('any');
      });
    }
  },

  componentWillMount() {
    MAX_DRAFT_FLOWS = localStorage.getItem('max_links_for_rules') || 10_000;

    if (this.props.links.length && this.props.links.some(link => !link.rules)) {
      this.getDraftCoverage(this.props);
    }
  },

  async componentWillReceiveProps(nextProps) {
    if (this.ruleUpdate || nextProps.aggregationLevel !== this.props.aggregationLevel) {
      this.getDraftCoverage(nextProps);
      this.ruleUpdate = false;
    }

    if (!_.isEqual(nextProps.links, this.props.links)) {
      await RestApiUtils.kvPairs.getInstance(SessionStore.getUserId(), 'hide_message');
      this.warnUserOnExceedResultLimit();

      this.clearSelections();

      const nextLength = (nextProps.links || []).length;
      const currentLength = (this.props.links || []).length;

      this.setState({
        ...((nextLength !== currentLength || nextLength < 50) && {currentPage: 1}),
        dnsResolved: false,
        links: [...nextProps.links],
        addresses: this.getUnresolvedDnsAddresses(nextProps.links),
      });

      if (this.state.dnsResolveInProcess) {
        _.defer(() => {
          ExplorerActions.interruptDnsLookup({value: true});
        });
      }
    }
  },

  componentWillUnmount() {
    ExplorerStore.removeAndOrActionListener(this.handleAndOrSelection);
    ExplorerStore.removeRuleChangeActionListener(this.handleRuleChange);
    ExplorerFilterStore.removeDownloadActionListener(this.handleRuleChange);

    _.defer(() => actionCreators.closeDialog());
  },

  handleRuleChange() {
    this.ruleUpdate = true;
  },

  handleAndOrSelection() {
    this.setState({andOrValue: ExplorerStore.getAndOrValue()});
  },

  areAllRulesLoaded(links) {
    return links.every(link => link.rules);
  },

  getAllRuleCoverage(links, aggregationLevel, onComplete = () => {}) {
    _.defer(async () => {
      this.setState({draftInProgress: true});
      actionCreators.setRuleCoverageType('visible');
      await GraphDataUtils.handleDraftCoverage(this.getVisibleLinks(links), aggregationLevel, this.props.href);

      actionCreators.setRuleCoverageType('all');
      await GraphDataUtils.handleDraftCoverage(links, aggregationLevel, this.props.href);
      onComplete();
      this.setState({draftInProgress: false});
    });
  },

  // This is called when getting new data from the store
  getDraftCoverage(nextProps) {
    const {policy, sorting} = this.state;
    const {aggregationLevel, href, links} = nextProps;
    const draftFiltered =
      policy === intl('Common.Blocked') || policy === intl('Common.Allowed') || policy === intl('Map.DraftView');
    const draftSort = sorting.length && sorting[0].key === 'rules';
    const overMax = links.length > MAX_DRAFT_FLOWS;

    if (policy === intl('Map.ReportedView')) {
      return;
    }

    //Get just one page
    if (policy === intl('Common.DraftAll') && !draftSort) {
      _.defer(async () => {
        this.setState({draftInProgress: true});
        actionCreators.setRuleCoverageType('visible');
        await GraphDataUtils.handleDraftCoverage(this.getVisibleLinks(links), aggregationLevel, href);
        this.setState({draftInProgress: false});
      });

      return;
    }

    // Get everything without a warning
    if (aggregationLevel === 'labels' || !overMax || (!draftFiltered && !draftSort)) {
      this.getAllRuleCoverage(links, aggregationLevel);

      return;
    }

    _.defer(() => {
      actionCreators.openDialog(
        <ConfirmationDialog
          message={intl('Explorer.TooManyFlowsDoYouWantToContinue')}
          onConfirm={() => this.getAllRuleCoverage(links, 'workloads')}
          onCancel={() => {
            // Switch back to All Draft if there are too many flows
            const policy = intl('Common.DraftAll');

            this.setState({policy});
            sessionStorage.removeItem('overwrite_explorer_policy');
            sessionStorage.setItem(`explorer_policy_${this.props.type}`, policy);

            if (this.state.sorting.length && this.state.sorting[0].key === 'rules') {
              // Change the sort silently
              const sorting = [{key: 'src_ip', direction: false}];

              actionCreators.updateGeneralSorting('linkTable', sorting);
              this.setState({sorting});
              localStorage.setItem('linkTable', JSON.stringify(sorting));
            }

            this.setState({policy});
            sessionStorage.removeItem('overwrite_explorer_policy');
            sessionStorage.setItem(`explorer_policy_${this.props.type}`, policy);

            _.defer(() => {
              actionCreators.setRuleCoverageType('visible');
              GraphDataUtils.handleDraftCoverage(this.getVisibleLinks(links), aggregationLevel, href);
            });
          }}
        />,
      );
    });
  },

  // This is called when changing configuration items
  updateRuleCoverage(policy, onConfirm = () => {}) {
    const {aggregationLevel, links} = this.props;
    const {sorting} = this.state;
    const draftFiltered =
      policy === intl('Common.Blocked') || policy === intl('Common.Allowed') || policy === intl('Map.DraftView');
    const overMax = links.length > MAX_DRAFT_FLOWS;
    const draftSort =
      policy === intl('Common.DraftAll') &&
      sorting.length &&
      (sorting[0].key === 'rules' || sorting[0].key === 'policy');

    // If we aren't already fetching the rules start now
    // This is the get everything condition
    if (!this.state.draftInProgress && (draftSort || draftFiltered)) {
      // These are the conditions for the warning
      if (overMax && aggregationLevel !== 'labels') {
        // Warn about the length of time
        actionCreators.openDialog(
          <ConfirmationDialog
            message={intl('Explorer.TooManyFlowsDoYouWantToContinue')}
            onConfirm={() => {
              this.getAllRuleCoverage(links, 'workloads');

              onConfirm();
            }}
          />,
        );

        return;
      }

      this.getAllRuleCoverage(links, aggregationLevel);
    } else if (policy === intl('Common.DraftAll')) {
      _.defer(async () => {
        this.setState({draftInProgress: true});
        actionCreators.setRuleCoverageType('visible');
        await GraphDataUtils.handleDraftCoverage(this.getVisibleLinks(links), aggregationLevel, this.props.href);
        this.setState({draftInProgress: false});
      });
    }

    onConfirm();
  },

  handleDownload() {
    const dateFormat = {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    const dateTime = intl.date(Date.now(), dateFormat);
    const fileName = `TrafficData ${dateTime}.csv`;
    const expandedTableData =
      this.props.aggregationLevel === 'workloads'
        ? this.props.links
        : this.props.links.reduce((result, link) => {
            result.push(...link.links);

            return result;
          }, []);

    const {sorting, currentPage} = this.state;
    const filteredLinks = this.getFilteredLinks(expandedTableData);
    const sortedLinks = this.getSortedLinks(filteredLinks, currentPage, sorting, 'workloads');

    const links = ExportUtils.getCSVData(
      sortedLinks,
      this.state.dnsAddresses,
      this.state.policy !== intl('Map.ReportedView'),
      this.props.aggregationLevel,
    );
    const blob = new Blob([links], {type: 'text/plain;charset=utf-8'});

    fileSaver.saveAs(blob, fileName);
  },

  handleExport() {
    const {aggregationLevel} = this.props;

    if (this.state.policy === intl('Map.ReportedView')) {
      this.handleDownload();

      return;
    }

    if (
      this.props.links.length > MAX_DRAFT_FLOWS &&
      aggregationLevel !== 'labels' &&
      !this.areAllRulesLoaded(this.props.links)
    ) {
      // Warn about the length of time
      actionCreators.openDialog(
        <ConfirmationDialog
          message={intl('Explorer.TooManyFlowsDoYouWantToContinue')}
          onConfirm={() => this.getAllRuleCoverage(this.props.links, aggregationLevel, this.handleDownload)}
        />,
      );
    } else {
      this.getAllRuleCoverage(this.props.links, aggregationLevel, this.handleDownload);
    }
  },

  handleCreateRules() {
    localStorage.setItem('explorer-page', this.state.currentPage);
    this.props.onCreateRules(this.state.selection);
  },

  handleRefreshRules() {
    actionCreators.updateRuleCoverageForAll();
  },

  // Handle the type of draft
  handleDraftChange(draftType) {
    const aggregationLevel = draftOptionReverseMap()[draftType];

    if (this.props.onAggregationChange && aggregationLevel !== !this.props.aggregationLevel) {
      this.props.onAggregationChange(aggregationLevel);
      this.clearSelections();
    }
  },

  // Handle the Policy Version Change
  handleActionChange(blockedDraft, policy) {
    if (!blockedDraft && policy === intl('Map.DraftView')) {
      return;
    }

    const {links, onPolicyChange} = this.props;

    if (this.areAllRulesLoaded(links)) {
      this.handleConfirmAction(policy);
    } else {
      this.updateRuleCoverage(policy, _.partial(this.handleConfirmAction, policy));
    }

    if (onPolicyChange) {
      onPolicyChange(policy);
    }

    if (policy !== this.state.policy) {
      this.clearSelections();
    }
  },

  handleConfirmAction(policy) {
    const {sorting} = this.state;

    if (policy === intl('Map.ReportedView') && sorting[0].key === 'rules') {
      sorting[0] = {...sorting[0], key: 'policy'};
    } else if (policy !== intl('Map.ReportedView') && sorting[0].key === 'policy') {
      sorting[0] = {...sorting[0], key: 'rules'};
    }

    this.setState({policy, sorting, currentPage: 1});
    sessionStorage.removeItem('overwrite_explorer_policy');
    sessionStorage.setItem(`explorer_policy_${this.props.type}`, policy);
  },

  async handleSort(key, direction) {
    const sorting = [];
    const {policy, draftInProgress} = this.state;
    const {aggregationLevel, links, href} = this.props;

    if (key) {
      const currentKey = this.state.sorting.length && this.state.sorting[0].key;
      const providerKeys = Object.keys(coreProviderSortingItems());
      const consumerKeys = Object.keys(coreConsumerSortingItems());

      // If the key is in the same column, but not the same key as previous start in the false direction
      if (
        key !== currentKey &&
        ((providerKeys.includes(key) && providerKeys.includes(currentKey)) ||
          (consumerKeys.includes(key) && consumerKeys.includes(currentKey)))
      ) {
        direction = false;
      }

      sorting.push({key, direction});
    }

    // In Draft View if sorting by the rules
    if (key === 'rules' && policy !== intl('Map.ReportedView')) {
      if (!draftInProgress) {
        if (
          aggregationLevel === 'workloads' &&
          this.props.links.length > MAX_DRAFT_FLOWS &&
          !this.areAllRulesLoaded(this.props.links)
        ) {
          // Warn about the length of time
          actionCreators.openDialog(
            <ConfirmationDialog
              message={intl('Explorer.TooManyFlowsDoYouWantToContinue')}
              onConfirm={() => {
                this.getAllRuleCoverage(this.props.links, 'workloads');
                actionCreators.updateGeneralSorting('linkTable', sorting);
                this.setState({sorting});
                localStorage.setItem('linkTable', JSON.stringify(sorting));
              }}
            />,
          );

          return;
        }

        this.getAllRuleCoverage(this.props.links, aggregationLevel);
      }
    } else if (this.state.policy !== intl('Map.ReportedView')) {
      actionCreators.setRuleCoverageType('visible');
      GraphDataUtils.handleDraftCoverage(this.getVisibleLinks(links, this.state.page, sorting), aggregationLevel, href);
    }

    if (!_.isEqual(sorting, this.state.sorting)) {
      this.clearSelections();
    }

    actionCreators.updateGeneralSorting('linkTable', sorting);
    this.setState({sorting});
    localStorage.setItem('linkTable', JSON.stringify(sorting));
  },

  handlePageChange(page) {
    const {aggregationLevel, links, href} = this.props;

    if (this.state.policy === intl('Common.DraftAll')) {
      actionCreators.setRuleCoverageType('visible');
      GraphDataUtils.handleDraftCoverage(this.getVisibleLinks(links, page), aggregationLevel, href);
    }

    this.clearSelections();

    this.setState({currentPage: page});
  },

  getUnresolvedDnsAddresses(links, dnsAddresses = this.state.dnsAddresses) {
    return (links || []).reduce((addresses, link) => {
      if (!(link.src_hostname || link.src_name) && !dnsAddresses.hasOwnProperty(link.src_ip)) {
        addresses.push(link.src_ip);
      }

      if (!(link.dst_hostname || link.dst_name) && !link.dst_domain && !dnsAddresses.hasOwnProperty(link.dst_ip)) {
        addresses.push(link.dst_ip);
      }

      return addresses;
    }, []);
  },

  async getDnsValues(links) {
    // we will look up dns names on demand for all links (instead of only visible links of current page)
    const addresses = this.getUnresolvedDnsAddresses(links);

    if (addresses.length) {
      for (const addressChunk of _.chunk([...new Set(addresses)], 25)) {
        try {
          await RestApiUtils.dns.dnsReverseLookup({ips: addressChunk});
        } catch {
          return;
        }
      }

      this.setState({dnsResolveInProcess: false});

      if (!ExplorerStore.getDnsLookupInterrupted()) {
        this.setState({dnsResolved: true});
      }

      _.defer(() => {
        ExplorerActions.interruptDnsLookup({value: false});
      });
    } else {
      this.setState({dnsResolveInProcess: false});

      if (!ExplorerStore.getDnsLookupInterrupted()) {
        this.setState({dnsResolved: true});
      }

      _.defer(() => {
        ExplorerActions.interruptDnsLookup({value: false});
      });
    }
  },

  getSortedLinks(links, page, sorting, aggregationLevel) {
    const sort = (sorting || this.state.sorting)[0];
    const reverseSort = ['numFlows', 'firstDetected', 'lastDetected', 'port'];

    let sortedLinks = [];

    if (links) {
      // if link[sort.key] is '' or undefined, regard it as the largest order (\uFFFF)
      // isNaN true: 'ABC', undefined
      // isNaN false: '', ' ', 123, '123', '123.123'
      sortedLinks = [...links].sort((rowA, rowB) => {
        let valueA;
        let valueB;

        switch (sort.key) {
          case 'dst_domain':
            valueA = (rowA[sort.key] || this.state.dnsAddresses[rowA.dst_ip] || '\uFFFF')
              .toLocaleLowerCase()
              .split('.')
              .reverse()
              .join('');
            valueB = (rowB[sort.key] || this.state.dnsAddresses[rowB.dst_ip] || '\uFFFF')
              .toLocaleLowerCase()
              .split('.')
              .reverse()
              .join('');
            break;
          case 'rules':
            valueA = rowA.rules ? rowA.rules.length : -1;
            valueB = rowB.rules ? rowB.rules.length : -1;
            break;
          case 'port':
            valueA = rowA.port;
            valueB = rowB.port;
            break;
          case 'state':
            valueA = rowA.state;
            valueB = rowB.state;
            break;
          case 'processName':
            valueA = (rowA.flow_direction === 'outbound' && (rowA.windowsService || rowA.processName)) || '';
            valueB = (rowB.flow_direction === 'outbound' && (rowB.windowsService || rowB.processName)) || '';
            break;
          case 'src_ip':
          case 'dst_ip':
            if (aggregationLevel === 'labels') {
              valueA = rowA[`${sort.key.split('_')[0]}_ips`].size;
              valueB = rowB[`${sort.key.split('_')[0]}_ips`].size;
            } else {
              valueA =
                isNaN(rowA[sort.key]) && rowA[sort.key]
                  ? rowA[sort.key].toLocaleLowerCase()
                  : rowA[sort.key] || '\uFFFF';
              valueB =
                isNaN(rowB[sort.key]) && rowB[sort.key]
                  ? rowB[sort.key].toLocaleLowerCase()
                  : rowB[sort.key] || '\uFFFF';
            }

            break;
          case 'policy':
            valueA = rowA.policy;
            valueB = rowB.policy;
            break;

          default:
            valueA =
              isNaN(rowA[sort.key]) && rowA[sort.key] ? rowA[sort.key].toLocaleLowerCase() : rowA[sort.key] || '\uFFFF';
            valueB =
              isNaN(rowB[sort.key]) && rowB[sort.key] ? rowB[sort.key].toLocaleLowerCase() : rowB[sort.key] || '\uFFFF';
        }

        if (valueA === valueB) {
          valueA = rowA.connectionKey;
          valueB = rowB.connectionKey;
        }

        return valueB > valueA ? 1 : valueB < valueA ? -1 : 0;
      });

      if (reverseSort.includes(sort.key) ? !sort.direction : sort.direction) {
        sortedLinks.reverse();
      }
    }

    return sortedLinks;
  },

  getVisibleLinks(links, page, sorting) {
    const offset = ((page || this.state.currentPage) - 1) * MAX_RESULTS_PER_PAGE;
    const sortedLinks = this.getSortedLinks(links, page, sorting, this.props.aggregationLevel);

    return sortedLinks.slice(offset, offset + MAX_RESULTS_PER_PAGE + 1);
  },

  getFilteredLinks(links) {
    const {policy} = this.state;

    if (policy === intl('Common.Allowed') || policy === intl('Common.Blocked') || policy === intl('Map.DraftView')) {
      return links.filter(link => {
        const draftPolicy = ExplorerUtils.getDraftPolicyDecision(link);

        // Enforcement Boundary case: show all blocked/potentially blocked traffic
        if (policy === intl('Map.DraftView')) {
          return draftPolicy === intl('Common.Blocked') || draftPolicy === intl('Common.PotentiallyBlocked');
        }

        return (
          draftPolicy === policy ||
          (policy === intl('Common.Blocked') && draftPolicy === intl('Common.PotentiallyBlocked'))
        );
      });
    }

    return links;
  },

  clearSelections() {
    _.defer(() => actionCreators.updateGeneralSelection('linkTable', {id: this.props.href, selection: []}));

    this.setState({selection: [], lastSelected: [], providerWorkloads: [], consumerWorkloads: []});
  },

  handleResolveDomains() {
    this.setState({dnsResolveInProcess: true});
    this.getDnsValues(this.props.links);

    if (!GraphStore.getHideMessage().hideResolveDomainsAlert) {
      actionCreators.openDialog(
        <ModeAlert
          message="resolveDomains"
          onConfirm={() => {
            this.ignoreChanges = true;
          }}
        />,
      );
    }
  },

  warnUserOnExceedResultLimit() {
    const warnUser =
      ExplorerStore.getExceededResultsLimit() &&
      !GraphStore.getHideMessage().hideWarnUserModal &&
      !ExplorerStore.getExceededWarningConfirm();

    if (warnUser) {
      actionCreators.exceededWarningConfirm(true);
      actionCreators.openDialog(<ModeAlert message="warnUserSearchLimit" isWarnModal={true} hideCheckbox={false} />);
    }
  },

  viewRule(rules, denyRules) {
    actionCreators.openDialog(
      <div className="ViewRuleDialog">
        <Dialog title={intl('Explorer.ViewPolicy')}>
          <ViewRulePanel
            noDelete={this.props.blockedDraft}
            modal
            data={{form: {rules: [..._.uniq(rules || []), ..._.uniq(denyRules || [])]}}}
          />
        </Dialog>
      </div>,
    );
  },

  getWorkloadLinkMap(links) {
    return links.reduce(
      (result, link) => {
        if (link.dst_href && link.dst_href.includes('workload') && !link.dst_href.includes('container')) {
          result.provider[link.index] = {href: link.dst_href, labels: link.dst_labels};
        }

        if (link.src_href && link.src_href.includes('workload') && !link.src_href.includes('container')) {
          result.consumer[link.index] = {href: link.src_href, labels: link.src_labels};
        }

        return result;
      },
      {provider: {}, consumer: {}},
    );
  },

  getExpandedLinkIndexMap() {
    const {aggregationLevel, links} = this.props;

    return links.reduce((result, link) => {
      result[link.index] =
        aggregationLevel === 'workloads' ? [link.index] : [...link.links].map(uniqueLink => uniqueLink.index);

      return result;
    }, {});
  },

  getExpandedLinks() {
    const {aggregationLevel, links} = this.props;

    return aggregationLevel === 'workloads'
      ? links
      : links.reduce((result, link) => {
          result.push(...link.links);

          return result;
        }, []);
  },

  getWorkloads(selection, type) {
    const expandedLinks = this.getExpandedLinks();
    const expandedLinkMap = this.getExpandedLinkIndexMap();
    const workloadLinkMap = this.getWorkloadLinkMap(expandedLinks);

    return Object.values(
      selection.reduce((result, selectionIndex) => {
        expandedLinkMap[selectionIndex].forEach(index => {
          const workload = workloadLinkMap[type][index];

          if (workload && workload.href) {
            result[workload.href] = workload;
          }
        });

        return result;
      }, {}),
    );
  },

  handleRowSelect(selection, lastSelected = selection) {
    const newSelection = GridDataUtils.selectToggle(this.state.selection, selection);
    const providerWorkloads = this.getWorkloads(newSelection, 'provider');
    const consumerWorkloads = this.getWorkloads(newSelection, 'consumer');

    actionCreators.updateGeneralSelection('linkTable', {id: this.props.href, selection: newSelection});
    this.setState({selection: newSelection, lastSelected, providerWorkloads, consumerWorkloads});
  },

  handleEditLabels(selected) {
    const workloads = this.getWorkloads(this.state.selection, selected.value);

    if (!workloads.length || workloads.length > MAX_EDIT_LABELS) {
      return;
    }

    const dontAskAgain = evt => {
      this.dontAskAgain = evt.target.checked;
    };

    const confirmMessage = (
      <div>
        {intl('Workloads.List.ConfirmAffectMultipleWorkloads')}
        <div className="Dialog-confirmation-message-checkbox">
          <Checkbox label={intl('Workloads.List.AskAgainCheck')} onChange={dontAskAgain} />
        </div>
      </div>
    );

    if (workloads.length === 1 || JSON.parse(localStorage.getItem('editMultiLabelDontAskAgain')) === true) {
      this.openEditLabels(workloads);
    } else {
      actionCreators.openDialog(
        <ConfirmationDialog
          className="Dialog-editmultiple-workloads"
          title={intl('Workloads.List.EditMultipleLabels')}
          message={confirmMessage}
          onConfirm={() => {
            if (this.dontAskAgain) {
              localStorage.editMultiLabelDontAskAgain = this.dontAskAgain;
            }

            _.defer(this.openEditLabels, workloads);
          }}
        />,
      );
    }
  },

  handleExpandCollapse(href) {
    const expanded = this.state.expanded;

    if (expanded.has(href)) {
      expanded.delete(href);
    } else {
      expanded.add(href);
    }

    this.setState({expanded});
  },

  getItem(row, endpoint) {
    const {disableHover} = this.props;
    const {dnsAddresses} = this.state;

    const itemType = row[[endpoint, 'type'].join('_')];
    const ip = row[[endpoint, 'ip'].join('_')];
    const workload = row[[endpoint, 'workload'].join('_')];
    const managed = row[[endpoint, 'managed'].join('_')];
    const virtualService = row[[endpoint, 'virtual_service'].join('_')];
    const virtualServer = row[[endpoint, 'virtual_server'].join('_')];
    const href = row[[endpoint, 'href'].join('_')];
    const ips = row[[endpoint, 'ips'].join('_')];
    const deletedWorkloadIps = row.deletedWorkloadIps;

    let label = null;

    switch (itemType) {
      case intl('Common.VirtualServices'):
        label = (
          <div className="Explorer-name Explorer-ip">
            <Label icon="virtual-service" text={virtualService || dnsAddresses[ip]} />
          </div>
        );
        break;
      case intl('Common.VirtualServers'):
        label = (
          <div className="Explorer-name Explorer-ip">
            <Label icon="virtual-server" text={virtualServer || dnsAddresses[ip]} />
          </div>
        );
        break;
      case intl('Common.Workloads'):
        let labelKey;
        let labelIconName;

        if (href.search(/container_workloads/) > 0) {
          labelKey = 'containerWorkloads';
          labelIconName = 'workload';
        } else if (href.search(/kubernetes_workloads/) > 0) {
          labelKey = 'kubernetesWorkloads';
          labelIconName = 'workload';
        } else {
          labelKey = 'workloads';
          labelIconName = managed ? 'workload' : 'unmanaged';
        }

        label =
          workload === intl('Common.DeletedWorkload') ? (
            <div className="Explorer-name Explorer-ip">
              <Label
                icon="workload"
                text={
                  deletedWorkloadIps && deletedWorkloadIps.size
                    ? intl('Common.DeletedWorkloadIps', {count: deletedWorkloadIps.size})
                    : workload
                }
              />
            </div>
          ) : (
            <HoverMenu
              menuItems={this.state.andOrValue === 'and' ? providerMenuItems() : consumerOrProviderMenuItems()}
              disabled={disableHover}
              labelActionData={{
                key: labelKey,
                hostname: workload || dnsAddresses[ip],
                name: null,
                href,
              }}
              labelProps={{
                iconName: labelIconName,
                text: workload || dnsAddresses[ip],
              }}
              type="workload"
            />
          );
        break;
      case 'aggregated':
        const count = ips.size;

        label = endpoint === 'src' ? intl('Explorer.ConsumerCount', {count}) : intl('Explorer.ProviderCount', {count});
        break;
      default:
        label = null;
    }

    return label;
  },

  handleExpandableItem(value, row, type) {
    const {disableHover} = this.props;
    const {dnsAddresses} = this.state;

    const expanded = this.state.expanded.has([row.index, type].join(','));
    const endpoint = type === 'consumerItem' ? 'src' : 'dst';

    const itemType = row[[endpoint, 'type'].join('_')];
    const ip = row[[endpoint, 'ip'].join('_')];
    const domain = row[[endpoint, 'domain'].join('_')];
    const transmission = row[[endpoint, 'transmission'].join('_')];
    const ipLists = row[[endpoint, 'ip_lists'].join('_')];
    const label = this.getItem(row, endpoint);
    const mode = row[[endpoint, 'mode'].join('_')];
    const modes = row[[endpoint, 'modes'].join('_')];
    const managed = row[[endpoint, 'managed'].join('_')];

    let address = null;
    let fqdn = null;
    let cast = null;
    let lists = null;
    let enMode = null;

    if (ip && itemType !== 'aggregated') {
      address = (
        <HoverMenu
          menuItems={this.state.andOrValue === 'and' ? providerMenuItems() : consumerOrProviderMenuItems()}
          noLabel
          labelActionData={{key: 'ipaddress', value: ip, href: ip}}
          labelProps={{text: ip}}
          type="ipaddress"
          disabled={disableHover}
        />
      );
    }

    if (domain || dnsAddresses[ip]) {
      fqdn = (
        <HoverMenu
          menuItems={this.state.andOrValue === 'and' ? transmissionAndFQDNMenuItems() : consumerOrProviderMenuItems()}
          noLabel
          labelActionData={{
            key: 'fqdn',
            value: domain || dnsAddresses[ip],
            href: domain || dnsAddresses[ip],
          }}
          labelProps={{text: domain || dnsAddresses[ip]}}
          type="fqdn"
          disabled={disableHover}
        />
      );
    }

    enMode = mode && itemType !== 'aggregated' && managed ? <div className="Explorer-Mode">{modeMap[mode]}</div> : null;

    if (transmission) {
      cast = (
        <HoverMenu
          menuItems={this.state.andOrValue === 'and' ? transmissionAndFQDNMenuItems() : transmissionOrMenuItem()}
          noLabel
          labelActionData={{
            key: 'transmission',
            value: transmission,
            href: transmission.toLowerCase(),
          }}
          labelProps={{text: transmission}}
          type="transmission"
          disabled={disableHover}
        />
      );
    }

    if (ipLists && ipLists.length) {
      const data = ipLists.map(ipList => (
        <HoverMenu
          menuItems={this.state.andOrValue === 'and' ? providerMenuItems() : consumerOrProviderMenuItems()}
          iconType="ipList"
          labelActionData={{key: 'iplist', name: ipList.name, href: ipList.href}}
          labelProps={{iconName: 'ipList', text: ipList.name}}
          type="iplist"
          disabled={disableHover}
        />
      ));

      lists = (
        <span className="hover-menu-ipmargin hover-menu-expandable">
          <ExpandableGridDataList
            data={data}
            expanded={expanded}
            href={[row.index, type].join(',')}
            maxDisplay={3}
            onExpandCollapse={this.handleExpandCollapse}
            expandMessage={intl('Explorer.MoreItems', {numLists: data.length - 3})}
            collapseMessage={intl('Common.ShowLess')}
          />
        </span>
      );
    }

    let aggregated;

    if (itemType === 'aggregated') {
      let aggregatedItems = ExplorerUtils.getAggregatedItems(row, endpoint);

      const uniqueMode = [...modes];
      const getMode = uniqueMode.length > 1 ? intl('EnforcementBoundaries.MixedEnforcement') : modeMap[uniqueMode];

      enMode = getMode ? <div>{getMode}</div> : null;
      aggregatedItems = Object.keys(aggregatedItems).map(item => this.getItem(aggregatedItems[item], endpoint) || item);

      aggregated = (
        <span className="hover-menu-ipmargin hover-menu-expandable test">
          <ExpandableGridDataList
            data={aggregatedItems}
            expanded={expanded}
            href={[row.index, type].join(',')}
            maxDisplay={3}
            onExpandCollapse={this.handleExpandCollapse}
            expandMessage={intl('Explorer.MoreItems', {numLists: aggregatedItems.length - 3})}
            collapseMessage={intl('Common.ShowLess')}
          />
        </span>
      );
    }

    return [label, address, fqdn, cast, enMode, lists, aggregated];
  },

  openEditLabels(workloads) {
    actionCreators.openDialog(<EditLabelsDialog workloads={workloads} onComplete={this.handleChangeComplete} />);
  },

  handleChangeComplete() {
    if (this.props.onEdit) {
      this.props.onEdit();
    }

    this.clearSelections();
  },

  formatPolicySelection(blockedDraft, selection) {
    switch (selection.key) {
      case 'draft':
        return intl('Map.DraftView');
      case 'draftBlocked':
        return blockedDraft ? intl('Map.DraftView') : intl('Map.DraftViewBlocked');
      case 'draftAllowed':
        return intl('Map.DraftViewAllowed');
      default:
        return selection.value;
    }
  },

  formatPolicyResult(blockedDraft, result) {
    const key = result?.key;
    const classes = cx({
      'Explorer-Select--disabled': key === 'draftSection',
      'Explorer-Subresult': !blockedDraft && (key === 'draft' || key === 'draftAllowed' || key === 'draftBlocked'),
    });

    return <div className={classes}>{result?.value}</div>;
  },

  formatArrow(row, arrowDirection) {
    const policyType = this.state.policy.includes(intl('Common.Reported')) ? 'reported' : 'draft';
    let policy;
    let boundaryIcon;

    if (policyType === 'draft') {
      boundaryIcon = ExplorerUtils.getDraftBoundaryInfo(row);
      policy = ExplorerUtils.getDraftPolicyDecision(row);
    } else {
      const policyDecision = ExplorerUtils.getReportedPolicyDecision(row);

      boundaryIcon = ExplorerUtils.getReportedBoundaryInfo(policyDecision);
      policy = policyDecision.policy;
    }

    let policyClass = '';

    switch (policy) {
      case intl('Common.Unknown'):
        policyClass = 'PolicyGeneratorGrid-arrow--unknown';
        break;
      case intl('Common.PotentiallyBlocked'):
        policyClass = 'PolicyGeneratorGrid-arrow--potentiallyBlocked';
        break;
      case intl('Common.Blocked'):
        policyClass = 'PolicyGeneratorGrid-arrow--blocked';
        break;
      case intl('Common.Allowed'):
        policyClass = 'PolicyGeneratorGrid-arrow--allowed';
        break;
    }

    const arrowClass = cx('PolicyGeneratorGrid-arrow-bar', policyClass);

    if (arrowDirection) {
      return (
        <span className={arrowClass}>
          {boundaryIcon ? (
            <Icon name={`${boundaryIcon}-${arrowDirection}`} size="xxlarge" />
          ) : (
            <Icon name={`arrow-${arrowDirection}-long`} size="xlarge" />
          )}
        </span>
      );
    }
  },

  formatDraftSelection(selection) {
    return selection.value;
  },

  getDraftPolicyDecisionDetail(row) {
    const boundary = row.flow_direction === 'outbound' && ExplorerUtils.getDraftBoundaryInfo(row);
    const policyDecision = ExplorerUtils.getDraftPolicyDecision(row);
    const scopedUser = SessionStore.isUserScoped();

    let draftPolicyDecision = policyDecision;
    let subtext;
    let link;

    if (policyDecision === intl('Common.Allowed')) {
      link = _.partial(this.viewRule, row.rules, scopedUser || !boundary ? [] : row.denyRules);

      subtext = boundary ? intl('Explorer.AcrossDenyRules') : intl('Explorer.ByRule');
    } else if (boundary) {
      // Blocked
      link = scopedUser ? null : _.partial(this.viewRule, row.rules, row.denyRules);
      subtext = intl('Explorer.ByDenyRules');
    } else if (row.rules) {
      // If we've retrieved the draft rules, but none existed
      subtext = intl('Explorer.NoRule');
    } else {
      draftPolicyDecision = '';
      subtext = '';
    }

    return {policyDecision: draftPolicyDecision, subtext, link};
  },

  getReportedPolicyDecisionDetail(row) {
    const {policy, boundaryDecision} = ExplorerUtils.getReportedPolicyDecision(row);
    const by = row.flow_direction === 'outbound' ? intl('Common.Source') : intl('Common.Destination');
    const boundary = boundaryDecision ? intl('Common.Boundary') : '';
    const subtext =
      policy === intl('Common.Unknown')
        ? ''
        : (row.src_type === 'aggregated'
            ? boundary
              ? [intl('Common.By'), boundary]
              : []
            : [intl('Common.By'), by, boundary]
          )
            .filter(s => s)
            .join(' ');

    return {policyDecision: policy, subtext};
  },

  render() {
    const {
      dnsAddresses,
      dnsResolveInProcess,
      dnsResolved,
      draftInProgress,
      policy,
      addresses,
      providerConsumerOrder,
      workloadEdit,
      selection,
      disableServices,
      asyncEnabled,
    } = this.state;

    const {
      disableHover,
      draft,
      fqdn,
      exportable,
      onEdit,
      loadedQuery,
      withFilter,
      blockedDraft,
      blockedConnections,
      banner,
      showData,
      hideDraftSpinner,
      type,
      aggregationLevel,
    } = this.props;

    const sortingItems = {
      provider: coreProviderSortingItems()[this.state.sorting[0].key] || {
        key: 'dst_loc',
        label: <div className="Explorer-Table-Header">{intl('Explorer.ProviderLabels')}</div>,
      },
      consumer: coreConsumerSortingItems()[this.state.sorting[0].key] || {
        key: 'src_loc',
        label: <div className="Explorer-Table-Header">{intl('Explorer.ConsumerLabels')}</div>,
      },
    };
    const workloadSortingItems = {
      provider: providerWorkloadSortingItems()[this.state.sorting[0].key] || {
        key: 'dst_ip',
        label: (
          <div className="Explorer-Table-Header">{[intl('Common.Destination'), <br />, intl('Common.IPAddress')]}</div>
        ),
      },
      consumer: consumerWorkloadSortingItems()[this.state.sorting[0].key] || {
        key: 'src_ip',
        label: <div className="Explorer-Table-Header">{[intl('Common.Source'), <br />, intl('Common.IPAddress')]}</div>,
      },
    };

    const actionOptions = [
      {value: intl('Map.ReportedView'), key: 'reported'},
      {value: intl('Map.DraftView'), key: 'draftSection', disabled: true},
      {value: intl('Common.DraftAll'), key: 'draft'},
      {value: intl('Common.Blocked'), key: 'draftBlocked'},
      {value: intl('Common.Allowed'), key: 'draftAllowed'},
    ];

    const blockedActionOptions = [
      {value: intl('Map.ReportedView'), key: 'reported'},
      {value: intl('Map.DraftView'), key: 'draftBlocked'},
    ];

    const columns = [
      {
        key: policy.includes(intl('Common.Reported'))
          ? (policySortingItems()[this.state.sorting[0].key] || policySortingItems().policy).key
          : 'rules',
        label: policy.includes(intl('Common.Reported'))
          ? (policySortingItems()[this.state.sorting[0].key] || policySortingItems().policy).label
          : policySortingItems().rules.label,
        sortValue: (value, row) => {
          const {policyDecision, subtext} = policy.includes(intl('Common.Reported'))
            ? this.getReportedPolicyDecisionDetail(row)
            : this.getDraftPolicyDecisionDetail(row);

          return `${policyDecision} ${subtext}`.trimEnd();
        },
        style: 'policy',
        sortable: true,
        sortFunction: (rowA, rowB) => {
          let valueA;
          let valueB;

          if (policy.includes(intl('Common.Reported'))) {
            valueA = rowA.policy;
            valueB = rowB.policy;
          } else {
            valueA = rowA.rules ? rowA.rules.length : -1;
            valueB = rowB.rules ? rowB.rules.length : -1;
          }

          if (valueA === valueB) {
            return rowB.connectionKey > rowA.connectionKey ? 1 : rowB.connectionKey < rowA.connectionKey ? -1 : 0;
          }

          return valueB > valueA ? 1 : valueB < valueA ? -1 : 0;
        },
        format: (value, row) => {
          const reportedView = policy.includes(intl('Common.Reported'));

          let reportedPolicy = null;
          let reportedSubtext = null;
          let draftPolicy = null;
          let draftSubtext = null;

          if (reportedView) {
            const {policyDecision, subtext} = this.getReportedPolicyDecisionDetail(row);

            reportedPolicy = <div className={ExplorerUtils.getPolicyClass(policyDecision)}>{policyDecision}</div>;

            if (subtext) {
              reportedSubtext = <div className="Explorer-subText">{subtext}</div>;
            }
          } else {
            // Draft View
            const {policyDecision, subtext, link} = this.getDraftPolicyDecisionDetail(row);

            const className = ExplorerUtils.getPolicyClass(policyDecision);

            if (link) {
              draftPolicy = (
                <div className={`${className} Explorer-link`} onClick={link}>
                  {policyDecision}
                </div>
              );
            } else {
              draftPolicy = <div className={className}>{policyDecision}</div>;
            }

            draftSubtext = <div className="Explorer-subText">{subtext}</div>;
          }

          return [reportedPolicy, reportedSubtext, draftPolicy, draftSubtext];
        },
      },
      ...ProviderConsumerUtils.setProviderConsumerWithLabelsColumnOrder(
        {
          key: workloadSortingItems.provider.key,
          style: 'hostname-provider',
          label: intl('Common.Destination'),
          format: (value, row) => this.handleExpandableItem(value, row, 'providerItem'),
          sortFunction: (rowA, rowB) => {
            if (rowA.dst_type === 'aggregated') {
              return (
                -1 * (rowA.dst_ips.size - rowB.dst_ips.size) ||
                (rowB.connectionKey > rowA.connectionKey ? 1 : rowB.connectionKey < rowA.connectionKey ? -1 : 0)
              );
            }

            const valueA =
              workloadSortingItems.provider.key === 'dst_domain'
                ? (rowA[workloadSortingItems.provider.key] || dnsAddresses[rowA.dst_ip] || '\uFFFF')
                    .toLocaleLowerCase()
                    .split('.')
                    .reverse()
                    .join('')
                : (rowA[workloadSortingItems.provider.key] || '\uFFFF').toLocaleLowerCase();
            const valueB =
              workloadSortingItems.provider.key === 'dst_domain'
                ? (rowB[workloadSortingItems.provider.key] || dnsAddresses[rowB.dst_ip] || '\uFFFF')
                    .toLocaleLowerCase()
                    .split('.')
                    .reverse()
                    .join('')
                : (rowB[workloadSortingItems.provider.key] || '\uFFFF').toLocaleLowerCase();

            if (valueA === valueB) {
              return rowB.connectionKey > rowA.connectionKey ? 1 : rowB.connectionKey < rowA.connectionKey ? -1 : 0;
            }

            return valueB > valueA ? 1 : valueB < valueA ? -1 : 0;
          },
          sortingItems: aggregationLevel === 'labels' ? null : Object.values(providerWorkloadSortingItems()),
          sortable: true,
        },
        {
          key: sortingItems.provider.key,
          label: sortingItems.provider.label,
          style: 'table-labels-provider',
          format: (value, row) => {
            if (!row.dst_labels) {
              return [
                row.dst_role ? <Label text={row.dst_role} type="role" /> : null,
                row.dst_app ? <Label text={row.dst_app} type="app" /> : null,
                row.dst_env ? <Label text={row.dst_env} type="env" /> : null,
                row.dst_loc ? <Label text={row.dst_loc} type="loc" /> : null,
              ];
            }

            const labelActionData = Object.assign(
              {},
              ...row.dst_labels.map(item => ({[item.key]: {...item, icon: item.key}})),
            );

            const labelData = row.dst_labels.map(label => (
              <HoverMenu
                menuItems={this.state.andOrValue === 'and' ? providerMenuItems() : consumerOrProviderMenuItems()}
                labelActionData={{...labelActionData[label.key]}}
                labelProps={{text: label.value}}
                type={label.key}
                labelType={label.key}
                disabled={disableHover || !['app', 'env', 'loc', 'role'].includes(label.key)}
              />
            ));

            const expanded = this.state.expanded.has([row.index, 'dst'].join(','));

            return (
              <ExpandableGridDataList
                data={labelData}
                expanded={expanded}
                href={[row.index, 'dst'].join(',')}
                maxDisplay={8}
                onExpandCollapse={this.handleExpandCollapse}
                expandMessage={intl('Explorer.MoreItems', {numLists: labelData.length - 8})}
                collapseMessage={intl('Common.ShowLess')}
              />
            );
          },
          sortingItems: Object.values(coreProviderSortingItems()),
          sortFunction: (rowA, rowB) => {
            const valueA = rowA[sortingItems.provider.key]
              ? rowA[sortingItems.provider.key].toLocaleLowerCase()
              : '\uFFFF';
            const valueB = rowB[sortingItems.provider.key]
              ? rowB[sortingItems.provider.key].toLocaleLowerCase()
              : '\uFFFF';

            if (valueA === valueB) {
              return rowB.connectionKey > rowA.connectionKey ? 1 : rowB.connectionKey < rowA.connectionKey ? -1 : 0;
            }

            return valueB > valueA ? 1 : valueB < valueA ? -1 : 0;
          },
          sortable: true,
        },
        {
          key: 'port',
          style: 'port',
          label: (
            <div className="Explorer-Table-Header">
              {intl('Port.PortProcess')}
              <div>{`[${intl('Users.User')}]`}</div>
            </div>
          ),
          format: (value, row) => {
            if (row.protocol === intl('Protocol.IPv4') || row.protocol === intl('Protocol.IPv6')) {
              row.protocol = `${row.protocol} ${intl('Explorer.Encap')}`;
            }

            const portProtocol = `${ServiceUtils.getPort(row) || ''} ${row.protocol}`;
            const {processName, windowsService, username} = ExplorerUtils.getEndpointService(
              row,
              'inbound',
              aggregationLevel,
            );
            const expanded = this.state.expanded.has([row.index, 'policyService'].join(','));
            let services;

            if (!disableServices && row.services && row.services.length) {
              const data = row.services.map(service => (
                <HoverMenu
                  menuItems={portProtocolMenuItems()}
                  iconType="service"
                  disabled={disableHover}
                  labelActionData={{
                    key: 'policyService',
                    name: service.name,
                    href: service.href,
                    value: service.service_ports || service.windows_services,
                  }}
                  labelProps={{text: service.name, iconName: 'service'}}
                  type="policyService"
                />
              ));

              services = (
                <span className="hover-menu-ipmargin hover-menu-expandable">
                  <ExpandableGridDataList
                    data={data}
                    expanded={expanded}
                    href={[row.index, 'policyService'].join(',')}
                    maxDisplay={3}
                    onExpandCollapse={this.handleExpandCollapse}
                    expandMessage={intl('Explorer.MoreItems', {numLists: data.length - 3})}
                    collapseMessage={intl('Common.ShowLess')}
                  />
                </span>
              );
            }

            return [
              portProtocol ? (
                <HoverMenu
                  menuItems={portProtocolMenuItems()}
                  noLabel
                  disabled={portProtocol.includes('Protocol:') ? true : disableHover}
                  labelActionData={{key: 'portProtocol', value: portProtocol, href: portProtocol}}
                  labelProps={{text: portProtocol}}
                  type="portProtocol"
                />
              ) : null,
              isNaN(row.icmpType) ? null : (
                <div>
                  {ServiceUtils.lookupICMPCode(row.icmpType, row.protocol) ||
                    intl('Explorer.ICMPType', {type: row.icmpType})}
                </div>
              ),
              row.icmpCode && (isNaN(row.icmpType) || row.icmpType !== row.icmpCode) ? (
                <div>
                  {ServiceUtils.lookupICMPCode(row.icmpCode, row.protocol) ||
                    intl('Explorer.ICMPCode', {code: row.icmpCode})}
                </div>
              ) : null,
              processName ? (
                <HoverMenu
                  menuItems={portProtocolMenuItems()}
                  noLabel
                  disabled={disableHover}
                  labelActionData={{key: 'processName', value: processName, href: processName}}
                  labelProps={{text: processName}}
                  type="processName"
                />
              ) : null,
              windowsService ? (
                <HoverMenu
                  menuItems={portProtocolMenuItems()}
                  noLabel
                  disabled={disableHover}
                  labelActionData={{key: 'windowsService', value: windowsService, href: windowsService}}
                  labelProps={{text: row.windowsService}}
                  type="windowsService"
                />
              ) : null,
              username ? <div className="Explorer-username">{`[${username}]`}</div> : null,
              services,
            ];
          },
          sortable: true,
          sortFunction: (rowA, rowB) => {
            let valueA = rowA.port;
            let valueB = rowB.port;

            if (valueA === valueB) {
              valueA = rowA.connectionKey;
              valueB = rowB.connectionKey;
            }

            return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
          },
        },
        {
          key: 'consumer_to_provider_arrow',
          style: 'consumerToProviderArrow',
          format: (value, row, arrowDirection) => this.formatArrow(row, arrowDirection),
        },
        {
          key: workloadSortingItems.consumer.key,
          style: 'hostname',
          label: intl('Common.Source'),
          format: (value, row) => this.handleExpandableItem(value, row, 'consumerItem'),
          sortFunction: (rowA, rowB) => {
            if (rowA.src_type === 'aggregated') {
              return (
                -1 * (rowA.src_ips.size - rowB.src_ips.size) ||
                (rowB.connectionKey > rowA.connectionKey ? 1 : rowB.connectionKey < rowA.connectionKey ? -1 : 0)
              );
            }

            const valueA = (rowA[workloadSortingItems.consumer.key] || '\uFFFF').toLocaleLowerCase();
            const valueB = (rowB[workloadSortingItems.consumer.key] || '\uFFFF').toLocaleLowerCase();

            // If the sort values are the same, use the connection key as the secondary sort
            // to pin identical flow together, making it easier to evaluate timestamps/policy state etc
            if (valueA === valueB) {
              return rowB.connectionKey > rowA.connectionKey ? 1 : rowB.connectionKey < rowA.connectionKey ? -1 : 0;
            }

            return valueB > valueA ? 1 : valueB < valueA ? -1 : 0;
          },
          sortingItems: aggregationLevel === 'labels' ? null : Object.values(consumerWorkloadSortingItems()),
          sortable: true,
        },
        {
          key: sortingItems.consumer.key,
          label: sortingItems.consumer.label,
          style: 'table-labels',
          format: (value, row) => {
            if (!row.src_labels) {
              return [
                row.src_role ? <Label text={row.src_role} type="role" /> : null,
                row.src_app ? <Label text={row.src_app} type="app" /> : null,
                row.src_env ? <Label text={row.src_env} type="env" /> : null,
                row.src_loc ? <Label text={row.src_loc} type="loc" /> : null,
              ];
            }

            const labelActionData = Object.assign(
              {},
              ...row.src_labels.map(item => ({[item.key]: {...item, icon: item.key}})),
            );

            const labelData = row.src_labels.map(label => (
              <HoverMenu
                menuItems={this.state.andOrValue === 'and' ? providerMenuItems() : consumerOrProviderMenuItems()}
                labelActionData={{...labelActionData[label.key]}}
                labelProps={{text: label.value}}
                type={label.key}
                labelType={label.key}
                disabled={disableHover || !['app', 'env', 'loc', 'role'].includes(label.key)}
              />
            ));

            const expanded = this.state.expanded.has([row.index, 'dst'].join(','));

            return (
              <ExpandableGridDataList
                data={labelData}
                expanded={expanded}
                href={[row.index, 'dst'].join(',')}
                maxDisplay={8}
                onExpandCollapse={this.handleExpandCollapse}
                expandMessage={intl('Explorer.MoreItems', {numLists: labelData.length - 8})}
                collapseMessage={intl('Common.ShowLess')}
              />
            );
          },
          sortingItems: Object.values(coreConsumerSortingItems()),
          sortFunction: (rowA, rowB) => {
            const valueA = rowA[sortingItems.consumer.key]
              ? rowA[sortingItems.consumer.key].toLocaleLowerCase()
              : '\uFFFF';
            const valueB = rowB[sortingItems.consumer.key]
              ? rowB[sortingItems.consumer.key].toLocaleLowerCase()
              : '\uFFFF';

            if (valueA === valueB) {
              return rowB.connectionKey > rowA.connectionKey ? 1 : rowB.connectionKey < rowA.connectionKey ? -1 : 0;
            }

            return valueB > valueA ? 1 : valueB < valueA ? -1 : 0;
          },
          sortable: true,
        },
        {
          key: 'processName',
          style: 'consumer-process',
          label: (
            <div className="Explorer-Table-Header">
              {intl('Port.ConsumerProcess')}
              <div>{`[${intl('Users.User')}]`}</div>
            </div>
          ),
          format: (value, row) => {
            const {processName, windowsService, username} = ExplorerUtils.getEndpointService(
              row,
              'outbound',
              aggregationLevel,
            );

            return [
              processName ? (
                <HoverMenu
                  menuItems={portProtocolMenuItems()}
                  noLabel
                  disabled={disableHover}
                  labelActionData={{key: 'processName', value: processName, href: processName}}
                  labelProps={{text: processName}}
                  type="processName"
                />
              ) : null,
              windowsService ? (
                <HoverMenu
                  menuItems={portProtocolMenuItems()}
                  noLabel
                  disabled={disableHover}
                  labelActionData={{key: 'windowsService', value: windowsService, href: windowsService}}
                  labelProps={{text: windowsService}}
                  type="windowsService"
                />
              ) : null,
              username ? <div className="Explorer-username">{`[${username}]`}</div> : null,
            ];
          },
          sortable: true,
          sortFunction: (rowA, rowB) => {
            let valueA = (rowA.flow_direction === 'outbound' && (rowA.windowsService || rowA.processName)) || '';
            let valueB = (rowB.flow_direction === 'outbound' && (rowB.windowsService || rowB.processName)) || '';

            if (valueA === valueB) {
              valueA = rowA.connectionKey;
              valueB = rowB.connectionKey;
            }

            return valueB > valueA ? 1 : valueB < valueA ? -1 : 0;
          },
        },
        providerConsumerOrder,
      ),
      {
        key: 'numFlows',
        format: (value, row) => [
          ...(row.connectionCount
            ? [
                <div className="Explorer-Table-Connections">
                  {intl('PolicyGenerator.Connections', {count: row.connectionCount})}
                </div>,
              ]
            : []),
          <div>{intl('Explorer.FlowCount', {count: row.numFlows})}</div>,
          row.byteOut ? (
            <div>
              {String(ExplorerUtils.formatBytes(row.byteOut, 1))}
              <Icon name="arrow-right" helpText="Bytes Out" position="after" styleClass="ByteInOut" size="large" />
            </div>
          ) : null,
          row.byteIn ? (
            <div>
              {String(ExplorerUtils.formatBytes(row.byteIn, 1))}
              <Icon name="arrow-left" helpText="Bytes In" position="after" styleClass="ByteInOut" size="large" />
            </div>
          ) : null,
        ],
        style: 'flows',
        label: <div className="Explorer-Table-Header">{`${intl('Common.Flows')}/${intl('Common.Bytes')}`}</div>,
        sortable: true,
        sortFunction: (rowA, rowB) => {
          const valueA = rowA.numFlows;
          const valueB = rowB.numFlows;

          if (valueA === valueB) {
            return rowA.connectionKey > rowB.connectionKey ? 1 : rowA.connectionKey < rowB.connectionKey ? -1 : 0;
          }

          return valueA - valueB;
        },
      },
      {
        key: 'profile',
        format: value => (value !== '[null]' && value ? value : ''),
        style: 'network',
        label: <div className="Explorer-Table-Header">{intl('SystemSettings.Network')}</div>,
        sortable: true,
      },
      {
        key: 'firstDetected',
        format: value => [intl.date(value, 'L'), ' ', intl.date(value, 'HH_mm_ss')],
        style: 'date-wrap',
        label: <div className="Explorer-Table-Header">{intl('Explorer.FirstDetected')}</div>,
        sortable: true,
      },
      {
        key: 'lastDetected',
        format: value => [intl.date(value, 'L'), ' ', intl.date(value, 'HH_mm_ss')],
        style: 'date-wrap',
        label: <div className="Explorer-Table-Header">{intl('BlockedTraffic.List.LastDetected')}</div>,
        sortable: true,
      },
    ];

    if (localStorage.getItem('show_connection_state')) {
      columns.splice(1, 0, {
        key: 'state',
        style: 'state',
        label: intl('Common.ConnectionState'),
        sortable: true,
        sortFunction: (rowA, rowB) => {
          let valueA = rowA.state;
          let valueB = rowB.state;

          if (valueA === valueB) {
            valueA = rowA.connectionKey;
            valueB = rowB.connectionKey;
          }

          return valueB > valueA ? 1 : valueB < valueA ? -1 : 0;
        },
      });
    }

    let providerCount = this.state.providerWorkloads.length;
    let consumerCount = this.state.consumerWorkloads.length;

    providerCount = providerCount > MAX_EDIT_LABELS ? 0 : providerCount;
    consumerCount = consumerCount > MAX_EDIT_LABELS ? 0 : consumerCount;

    const labelOptions = [
      {
        value: 'provider',
        label: (
          <div
            data-tid="labels"
            className={`ButtonDropdown-labels${providerCount ? '' : ' ButtonDropdown-labels--disabled'}`}
          >
            <div data-tid="button-dropdown-title" className="ButtonDropdown-title">
              {this.state.providerWorkloads.length > MAX_EDIT_LABELS
                ? intl('Labels.TooManySelected')
                : intl('Labels.ProviderEdit')}
            </div>
            {providerCount ? (
              <div className="Labels-counter" data-tid="count">
                {intl.num(providerCount)}
              </div>
            ) : null}
          </div>
        ),
      },
      {
        value: 'consumer',
        label: (
          <div
            data-tid="labels"
            className={`ButtonDropdown-labels${consumerCount ? '' : ' ButtonDropdown-labels--disabled'}`}
          >
            <div data-tid="button-dropdown-title" className="ButtonDropdown-title">
              {this.state.consumerWorkloads.length > MAX_EDIT_LABELS
                ? intl('Labels.TooManySelected')
                : intl('Labels.ConsumerEdit')}
            </div>
            {consumerCount ? (
              <div className="Labels-counter" data-tid="count">
                {intl.num(consumerCount)}
              </div>
            ) : null}
          </div>
        ),
      },
    ];

    const links = this.getFilteredLinks(this.props.links);
    const exceededResultsLimit = ExplorerStore.getExceededResultsLimit();

    const pagination =
      links.length && (!banner || showData) ? (
        <ToolGroup>
          <Pagination
            page={this.state.currentPage}
            totalRows={links.length}
            isFiltered={links.length !== this.props.links}
            count={{total: this.props.links.length, matches: links.length}}
            pageLength={MAX_RESULTS_PER_PAGE}
            onPageChange={this.handlePageChange}
            type={aggregationLevel === 'labels' ? 'label' : 'connections'}
            exceededResultsLimit={exceededResultsLimit}
          />
        </ToolGroup>
      ) : null;

    const timestamp = loadedQuery?.updated_at ? (
      <div className="Explorer-table-timestamp">
        <span className="Explorer-format-label">{intl('Common.Timestamp')}:</span>
        {intl.date(loadedQuery.created_at, 'L_HH_mm_ss')}
      </div>
    ) : null;

    const policySelection = draft && (
      <div className="Explorer-PolicySelect">
        <Select
          options={blockedDraft ? blockedActionOptions : actionOptions}
          value={policy}
          onChange={_.partial(this.handleActionChange, blockedDraft)}
          formatResult={_.partial(this.formatPolicyResult, blockedDraft)}
          formatSelection={_.partial(this.formatPolicySelection, blockedDraft)}
          tid="action"
        />
      </div>
    );

    const draftSelection = (
      <div className="Explorer-DraftSelect">
        <Select
          options={draftOptions(policy)}
          value={draftOptionMap()[aggregationLevel]}
          onChange={this.handleDraftChange}
          formatSelection={this.formatDraftSelection}
          tid="action"
        />
      </div>
    );

    const editLabels = onEdit && (
      <ButtonDropdown
        text={intl('Labels.Edit')}
        options={labelOptions}
        disabled={(!providerCount && !consumerCount) || !workloadEdit}
        tid="editlabels"
        type="secondary"
        onSelect={this.handleEditLabels}
        counter={workloadEdit ? providerCount + consumerCount : 0}
      />
    );

    const resolveFqdns = fqdn && (
      <Button
        text={intl('Explorer.ResolveUnknownFqdns')}
        onClick={this.handleResolveDomains}
        autoFocus={true}
        disabled={!addresses.length || dnsResolved || dnsResolveInProcess}
        type="secondary"
        tid="resolveUnknownDomains"
      />
    );

    const exportButton = exportable && (
      <Button
        text={intl('Common.Export')}
        disabled={draftInProgress || !this.props.links.length}
        tid="export"
        type="secondary"
        onClick={this.handleExport}
      />
    );

    const numRulesToWrite = links.filter(
      link =>
        selection.includes(link.index) &&
        link.rules &&
        !link.rules.length &&
        link.ruleWritingAvailable &&
        !link.protocol.includes('Protocol'),
    ).length;

    const ruleWritingDisabled = policy === intl('Map.ReportedView') || aggregationLevel === 'workloads';
    let tooltipContent;

    if (policy === intl('Map.ReportedView') && aggregationLevel === 'workloads') {
      tooltipContent = intl('Explorer.EnableRuleWritingWithDraftAndLabels');
    } else if (policy === intl('Map.ReportedView')) {
      tooltipContent = intl('Explorer.EnableRuleWritingWithDraftView');
    } else if (aggregationLevel === 'workloads') {
      tooltipContent = intl('Explorer.EnableRuleWritingWithLabelBased');
    } else if (numRulesToWrite) {
      tooltipContent = intl('Explorer.ClickToStartWritingRules');
    } else {
      tooltipContent = intl('Explorer.SelectConnectionsToStartWritingRules');
    }

    const ruleCreation =
      asyncEnabled && SessionStore.isRuleWritingEnabled() ? (
        <Tooltip
          content={tooltipContent}
          location="bottomright"
          width={tooltipContent === intl('Explorer.ClickToStartWritingRules') ? 200 : 290}
        >
          <Button
            text={intl('Explorer.AllowSelectedConnections')}
            disabled={ruleWritingDisabled || !numRulesToWrite}
            tid="create-rules"
            type={ruleWritingDisabled ? 'secondary' : 'primary'}
            onClick={this.handleCreateRules}
            counter={ruleWritingDisabled ? 0 : numRulesToWrite}
          />
        </Tooltip>
      ) : null;

    const refreshDraftRules = (
      <Button
        text={intl('Explorer.RefreshDraft')}
        disabled={policy === intl('Map.ReportedView') || !this.props.links.length}
        tid="refresh-rules"
        type="secondary"
        onClick={this.handleRefreshRules}
      />
    );

    let tools = null;

    if (type === 'boundaries') {
      tools = (
        <ToolBar>
          <ToolGroup>
            {policySelection}
            {draftSelection}
            {ruleCreation}
            {refreshDraftRules}
            {draftInProgress && !hideDraftSpinner ? <Spinner size="twenty" color="dark" /> : null}
          </ToolGroup>
          <ToolGroup>
            {pagination}
            {blockedConnections ? <div className="Boundary-Export">{exportButton}</div> : null}
          </ToolGroup>
        </ToolBar>
      );
    } else {
      tools = [
        <ToolBar>
          <ToolGroup>
            {policySelection}
            {draftSelection}
          </ToolGroup>
          <ToolGroup>{type === 'appgroup' ? pagination : timestamp}</ToolGroup>
        </ToolBar>,
        <ToolBar>
          <ToolGroup>
            {ruleCreation}
            {refreshDraftRules}
            {editLabels}
            {resolveFqdns}
            {exportButton}
            {dnsResolveInProcess || draftInProgress ? <Spinner size="twenty" color="dark" /> : null}
          </ToolGroup>
          <ToolGroup>{type === 'appgroup' ? timestamp : pagination}</ToolGroup>
        </ToolBar>,
      ];
    }

    return (
      <div className={`ExplorerTable${withFilter ? '' : ' ExplorerTable-Header-small'}`} data-tid="explorer-table">
        {tools}
        <div className="LinkTable">
          {banner}
          {!banner || showData ? (
            <Grid
              columns={columns}
              data={[...links]}
              selectable={!draftInProgress && ((workloadEdit && onEdit) || (ruleCreation && !ruleWritingDisabled))}
              onRowSelectToggle={this.handleRowSelect}
              idField="index"
              selection={this.state.selection}
              sorting={this.state.sorting}
              sortable={true}
              sortDirection={false}
              onSort={this.handleSort}
              currentPage={this.state.currentPage}
              resultsPerPage={MAX_RESULTS_PER_PAGE}
              totalRows={links.length}
              emptyContent={null}
            />
          ) : null}
        </div>
        <div className="LinkTable-Position-Right">
          <ToolBar>{pagination}</ToolBar>
        </div>
      </div>
    );
  },
});
