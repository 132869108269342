/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {ExplorerUtils} from '.';

const modeMap = {
  idle: intl('Common.Idle'),
  selective: intl('EnforcementBoundaries.SelectiveEnforcement'),
  visibility_only: intl('Common.VisibilityOnly'),
  full: intl('Workloads.FullEnforcement'),
};

const getEndpointInfo = (link, endpoint) => {
  const ip = link[[endpoint, 'ip'].join('_')];
  const ipLists = link[[endpoint, 'ip_lists'].join('_')];
  const name = link[[endpoint, 'name'].join('_')];
  const hostname = link[[endpoint, 'hostname'].join('_')];
  const mode = link[[endpoint, 'mode'].join('_')];

  return [
    ip,
    ipLists ? ipLists.map(ipList => ipList.name).join('; ') : '',
    name ? `"${name}"` : null,
    hostname ? `"${hostname}"` : null,
    modeMap[mode] || '',
  ];
};

const getDraftInfo = link => {
  const policyDecision = ExplorerUtils.getDraftPolicyDecision(link);
  const boundary = ExplorerUtils.getDraftBoundaryInfo(link);

  const draftInfo = [policyDecision];

  if (policyDecision === intl('Common.Allowed')) {
    draftInfo.push(boundary ? intl('Explorer.AcrossDenyRules') : '');
  } else {
    draftInfo.push(boundary ? intl('Explorer.ByDenyRules') : '');
  }

  return draftInfo;
};

const getDirection = link => {
  return link.policy === 'Unknown'
    ? null
    : link.flow_direction === 'outbound'
    ? intl('Common.Source')
    : intl('Common.Destination');
};

export function getCSVData(tableData, dnsAddresses, draft) {
  const links = tableData.map(link => {
    const linkDataMap = {
      srcEndpoint: getEndpointInfo(link, 'src'),
      srcLabels: [`"${link.src_role}"`, `"${link.src_app}"`, `"${link.src_env}"`, `"${link.src_loc}"`],
      srcDns: [dnsAddresses[link.src_ip]],
      srcService: Object.values(ExplorerUtils.getEndpointService(link, 'outbound', 'workloads')),
      dstEndpoint: getEndpointInfo(link, 'dst'),
      dstLabels: [`"${link.dst_role}"`, `"${link.dst_app}"`, `"${link.dst_env}"`, `"${link.dst_loc}"`],
      dstDns: [link.dst_domain || dnsAddresses[link.src_ip]],
      dstTransmission: [link.dst_transmission],
      dstPort: [link.port, link.protocol],
      dstService: Object.values(ExplorerUtils.getEndpointService(link, 'inbound', 'workloads')),
      flows: [link.numFlows],
      connState: [link.state === 'Unknown' ? '' : link.state],
      reportedPolicy: [link.policy],
      networkProfile: link.network.name === 'External' ? [intl('Common.ExternalNonCorporate')] : [link.network.name],
      reportedBoundary: [ExplorerUtils.getReportedBoundaryInfo(link) ? intl('Explorer.ByDenyRules') : ''],
      draftPolicy: draft ? getDraftInfo(link) : [],
      direction: [getDirection(link)],
      dates: [
        intl.date(link.firstDetected, 'L_HH_mm_ss').replace(',', ' '),
        intl.date(link.lastDetected, 'L_HH_mm_ss').replace(',', ' '),
      ],
    };

    return Object.values(linkDataMap)
      .reduce((result, data) => {
        result.push(...data);

        return result;
      }, [])
      .join(',');
  });

  const header = [
    intl('Explorer.ConsumerIP'),
    intl('Explorer.ConsumerIPList'),
    intl('Explorer.ConsumerName'),
    intl('Explorer.ConsumerHostname'),
    intl('Explorer.ConsumerEnforcement'),
    intl('Explorer.ConsumerRole'),
    intl('Explorer.ConsumerApp'),
    intl('Explorer.ConsumerEnv'),
    intl('Explorer.ConsumerLoc'),
    intl('Common.ConsumerFqdn'),
    intl('Rulesets.Rules.ConsumingProcess'),
    intl('Rulesets.Rules.ConsumingService'),
    intl('Rulesets.Rules.ConsumingUsername'),
    intl('Explorer.ProviderIP'),
    intl('Explorer.ProviderIPList'),
    intl('Explorer.ProviderName'),
    intl('Explorer.ProviderHostname'),
    intl('Explorer.ProviderEnforcement'),
    intl('Explorer.ProviderRole'),
    intl('Explorer.ProviderApp'),
    intl('Explorer.ProviderEnv'),
    intl('Explorer.ProviderLoc'),
    intl('Common.ProviderFqdn'),
    intl('Components.Transmission'),
    intl('Port.Port'),
    intl('Common.Protocol'),
    intl('Rulesets.Rules.ProvidingProcess'),
    intl('Rulesets.Rules.ProvidingService'),
    intl('Rulesets.Rules.ProvidingUsername'),
    intl('Explorer.NumFlows'),
    intl('Common.ConnectionState'),
    intl('Common.ReportedPolicy'),
    intl('Common.NetworkProfile'),
    intl('Common.ReportedEnforcementBoundary'),
  ];

  if (draft) {
    header.push(intl('Common.DraftPolicy'), intl('Common.DraftEnforcementBoundary'));
  }

  header.push(intl('Explorer.ReportedBy'), intl('Explorer.FirstDetected'), intl('BlockedTraffic.List.LastDetected'));

  links.unshift(header.join(','));

  return links.join('\r\n');
}

export function getUnmanagedIpCSVData(dnsAddresses, tableData) {
  const links = tableData.map(link =>
    [
      link.address,
      link.domain || dnsAddresses[link.address],
      link.transmission,
      link.ports.map(port => port.trim().split(' ').join('; ')).join(' | '),
      link.direction,
      link.workloads.length,
      link.flows,
    ].join(','),
  );

  links.unshift(
    [
      intl('Common.IPAddress'),
      intl('PCE.FQDN'),
      intl('Components.Transmission'),
      intl('Port.PortProcess'),
      intl('TrafficEvents.Direction'),
      intl('Common.Workloads'),
      intl('Common.Flows'),
    ].join(','),
  );

  return links.join('\r\n');
}

export function getUnmanagedFQDNCSVData(dnsAddresses, tableData) {
  const links = tableData.map(link =>
    [
      link.domain,
      link.ports.map(port => port.trim().split(' ').join('; ')).join(' | '),
      link.address.join(' | '),
      link.transmission,
      link.workloads.length,
      link.flows,
    ].join(','),
  );

  links.unshift(
    [
      intl('PCE.FQDN'),
      intl('Port.PortProcess'),
      intl('Common.IPAddress'),
      intl('Components.Transmission'),
      intl('Common.Workloads'),
      intl('Common.Flows'),
    ].join(','),
  );

  return links.join('\r\n');
}

export default {
  getCSVData,
  getUnmanagedIpCSVData,
  getUnmanagedFQDNCSVData,
};
